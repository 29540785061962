import React, { FC } from 'react'
import { IThirdTab } from '../../../interfaces/IVisitReports'
import {
  VISIT_MISSIONS_V2,
  VISIT_SECOND_PANEL_MISSIONS_V2,
} from '../../../constants/visitReport'
import { VisitReportSecondPanelMission } from './commonComponents/VisitReportSecondPanelMission'

export const VisitReportSecondPanel: FC<IThirdTab> = ({
  defaultData,
  plvOptions,
}) => {
  return (
    <div className="row">
      <VisitReportSecondPanelMission
        mission={VISIT_MISSIONS_V2.type1}
        missionData={VISIT_SECOND_PANEL_MISSIONS_V2.type1}
        plvOptions={plvOptions}
        defaultData={defaultData}
        customClass="hasBorderRight"
      />
      <VisitReportSecondPanelMission
        mission={VISIT_MISSIONS_V2.type2}
        missionData={VISIT_SECOND_PANEL_MISSIONS_V2.type2}
        plvOptions={plvOptions}
        defaultData={defaultData}
      />
      <VisitReportSecondPanelMission
        mission={VISIT_MISSIONS_V2.type3}
        missionData={VISIT_SECOND_PANEL_MISSIONS_V2.type3}
        plvOptions={plvOptions}
        defaultData={defaultData}
        customClass="hasBorderRight"
      />
    </div>
  )
}
