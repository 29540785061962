import React from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import { useClientsDetails } from '../../hooks/clientDetails/UseClientDetails'

export const FormsPageTitle = ({ clientId }: { clientId: number }) => {
  const { name, cipCode } = useClientsDetails(
    clientId.toString()
  )?.clientDetailsData

  return (
    <>
      {name && (
        <>
          <section className="pageTitleWrapper">
            <h1 className="pageTitle">
              <DynamicFormattedMessage tag="span" id="page.forms.title.label" />
            </h1>
          </section>
          <h4 className="mt2 mb1 textMd textSecondary text">
            <span className="pr05">{name}</span>
            <ClientCipCode cipCode={cipCode} />
          </h4>
        </>
      )}
    </>
  )
}
