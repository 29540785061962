import { format, utcToZonedTime } from 'date-fns-tz'
import {
  IEngagementHistory,
  IHistoryAnnualTotal,
  IHistoryTableRow,
  IOrderHistory,
  IRecapHistory,
  IRecapPeriod,
  IRecapPeriodProcessed,
  IRecapProducts,
  IRecapSummary,
  THistoryViewType,
} from '../interfaces/IHistory'
import { HISTORY_DATE_FORMAT, HISTORY_DATE_TIME_FORMAT } from '../enums/common'
import {
  KEY_CURRENT_YEAR,
  KEY_PREVIOUS_YEAR,
  TYPE_PRODUCT,
  TYPE_SUMMARY,
  VIEW_TYPE,
  VIEW_TYPE_PERIODS,
} from '../constants/tables'
import { roundTwoDecimals, currentYearValue } from './helpers'

export const prepareEngagementHistoryResponse = (data: IEngagementHistory[]) =>
  data.map(
    ({ contractLabel, category, date, discountRate, details, progress }) => ({
      contractLabel,
      category,
      date: date
        ? format(utcToZonedTime(date, 'UTC'), HISTORY_DATE_TIME_FORMAT)
        : '',
      discountRate: `${discountRate}%`,
      details,
      progress: progress !== 0 ? `${progress}%` : progress,
    })
  )

export const prepareOrderHistoryResponse = (data: IOrderHistory[]) =>
  data.map(
    ({ date, deliveryDate, orderNumber, productLine, source, amount }) => ({
      date: date
        ? format(utcToZonedTime(date, 'UTC'), HISTORY_DATE_FORMAT)
        : '',
      deliveryDate: deliveryDate
        ? format(utcToZonedTime(deliveryDate, 'UTC'), HISTORY_DATE_FORMAT)
        : '',
      orderNumber,
      productLine,
      source,
      amount,
    })
  )

const processPeriods = (periods: IRecapPeriod[]) => {
  const processedPeriods = periods.reduce(
    (acc: IRecapPeriodProcessed, item: IRecapPeriod) => {
      const key = item.name

      if (!acc[key]) {
        acc[key] = { quantity: 0, value: 0 }
      }

      acc[key].quantity += +item.quantity
      acc[key].value += +item.value

      return acc
    },
    {}
  )

  Object.keys(processedPeriods).forEach((key) => {
    processedPeriods[key].quantity = roundTwoDecimals(
      processedPeriods[key].quantity
    )
    processedPeriods[key].value = roundTwoDecimals(processedPeriods[key].value)
  })

  return processedPeriods
}

export const processSummaryData = (summary: IRecapSummary[]) =>
  summary.map(({ year, periods }) => {
    const key = year === currentYearValue ? KEY_CURRENT_YEAR : KEY_PREVIOUS_YEAR
    return {
      [key]: {
        ...processPeriods(periods),
      },
    }
  })

export const processProductsData = (products: IRecapProducts[]) =>
  products.map(({ name, summary }) => ({
    name,
    type: TYPE_PRODUCT,
    ...Object.assign({}, ...processSummaryData(summary)),
  }))

export const processRecapHistoryTableData = (data: IRecapHistory[]) => {
  const tableRows: IHistoryTableRow[] = []
  if (!(data && data.length !== 0)) {
    return []
  }

  data.forEach(({ name, summary, products }: IRecapHistory) => {
    tableRows.push(
      {
        name,
        type: TYPE_SUMMARY,
        ...Object.assign({}, ...processSummaryData(summary)),
      },
      ...processProductsData(products)
    )
  })

  return tableRows
}

export const getHistoryTotalPerViewType = (
  summaryData: IHistoryTableRow[],
  viewType: THistoryViewType,
  initialState: IHistoryAnnualTotal
) =>
  summaryData.reduce((acc, { previousYear, currentYear }) => {
    if (viewType !== VIEW_TYPE.TOTAL) {
      const previousYearByPeriod = VIEW_TYPE_PERIODS[viewType].reduce(
        (previousYearAcc, periodKey) => {
          const period = periodKey.toUpperCase()

          return {
            ...previousYearAcc,
            [period]: {
              quantity:
                acc.previousYear[period]?.quantity +
                  (previousYear ? previousYear[period]?.quantity : 0) ||
                acc.previousYear[period]?.quantity,
              value:
                acc.previousYear[period]?.value +
                  (previousYear ? previousYear[period]?.value : 0) ||
                acc.previousYear[period]?.value,
            },
          }
        },
        {} as IRecapPeriodProcessed
      )

      const currentYearByPeriod = VIEW_TYPE_PERIODS[viewType].reduce(
        (currentYearAcc, periodKey) => {
          const period = periodKey.toUpperCase()

          return {
            ...currentYearAcc,
            [period]: {
              quantity:
                acc.currentYear[period]?.quantity +
                  (currentYear ? currentYear[period]?.quantity : 0) ||
                acc.currentYear[period]?.quantity,
              value:
                acc.currentYear[period]?.value +
                  (currentYear ? currentYear[period]?.value : 0) ||
                acc.currentYear[period]?.value,
            },
          }
        },
        {} as IRecapPeriodProcessed
      )

      return {
        previousYear: {
          ...previousYearByPeriod,
        },
        currentYear: {
          ...currentYearByPeriod,
        },
      }
    }

    return {
      previousYear: {
        TOTAL1: {
          quantity:
            acc.previousYear.TOTAL1.quantity +
            (previousYear?.TOTAL1?.quantity || 0),
          value:
            acc.previousYear.TOTAL1.value + (previousYear?.TOTAL1?.value || 0),
        },
      },
      currentYear: {
        TOTAL2: {
          quantity:
            acc.currentYear.TOTAL2.quantity +
            (currentYear?.TOTAL2?.quantity || 0),
          value:
            acc.currentYear.TOTAL2.value + (currentYear?.TOTAL2?.value || 0),
        },
      },
    }
  }, initialState)
