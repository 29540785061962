import React, { FC } from 'react'
import SelectInput from '../form/SelectInput'
import { IUsersOptions, IUsersSelect } from '../../interfaces/IUsers'
import { userIsAdmin } from '../../utils/api'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

export const UsersSelect: FC<IUsersSelect> = ({
  name,
  users,
  onUserChange,
  defaultUser,
  disabled,
  wrapperClass = '',
  isUsersLoadingError,
  isUserNotAvailableError,
}) => {
  return (
    <div className={wrapperClass as string}>
      <SelectInput
        name={name}
        id={name}
        data-qa={name}
        options={users as IUsersOptions[]}
        onChange={onUserChange}
        renderLabel={false}
        shouldRender={userIsAdmin()}
        defaultValue={defaultUser}
        className="withBackgroundColorDefault"
        disabled={disabled}
      />
      {isUsersLoadingError && (
        <DynamicFormattedMessage
          id="section.users.unavailable"
          tag="span"
          className="dInlineBlock px05 mt1 textWarning"
        />
      )}
      {isUserNotAvailableError && (
        <DynamicFormattedMessage
          id="section.users.user.unavailable"
          tag="span"
          className="dInlineBlock px05 mt1 textWarning"
        />
      )}
    </div>
  )
}
