import React, { ReactElement } from 'react'
import { FieldError } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ErrorMessage from './ErrorMessage'

function Checkbox({
  name,
  id,
  register,
  error,
  customClass = '',
  defaultValue,
  values,
  wrapperClass,
}: {
  name: string
  id: string
  register: (ref: HTMLInputElement) => void
  error?: FieldError | undefined
  customClass?: string
  defaultValue?: boolean
  values?: {}
  wrapperClass?: string
}): ReactElement {
  return (
    <div className={`inputGroup ${customClass}`}>
      <div className={`inputCheckboxWrapper ${wrapperClass} dInlineFlex`}>
        <DynamicFormattedMessage
          tag="label"
          id={`form.field.${name}.label`}
          className="inputLabel"
          htmlFor={id}
          values={values}
        />

        <input
          name={name}
          type="checkbox"
          id={id}
          ref={register}
          className="inputCheckboxField"
          defaultChecked={defaultValue}
        />
        <span className="inputCheckboxControl" />
      </div>

      <ErrorMessage error={error} />
    </div>
  )
}

export default Checkbox
