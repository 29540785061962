import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import SelectInput from '../form/SelectInput'
import { useLoadData } from '../../hooks/UseLoadData'
import { getContactPersons } from '../../services/appointmentsService'
import { getContractForm } from '../../services/contractsServices'
import { getMissingData } from '../../utils/contracts'
import SignatureSection from '../form/SignatureSection'
import Alerts from '../alerts/Alerts'
import { useCreateContractForm } from '../../hooks/clientContracts/UseClientContractForm'
import CustomModal from '../modal/CustomModal'
import {
  FORM_TYPE_CONTRACT,
  INITIAL_ACTIVE_PAGE,
  INITIAL_OFFSET,
  STATUS,
} from '../../enums/common'
import Loader from '../loader/Loader'
import TextInput from '../form/TextInput'
import {
  getAlertType,
  getFormattedString,
  isEmptyOrSpaces,
  joinData,
  objEqual,
} from '../../utils/helpers'
import { PageTitleSection } from '../common/ui/PageTitleSection'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import { useClientsDetails } from '../../hooks/clientDetails/UseClientDetails'
import Checkbox from '../form/Checkbox'
import { InfoMessageAlerts } from '../alerts/InfoMessageAlerts'
import { ICustomObject } from '../../interfaces/ICommonComponents'
import { EMAIL_CONTACT } from '../../constants/userData'
import { clientContactPersonsActions } from '../../store/reducers/clientContactPersonsReducer'
import { getClientContactPersons } from '../../store/selectors/clientContactPersonsSelectors'
import { getClientContractsData } from '../../store/selectors/clientContractsSelectors'
import { IContractForm } from '../../interfaces/IContracts'
import { Contract } from '../contracts/Contract'
import { ROUTES } from '../../enums/routes'
import ClientInfo from '../common/ui/ClientInfo'
import { NAME_INPUT_MAX_LENGTH } from '../../constants/form'
import {
  CONTACT_PERSON_INPUT,
  CUSTOM_CONTACT_PERSON_VALUE,
} from '../../constants/contracts'
import { StickyWrapper } from '../common/ui/StickyWrapper'

export const ContractForm: FC = () => {
  const { clientId, contractId } = useParams()
  const history = useHistory()

  const { potentiel1, potentiel2, name, cipCode } = useClientsDetails(
    clientId
  )?.clientDetailsData
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isContactSectionOpen, setIsContactSectionOpen] = useState(false)
  const [newContactPerson, setNewContactPerson] = useState({
    name: '',
    value: '',
  })
  const [missingEmailWarning, setMissingEmailWarning] = useState(false)
  const [missingEmailDanger, setMissingEmailDanger] = useState(false)
  const [emailDanger, setEmailDanger] = useState(false)

  const { data, status, message } = useLoadData(
    () => getContractForm(contractId, clientId),
    {
      fetchFromRedux: !!clientId,
      storeInRedux: true,
      reduxSelector: getClientContractsData,
      reduxStorePath: `${clientId}.forms.${contractId}`,
    }
  )
  const contactPersons = useLoadData(() => getContactPersons(clientId), {
    dependencies: [clientId],
    fetchFromRedux: true,
    storeInRedux: true,
    reduxAction: clientContactPersonsActions.setClientContactPersons,
    reduxSelector: getClientContactPersons,
    reduxStorePath: clientId,
  })

  const { formState, form, onSubmit } = useCreateContractForm(
    data as IContractForm,
    clientId,
    contractId,
    contactPersons?.data,
    newContactPerson,
    setIsModalOpen,
    setIsContactSectionOpen,
    emailDanger
  )

  const { client, fields } = data as IContractForm
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
  } = form
  const watchFields = watch(['emailCheckbox'])
  const [defaultValues, setDefaultValues] = useState({})
  const firstUpdate = useRef(false)

  const availableEmails = [
    watchFields.emailCheckbox ? client?.email : '',
    client?.kamEmail,
    client?.dcrEmail,
  ]
  const emailLegend = joinData(availableEmails)
  const clientEmails: ICustomObject = {
    email: client?.email || '',
    kamEmail: client?.kamEmail || '',
    dcrEmail: client?.dcrEmail || '',
  }

  const emailMissing = client && getMissingData(clientEmails)
  const emailMissingContact = emailMissing?.map(
    (el: string) => EMAIL_CONTACT[FORM_TYPE_CONTRACT][el]
  )

  const clearContactPersonErrors = () => clearErrors(['firstName', 'lastName'])

  const handleCreateContactPerson = () => {
    const contactPerson = {
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
    }

    clearContactPersonErrors()

    let hasError = false
    Object.entries(contactPerson).forEach((item) => {
      if (item[1]?.length > NAME_INPUT_MAX_LENGTH) {
        setError(item[0], { type: 'maxLength', message: 'maxLength' })
        hasError = true
      }

      if (isEmptyOrSpaces(item[1])) {
        setError(item[0], { type: 'required', message: 'required' })
        hasError = true
      }
    })

    if (!hasError) {
      const newContact = {
        name: `${contactPerson.lastName} ${contactPerson.firstName}`,
        value: CUSTOM_CONTACT_PERSON_VALUE,
      }

      setNewContactPerson(newContact)
      setValue(CONTACT_PERSON_INPUT, CUSTOM_CONTACT_PERSON_VALUE)
    }

    return hasError
  }

  const handleModal = useCallback(() => {
    const formValues = getValues()
    setIsModalOpen(
      !objEqual(formValues, { ...defaultValues, ...{ signature: '' } })
    )
  }, [getValues, defaultValues])

  useEffect(() => {
    if (!emailMissing) return

    const allMissing = emailMissing?.length === Object.keys(clientEmails).length
    setMissingEmailDanger(allMissing)

    const someMissing = emailMissing?.length > 0 && !allMissing
    setMissingEmailWarning(someMissing)
  }, [emailMissing, clientEmails])

  useEffect(() => {
    setValue('emailCheckbox', client?.email)
  }, [client, setValue])

  useEffect(() => {
    const emptyEmailLegend = emailLegend.length === 0
    setEmailDanger(emptyEmailLegend)
  }, [emailLegend.length])

  useEffect(() => {
    const submitDisabled =
      formState.status === STATUS.PENDING ||
      formState.status === STATUS.SUCCESS ||
      status === STATUS.DANGER
    setIsSubmitDisabled(submitDisabled)
  }, [formState.status, status])

  useEffect(() => {
    if (firstUpdate.current) return

    if (Object.keys(getValues()).length > 0) {
      firstUpdate.current = true
      setDefaultValues(getValues())
    }
  }, [watch, getValues])

  return (
    <>
      <PageTitleSection
        pageName={getFormattedString((data as IContractForm).label)}
      >
        <ClientInfo name={name} code={cipCode} />
      </PageTitleSection>
      <StickyWrapper>
        <Alerts
          status={contactPersons.status}
          message={contactPersons.message}
        />
        <Alerts status={status} message={message} />
        {missingEmailWarning && (
          <InfoMessageAlerts
            message="form.contract.missingEmailWarning"
            type="warning"
            data={emailMissingContact}
            joinSeparator="/"
          />
        )}
        {missingEmailDanger && (
          <InfoMessageAlerts
            message="form.contract.missingEmailDanger"
            type="danger"
          />
        )}
        <Alerts
          status={formState.status}
          message={formState.message}
          type={getAlertType(formState.messageCode)}
        />
      </StickyWrapper>
      <div className="contractFormWrapper withBackgroundColorDefault hasBorderRadius px2 py2">
        {((status && status === STATUS.PENDING) ||
          formState.status === STATUS.PENDING) && (
          <div className="mb3">
            <Loader />
          </div>
        )}
        <FormProvider {...form}>
          {status && status !== STATUS.PENDING && (
            <form
              className="contractForm"
              onSubmit={handleSubmit(onSubmit, () =>
                setIsContactSectionOpen(false)
              )}
              onChange={() => {
                handleModal()
              }}
            >
              <div className="row mb4">
                <div className="colMd6 hasBorderRight">
                  <div className="dFlex justifyContentBetween">
                    <div className="colMd11 pl0 pr0 mb2">
                      <SelectInput
                        name={CONTACT_PERSON_INPUT}
                        id={CONTACT_PERSON_INPUT}
                        register={register({ required: true })}
                        options={[...contactPersons.data, newContactPerson]}
                        className="textPrimary"
                        error={errors.contactPerson}
                        defaultValue={newContactPerson.value}
                      />
                    </div>
                    <Button
                      className="btn textUnderlineNone withBackgroundColorDefault btnContactSection textLg linkDefault my0 py0"
                      data-qa="ctaNewContact "
                      onClick={() => {
                        clearContactPersonErrors()
                        setIsContactSectionOpen(!isContactSectionOpen)
                      }}
                    >
                      {isContactSectionOpen ? '-' : '+'}
                    </Button>
                  </div>
                  {isContactSectionOpen && (
                    <div className="row hasBorderBottom">
                      <div className="colMd12 px15 py0">
                        <DynamicFormattedMessage
                          id="form.field.label.extraContactPerson"
                          tag="h4"
                          className="inputLabel"
                        />
                      </div>
                      <TextInput
                        name="firstName"
                        id="firstName"
                        customClass="colMd6"
                        register={register({
                          required: false,
                          maxLength: NAME_INPUT_MAX_LENGTH,
                        })}
                        error={errors.firstName}
                        placeholder="firstName"
                        hasLabel={false}
                      />
                      <TextInput
                        name="lastName"
                        id="lastName"
                        customClass="colMd6"
                        register={register({
                          required: false,
                          maxLength: NAME_INPUT_MAX_LENGTH,
                        })}
                        error={errors.lastName}
                        placeholder="lastName"
                        hasLabel={false}
                      />
                      <div className="row justifyContentEnd wFull">
                        <DynamicFormattedMessage
                          id="form.button.confirmation"
                          tag={Button}
                          className="btn btnPrimary textUppercase mb15 mx0 px15"
                          data-qa="ctaNewContact"
                          onClick={() => {
                            const hasError = handleCreateContactPerson()
                            if (!hasError) {
                              setIsContactSectionOpen(!isContactSectionOpen)
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="contractAddressArea mt2">
                    <div className="textPrimary mb2">
                      <DynamicFormattedMessage
                        id="form.contract.address"
                        tag="h4"
                        className="textPrimary fontMedium pb05"
                      />
                      <p>
                        {joinData([
                          client?.address1,
                          client?.zipCode,
                          client?.city,
                        ]) || '-'}
                      </p>
                      {client?.address2 && (
                        <p className="pt05">
                          {joinData([
                            client?.address2,
                            client?.zipCode,
                            client?.city,
                          ])}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="contractNameArea mt2">
                    <div className="textPrimary mb2">
                      <DynamicFormattedMessage
                        id="form.contract.name"
                        tag="h4"
                        className="textPrimary fontMedium pb05"
                      />
                      <p>
                        {`${client?.clientName} `}
                        {client?.cipCode && (
                          <ClientCipCode cipCode={client?.cipCode} />
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="contractGroupArea mt2">
                    <div className="textPrimary mb2">
                      <DynamicFormattedMessage
                        id="form.contract.group"
                        tag="h4"
                        className="textPrimary fontMedium pb05"
                      />
                      <p>{client?.group || '-'}</p>
                    </div>
                  </div>
                  <div className="contractPotentialArea row mx0 mt2">
                    <div className="mr6 textPrimary mb2">
                      <DynamicFormattedMessage
                        id="form.contract.potentiel1"
                        tag="h4"
                        className="textPrimary fontMedium pb05"
                      />
                      <p>{potentiel1 || '-'}</p>
                    </div>
                    <div className="textPrimary mb2">
                      <DynamicFormattedMessage
                        id="form.contract.potentiel2"
                        tag="h4"
                        className="textPrimary fontMedium pb05"
                      />
                      <p>{potentiel2 || '-'}</p>
                    </div>
                  </div>
                  <div className="contractEmailArea mt2">
                    <div className="textPrimary mb2">
                      <DynamicFormattedMessage
                        id="form.contract.email"
                        tag="h4"
                        className="textPrimary fontMedium pb05"
                      />
                      {(client?.email && (
                        <Checkbox
                          name="emailCheckbox"
                          id="emailCheckbox"
                          register={register}
                          defaultValue={!!client?.email}
                          values={{ email: client?.email }}
                        />
                      )) ||
                        '-'}
                    </div>
                  </div>
                  <div className="contractEmailLegendArea">
                    <DynamicFormattedMessage
                      id="form.contract.emailLegend"
                      tag="h4"
                      className="textPrimary fontRegular pb05"
                      values={{
                        emails: !emailDanger ? emailLegend : '-',
                      }}
                    />
                  </div>
                </div>
                <div className="colMd6">
                  {fields?.length > 0 && <Contract fields={fields} />}
                </div>
              </div>
              <SignatureSection
                label="contractSignature"
                changeForm={handleModal}
              />
              <div className="contractButtonsWrapper justifyContentBetween dFlex">
                <DynamicFormattedMessage
                  id="form.button.cancel"
                  tag={Button}
                  onClick={() => {
                    handleModal()
                    return window.history.state
                      ? window.history.back()
                      : history.replace(
                          generatePath(ROUTES.clientEngagements, {
                            clientId,
                            contractId,
                            contractLabel: getFormattedString(
                              (data as IContractForm).label
                            ),
                          }),
                          {
                            offset: INITIAL_OFFSET,
                            currentPage: INITIAL_ACTIVE_PAGE,
                          }
                        )
                  }}
                  className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15"
                  data-qa="cancelNewContract"
                />
                <DynamicFormattedMessage
                  id="form.button.submit"
                  type="submit"
                  tag={Button}
                  className={`btn btnPrimary px15 py15 submitNewContract ${
                    isSubmitDisabled ? 'btnDisabled' : ''
                  }`}
                  data-qa="submitNewContract"
                  disabled={isSubmitDisabled}
                  onClick={() => {
                    clearContactPersonErrors()
                    setIsContactSectionOpen(false)
                  }}
                />
              </div>
            </form>
          )}
        </FormProvider>
        <CustomModal isModalOpen={isModalOpen} />
      </div>
    </>
  )
}
