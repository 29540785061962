import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faWifi } from '@fortawesome/free-solid-svg-icons'
import { IOfflineIcon } from '../../../../interfaces/ICommonComponents'

export const OfflineIcon: FC<IOfflineIcon> = ({ color, className }) => {
  return (
    <span className="fa-stack">
      <FontAwesomeIcon
        icon={faWifi}
        className={className}
        color={color}
        style={{
          zIndex: 2,
          animationDuration: '3s',
          paddingRight: '2px',
        }}
      />
      <FontAwesomeIcon
        icon={faExclamation}
        className={className}
        color={color}
        style={{
          zIndex: 1,
          animationDuration: '3s',
        }}
      />
    </span>
  )
}
