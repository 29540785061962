import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { FieldError } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ErrorMessage from './ErrorMessage'

function TextareaInput({
  name,
  id,
  register,
  error,
  placeholder = undefined,
  defaultValue,
  onFocus,
  disabled = false,
  customLabel,
}: {
  name: string
  id: string
  register: (ref: HTMLInputElement) => void
  error?: FieldError | undefined
  placeholder?: string | undefined
  defaultValue?: string
  onFocus?: () => void
  disabled?: boolean
  customLabel?: string
}): ReactElement {
  const intl = useIntl()
  return (
    <div className="inputGroup mb15">
      <DynamicFormattedMessage
        tag="label"
        id={`form.field.${name}.label`}
        className="inputLabel"
        htmlFor={id}
      />
      <div className="relative" data-content={customLabel}>
        <textarea
          className={`inputField inputCommentaryInput ${
            customLabel ? 'pr3' : ''
          } px15 py1`}
          name={name}
          ref={register as any}
          id={id}
          placeholder={
            placeholder &&
            intl.formatMessage({
              id: `placeholder.${placeholder}`,
            })
          }
          defaultValue={defaultValue}
          data-qa={id}
          onFocus={onFocus}
          disabled={disabled}
          rows={3}
        />
      </div>
      <ErrorMessage error={error} />
    </div>
  )
}

export default TextareaInput
