import { useMemo } from 'react'
import { getUserActivity } from '../../services/usersService'
import { useLoadData } from '../UseLoadData'
import { getUserAppoinments } from '../../services/appointmentsService'
import { STATUS } from '../../enums/common'
import { useUsersDashboardHook } from './UseUsersDashboardHook'

export const useDashboardHook = () => {
  const {
    userId,
    users,
    statusPending,
    onUserChange,
    isUsersLoadingError,
    isUserNotAvailableError,
  } = useUsersDashboardHook()

  const appointments = useLoadData(
    () => {
      if (!statusPending) return getUserAppoinments(+userId)
    },
    {
      dependencies: [userId, statusPending],
    }
  )
  const userActivity = useLoadData(
    () => {
      if (!statusPending) return getUserActivity(+userId)
    },
    {
      dependencies: [userId, statusPending],
    }
  )

  const dataStatusPending = useMemo(
    () =>
      (appointments.status || userActivity.status || users.status) ===
      STATUS.PENDING,
    [appointments, userActivity, users]
  )

  return {
    users,
    appointments,
    userActivity,
    onUserChange,
    dataStatusPending,
    isUsersLoadingError,
    isUserNotAvailableError,
  }
}
