import React, { useEffect, useMemo, useRef, useState } from 'react'

export const StickyWrapper = ({
  children,
  customClass = '',
}: {
  children: React.ReactNode
  customClass?: string
}) => {
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef<HTMLElement>(null)

  const stickyWrapperContent = (document.getElementsByClassName(
    'stickyWrapper'
  )[0] as HTMLElement)?.innerText

  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([event]) => setIsSticky(event.intersectionRatio < 1),
      {
        threshold: [1],
      }
    )

    observer.observe(cachedRef as HTMLElement)

    // unmount
    return () => {
      observer.unobserve(cachedRef as HTMLElement)
    }
  }, [])

  const hasContent = useMemo(() => !!stickyWrapperContent?.length, [
    stickyWrapperContent,
  ])

  return (
    <section
      className={`stickyWrapper sticky ${customClass} ${
        isSticky && hasContent ? 'isSticky' : ''
      }`}
      ref={ref}
    >
      {children}
    </section>
  )
}
