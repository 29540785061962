import React, { FC } from 'react'
import { PageTitleSection } from '../../components/common/ui/PageTitleSection'
import { DASHBOARD_VISITS_COVERAGE } from '../../constants/routes'
import { DashboardVisitsCoverageContent } from '../../components/dashboard/dashboardVisitsCoverage/DashboardVisitsCoverageContent'

const DashboardVisitsCoverage: FC = () => {
  return (
    <section
      className={`pageContentWrapper ${DASHBOARD_VISITS_COVERAGE}Page pl75`}
    >
      <div className="px3 py3">
        <PageTitleSection pageName={DASHBOARD_VISITS_COVERAGE} />
        <DashboardVisitsCoverageContent />
      </div>
    </section>
  )
}

export default DashboardVisitsCoverage
