import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IContractData,
  IContract,
  IContractHistoryData,
  IReclamation,
  IDestructionCertificate,
  IContractForm,
} from '../../interfaces/IContracts'

export type TContracts = {
  [contractTypeId: string]: {
    data: IContract[]
    totalCount?: number
  }
}

export interface IClientContractsReducer {
  [id: string]: {
    contractHistoryData?: IContractHistoryData
    contractTypes?: IContractData[]
    reclamations?: IReclamation[]
    destructionCertificates?: IDestructionCertificate[]
    contracts: TContracts
    forms: {
      [contractTypeId: string]: IContractForm
    }
  }
}

const clientContractsReducer = createSlice({
  name: 'clientContractsReducer',
  initialState: {} as IClientContractsReducer,
  reducers: {
    setContractHistoryData: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          contractHistoryData: payload[clientId].contractHistoryData,
          contractTypes:
            payload[clientId].contractHistoryData!.currentYear
              .listContractTypes ?? [],
        },
      }
    },
    setClientContractTypes: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          contractTypes: payload[clientId].contractTypes,
        },
      }
    },
    setClientReclamations: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          reclamations: payload[clientId].reclamations,
        },
      }
    },
    setClientDestructionCertificates: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          destructionCertificates: payload[clientId].destructionCertificates,
        },
      }
    },
    addReclamation: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]

      if (!state[clientId]) return state

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          reclamations: [
            ...(payload[clientId].reclamations || []),
            ...(state[clientId].reclamations || []),
          ],
        },
      }
    },
    addDestructionCertificate: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]

      if (!state[clientId]) return state

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          destructionCertificates: [
            ...(payload[clientId].destructionCertificates || []),
            ...(state[clientId].destructionCertificates || []),
          ],
        },
      }
    },
    deleteOldReclamation: (
      state,
      {
        payload: { clientId, reclamationIdentifier },
      }: PayloadAction<{ clientId: string; reclamationIdentifier: string }>
    ) => {
      if (!state[clientId]) return state

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          reclamations: (
            state[clientId].reclamations || []
          ).map((reclamation) =>
            reclamation.id.toString() === reclamationIdentifier.toString()
              ? { ...reclamation, deleted: true }
              : reclamation
          ),
        },
      }
    },
    deleteNewReclamation: (
      state,
      {
        payload: { clientId, reclamationIdentifier },
      }: PayloadAction<{ clientId: string; reclamationIdentifier: string }>
    ) => {
      if (!state[clientId]) return state

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          reclamations: (state[clientId].reclamations || []).filter(
            (reclamation) =>
              reclamation.timestamp?.toString() !==
              reclamationIdentifier.toString()
          ),
        },
      }
    },
    deleteOldDestructionCertificate: (
      state,
      {
        payload: { clientId, identifier },
      }: PayloadAction<{ clientId: string; identifier: string }>
    ) => {
      if (!state[clientId]) return state

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          destructionCertificates: (
            state[clientId].destructionCertificates || []
          ).map((destructionCertificate) =>
            destructionCertificate.id.toString() === identifier.toString()
              ? { ...destructionCertificate, deleted: true }
              : destructionCertificate
          ),
        },
      }
    },
    deleteNewDestructionCertificate: (
      state,
      {
        payload: { clientId, identifier },
      }: PayloadAction<{ clientId: string; identifier: string }>
    ) => {
      if (!state[clientId]) return state

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          destructionCertificates: (
            state[clientId].destructionCertificates || []
          ).filter(
            (destructionCertificate) =>
              destructionCertificate.timestamp?.toString() !==
              identifier.toString()
          ),
        },
      }
    },
    setClientContracts: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          contracts: {
            ...(state && state[clientId] && state[clientId].contracts),
            ...payload[clientId].contracts,
          },
        },
      }
    },
    addClientContractsPerType: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      const contractTypeId = Object.keys(payload[clientId].contracts)[0]
      const currentContractTypes: IContractData[] =
        state && state[clientId] && state[clientId].contractTypes
          ? state[clientId].contractTypes || []
          : []
      const currentContracts =
        state &&
        state[clientId] &&
        state[clientId].contracts[contractTypeId].data
          ? state[clientId].contracts[contractTypeId].data
          : []
      const currentTotalCount =
        state[clientId].contracts[contractTypeId].totalCount

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          contractTypes: currentContractTypes.map((contractType) => ({
            ...contractType,
            nrOfContracts:
              contractType.id.toString() === contractTypeId
                ? contractType.nrOfContracts + 1
                : contractType.nrOfContracts,
          })),
          contracts: {
            ...(state && state[clientId] && state[clientId].contracts),
            [contractTypeId]: {
              data: [
                ...payload[clientId].contracts[contractTypeId].data,
                ...currentContracts,
              ],
              totalCount: currentTotalCount && currentTotalCount + 1,
            },
          },
        },
      }
    },
    deleteOldClientContract: (
      state,
      {
        payload: { clientId, contractTypeId, contractIdentifier },
      }: PayloadAction<{
        clientId: string
        contractTypeId: string
        contractIdentifier: string
      }>
    ) => {
      if (!state[clientId]) return state

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          contracts: {
            ...state[clientId].contracts,
            [contractTypeId]: {
              data: state[clientId].contracts[
                contractTypeId
              ].data.map((contract) =>
                contract.id?.toString() === contractIdentifier.toString()
                  ? { ...contract, deleted: true }
                  : contract
              ),
              totalCount: state[clientId].contracts[contractTypeId].totalCount,
            },
          },
        },
      }
    },
    deleteNewClientContract: (
      state,
      {
        payload: { clientId, contractTypeId, contractIdentifier },
      }: PayloadAction<{
        clientId: string
        contractTypeId: string
        contractIdentifier: string
      }>
    ) => {
      const currentTotalCount =
        state[clientId].contracts[contractTypeId].totalCount

      if (!state[clientId]) return state

      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          contractTypes: (state[clientId].contractTypes || []).map(
            (contractType) => {
              return contractType.id.toString() === contractTypeId.toString()
                ? {
                    ...contractType,
                    nrOfContracts: contractType.nrOfContracts - 1,
                  }
                : { ...contractType }
            }
          ),
          contracts: {
            ...state[clientId].contracts,
            [contractTypeId]: {
              data: state[clientId].contracts[contractTypeId].data.filter(
                (contract) =>
                  contract.timestamp?.toString() !==
                  contractIdentifier.toString()
              ),
              totalCount: currentTotalCount && currentTotalCount - 1,
            },
          },
        },
      }
    },
    setClientContractsForms: (
      state,
      { payload }: PayloadAction<IClientContractsReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          forms: {
            ...(state && state[clientId] && state[clientId].forms),
            ...payload[clientId].forms,
          },
        },
      }
    },
    removeUnsavedData: (state) => {
      if (!state) return

      return Object.keys(state).reduce((newState: any, clientId: string) => {
        const contractTypes = state[clientId].contracts
          ? Object.keys(state[clientId].contracts)
          : []
        const savedContractsPerType = contractTypes.reduce(
          (acc, contractType) => {
            return {
              ...acc,
              [contractType]: {
                data: state[clientId].contracts[contractType].data
                  .filter((contract) => !!contract.id)
                  .map((contract) => ({ ...contract, deleted: false })),
                totalCount: state[clientId].contracts[contractType].data.filter(
                  (contract) => !!contract.id
                ).length,
              },
            }
          },
          {} as TContracts
        )

        const newContractTypes: IContractData[] = (
          state[clientId].contractTypes || ([] as IContractData[])
        ).map((ctType) => ({
          ...ctType,
          nrOfContracts: (
            savedContractsPerType[ctType.id.toString()].data || []
          ).length,
        }))

        return {
          ...newState,
          [clientId]: {
            contractTypes: newContractTypes,
            reclamations: state[clientId].reclamations
              ? state[clientId].reclamations
                  ?.filter(({ id }) => !!id)
                  .map((reclamation) => ({ ...reclamation, deleted: false }))
              : [],
            destructionCertificates: state[clientId].destructionCertificates
              ? state[clientId].destructionCertificates
                  ?.filter(({ id }) => !!id)
                  .map((certificate) => ({ ...certificate, deleted: false }))
              : [],
            contracts: savedContractsPerType,
          },
        }
      }, {} as IClientContractsReducer)
    },
  },
})

export const clientContractsActions = clientContractsReducer.actions

export default clientContractsReducer.reducer
