import React, { FC } from 'react'
import { useToggleNavigation } from '../../hooks/UseToggleNavigation'
import { NavbarLink } from './NavbarLink'
import { NavbarToggleButton } from './NavbarToggleButton'
import { NAVBAR_LINKS } from '../../constants/routes'

export const Navbar: FC = () => {
  const { isMenuOpen, setIsMenuOpen, toggleMenu } = useToggleNavigation()

  return (
    <header className={`navbar ${isMenuOpen ? 'navbarOpened' : ''}`}>
      <nav className="navbarNav">
        <NavbarToggleButton isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        <ul className="navbarItems">
          {NAVBAR_LINKS.map((navItem) => {
            const {
              navName,
              nodes,
              imgClosed,
              imgOpened,
              isLogout,
              query,
            } = navItem

            return (
              <NavbarLink
                key={navName}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                route={navName}
                query={query}
                iconClosed={imgClosed}
                iconOpened={imgOpened}
                isLogout={isLogout}
                nodes={nodes}
                closeMenuOnClick={() => {
                  if (isMenuOpen) {
                    toggleMenu()
                  }
                }}
              />
            )
          })}
        </ul>
      </nav>
      <span
        className={`navbarOverlay ${isMenuOpen ? '' : 'hidden'}`}
        onClick={() => setIsMenuOpen(false)}
        data-qa="toggleOverlay"
      />
    </header>
  )
}
