import {
  IClientReportData,
  IClientReportsResponse,
  TClientDetailsItem,
} from '../interfaces/IClientDetails'
import { IFilters } from '../interfaces/IFilters'
import {
  IMarkerCoordinates,
  IUserLocationCoordinates,
} from '../interfaces/IMap'
import { MISSING_LABEL, NON, OUI } from '../constants/form'

export const processClientReports: (
  reportsData: IClientReportsResponse[]
) => IClientReportData[] = (reports) => {
  return reports.map((report) => {
    const {
      id,
      eventType,
      formationType,
      outcome,
      date,
      contactPerson,
    } = report
    return {
      id,
      eventType,
      formationType,
      outcome,
      startDate: date,
      contactPerson,
    }
  })
}

/**
 * Used to convert javascript datetime object from filters for redux state
 */
export const convertFiltersDatetime = (filters: IFilters) => {
  return {
    ...filters,
    lastVisitStart: JSON.stringify(filters.lastVisitStart),
    lastVisitEnd: JSON.stringify(filters.lastVisitEnd),
  }
}

export const getClientListMapCenter: (
  clients: TClientDetailsItem[],
  userCoordinates: IUserLocationCoordinates
) => IMarkerCoordinates = (clients, { latitude, longitude }) => {
  if (!longitude && !latitude && clients.length > 0) {
    const { gpsLatitude, gpsLongitude } = clients[0]

    return { gpsLatitude, gpsLongitude }
  }

  return {
    gpsLatitude: latitude,
    gpsLongitude: longitude,
  } as IMarkerCoordinates
}

/**
 * Used to convert yes / no / N/A answers
 */
export const convertYesNoAnswer = <T>(data: T, rawAnswer: keyof T) => {
  let renderedItem = MISSING_LABEL
  const propInformation = data[rawAnswer]

  if (propInformation) {
    renderedItem = OUI.toLowerCase()
  }

  if (
    propInformation !== undefined &&
    propInformation !== null &&
    !propInformation
  ) {
    renderedItem = NON.toLowerCase()
  }

  return renderedItem
}
