import { format } from 'date-fns'
import { IFilters, ISelectOptionTypes } from '../interfaces/IFilters'
import { IMultipleSelectOption, ISelectOption } from '../interfaces/IForms'
import { GEOLOCATION, ITEMS_PER_PAGE } from '../enums/common'
import { CLIENT_SOURCE } from '../constants/userData'
import { FILTER_RANGE_FIELD } from '../constants/filters'

export const createFiltersQuery = (
  filters: IFilters,
  offset: number,
  size: number,
  source?: CLIENT_SOURCE
) => {
  let query = `?size=${size}&offset=${offset * ITEMS_PER_PAGE}`
  let filterValue = ''

  if (source) {
    query += `&source=${source}`
  }
  Object.entries(filters).map(([key, val]) => {
    filterValue = val
    if (!val || val.length === 0) {
      return query
    }

    if (key === 'lastVisitStart' || key === 'lastVisitEnd') {
      filterValue = format(new Date(val), 'yyyy-MM-dd')
    }

    if (key === FILTER_RANGE_FIELD) {
      const geolocation = localStorage.getItem(GEOLOCATION)
        ? JSON.parse(localStorage.getItem(GEOLOCATION) || '')
        : null

      if (geolocation) {
        const { latitude, longitude } = geolocation

        query += `&currentLocationLatitude=${latitude}&currentLocationLongitude=${longitude}`
      }
    }

    if (Array.isArray(val)) {
      query += getQueryFromArray(
        val,
        key,
        key === 'potential1' || key === 'potential2'
      )

      return query
    }

    query += `&filters[${key}][]=${filterValue}`

    return query
  })

  return query
}

const getQueryFromArray = (
  array: (IMultipleSelectOption | string)[],
  key: string,
  useLabel: boolean = false
) => {
  return array
    .map(
      (item: IMultipleSelectOption | string) =>
        `&filters[${key}][]=${
          (useLabel
            ? (item as IMultipleSelectOption).label
            : (item as IMultipleSelectOption).value) || item
        }`
    )
    .join('')
}

export const fieldHasValue = (
  field: string | Array<ISelectOption>
): boolean => {
  return (
    (typeof field !== 'object' && !!field) ||
    (typeof field === 'object' && field !== null && !!field?.length) ||
    field instanceof Date
  )
}

export const setGeolocationToLocalStorage = (data: Position) => {
  const geolocation = {
    latitude: data.coords.latitude.toFixed(4),
    longitude: data.coords.longitude.toFixed(4),
  }

  localStorage.setItem(GEOLOCATION, JSON.stringify(geolocation))
}

export const getGeolocationCurrentPosition = async (
  successCallback: PositionCallback,
  errorCallback?: PositionErrorCallback | undefined
) => navigator.geolocation.getCurrentPosition(successCallback, errorCallback)

export const extractOptionIdentifier = (obj: ISelectOptionTypes) => {
  return (obj as ISelectOption | IMultipleSelectOption).label
    ? (obj as ISelectOption | IMultipleSelectOption).label.toString()
    : obj.id
    ? obj.id.toString()
    : obj.name
    ? obj.toString()
    : ''
}

export const prepareFieldSelectOptions = (
  field: ISelectOptionTypes[],
  values: string,
  filterByValue: boolean = true
) => {
  return field.filter((option: ISelectOptionTypes) =>
    values
      .split(',')
      .includes(
        filterByValue
          ? (option as ISelectOption | IMultipleSelectOption).value
            ? (option as ISelectOption | IMultipleSelectOption).value.toString()
            : ''
          : extractOptionIdentifier(option)
      )
  )
}

export const isPage = (pathname: string, page: string) =>
  pathname.indexOf(page) >= 0

export const clearSimpleSearchInput = (inputName: string) => {
  const simpleSearchInput = document.getElementsByClassName(
    inputName
  )[0] as HTMLInputElement
  simpleSearchInput.value = ''
}

export const isFiltersSet = (filters: IFilters) =>
  !!Object.entries(filters).find(([, value]) => fieldHasValue(value)) ||
  !!filters?.search
