import React, { FC } from 'react'
import { TYPE_SUMMARY, VIEW_TYPE } from '../../../../constants/tables'
import {
  IHistoryTableData,
  IHistoryTableRow,
} from '../../../../interfaces/IHistory'
import { useHistoryTotal } from '../../../../hooks/history/UseHistoryTotal'
import { isEmptyObj } from '../../../../utils/helpers'
import { SemesterTableRow } from './SemesterTableRow'
import { HISTORY_SEMESTER_INITIAL_STATE_TOTAL } from '../../../../__mocks__/historyMock'

export const SemesterTableContent: FC<IHistoryTableData> = ({ data }) => {
  const summaryTotal = useHistoryTotal(
    data,
    VIEW_TYPE.SEMESTER,
    HISTORY_SEMESTER_INITIAL_STATE_TOTAL
  )

  return (
    <>
      {!!data?.length && !isEmptyObj(summaryTotal) && (
        <SemesterTableRow
          currentYear={summaryTotal.currentYear}
          previousYear={summaryTotal.previousYear}
          customClass="fontBold backgroundSecondaryLight"
        />
      )}
      {!!data?.length &&
        data.map(
          (
            { type, name, currentYear, previousYear }: IHistoryTableRow,
            index: number
          ) => (
            <SemesterTableRow
              key={index.toString()}
              currentYear={currentYear}
              previousYear={previousYear}
              name={name}
              customClass={`${
                type === TYPE_SUMMARY && 'fontBold backgroundNeutralLight'
              }`}
            />
          )
        )}
    </>
  )
}
