import React, { FC, useState } from 'react'
import { Controller } from 'react-hook-form'
import { IWysiwygSection } from '../../interfaces/IForms'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ErrorMessage from './ErrorMessage'
import WysiwygEditor from './WysiwygEditor'
import { validateTextInput } from '../../utils/helpers'

const WysiwygSection: FC<IWysiwygSection> = ({
  control,
  errors,
  defaultValue = '',
  shouldValidate = true,
}) => {
  const [inputPlainText, setInputPlainText] = useState(defaultValue)

  return (
    <div className="editor">
      <DynamicFormattedMessage
        id="form.field.comment.label"
        className="inputLabel textPrimary mb1"
        tag="label"
        htmlFor="comment"
      />
      <Controller
        render={({ onChange }) => (
          <WysiwygEditor
            onChange={onChange}
            defaultValue={defaultValue}
            setInputPlainText={setInputPlainText}
          />
        )}
        name="comment"
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: false,
          validate: () => validateTextInput(inputPlainText, shouldValidate),
        }}
      />
      <ErrorMessage error={errors.comment} />
    </div>
  )
}

export default WysiwygSection
