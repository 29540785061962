import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  IClientDetailsResponseData,
  TApiResponse,
} from '../../interfaces/IClientDetails'
import { getClientDetailsService } from '../../services/getClientsListService'
import { STATUS } from '../../enums/common'
import { clientDetailsActions } from '../../store/reducers/clientDetailsReducer'
import {
  clientDetailsSelectors,
  statusNetworkSelector,
} from '../../store/selectors'
import { clientDetailDataInitialState } from '../../constants/userData'
import { handleError } from '../../utils/api'

/**
 * Used to fetch the client details.
 */
export const useClientsDetails: (
  clientId: string,
  setIsClientCached?: Dispatch<SetStateAction<boolean>>
) => IClientDetailsResponseData = (clientId, setIsClientCached) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const [clientState, setClientState] = useState<TApiResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })

  const { status, message } = clientState
  const clientDetailsData = useSelector(
    clientDetailsSelectors.getClientDetailsValue
  )

  const getClientData = useCallback(async () => {
    const { body } = await getClientDetailsService(clientId)
    dispatch(clientDetailsActions.setClientDetails(body))
  }, [clientId, dispatch])

  useEffect(() => {
    const getClientDetailsList = async () => {
      setClientState({ status: STATUS.PENDING, message: '' })
      try {
        await getClientData()
        setClientState((prevState) => {
          return {
            ...prevState,
            status: STATUS.SUCCESS,
          }
        })
      } catch (e) {
        const handledError = handleError(e, intl)
        setClientState(handledError)
      }
    }

    if (clientDetailsData.id.toString() !== clientId) {
      if (!hasNetwork)
        return (setIsClientCached as Dispatch<SetStateAction<boolean>>)(false)

      dispatch(
        clientDetailsActions.setClientDetails(clientDetailDataInitialState)
      )

      getClientDetailsList()
    } else {
      setClientState({ status: STATUS.SUCCESS, message: '' })
    }
  }, [
    clientId,
    clientDetailsData.id,
    dispatch,
    intl,
    hasNetwork,
    setIsClientCached,
    getClientData,
  ])

  return { clientDetailsData, getClientData, status, message }
}
