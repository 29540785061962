import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { statusNetworkSelector } from '../../store/selectors'
import { downloadForm } from '../../services/contractsServices'
import {
  IControlDownload,
  IFileDetails,
} from '../../interfaces/ICommonComponents'
import { API_PATHS } from '../../enums/apiPaths'
import { getFileForDownload } from '../../utils/helpers'
import { TApiResponse } from '../../interfaces/IClientDetails'
import { handleError } from '../../utils/api'
import { STATUS } from '../../enums/common'
import { MIXED_FORM_CONTRACT_TYPE } from '../../interfaces/IContracts'
import { TApiRoutes } from '../../interfaces/IRequest'

export const useDetailItem = (
  id: number,
  clientName: string,
  creationDate: string,
  cipCode: string,
  setControlDelete: Dispatch<SetStateAction<any>>,
  isDownloadModalOpen: boolean,
  setControlDownload: Dispatch<SetStateAction<IControlDownload>>,
  downloadFormState: TApiResponse,
  setDownloadFormState: Dispatch<SetStateAction<TApiResponse>>,
  contractLabel: string,
  deleted: boolean,
  timestamp?: number,
  isPdfGenerated?: boolean,
  isMixedForm?: boolean,
  mixedFormType?: MIXED_FORM_CONTRACT_TYPE,
  mixedFormTypeApiPath?: TApiRoutes
) => {
  const intl = useIntl()
  const { contractId } = useParams()
  const timeZoneTime = new Date(creationDate)
  const [isAreaOpen, setIsAreaOpen] = useState(false)
  const [shouldDisplayNoPdfWarning, setShouldDisplayNoPdfWarning] = useState(
    false
  )
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const handleSendEmailClick = () => {
    setIsAreaOpen((prev) => !prev)
  }

  const downloadFormRoute = contractId
    ? API_PATHS.contracts
    : mixedFormTypeApiPath

  const formName = contractId
    ? contractLabel
    : intl.formatMessage({
        id: `file.download.${mixedFormType}`,
      })

  const fileDetails: IFileDetails = useMemo(() => {
    return {
      creationDate,
      formName,
      clientName,
      cipCode,
    }
  }, [creationDate, clientName, cipCode, formName])

  const handleDownload = () => {
    setDownloadFormState({
      status: STATUS.PENDING,
      message: id.toString(),
    })
    setControlDownload({
      isDownloadModalOpen: false,
      url: '',
      fileName: '',
    })

    downloadForm(id, downloadFormRoute)
      .then((response) => {
        setDownloadFormState({
          status: STATUS.SUCCESS,
          message: '',
        })

        getFileForDownload(response.body, fileDetails, setControlDownload)
      })
      .catch((error) => {
        const handledError = handleError(error, intl)

        setControlDownload({
          isDownloadModalOpen: false,
          url: '',
          fileName: '',
        })
        setDownloadFormState({
          ...handledError,
        })
      })
  }

  const handleAdminDeleteControl = () => {
    setControlDelete({
      isModalOpen: true,
      contractId: id,
      timestamp,
      isTimePassed: false,
    })
  }

  useEffect(() => {
    if (typeof isPdfGenerated !== 'undefined') {
      setShouldDisplayNoPdfWarning(!isPdfGenerated)
    }
  }, [isPdfGenerated])

  const isDownloadButtonDisabled = useMemo(
    () => !hasNetwork || deleted || shouldDisplayNoPdfWarning,
    [hasNetwork, deleted, shouldDisplayNoPdfWarning]
  )

  const isDownloadLoading = useMemo(
    () =>
      isDownloadModalOpen ||
      (downloadFormState.status === STATUS.PENDING &&
        `${id}` === downloadFormState.message),
    [
      isDownloadModalOpen,
      downloadFormState.status,
      downloadFormState.message,
      id,
    ]
  )

  const isSendEmailButtonDisabled = useMemo(
    () => deleted || shouldDisplayNoPdfWarning,
    [deleted, shouldDisplayNoPdfWarning]
  )

  return {
    contractId,
    isAreaOpen,
    shouldDisplayNoPdfWarning,
    handleSendEmailClick,
    handleDownload,
    handleAdminDeleteControl,
    isDownloadButtonDisabled,
    isDownloadLoading,
    isSendEmailButtonDisabled,
    timeZoneTime,
    setIsAreaOpen,
  }
}
