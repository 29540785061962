import React, {
  useEffect,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
} from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'
import { getJwtUserDetails } from '../../utils/api'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import { ReclamationFormFirstSection } from './reclamationFormComponents/ReclamationFormFirstSection'
import { ReclamationFormSecondSection } from './reclamationFormComponents/ReclamationFormSecondSection'
import { ReclamationFormThirdSection } from './reclamationFormComponents/ReclamationFormThirdSection'
import useNewReclamationFormHook from '../../hooks/UseReclamationForm'
import Alerts from '../alerts/Alerts'
import { getAlertType, objEqual } from '../../utils/helpers'
import { mockReclamationFormValues } from '../../__mocks__/dataMock'
import { INITIAL_ACTIVE_PAGE, INITIAL_OFFSET, STATUS } from '../../enums/common'
import SignatureSection from '../form/SignatureSection'
import { ROUTES } from '../../enums/routes'
import { IClientDetailsData } from '../../interfaces/IClientDetails'
import { StickyWrapper } from '../common/ui/StickyWrapper'

export function ReclamationForm({
  setIsModalOpen,
  clientDetailsData: { id, name, cipCode },
}: {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  clientDetailsData: IClientDetailsData
}): ReactElement {
  const history = useHistory()
  const jwt = getJwtUserDetails()
  const userName = `${jwt?.lastName} ${jwt?.firstName}` || ''

  const methods = useNewReclamationFormHook(
    id.toString(),
    name,
    cipCode,
    setIsModalOpen
  )
  const {
    form: { handleSubmit, getValues },
    formState: { status, message, messageCode },
    form,
    onSubmit,
  } = methods

  const handleModal = useCallback(() => {
    const values = getValues()
    const defVal = mockReclamationFormValues

    setIsModalOpen(!objEqual(values, defVal))
  }, [getValues, setIsModalOpen])

  useEffect(() => {
    handleModal()
  }, [handleModal])

  return (
    <FormProvider {...methods.form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleModal}
        className="reclamationForm"
      >
        <StickyWrapper customClass="px0">
          <Alerts
            status={status}
            message={message}
            type={getAlertType(messageCode)}
          />
        </StickyWrapper>
        <ReclamationFormFirstSection />
        <ReclamationFormSecondSection />
        <ReclamationFormThirdSection />
        <div className="row mx0 mb2">
          <DynamicFormattedMessage
            id="form.reclamation.person.label"
            tag="p"
            className="textPrimary pr15 fontMedium"
          />
          <p className="textPrimary">{userName}</p>
        </div>
        <SignatureSection />
        <div className="reclamationButtonsWrapper justifyContentBetween dFlex">
          <DynamicFormattedMessage
            id="form.button.cancel"
            tag={Button}
            className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15 cancelNewReclamation"
            data-qa="cancelNewReclamation"
            onClick={() => {
              handleModal()
              return window.history.state
                ? window.history.back()
                : history.replace(
                    generatePath(ROUTES.clientReclamations, {
                      id,
                    }),
                    { offset: INITIAL_OFFSET, currentPage: INITIAL_ACTIVE_PAGE }
                  )
            }}
          />
          <DynamicFormattedMessage
            id="form.button.submit"
            type="submit"
            tag={Button}
            className="btn btnPrimary px15 py15 submitNewReclamation"
            data-qa="submitNewReclamation"
            disabled={status === STATUS.PENDING}
          />
        </div>
      </form>
    </FormProvider>
  )
}
