import React, { FC, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { statusNetworkReducerActions } from '../../store/reducers/statusNetworkReducer'

const NetworkStatus: FC<{}> = ({ children }: { children?: ReactNode }) => {
  const dispatch = useDispatch()

  window.addEventListener('load', () => {
    window.addEventListener('offline', () =>
      dispatch(statusNetworkReducerActions.setOfflineStatus())
    )

    window.addEventListener('online', () =>
      dispatch(statusNetworkReducerActions.setOnlineStatus())
    )
  })

  return <>{children}</>
}

export default NetworkStatus
