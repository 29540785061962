import { addHours, format } from 'date-fns'
import {
  IClientAppointmentData,
  IClientDetailsActions,
  IClientDetailsData,
  IClientReportData,
} from '../interfaces/IClientDetails'
import { IAppointmentDate, IContactPerson } from '../interfaces/IAppointments'
import { ROUTES } from '../enums/routes'
import { ICustomObject } from '../interfaces/ICommonComponents'
import {
  DATE_REQUEST_FORMAT,
  DCR_EMAIL,
  FORM_TYPES,
  KAM_EMAIL,
} from '../enums/common'

export const clientDetailDataInitialState: IClientDetailsData = {
  id: 0,
  cipCode: '',
  clientCode: '',
  name: '',
  email: '',
  address1: '',
  address2: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  faxNumber: '',
  phoneAgentName: '',
  phoneAgentComment: '',
  fieldAgentName: '',
  fieldAgentComment: '',
  group: '',
  gpsLongitude: 0,
  gpsLatitude: 0,
  potentiel1: '',
  potentiel2: '',
  potentiel3: '',
  qualification1: '',
  qualification2: '',
  qualification3: '',
  targetType1: '',
  targetType2: '',
  targetType3: '',
  decileBebe: '',
  clientHipp: '',
  clientNuk: '',
  clientMarque3: '',
  clientType24: '',
  objectiveFrequency: 0,
  uga: '',
  kamEmail: '',
}

export const clientAppointmentDataInitialState: IClientAppointmentData[] = [
  {
    id: 0,
    startDate: '',
    eventType: '',
    contactPerson: '',
  },
]

export const clientReportsDataInitialState: IClientReportData[] = [
  {
    id: 0,
    startDate: '',
    eventType: '',
    formationType: '',
    outcome: '',
    contactPerson: '',
  },
]

export const contactPersonsDataInitialState: IContactPerson[] = [
  {
    id: 0,
    firstName: '',
    lastName: '',
  },
]

export const datesInitialState: IAppointmentDate = {
  startDate: format(addHours(new Date(), 1), DATE_REQUEST_FORMAT),
  endDate: format(addHours(new Date(), 2), DATE_REQUEST_FORMAT),
}

export const ACTION_NOUVEAU_RENDEZ_VOUS: string = 'newAppointment'
export const ACTION_NOUVEAU_RAPPORT = 'raport'
const ACTION_FICHE_CLIENT: string = 'ficheClient'
export const ACTION_FORMULAIRES: string = 'formularies'
export const ACTION_HISTORIQUE: string = 'historique'

export const USER_DETAILS_ACTION_LIST: IClientDetailsActions[] = [
  {
    actionName: ACTION_NOUVEAU_RAPPORT,
    actionUrl: ROUTES.newVisitReport,
  },
  {
    actionName: ACTION_NOUVEAU_RENDEZ_VOUS,
    actionUrl: ROUTES.newAppointmentAgenda,
  },
  {
    actionName: ACTION_FICHE_CLIENT,
    actionUrl: ROUTES.clientFile,
  },
  {
    actionName: ACTION_FORMULAIRES,
    actionUrl: ROUTES.clientForms,
  },
  {
    actionName: ACTION_HISTORIQUE,
    actionUrl: ROUTES.history,
  },
]

export const FIELD_AGENT_COMMENT = 'fieldAgentComment'
export const PHONE_AGENT_COMMENT = 'phoneAgentComment'
export const GPS_LONGITUDE = 'gpsLongitude'
export const GPS_LATITUDE = 'gpsLatitude'
export const POTENTIEL_1 = 'potentiel1'
export const POTENTIEL_2 = 'potentiel2'
export const POTENTIEL_3 = 'potentiel3'
export const QUALIFICATION_1 = 'qualification1'
export const QUALIFICATION_2 = 'qualification2'
export const QUALIFICATION_3 = 'qualification3'
export const TARGET_TYPE_1 = 'targetType1'
export const TARGET_TYPE_2 = 'targetType2'
export const TARGET_TYPE_3 = 'targetType3'
export const KAM = 'fieldAgentName'
export const DCR = 'phoneAgentName'
export const CLIENT_CODE = 'clientCode'

export const FILTERED_DATA_FIELDS: (keyof IClientDetailsData)[] = [
  FIELD_AGENT_COMMENT,
  PHONE_AGENT_COMMENT,
  GPS_LONGITUDE,
  GPS_LATITUDE,
  POTENTIEL_3,
  QUALIFICATION_1,
  QUALIFICATION_2,
  QUALIFICATION_3,
  KAM,
  DCR,
  TARGET_TYPE_1,
  TARGET_TYPE_2,
  TARGET_TYPE_3,
  CLIENT_CODE,
  KAM_EMAIL,
]

export const SYNERGIE_DATA_FIELDS: (keyof IClientDetailsData)[] = [KAM, DCR]

const MONDAY = 'monday'
const TUESDAY = 'tuesday'
const WEDNESDAY = 'wednesday'
const THURSDAY = 'thursday'
const FRIDAY = 'friday'
const SATURDAY = 'saturday'
const SUNDAY = 'sunday'

export const WEEK_DAYS_LIST = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
]

const CLIENT_NAME = 'CLIENT'
const KAM_NAME = 'KAM'
const DCR_NAME = 'DCR'

export const EMAIL_CONTACT: { [key: string]: ICustomObject } = {
  [FORM_TYPES.contract]: {
    email: CLIENT_NAME,
    [KAM_EMAIL]: KAM_NAME,
    [DCR_EMAIL]: DCR_NAME,
  },
  [FORM_TYPES.destructionCertificate]: {
    email: CLIENT_NAME,
    [KAM_EMAIL]: KAM_NAME,
  },
}

// Offline sync page actions list
export const ACTION_TYPES = [
  'appointments',
  'visit-reports',
  'reclamations',
  'destructionCertificates',
  'contracts',
  'sendEmail',
  'clients',
  'upload',
] as const

export enum CLIENT_SOURCE {
  MAP = 'map',
}

export const clientDetailsDataFieldsOrder = [
  'id',
  'cipCode',
  'targetType3',
  'name',
  'email',
  'address1',
  'address2',
  'zipCode',
  'city',
  'phoneNumber',
  'faxNumber',
  'phoneAgentName',
  'phoneAgentComment',
  'fieldAgentName',
  'fieldAgentComment',
  'group',
  'gpsLongitude',
  'gpsLatitude',
  'qualification1',
  'qualification2',
  'qualification3',
  'clientHipp',
  'clientNuk',
  'clientMarque3',
  'decileBebe',
  'marcheNuk',
  'uga',
  'kamEmail',
]
