import {
  ILastVisitReportImages,
  IMissionData,
  IVisitReport,
  TVisitReportCurrentVersion,
} from '../interfaces/IVisitReports'
import { IFormSelectOption, IMultipleSelectOption } from '../interfaces/IForms'
import { V2 } from '../enums/common'

// This should be changed and increased at each new visit report form fields update
export const VISIT_REPORT_CURRENT_VERSION: TVisitReportCurrentVersion = V2

export const IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE = 'images'

export const NO_PLV = 'Autre PLV'

export const ACTION_TYPE = ['Visite', 'Appel', 'Refus']
export const VISIT_ACTION_TYPE_1 = '1'
export const VISIT_ACTION_TYPE_2 = '2'

const visitReportImageInitialState = {
  key: null,
  content: null,
}

export const visitReportInitialState: IVisitReport = {
  id: 0,
  comment: '',
  contactOutcome: '',
  contactPerson: '',
  contactType: '',
  date: '',
  duration: null,
  rangeCompeed: false,
  dualLayoutCompeed: false,
  liniarGainCompeed: false,
  trainingCompeed: false,
  trainingBdf: false,
  promoCompeed: false,
  plvOptionsCompeed: [],
  pdlCompeed: '',
  trainingEllaone: false,
  trainingCicatridine: false,
  plvOptionsCicatridine: [],
  rangeNuk: false,
  dualLayoutNuk: false,
  liniarGainNuk: false,
  trainingNuk: false,
  promoNuk: false,
  monthsOptradeNuk: [],
  plvOptionsNuk: [],
  pdlNuk: '',
  rotationPrevCompeed: 0,
  rotationCurrentCompeed: 0,
  priceMfxCompeed: 0,
  priceExtremeCompeed: 0,
  priceRangeCompeed: 0,
  compCompeed: '',
  compPromoCompeed: false,
  compPlvCompeed: false,
  compPdlCompeed: '',
  compPriceUrgoCompeed: 0,
  compPriceExtremeCompeed: 0,
  rotationCurrentEllaone: 0,
  rotationPrevEllaone: 0,
  compEllaone: '',
  rotationCurrentCicatridine: 0,
  rotationPrevCicatridine: 0,
  compCicatridine: '',
  rotationCurrentNuk: 0,
  rotationPrevNuk: 0,
  compNuk: [],
  compPlvNuk: false,
  compPromoNuk: false,
  compMonthsOptradeNuk: [],
  compPdlNuk: '',
  presenceNuk: false,
  rangeHipp: false,
  dualLayoutHipp: false,
  liniarGainHipp: false,
  trainingHipp: false,
  promoHipp: false,
  monthsOptradeHipp: [],
  plvOptionsHipp: [],
  pdlHipp: '',
  rotationCurrentHipp: '',
  rotationPrevHipp: '',
  compHipp: [],
  compPlvHipp: false,
  compPromoHipp: false,
  compMonthsOptradeHipp: [],
  presenceHipp: false,
  referencesHipp: [],
  rangeMarque3: false,
  dualLayoutMarque3: false,
  liniarGainMarque3: false,
  trainingMarque3: false,
  promoMarque3: false,
  monthsOptradeMarque3: [],
  plvOptionsMarque3: [],
  pdlMarque3: '',
  rotationCurrentMarque3: '',
  rotationPrevMarque3: '',
  compMarque3: [],
  compPlvMarque3: false,
  compPromoMarque3: false,
  compMonthsOptradeMarque3: [],
  plvCompeed: visitReportImageInitialState,
  merchCompeed: visitReportImageInitialState,
  plvAcc45: visitReportImageInitialState,
  merch2Acc45: visitReportImageInitialState,
  merch3Acc45: visitReportImageInitialState,
  plvAcc8: visitReportImageInitialState,
  merch1Acc8: visitReportImageInitialState,
  merch2Acc8: visitReportImageInitialState,
  merch3Acc8: visitReportImageInitialState,
  merch4Acc8: visitReportImageInitialState,
  plvCicatridine: visitReportImageInitialState,
  merchCicatridine: visitReportImageInitialState,
  plvNuk: visitReportImageInitialState,
  merchNuk: visitReportImageInitialState,
  plvHipp: visitReportImageInitialState,
  merchHipp: visitReportImageInitialState,
  plvMarque3: visitReportImageInitialState,
  merchMarque3: visitReportImageInitialState,
  version: VISIT_REPORT_CURRENT_VERSION,
}

// Visit report panel fields
// TAB1
export const VISIT_DATE = 'date'
export const VISIT_VISIT_DATE = 'visitDate'
export const VISIT_DURATION = 'duration'
export const VISIT_CONTACT = 'contactPerson'
export const VISIT_CONTACT_ID = 'contactPersonId'
export const VISIT_ACTION_TYPE = 'contactType'
export const VISIT_ACTION_TYPE_ID = 'contactTypeId'
export const VISIT_OUTCOME = 'contactOutcome'
export const VISIT_OUTCOME_ID = 'contactOutcomeId'
export const VISIT_COMMENT = 'comment'

// TAB2
const VISIT_MISSION_TYPE1 = 'Compeed'
const VISIT_MISSION_TYPE2 = 'Cicatridine'
const VISIT_MISSION_TYPE3 = 'Ellaone'
export const VISIT_MISSION_TYPE4 = 'Nuk'
export const VISIT_MISSION_TYPE5 = 'Hipp'
export const VISIT_MISSION_TYPE6 = 'Marque3'

export const VISIT_MISSIONS_V1 = {
  type1: VISIT_MISSION_TYPE1,
  type2: VISIT_MISSION_TYPE2,
  type3: VISIT_MISSION_TYPE3,
  type4: VISIT_MISSION_TYPE4,
}

export const VISIT_MISSIONS_V2 = {
  type1: VISIT_MISSION_TYPE5,
  type2: VISIT_MISSION_TYPE4,
  type3: VISIT_MISSION_TYPE6,
}

const VISIT_RANGE = 'range'
const VISIT_DUAL_LAYOUT = 'dualLayout'
const VISIT_LINIAR_GAIN = 'liniarGain'
const VISIT_TRAINING = 'training'
const VISIT_PROMO = 'promo'
const VISIT_MONTHS = 'monthsOptrade'
const VISIT_PLV = 'plvOptions'
const VISIT_PDL = 'pdl'

export const VISIT_PDL_OPTIONS: IFormSelectOption[] = [
  {
    name: '0%-20%',
    value: '1',
  },
  {
    name: '20%-40%',
    value: '2',
  },
  {
    name: '40%-60%',
    value: '3',
  },
  {
    name: '60%-80%',
    value: '4',
  },
  {
    name: '80%-100%',
    value: '5',
  },
]

export const VISIT_RANGE_TYPE1 = VISIT_RANGE + VISIT_MISSION_TYPE1
export const VISIT_DUAL_LAYOUT_TYPE1 = VISIT_DUAL_LAYOUT + VISIT_MISSION_TYPE1
export const VISIT_LINIAR_GAIN_TYPE1 = VISIT_LINIAR_GAIN + VISIT_MISSION_TYPE1
export const VISIT_TRAINING_COMPEED_TYPE1 = VISIT_TRAINING + VISIT_MISSION_TYPE1
export const VISIT_TRAINING_BDF_TYPE1 = 'trainingBdf'
export const VISIT_PROMO_TYPE1 = VISIT_PROMO + VISIT_MISSION_TYPE1
export const VISIT_PLV_TYPE1 = VISIT_PLV + VISIT_MISSION_TYPE1
export const VISIT_PDL_TYPE1 = VISIT_PDL + VISIT_MISSION_TYPE1
export const VISIT_TRAINING_TYPE2 = VISIT_TRAINING + VISIT_MISSION_TYPE2
export const VISIT_PLV_TYPE2 = VISIT_PLV + VISIT_MISSION_TYPE2
export const VISIT_TRAINING_TYPE3 = VISIT_TRAINING + VISIT_MISSION_TYPE3
export const VISIT_RANGE_TYPE4 = VISIT_RANGE + VISIT_MISSION_TYPE4
export const VISIT_DUAL_LAYOUT_TYPE4 = VISIT_DUAL_LAYOUT + VISIT_MISSION_TYPE4
export const VISIT_LINIAR_GAIN_TYPE4 = VISIT_LINIAR_GAIN + VISIT_MISSION_TYPE4
export const VISIT_TRAINING_TYPE4 = VISIT_TRAINING + VISIT_MISSION_TYPE4
export const VISIT_PROMO_TYPE4 = VISIT_PROMO + VISIT_MISSION_TYPE4
export const VISIT_MONTHS_TYPE4 = VISIT_MONTHS + VISIT_MISSION_TYPE4
export const VISIT_PLV_TYPE4 = VISIT_PLV + VISIT_MISSION_TYPE4
export const VISIT_PDL_TYPE4 = VISIT_PDL + VISIT_MISSION_TYPE4
export const VISIT_RANGE_TYPE5 = VISIT_RANGE + VISIT_MISSION_TYPE5
export const VISIT_DUAL_LAYOUT_TYPE5 = VISIT_DUAL_LAYOUT + VISIT_MISSION_TYPE5
export const VISIT_LINIAR_GAIN_TYPE5 = VISIT_LINIAR_GAIN + VISIT_MISSION_TYPE5
export const VISIT_TRAINING_TYPE5 = VISIT_TRAINING + VISIT_MISSION_TYPE5
export const VISIT_PROMO_TYPE5 = VISIT_PROMO + VISIT_MISSION_TYPE5
export const VISIT_MONTHS_TYPE5 = VISIT_MONTHS + VISIT_MISSION_TYPE5
export const VISIT_PLV_TYPE5 = VISIT_PLV + VISIT_MISSION_TYPE5
export const VISIT_PDL_TYPE5 = VISIT_PDL + VISIT_MISSION_TYPE5
export const VISIT_RANGE_TYPE6 = VISIT_RANGE + VISIT_MISSION_TYPE6
export const VISIT_DUAL_LAYOUT_TYPE6 = VISIT_DUAL_LAYOUT + VISIT_MISSION_TYPE6
export const VISIT_LINIAR_GAIN_TYPE6 = VISIT_LINIAR_GAIN + VISIT_MISSION_TYPE6
export const VISIT_TRAINING_TYPE6 = VISIT_TRAINING + VISIT_MISSION_TYPE6
export const VISIT_PROMO_TYPE6 = VISIT_PROMO + VISIT_MISSION_TYPE6
export const VISIT_MONTHS_TYPE6 = VISIT_MONTHS + VISIT_MISSION_TYPE6
export const VISIT_PLV_TYPE6 = VISIT_PLV + VISIT_MISSION_TYPE6
export const VISIT_PDL_TYPE6 = VISIT_PDL + VISIT_MISSION_TYPE6

export const VISIT_PLV_LIST = [
  VISIT_PLV_TYPE4,
  VISIT_PLV_TYPE5,
  VISIT_PLV_TYPE6,
]

export const VISIT_PDL_LIST = [
  VISIT_PDL_TYPE4,
  VISIT_PDL_TYPE5,
  VISIT_PDL_TYPE6,
]

export const VISIT_MONTHS_LIST = [
  VISIT_MONTHS_TYPE4,
  VISIT_MONTHS_TYPE5,
  VISIT_MONTHS_TYPE6,
]

export const VISIT_SECOND_PANEL_MISSION_TYPE1_V1: IMissionData = {
  actions: [
    VISIT_RANGE_TYPE1,
    VISIT_DUAL_LAYOUT_TYPE1,
    VISIT_LINIAR_GAIN_TYPE1,
  ],
  training: [VISIT_TRAINING_COMPEED_TYPE1, VISIT_TRAINING_BDF_TYPE1],
  promo: VISIT_PROMO_TYPE1,
  plv: VISIT_PLV_TYPE1,
  pdl: VISIT_PDL_TYPE1,
}

export const VISIT_SECOND_PANEL_MISSION_TYPE2_V1: IMissionData = {
  training: [VISIT_TRAINING_TYPE2],
  plv: VISIT_PLV_TYPE2,
}

export const VISIT_SECOND_PANEL_MISSION_TYPE3_V1: IMissionData = {
  training: [VISIT_TRAINING_TYPE3],
}

export const VISIT_SECOND_PANEL_MISSION_TYPE4_V1: IMissionData = {
  actions: [
    VISIT_RANGE_TYPE4,
    VISIT_DUAL_LAYOUT_TYPE4,
    VISIT_LINIAR_GAIN_TYPE4,
  ],
  training: [VISIT_TRAINING_TYPE4],
  promo: VISIT_PROMO_TYPE4,
  plv: VISIT_PLV_TYPE4,
  pdl: VISIT_PDL_TYPE4,
}

export const VISIT_SECOND_PANEL_MISSION_TYPE1_V2: IMissionData = {
  actions: [
    VISIT_RANGE_TYPE5,
    VISIT_DUAL_LAYOUT_TYPE5,
    VISIT_LINIAR_GAIN_TYPE5,
  ],
  training: [VISIT_TRAINING_TYPE5],
  promo: VISIT_PROMO_TYPE5,
  months: VISIT_MONTHS_TYPE5,
  plv: VISIT_PLV_TYPE5,
  pdl: VISIT_PDL_TYPE5,
}

export const VISIT_SECOND_PANEL_MISSION_TYPE2_V2: IMissionData = {
  actions: [
    VISIT_RANGE_TYPE4,
    VISIT_DUAL_LAYOUT_TYPE4,
    VISIT_LINIAR_GAIN_TYPE4,
  ],
  training: [VISIT_TRAINING_TYPE4],
  promo: VISIT_PROMO_TYPE4,
  months: VISIT_MONTHS_TYPE4,
  plv: VISIT_PLV_TYPE4,
  pdl: VISIT_PDL_TYPE4,
}

export const VISIT_SECOND_PANEL_MISSION_TYPE3_V2: IMissionData = {
  actions: [
    VISIT_RANGE_TYPE6,
    VISIT_DUAL_LAYOUT_TYPE6,
    VISIT_LINIAR_GAIN_TYPE6,
  ],
  training: [VISIT_TRAINING_TYPE6],
  promo: VISIT_PROMO_TYPE6,
  months: VISIT_MONTHS_TYPE6,
  plv: VISIT_PLV_TYPE6,
  pdl: VISIT_PDL_TYPE6,
}

export const VISIT_SECOND_PANEL_MISSIONS_V1 = {
  type1: VISIT_SECOND_PANEL_MISSION_TYPE1_V1,
  type2: VISIT_SECOND_PANEL_MISSION_TYPE2_V1,
  type3: VISIT_SECOND_PANEL_MISSION_TYPE3_V1,
  type4: VISIT_SECOND_PANEL_MISSION_TYPE4_V1,
}

export const VISIT_SECOND_PANEL_MISSIONS_V2 = {
  type1: VISIT_SECOND_PANEL_MISSION_TYPE1_V2,
  type2: VISIT_SECOND_PANEL_MISSION_TYPE2_V2,
  type3: VISIT_SECOND_PANEL_MISSION_TYPE3_V2,
}

// TAB3
export const VISIT_ROTATIONS_1 = 'rotationPrev'
export const VISIT_ROTATIONS_2 = 'rotationCurrent'
export const VISIT_COMPETITION_PROMO = 'compPromo'
export const VISIT_COMPETITION_MONTHS = 'compMonthsOptrade'
export const VISIT_COMPETITIONS = 'comp'
export const VISIT_COMPETITION_PLV = 'compPlv'
export const VISIT_COMPETITION_PDL = 'compPdl'
export const VISIT_PRESENCE = 'presence'
export const VISIT_REFERENCES = 'references'

// competitions section - types used for products
export const VISIT_COMPETITIONS_TYPE1 = VISIT_COMPETITIONS + VISIT_MISSION_TYPE1
export const VISIT_COMPETITIONS_TYPE2 = VISIT_COMPETITIONS + VISIT_MISSION_TYPE2
export const VISIT_COMPETITIONS_TYPE3 = VISIT_COMPETITIONS + VISIT_MISSION_TYPE3
export const VISIT_COMPETITIONS_TYPE4 = VISIT_COMPETITIONS + VISIT_MISSION_TYPE4
export const VISIT_COMPETITIONS_TYPE5 = VISIT_COMPETITIONS + VISIT_MISSION_TYPE5
export const VISIT_COMPETITIONS_TYPE6 = VISIT_COMPETITIONS + VISIT_MISSION_TYPE6

// Mission Compeed (VISIT_MISSION_TYPE1)
export const VISIT_ROTATIONS_TYPE1_1 = VISIT_ROTATIONS_1 + VISIT_MISSION_TYPE1
export const VISIT_ROTATIONS_TYPE1_2 = VISIT_ROTATIONS_2 + VISIT_MISSION_TYPE1
export const VISIT_PRICE_TYPE1_1 = 'priceMfxCompeed'
export const VISIT_PRICE_TYPE1_2 = 'priceExtremeCompeed'
export const VISIT_PRICE_TYPE1_3 = 'priceRangeCompeed'
export const VISIT_COMPETITION_TYPE1: IFormSelectOption[] = [
  {
    name: 'EPITACT',
    value: '1',
  },
  {
    name: 'SCHOLL',
    value: '2',
  },
  {
    name: 'URGO',
    value: '3',
  },
]
export const VISIT_COMPETITION_PROMO_TYPE1 =
  VISIT_COMPETITION_PROMO + VISIT_MISSION_TYPE1
export const VISIT_COMPETITION_PLV_TYPE1 =
  VISIT_COMPETITION_PLV + VISIT_MISSION_TYPE1
export const VISIT_COMPETITION_PDL_TYPE1 =
  VISIT_COMPETITION_PDL + VISIT_MISSION_TYPE1
export const VISIT_COMPETITION_PRICE_TYPE1_1 = 'compPriceUrgoCompeed'
export const VISIT_COMPETITION_PRICE_TYPE1_2 = 'compPriceExtremeCompeed'

// Mission Cicatridine (VISIT_MISSION_TYPE2)
export const VISIT_ROTATIONS_TYPE2_1 = VISIT_ROTATIONS_1 + VISIT_MISSION_TYPE2
export const VISIT_ROTATIONS_TYPE2_2 = VISIT_ROTATIONS_2 + VISIT_MISSION_TYPE2
export const VISIT_COMPETITION_TYPE2: IFormSelectOption[] = [
  {
    name: 'MUCOGYNE',
    value: '1',
  },
  {
    name: 'SAFORELLE',
    value: '2',
  },
  {
    name: 'REPLENS',
    value: '3',
  },
]

// Mission Ellaone (VISIT_MISSION_TYPE3)
export const VISIT_ROTATIONS_TYPE3_1 = VISIT_ROTATIONS_1 + VISIT_MISSION_TYPE3
export const VISIT_ROTATIONS_TYPE3_2 = VISIT_ROTATIONS_2 + VISIT_MISSION_TYPE3
export const VISIT_COMPETITION_TYPE3: IFormSelectOption[] = [
  {
    name: 'UPA MYLAN',
    value: '1',
  },
  {
    name: 'LNG EG LABO',
    value: '2',
  },
  {
    name: 'UPA EXCELTIS',
    value: '3',
  },
  {
    name: 'NORLEVO',
    value: '4',
  },
  {
    name: 'LNG BIOGARAN',
    value: '5',
  },
]

// Mission Nuk (VISIT_MISSION_TYPE4)
export const VISIT_ROTATIONS_TYPE4_1 = VISIT_ROTATIONS_1 + VISIT_MISSION_TYPE4
export const VISIT_ROTATIONS_TYPE4_2 = VISIT_ROTATIONS_2 + VISIT_MISSION_TYPE4

export const VISIT_COMPETITION_TYPE4: IFormSelectOption[] = [
  {
    name: 'MAM',
    value: '1',
  },
  {
    name: 'DODIE',
    value: '2',
  },
  {
    name: 'AVENT',
    value: '3',
  },
]

export const VISIT_COMPETITION_TYPE5: IFormSelectOption[] = [
  {
    name: 'BIOSTIME',
    value: '1',
  },
  {
    name: 'BABYBIO',
    value: '2',
  },
  {
    name: 'MODILAC',
    value: '3',
  },
]

export const VISIT_COMPETITION_TYPE6: IFormSelectOption[] = [
  {
    name: 'MAM',
    value: '1',
  },
  {
    name: 'DODIE',
    value: '2',
  },
  {
    name: 'AVENT',
    value: '3',
  },
]

// this will be used on form data processing for visit report
// should be updated each time the missions change for a visit report
export const VISIT_COMPETITION_TYPES_MAPPING: {
  [key in keyof typeof CURRENT_VISIT_COMPETITION_LIST]:
    | typeof VISIT_COMPETITION_TYPE4
    | typeof VISIT_COMPETITION_TYPE5
    | typeof VISIT_COMPETITION_TYPE6
} = {
  [VISIT_COMPETITIONS_TYPE4]: VISIT_COMPETITION_TYPE4,
  [VISIT_COMPETITIONS_TYPE5]: VISIT_COMPETITION_TYPE5,
  [VISIT_COMPETITIONS_TYPE6]: VISIT_COMPETITION_TYPE6,
}

export const VISIT_MULTI_COMPETITION_TYPE4: IMultipleSelectOption[] = [
  {
    value: '1',
    label: 'MAM',
  },
  {
    value: '2',
    label: 'DODIE',
  },
  {
    value: '3',
    label: 'AVENT',
  },
]

export const VISIT_MULTI_COMPETITION_TYPE5: IMultipleSelectOption[] = [
  {
    value: '1',
    label: 'BIOSTIME',
  },
  {
    value: '2',
    label: 'BABYBIO',
  },
  {
    value: '3',
    label: 'MODILAC',
  },
]

export const VISIT_MULTI_COMPETITION_TYPE6: IMultipleSelectOption[] = [
  {
    value: '1',
    label: 'MAM',
  },
  {
    value: '2',
    label: 'DODIE',
  },
  {
    value: '3',
    label: 'AVENT',
  },
]
export const VISIT_COMPETITION_PROMO_TYPE4 =
  VISIT_COMPETITION_PROMO + VISIT_MISSION_TYPE4
export const VISIT_COMPETITION_MONTHS_TYPE4 =
  VISIT_COMPETITION_MONTHS + VISIT_MISSION_TYPE4
export const VISIT_COMPETITION_PLV_TYPE4 =
  VISIT_COMPETITION_PLV + VISIT_MISSION_TYPE4
export const VISIT_COMPETITION_PDL_TYPE4 =
  VISIT_COMPETITION_PDL + VISIT_MISSION_TYPE4
export const VISIT_PRESENCE_TYPE4 = VISIT_PRESENCE + VISIT_MISSION_TYPE4

// Mission Hipp (VISIT_MISSION_TYPE5)
export const VISIT_ROTATIONS_TYPE5_1 = VISIT_ROTATIONS_1 + VISIT_MISSION_TYPE5
export const VISIT_ROTATIONS_TYPE5_2 = VISIT_ROTATIONS_2 + VISIT_MISSION_TYPE5
export const VISIT_COMPETITION_PROMO_TYPE5 =
  VISIT_COMPETITION_PROMO + VISIT_MISSION_TYPE5
export const VISIT_COMPETITION_MONTHS_TYPE5 =
  VISIT_COMPETITION_MONTHS + VISIT_MISSION_TYPE5
export const VISIT_COMPETITION_PLV_TYPE5 =
  VISIT_COMPETITION_PLV + VISIT_MISSION_TYPE5
export const VISIT_PRESENCE_TYPE5 = VISIT_PRESENCE + VISIT_MISSION_TYPE5
export const VISIT_REFERENCES_TYPE5 = VISIT_REFERENCES + VISIT_MISSION_TYPE5

export const VISIT_REFERENCES_OPTIONS_TYPE5: IMultipleSelectOption[] = [
  {
    value: '1',
    label: 'Lait 1 COMBIOTIC® Formule Epaissie',
  },
  {
    value: '2',
    label: 'Lait 2 COMBIOTIC® Formule Epaissie',
  },
  {
    value: '3',
    label: 'Lait 1 COMBIOTIC® pour nourrissons',
  },
  {
    value: '4',
    label: 'Lait 2 COMBIOTIC® de suite',
  },
  {
    value: '5',
    label: 'Lait 3 COMBIOTIC® Croissance',
  },
  {
    value: '6',
    label: 'Lait 3 BIO Croissance',
  },
  {
    value: '7',
    label: 'Lait COMBIOTIC® Expert Formule Anti-Régurgitations 0-12 mois',
  },
]

// Mission Marque3 (VISIT_MISSION_TYPE6)
export const VISIT_ROTATIONS_TYPE6_1 = VISIT_ROTATIONS_1 + VISIT_MISSION_TYPE6
export const VISIT_ROTATIONS_TYPE6_2 = VISIT_ROTATIONS_2 + VISIT_MISSION_TYPE6
export const VISIT_COMPETITION_PROMO_TYPE6 =
  VISIT_COMPETITION_PROMO + VISIT_MISSION_TYPE6
export const VISIT_COMPETITION_MONTHS_TYPE6 =
  VISIT_COMPETITION_MONTHS + VISIT_MISSION_TYPE6
export const VISIT_COMPETITION_PLV_TYPE6 =
  VISIT_COMPETITION_PLV + VISIT_MISSION_TYPE6

export const VISIT_COMPETITION_LIST = [
  VISIT_COMPETITIONS_TYPE4,
  VISIT_COMPETITIONS_TYPE5,
  VISIT_COMPETITIONS_TYPE6,
]

// update each time missions change and VISIT_COMPETITION_LIST changes
export const CURRENT_VISIT_COMPETITION_LIST = {
  [VISIT_COMPETITIONS_TYPE4]: VISIT_COMPETITIONS_TYPE4,
  [VISIT_COMPETITIONS_TYPE5]: VISIT_COMPETITIONS_TYPE5,
  [VISIT_COMPETITIONS_TYPE6]: VISIT_COMPETITIONS_TYPE6,
}

export const VISIT_COMPETITION_MONTHS_LIST = [
  VISIT_COMPETITION_MONTHS_TYPE4,
  VISIT_COMPETITION_MONTHS_TYPE5,
  VISIT_COMPETITION_MONTHS_TYPE6,
]

export const VISIT_THIRD_PANEL_MISSION_TYPE1_V1: IMissionData = {
  rotations: [VISIT_ROTATIONS_TYPE1_2, VISIT_ROTATIONS_TYPE1_1],
  prices: [VISIT_PRICE_TYPE1_1, VISIT_PRICE_TYPE1_2, VISIT_PRICE_TYPE1_3],
  competition: VISIT_COMPETITION_TYPE1,
  competitionPrices: [
    VISIT_COMPETITION_PRICE_TYPE1_1,
    VISIT_COMPETITION_PRICE_TYPE1_2,
  ],
  competitionPromoPlv: [
    VISIT_COMPETITION_PROMO_TYPE1,
    VISIT_COMPETITION_PLV_TYPE1,
  ],
  pdl: VISIT_COMPETITION_PDL_TYPE1,
}

export const VISIT_THIRD_PANEL_MISSION_TYPE2_V1: IMissionData = {
  rotations: [VISIT_ROTATIONS_TYPE2_2, VISIT_ROTATIONS_TYPE2_1],
  competition: VISIT_COMPETITION_TYPE2,
}

export const VISIT_THIRD_PANEL_MISSION_TYPE3_V1: IMissionData = {
  rotations: [VISIT_ROTATIONS_TYPE3_2, VISIT_ROTATIONS_TYPE3_1],
  competition: VISIT_COMPETITION_TYPE3,
}

export const VISIT_THIRD_PANEL_MISSION_TYPE4_V1: IMissionData = {
  rotations: [VISIT_ROTATIONS_TYPE4_2, VISIT_ROTATIONS_TYPE4_1],
  competition: VISIT_MULTI_COMPETITION_TYPE4,
  competitionPromoPlv: [
    VISIT_COMPETITION_PROMO_TYPE4,
    VISIT_COMPETITION_PLV_TYPE4,
  ],
  pdl: VISIT_COMPETITION_PDL_TYPE4,
  compMultiselect: true,
}

export const VISIT_THIRD_PANEL_MISSION_TYPE1_V2: IMissionData = {
  rotations: [VISIT_ROTATIONS_TYPE5_2, VISIT_ROTATIONS_TYPE5_1],
  competition: VISIT_MULTI_COMPETITION_TYPE5, // TODO change this value when have details from client
  promo: VISIT_COMPETITION_PROMO_TYPE5,
  months: VISIT_COMPETITION_MONTHS_TYPE5,
  competitionPromoPlv: [VISIT_COMPETITION_PLV_TYPE5],
  compMultiselect: true,
  presence: VISIT_PRESENCE_TYPE5,
  references: {
    key: VISIT_REFERENCES_TYPE5,
    options: VISIT_REFERENCES_OPTIONS_TYPE5,
  },
}

export const VISIT_THIRD_PANEL_MISSION_TYPE2_V2: IMissionData = {
  rotations: [VISIT_ROTATIONS_TYPE4_2, VISIT_ROTATIONS_TYPE4_1],
  competition: VISIT_MULTI_COMPETITION_TYPE4,
  promo: VISIT_COMPETITION_PROMO_TYPE4,
  months: VISIT_COMPETITION_MONTHS_TYPE4,
  competitionPromoPlv: [VISIT_COMPETITION_PLV_TYPE4],
  compMultiselect: true,
  presence: VISIT_PRESENCE_TYPE4,
}

export const VISIT_THIRD_PANEL_MISSION_TYPE3_V2: IMissionData = {
  rotations: [VISIT_ROTATIONS_TYPE6_2, VISIT_ROTATIONS_TYPE6_1],
  competition: VISIT_MULTI_COMPETITION_TYPE6, // TODO change this value when have details from client
  promo: VISIT_COMPETITION_PROMO_TYPE6,
  months: VISIT_COMPETITION_MONTHS_TYPE6,
  competitionPromoPlv: [VISIT_COMPETITION_PLV_TYPE6],
  compMultiselect: true,
}

// TAB4
export const PHOTO_1 = 'photo1'
export const PHOTO_2 = 'photo2'
export const PHOTO_3 = 'photo3'

export const VISIT_IMAGE_UPLOAD_PLV = 'plv'
export const VISIT_IMAGE_UPLOAD_MERCH0 = 'merch'
export const VISIT_IMAGE_UPLOAD_MERCH1 = 'merch1'
export const VISIT_IMAGE_UPLOAD_MERCH2 = 'merch2'
export const VISIT_IMAGE_UPLOAD_MERCH3 = 'merch3'
export const VISIT_IMAGE_UPLOAD_MERCH4 = 'merch4'

export const VISIT_IMAGE_UPLOAD_MISSION_TYPE1 = 'Compeed'
export const VISIT_IMAGE_UPLOAD_MISSION_TYPE2 = 'Acc45'
export const VISIT_IMAGE_UPLOAD_MISSION_TYPE3 = 'Acc8'
export const VISIT_IMAGE_UPLOAD_MISSION_TYPE4 = 'Cicatridine'
export const VISIT_IMAGE_UPLOAD_MISSION_TYPE5 = VISIT_MISSION_TYPE4
export const VISIT_IMAGE_UPLOAD_MISSION_TYPE6 = VISIT_MISSION_TYPE5
export const VISIT_IMAGE_UPLOAD_MISSION_TYPE7 = VISIT_MISSION_TYPE6

export const IMAGE_FILE_MISSION_TYPE1 = [
  VISIT_IMAGE_UPLOAD_PLV + VISIT_IMAGE_UPLOAD_MISSION_TYPE1,
  VISIT_IMAGE_UPLOAD_MERCH0 + VISIT_IMAGE_UPLOAD_MISSION_TYPE1,
]

export const IMAGE_FILE_MISSION_TYPE2 = [
  VISIT_IMAGE_UPLOAD_PLV + VISIT_IMAGE_UPLOAD_MISSION_TYPE2,
  VISIT_IMAGE_UPLOAD_MERCH2 + VISIT_IMAGE_UPLOAD_MISSION_TYPE2,
  VISIT_IMAGE_UPLOAD_MERCH3 + VISIT_IMAGE_UPLOAD_MISSION_TYPE2,
]

export const IMAGE_FILE_MISSION_TYPE3 = [
  VISIT_IMAGE_UPLOAD_PLV + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
  VISIT_IMAGE_UPLOAD_MERCH1 + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
  VISIT_IMAGE_UPLOAD_MERCH2 + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
  VISIT_IMAGE_UPLOAD_MERCH3 + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
  VISIT_IMAGE_UPLOAD_MERCH4 + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
]

export const IMAGE_FILE_MISSION_TYPE4 = [
  VISIT_IMAGE_UPLOAD_PLV + VISIT_IMAGE_UPLOAD_MISSION_TYPE4,
  VISIT_IMAGE_UPLOAD_MERCH0 + VISIT_IMAGE_UPLOAD_MISSION_TYPE4,
]

export const IMAGE_FILE_MISSION_TYPE5 = [
  VISIT_IMAGE_UPLOAD_PLV + VISIT_IMAGE_UPLOAD_MISSION_TYPE5,
  VISIT_IMAGE_UPLOAD_MERCH0 + VISIT_IMAGE_UPLOAD_MISSION_TYPE5,
]

export const IMAGE_FILE_MISSION_TYPE6 = [
  VISIT_IMAGE_UPLOAD_PLV + VISIT_IMAGE_UPLOAD_MISSION_TYPE6,
  VISIT_IMAGE_UPLOAD_MERCH0 + VISIT_IMAGE_UPLOAD_MISSION_TYPE6,
]

export const IMAGE_FILE_MISSION_TYPE7 = [
  VISIT_IMAGE_UPLOAD_PLV + VISIT_IMAGE_UPLOAD_MISSION_TYPE7,
  VISIT_IMAGE_UPLOAD_MERCH0 + VISIT_IMAGE_UPLOAD_MISSION_TYPE7,
]

export const IMAGE_FILE_MISSIONS_V1 = [
  IMAGE_FILE_MISSION_TYPE1 as (keyof ILastVisitReportImages)[],
  IMAGE_FILE_MISSION_TYPE5 as (keyof ILastVisitReportImages)[],
  IMAGE_FILE_MISSION_TYPE4 as (keyof ILastVisitReportImages)[],
  IMAGE_FILE_MISSION_TYPE2 as (keyof ILastVisitReportImages)[],
  IMAGE_FILE_MISSION_TYPE3 as (keyof ILastVisitReportImages)[],
]

export const IMAGE_FILE_MISSIONS_V2 = [
  IMAGE_FILE_MISSION_TYPE6 as (keyof ILastVisitReportImages)[],
  IMAGE_FILE_MISSION_TYPE5 as (keyof ILastVisitReportImages)[],
  IMAGE_FILE_MISSION_TYPE7 as (keyof ILastVisitReportImages)[],
]

export const VISIT_REPORT_IMAGE_TYPES_LIST: (keyof IVisitReport)[] = [
  ...(IMAGE_FILE_MISSION_TYPE5 as (keyof IVisitReport)[]),
  ...(IMAGE_FILE_MISSION_TYPE6 as (keyof IVisitReport)[]),
  ...(IMAGE_FILE_MISSION_TYPE7 as (keyof IVisitReport)[]),
]

export const IMAGE_FILE_MISSION_TYPE2_TITLE_HINT = [
  VISIT_IMAGE_UPLOAD_MERCH2 + VISIT_IMAGE_UPLOAD_MISSION_TYPE2,
  VISIT_IMAGE_UPLOAD_MERCH3 + VISIT_IMAGE_UPLOAD_MISSION_TYPE2,
]

export const IMAGE_FILE_MISSION_TYPE3_TITLE_HINT = [
  VISIT_IMAGE_UPLOAD_MERCH1 + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
  VISIT_IMAGE_UPLOAD_MERCH2 + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
  VISIT_IMAGE_UPLOAD_MERCH3 + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
  VISIT_IMAGE_UPLOAD_MERCH4 + VISIT_IMAGE_UPLOAD_MISSION_TYPE3,
]

export const IMAGE_FILE_MISSION_TYPES_TITLE_HINT = [
  ...IMAGE_FILE_MISSION_TYPE2_TITLE_HINT,
  ...IMAGE_FILE_MISSION_TYPE3_TITLE_HINT,
]

//
export const VISIT_REPORT_FIRST_PANEL_LIST: (keyof IVisitReport)[] = [
  VISIT_DATE,
  VISIT_DURATION,
  VISIT_CONTACT,
  VISIT_ACTION_TYPE,
  VISIT_OUTCOME,
]

export const VISIT_REPORT_ROTATIONS = [
  VISIT_ROTATIONS_TYPE4_1,
  VISIT_ROTATIONS_TYPE4_2,
  VISIT_ROTATIONS_TYPE5_1,
  VISIT_ROTATIONS_TYPE5_2,
  VISIT_ROTATIONS_TYPE6_1,
  VISIT_ROTATIONS_TYPE6_2,
]

// Visit report reset fields
export const VISIT_REPORT_RESET_EMPTY = [
  VISIT_VISIT_DATE,
  VISIT_COMMENT,
  VISIT_PDL_TYPE4,
  VISIT_PDL_TYPE5,
  VISIT_PDL_TYPE6,
]

export const VISIT_REPORT_RESET_EMPTY_ARRAY = [
  VISIT_PLV_TYPE4, // Mission Nuk
  VISIT_COMPETITIONS_TYPE4,
  VISIT_RANGE_TYPE4,
  VISIT_DUAL_LAYOUT_TYPE4,
  VISIT_LINIAR_GAIN_TYPE4,
  VISIT_PROMO_TYPE4,
  VISIT_MONTHS_TYPE4,
  VISIT_TRAINING_TYPE4,
  VISIT_COMPETITION_PROMO_TYPE4,
  VISIT_COMPETITION_MONTHS_TYPE4,
  VISIT_COMPETITION_PLV_TYPE4,
  VISIT_PRESENCE_TYPE4,
  VISIT_PLV_TYPE5, // Mission Hipp
  VISIT_COMPETITIONS_TYPE5,
  VISIT_RANGE_TYPE5,
  VISIT_DUAL_LAYOUT_TYPE5,
  VISIT_LINIAR_GAIN_TYPE5,
  VISIT_PROMO_TYPE5,
  VISIT_MONTHS_TYPE5,
  VISIT_TRAINING_TYPE5,
  VISIT_COMPETITION_PROMO_TYPE5,
  VISIT_COMPETITION_MONTHS_TYPE5,
  VISIT_COMPETITION_PLV_TYPE5,
  VISIT_PRESENCE_TYPE5,
  VISIT_REFERENCES_TYPE5,
  VISIT_PLV_TYPE6, // Mission Marque3
  VISIT_COMPETITIONS_TYPE6,
  VISIT_RANGE_TYPE6,
  VISIT_DUAL_LAYOUT_TYPE6,
  VISIT_LINIAR_GAIN_TYPE6,
  VISIT_PROMO_TYPE6,
  VISIT_MONTHS_TYPE6,
  VISIT_TRAINING_TYPE6,
  VISIT_COMPETITION_PROMO_TYPE6,
  VISIT_COMPETITION_MONTHS_TYPE6,
  VISIT_COMPETITION_PLV_TYPE6,
]

export const VISIT_REPORT_RESET_NULL = [
  VISIT_DURATION,
  VISIT_OUTCOME_ID,
  VISIT_ACTION_TYPE_ID,
]

// NVR validation fields
export const VISIT_REPORT_SECOND_PANEL_VALIDATION_FIELDS: string[] = [
  VISIT_RANGE_TYPE4, // Mission Nuk
  VISIT_DUAL_LAYOUT_TYPE4,
  VISIT_LINIAR_GAIN_TYPE4,
  VISIT_TRAINING_TYPE4,
  VISIT_PROMO_TYPE4,
  VISIT_RANGE_TYPE5, // Mission Hipp
  VISIT_DUAL_LAYOUT_TYPE5,
  VISIT_LINIAR_GAIN_TYPE5,
  VISIT_TRAINING_TYPE5,
  VISIT_PROMO_TYPE5,
  VISIT_RANGE_TYPE6, // Mission Marque3
  VISIT_DUAL_LAYOUT_TYPE6,
  VISIT_LINIAR_GAIN_TYPE6,
  VISIT_TRAINING_TYPE6,
  VISIT_PROMO_TYPE6,
  ...VISIT_PDL_LIST,
  ...VISIT_PLV_LIST,
  ...VISIT_MONTHS_LIST,
]

export const VISIT_REPORT_THIRD_PANEL_VALIDATION_FIELDS: string[] = [
  ...VISIT_REPORT_ROTATIONS,
  ...VISIT_COMPETITION_LIST,
  ...VISIT_COMPETITION_MONTHS_LIST,
  VISIT_COMPETITION_PROMO_TYPE4,
  VISIT_COMPETITION_PLV_TYPE4,
  VISIT_PRESENCE_TYPE4,
  VISIT_COMPETITION_PROMO_TYPE5,
  VISIT_COMPETITION_PLV_TYPE5,
  VISIT_PRESENCE_TYPE5,
  VISIT_REFERENCES_TYPE5,
  VISIT_COMPETITION_PROMO_TYPE6,
  VISIT_COMPETITION_PLV_TYPE6,
]

export const VISIT_ACTION_TYPE_APPEL = 'Appel'
export const VISIT_OUTCOME_VISITE_DISTANCE = 'Visite argu a Distance'

export const EVENT_SECTION_APPOINTMENTS_COUNT = 1
