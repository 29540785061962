import L from 'leaflet'
import { iconUserLocation } from '../../assets/images/images'

const iconUser = new L.Icon({
  iconUrl: iconUserLocation,
  iconRetinaUrl: iconUserLocation,
  popupAnchor: [-3, -76],
  iconSize: [40, 94],
  iconAnchor: [20, 47],
  className: 'leafletIconUser',
})

export { iconUser }
