import React, { FC } from 'react'
import { IFileToDownload } from '../../interfaces/ICommonComponents'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

export const FileToDownload: FC<IFileToDownload> = ({ url, fileName }) => {
  return (
    <div className="fileToDownloadWrapper col mb2">
      <object data={`${url}#toolbar=0`} type="application/pdf">
        <iframe title={fileName} src={`${url}#toolbar=0`}>
          <DynamicFormattedMessage
            tag="p"
            id="file.download.noSupport"
            className="dBlock textPrimary textCenter px15 py15"
          />
        </iframe>
      </object>
    </div>
  )
}
