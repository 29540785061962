import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import {
  IMissionData,
  IVisitReportForm,
} from '../../../../interfaces/IVisitReports'
import SelectInput from '../../../form/SelectInput'
import {
  getDynamicButtonDefaultValue,
  getNWord,
} from '../../../../utils/helpers'
import DynamicButtons from '../../../form/DynamicButtons'
import { RADIO_OPTIONS_LIST } from '../../../../constants/form'
import MultipleSelect from '../../../form/MultipleSelect'
import {
  IMultipleSelectOption,
  IFormSelectOption,
} from '../../../../interfaces/IForms'
import { DYNAMIC_BUTTONS_INPUT_TYPE } from '../../../../enums/common'
import { TDynamicButton } from '../../../../interfaces/ICommonComponents'
import { VisitReportPromoAndMonthsSection } from './VisitReportPromoAndMonthsSection'

export const VisitReportCompetitionSection: FC<IMissionData> = ({
  mission,
  competition,
  competitionPromoPlv,
  defaultData,
  compMultiselect,
  promo,
  months,
  presence,
  references,
}) => {
  const { errors, control, register, setValue } = useFormContext()

  return (
    <div className="competitionSection ml2">
      <DynamicFormattedMessage
        id="form.section.competition.label"
        className="competitionTitle dInlineBlock textPrimary fontBold mb2 pb05"
        tag="h4"
      />
      {competition?.length && (
        <div className="row mb2">
          <div className="px15 mb1">
            <DynamicFormattedMessage
              id="form.field.competition.label"
              className="textPrimary fontBold"
            />
          </div>
          {compMultiselect ? (
            <MultipleSelect
              id={`comp${mission}`}
              options={competition as IMultipleSelectOption[]}
              defaultValue={[]}
              hideMultiRemove={false}
              showLabel={false}
              className="wFull"
              parentClassName="wFull px15"
              control={control}
              error={errors[`comp${mission}`]}
            />
          ) : (
            <SelectInput
              id={`comp${mission}`}
              name={`comp${mission}`}
              options={competition as IFormSelectOption[]}
              register={register({ required: false })}
              error={errors[`${competition}`]}
              defaultValue={
                (defaultData as IVisitReportForm)[
                  `${competition}` as keyof IVisitReportForm
                ] as string
              }
              defaultPlaceholder={true}
              customClass="col12"
              renderLabel={false}
            />
          )}
        </div>
      )}
      {(promo?.length || months?.length) && (
        <VisitReportPromoAndMonthsSection
          defaultData={defaultData!}
          promo={promo}
          months={months}
          customLabel="form.field.competitionPromo.label"
        />
      )}
      {competitionPromoPlv?.length && (
        <div className="row alignItemsCenter">
          {competitionPromoPlv.map((value) => (
            <div className="dFlex alignItemsCenter px15 mb2 mr2" key={value}>
              <DynamicFormattedMessage
                id={`form.field.competition${getNWord(value, 1)}.label`}
                className="textPrimary fontBold mr2"
              />
              <DynamicButtons
                optionsArray={RADIO_OPTIONS_LIST}
                register={register}
                name={value}
                customClass="noLabel noWrap actionButtons"
                defaultValue={getDynamicButtonDefaultValue(
                  (defaultData as IVisitReportForm)[
                    `${value}` as keyof IVisitReportForm
                  ] as TDynamicButton
                )}
                hideLabel={true}
                inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
                setValue={setValue}
              />
            </div>
          ))}
        </div>
      )}
      <div className="row mb15 alignItemsCenter">
        {presence && (
          <div className="colMd6 mb15 alignItemsCenter">
            <DynamicFormattedMessage
              id={`form.section.${presence}.label`}
              className="textPrimary fontBold dBlock"
            />
            <div className="row px1">
              <div className="px05 py05 mr05">
                <DynamicButtons
                  optionsArray={RADIO_OPTIONS_LIST}
                  register={register}
                  name={presence}
                  customClass="noLabel noWrap actionButtons"
                  defaultValue={getDynamicButtonDefaultValue(
                    (defaultData as IVisitReportForm)[
                      `${presence}` as keyof IVisitReportForm
                    ] as TDynamicButton
                  )}
                  hideLabel={true}
                  inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
                  setValue={setValue}
                />
              </div>
            </div>
          </div>
        )}
        {references && (
          <div className="colMd6 mb15 alignItemsCenter">
            <DynamicFormattedMessage
              id="form.section.references.title.label"
              className="textPrimary fontBold dBlock"
            />
            <div className="row px15">
              <MultipleSelect
                id={references.key}
                options={references.options}
                defaultValue={[]}
                hideMultiRemove={false}
                showLabel={false}
                className="wFull"
                parentClassName="wFull"
                control={control}
                error={errors[`${references.key}`]}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
