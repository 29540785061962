import React, { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicButtons from '../../form/DynamicButtons'
import FormGroupDate from '../../form/FormGroupDate'
import {
  DATE_PICKER_TIME_INTERVALS_MIN,
  DATEPICKER_TIME_FORMAT,
  RANGE_INPUT_DEFAULT,
  RANGE_INPUT_MAX,
  RANGE_INPUT_MIN,
  RANGE_INPUT_STEP,
} from '../../../enums/common'
import { ICustomTabForm } from '../../../interfaces/IVisitReports'
import SelectInput from '../../form/SelectInput'
import { useLoadData } from '../../../hooks/UseLoadData'
import { getContactPersons } from '../../../services/appointmentsService'
import {
  getContactOutcome,
  getContactTypes,
} from '../../../services/visitReportsService'
import WysiwygSection from '../../form/WysiwygSection'
import RangeStepSection from '../../form/RangeStepSection'
import { dateInterval, getClosingHours } from '../../../utils/helpers'
import {
  isGreaterThanOpeningHours,
  isSmallerThanClosingHours,
  getMinHour,
} from '../../../utils/appointments'
import { getClientContactPersons } from '../../../store/selectors/clientContactPersonsSelectors'
import {
  getContactOutcomesValue,
  getContactTypesValue,
} from '../../../store/selectors/sharedDataSelectors'
import MultipleSelect from '../../form/MultipleSelect'
import { prepareContactOutcomeOptions } from '../../../utils/visitReports'
import {
  VISIT_ACTION_TYPE_ID,
  VISIT_OUTCOME_ID,
} from '../../../constants/visitReport'
import Alerts from '../../alerts/Alerts'

export const VisitReportFirstPanel: FC<ICustomTabForm> = ({
  clientId,
  defaultData,
  visitDate,
  setVisitDate,
}) => {
  const {
    register,
    errors,
    control,
    setValue,
    getValues,
    watch,
  } = useFormContext()

  const [visiteDuration, setVisiteDuration] = useState(RANGE_INPUT_DEFAULT)
  const [maxDuration, setMaxDuration] = useState(RANGE_INPUT_MAX)
  const [hoursBeforeClose, setHoursBeforeClose] = useState(RANGE_INPUT_MAX)

  const contactTypes = useLoadData(() => getContactTypes(), {
    fetchFromRedux: true,
    reduxSelector: getContactTypesValue,
  })
  const contactOutcome = useLoadData(() => getContactOutcome(), {
    fetchFromRedux: true,
    reduxSelector: getContactOutcomesValue,
  })
  const watchField = watch([VISIT_ACTION_TYPE_ID])

  const contactPersons = useLoadData(() => getContactPersons(clientId), {
    fetchFromRedux: true,
    reduxSelector: getClientContactPersons,
    reduxStorePath: clientId?.toString(),
  })

  const invalidTime =
    !isGreaterThanOpeningHours(new Date()) ||
    !isSmallerThanClosingHours(new Date())
  const currentTime = invalidTime ? getMinHour(new Date()) : new Date()

  const date = {
    id: 'visitDate',
    value: visitDate.startDate !== '' ? visitDate.startDate : currentTime,
    label: 'form.field.visitDate',
    setValue: setVisitDate,
    classes: 'inputField inputDate datepickerLeft',
    error: errors.visitDate,
    displayTime: true,
    timeIntervals: DATE_PICKER_TIME_INTERVALS_MIN,
    format: DATEPICKER_TIME_FORMAT,
    maxDate: new Date(),
    control,
    rules: {
      required: true,
    },
  }

  const visiteDurationFinal = {
    id: 'duration',
    name: 'duration',
    value: visiteDuration,
    setValue: setVisiteDuration,
    defValue: RANGE_INPUT_DEFAULT,
    step: RANGE_INPUT_STEP,
    minValue: RANGE_INPUT_MIN,
    maxValue: maxDuration,
    hasLabel: true,
    control,
    error: errors.duration,
    rules: {
      required: false,
    },
  }

  useEffect(() => {
    const closingHours = date.value && getClosingHours(date.value as Date)

    setHoursBeforeClose(dateInterval(date.value, closingHours))
  }, [date.value])

  useEffect(() => {
    setMaxDuration(Math.min(hoursBeforeClose, RANGE_INPUT_MAX))
  }, [hoursBeforeClose])

  useEffect(() => {
    if (getValues(visiteDurationFinal.id) > maxDuration) {
      setValue(visiteDurationFinal.id, maxDuration)
      setVisiteDuration(maxDuration)
    }
  }, [maxDuration, setValue, visiteDurationFinal.id, getValues])

  const resetOutcome = () => {
    setValue(VISIT_OUTCOME_ID, null)
  }

  const contactOutcomeOptions = useMemo(
    () =>
      prepareContactOutcomeOptions(
        contactOutcome?.data,
        watchField.contactTypeId
      ),
    [contactOutcome, watchField.contactTypeId]
  )

  return (
    <>
      <Alerts status={contactPersons.status} message={contactPersons.message} />
      <div className="row">
        <div className="colMd4 mb15">
          <FormGroupDate startDate={date} startDateOnly={true} />
        </div>
        <div className="colMd4 relative inputRange pl3 pr4">
          <RangeStepSection range={visiteDurationFinal} />
        </div>
        <div className="colMd4 mb15">
          <SelectInput
            id="contactPersonId"
            name="contactPersonId"
            options={contactPersons.data}
            register={register}
            defaultValue={contactPersons.data[0]?.value}
          />
        </div>
      </div>
      <div className="row my15">
        <div className="colMd3 mb15 pr0">
          <DynamicButtons
            optionsArray={contactTypes.data}
            register={register({ required: true })}
            error={errors.contactTypeId}
            name="contactTypeId"
            customClass="actionButtons"
            defaultValue={defaultData.contactTypeId || ''}
            onChange={resetOutcome}
          />
        </div>
        <div className="colMd4 mb15">
          <MultipleSelect
            id="contactOutcomeId"
            isMulti={false}
            options={contactOutcomeOptions}
            defaultValue={defaultData.contactOutcomeId}
            hideMultiRemove={true}
            showLabel={true}
            className="wFull"
            parentClassName="wFull"
            control={control}
            rules={{
              required: true,
            }}
            error={errors.contactOutcomeId}
            closeMenuOnSelect={true}
          />
        </div>
      </div>
      <div className="row mb2">
        <div className="col12">
          <WysiwygSection
            control={control}
            errors={errors}
            defaultValue={defaultData.comment}
          />
        </div>
      </div>
    </>
  )
}
