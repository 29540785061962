import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IChartUser,
  IDashboardCa,
  IDashboardCaFilters,
  ITurnoverData,
} from '../../interfaces/IDashboard'
import {
  dashboardCaFiltersInitialState,
  dashboardCaUserDefault,
} from '../../constants/dashboard'

const dashboardCaReducer = createSlice({
  name: 'dashboardCaReducer',
  initialState: {
    filtersCa: dashboardCaFiltersInitialState,
    defaultSelection: true,
    clearQuery: false,
    currentChartUser: dashboardCaUserDefault,
    chartData: {} as ITurnoverData,
  } as IDashboardCa,
  reducers: {
    setDashboardCaData: (state, { payload }: PayloadAction<IDashboardCa>) => ({
      ...state,
      ...payload,
    }),
    setCaFilters: (state, { payload }: PayloadAction<IDashboardCaFilters>) => ({
      ...state,
      ...{ filtersCa: payload },
    }),
    setIsDefaultSelection: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      ...{ defaultSelection: payload },
    }),
    setClearQuery: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      ...{ clearQuery: payload },
    }),
    setCurrentChartUser: (state, { payload }: PayloadAction<IChartUser>) => ({
      ...state,
      ...{ currentChartUser: payload },
    }),
    setChartData: (state, { payload }: PayloadAction<ITurnoverData>) => ({
      ...state,
      ...{ chartData: payload },
    }),
  },
})

export const dashboardCaActions = dashboardCaReducer.actions

export default dashboardCaReducer.reducer
