import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import {
  VISIT_ACTION_TYPE_1,
  VISIT_ACTION_TYPE_ID,
} from '../../constants/visitReport'

export const useIsTabDisabled = () => {
  const { watch } = useFormContext()
  const watchField = watch([VISIT_ACTION_TYPE_ID])

  return useMemo(
    () =>
      !!watchField.contactTypeId &&
      watchField.contactTypeId !== VISIT_ACTION_TYPE_1,
    [watchField.contactTypeId]
  )
}
