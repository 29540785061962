import React, { FC } from 'react'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import { OfflineSynchroContent } from '../components/offlineSynchro/OfflineSynchroContent'
import { OFFLINE_SYNCHRO } from '../constants/routes'

const OfflineSynchro: FC = () => {
  return (
    <section className="pageContentWrapper offlineSynchroPage pl75">
      <div className="px3 py3">
        <PageTitleSection pageName={OFFLINE_SYNCHRO} />
        <OfflineSynchroContent />
      </div>
    </section>
  )
}

export default OfflineSynchro
