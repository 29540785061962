import React, { FC } from 'react'
import { IImportFilesSection } from '../../../../interfaces/IVisitReports'
import { VisitReportImportFile } from './VisitReportImportFile'
import { IMAGE_FILE_MISSIONS_V2 } from '../../../../constants/visitReport'
import { VisitReportMissionTitle } from './VisitReportMissionTitle'
import { getNWord } from '../../../../utils/helpers'

export const VisitReportForthPanelMission: FC<IImportFilesSection> = ({
  camera,
  defaultData,
  images,
  isCameraOpen,
  updateForm,
}) => {
  return (
    <div className="imagesSection row">
      {IMAGE_FILE_MISSIONS_V2.map((mission, index) => (
        <div
          className={`missionSection colMd6 mt0 mb15 missionSection${getNWord(
            mission[0],
            1
          )}`}
          key={`mission${index + 1}`}
        >
          <VisitReportMissionTitle mission={getNWord(mission[0], 1)} />
          <VisitReportImportFile
            camera={camera}
            defaultData={defaultData}
            images={images}
            isCameraOpen={isCameraOpen}
            missionData={mission}
            updateForm={updateForm}
          />
        </div>
      ))}
    </div>
  )
}
