import React, { FC } from 'react'
import { IHistoryAnnualTotal } from '../../../../interfaces/IHistory'
import { roundTwoDecimals } from '../../../../utils/helpers'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'

export const TrimesterTableRow: FC<IHistoryAnnualTotal> = ({
  currentYear,
  previousYear,
  name,
  customClass = '',
}) => {
  return (
    <tr className={`textPrimary textCustomXs ${customClass}`}>
      <td>
        {name || (
          <DynamicFormattedMessage
            id="page.history.annual.total"
            className="textUppercase"
          />
        )}
      </td>
      <td className="textCenter">{currentYear?.T1?.quantity || '-'}</td>
      <td className="textCenter">{currentYear?.T2?.quantity || '-'}</td>
      <td className="textCenter">{currentYear?.T3?.quantity || '-'}</td>
      <td className="textCenter">{currentYear?.T4?.quantity || '-'}</td>
      <td className="textCenter">
        {roundTwoDecimals(currentYear?.T1?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(currentYear?.T2?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(currentYear?.T3?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(currentYear?.T4?.value) || '-'}
      </td>
      <td className="textCenter">{previousYear?.T1?.quantity || '-'}</td>
      <td className="textCenter">{previousYear?.T2?.quantity || '-'}</td>
      <td className="textCenter">{previousYear?.T3?.quantity || '-'}</td>
      <td className="textCenter">{previousYear?.T4?.quantity || '-'}</td>
      <td className="textCenter">
        {roundTwoDecimals(previousYear?.T1?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(previousYear?.T2?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(previousYear?.T3?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(previousYear?.T4?.value) || '-'}
      </td>
    </tr>
  )
}
