import React from 'react'

export const ScreenOverlay = ({
  active,
  handleOnClick,
}: {
  active: boolean
  handleOnClick: () => void
}) => {
  return (
    <span
      className={`tooltipOverlay ${active ? '' : 'hidden'}`}
      onClick={handleOnClick}
      data-qa="toggleTooltipOverlay"
    />
  )
}
