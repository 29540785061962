import React, { FC } from 'react'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import { DASHBOARD } from '../constants/routes'
import { DashboardContent } from '../components/dashboard/DashboardContent'

const Dashboard: FC = () => {
  return (
    <section className="pageContentWrapper dashboardPage pl75">
      <div className="px3 py3">
        <PageTitleSection pageName={DASHBOARD} />
        <DashboardContent />
      </div>
    </section>
  )
}

export default Dashboard
