import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import { useDispatch } from 'react-redux'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { CustomImageUpload } from '../../../form/CustomImageUpload'
import { PHOTO_FILE_NAME } from '../../../../enums/common'
import { PhotoCapture } from '../../../form/PhotoCapture'
import { ImagePlaceholder } from '../../../clientVisitReport/ImagePlaceholder'
import {
  IImportFilesSection,
  IVisitReportForm,
} from '../../../../interfaces/IVisitReports'
import { getNWord } from '../../../../utils/helpers'
import { IMAGE_FILE_MISSION_TYPES_TITLE_HINT } from '../../../../constants/visitReport'
import { ReactComponent as IconInfo } from '../../../../assets/images/iconInfo.svg'
import { newVisitReportActions } from '../../../../store/reducers/newVisitReportReducer'
import { convertFormDatetime } from '../../../../utils/visitReports'

export const VisitReportImportFile: FC<IImportFilesSection> = ({
  camera,
  defaultData,
  images,
  isCameraOpen,
  missionData,
  updateForm,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { register, getValues } = useFormContext()
  const { photoRef, hasUserMedia, grantedPermission, isVideoPlaying } = camera

  return (
    <div className="imagesSectionWrapper">
      {missionData?.map((imgKey) => {
        const imageValue = getValues(imgKey)
        const defaultValue = defaultData[imgKey]
          ? `${defaultData[imgKey as keyof IVisitReportForm]}`
          : JSON.stringify({
              keepOld: images![imgKey]?.content,
            })
        const image = imageValue?.length && JSON.parse(imageValue)
        const isImgDisabled =
          (image?.fileName && image?.fileName !== PHOTO_FILE_NAME) ||
          !!image?.keepOld ||
          !hasUserMedia ||
          !grantedPermission ||
          !isVideoPlaying

        return (
          <div className="mb2" key={imgKey}>
            <input
              type="hidden"
              name={imgKey}
              id={imgKey}
              ref={register}
              defaultValue={defaultValue}
            />
            <div className="imageTitle mt2 mb15">
              <DynamicFormattedMessage
                id={`imageType.${getNWord(imgKey, 0)}.label`}
                tag="h4"
                className="textPrimary fontBold textUppercase dInline"
              />
              {IMAGE_FILE_MISSION_TYPES_TITLE_HINT.map((title, index) =>
                title === imgKey ? (
                  <div
                    key={`image${index + 1}`}
                    className="dInlineBlock vAlign"
                  >
                    <IconInfo
                      stroke="currentColor"
                      fill="currentColor"
                      className="ml05"
                      data-tip={intl.formatMessage({
                        id: `imageType.titleInfo.${imgKey}.label`,
                      })}
                    />
                    <ReactTooltip
                      className="fontMediumSize"
                      place="bottom"
                      type="info"
                      effect="solid"
                      backgroundColor="#297380"
                    />
                  </div>
                ) : (
                  ''
                )
              )}
            </div>
            <div className="row mx0">
              <div className="colSm12 colLg5">
                <PhotoCapture
                  type={imgKey}
                  isCameraOpen={isCameraOpen}
                  photoRef={photoRef}
                  isDisabled={isImgDisabled}
                  onPhotoUpdateCallback={() => {
                    dispatch(
                      newVisitReportActions.setNewVisitReport({
                        ...convertFormDatetime(getValues() as IVisitReportForm),
                      })
                    )
                  }}
                />
                <CustomImageUpload
                  photoPath={images![imgKey]?.content || ''}
                  type={imgKey}
                  key={imgKey}
                  updateForm={updateForm}
                  isDisabled={
                    image?.fileName && image?.fileName === PHOTO_FILE_NAME
                  }
                  showUploadBtn={!isImgDisabled}
                  onUploadCallback={() => {
                    dispatch(
                      newVisitReportActions.setNewVisitReport({
                        ...convertFormDatetime(getValues() as IVisitReportForm),
                      })
                    )
                  }}
                />
              </div>
              {!image?.keepOld && !image?.dataURL ? (
                <div className="colSm12 colLg7">
                  <ImagePlaceholder />
                </div>
              ) : (
                <div className="customImageUploadItem row colMd7 justifyContentLeft">
                  <div className="customImageUploadImagePreview colMd12 pl2lg">
                    <img
                      src={image?.dataURL || image?.keepOld}
                      alt="Capture Preview"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
