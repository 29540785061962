import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { utcToZonedTime } from 'date-fns-tz'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { DateSelectArg, DatesSetArg } from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import { handleError } from '../../utils/api'
import {
  getDeviceInitialEventType,
  getDeviceInitialView,
  getMonthViewAvailableSlot,
} from '../../utils/agenda'
import { IClientCalendarEvent } from '../../interfaces/IClientDetails'
import {
  CALENDAR_INITIAL_EVENT_TYPE,
  CALENDAR_INITIAL_VIEW,
} from '../../constants/api'
import {
  getClientAppointmentsList,
  getClientReportsList,
} from '../../services/getClientsListService'
import {
  EVENT_TYPE_RANDEZ_VOUS,
  EVENT_TYPE_VISIT_REPORT,
} from '../../enums/agenda'
import { ROUTES } from '../../enums/routes'
import { defaultDate } from '../../utils/helpers'
import {
  MAX_TIME_DISPLAYED,
  MIN_TIME_DISPLAYED,
  CALENDAR_VIEW_MONTH,
} from '../../constants/calendar'
import { getClientAppointmentsValue } from '../../store/selectors/clientAppointmentsSelectors'
import {
  sharedDataSelectors,
  statusNetworkSelector,
} from '../../store/selectors'
import { IResponseData } from '../../interfaces/ICommonComponents'
import { STATUS } from '../../enums/common'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'

export const useCalendarHook = (calendarUserId?: string) => {
  const intl = useIntl()
  const history = useHistory()
  const { state } = useLocation()
  const calendarRef = React.createRef()
  const initialView = getDeviceInitialView()
  const initialEventType = getDeviceInitialEventType()
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const [events, setEvents] = useState<IResponseData<IClientCalendarEvent[]>>({
    data: [] as IClientCalendarEvent[],
    status: STATUS.PENDING,
    message: '',
    messageCode: '',
  })
  const [eventsType, setEventsType] = useState(
    history.action === 'POP' ? initialEventType : EVENT_TYPE_RANDEZ_VOUS
  )

  const [datesArg, setDatesArg] = useState<DatesSetArg>()
  const userId =
    calendarUserId ??
    useSelector(sharedDataSelectors.getUserSavedData).selectedUser

  const updateCalendarView = (view: string) => {
    // @ts-ignore
    calendarRef.current.getApi().changeView(view)
    localStorage.setItem(CALENDAR_INITIAL_VIEW, view)
  }

  useEffect(() => {
    localStorage.setItem(CALENDAR_INITIAL_EVENT_TYPE, eventsType)
  }, [eventsType])

  const handleDates = async (rangeInfo: DatesSetArg) => {
    setDatesArg(rangeInfo)
    await getEvents(eventsType, rangeInfo)
  }

  const appointmentData = useSelector(getClientAppointmentsValue)

  const filterAppointmentData = async (
    rangeInfo: DatesSetArg,
    selectedUser: number | undefined
  ): Promise<IClientCalendarEvent[]> => {
    return hasNetwork
      ? getClientAppointmentsList(rangeInfo, selectedUser)
      : appointmentData.filter((dates: IClientCalendarEvent) => {
          const startDate = new Date(dates?.start).getTime()
          const startDateRange = new Date(rangeInfo.startStr).getTime()
          const endDateRange = new Date(rangeInfo.endStr).getTime()
          return startDateRange <= startDate && endDateRange >= startDate
        })
  }

  const getEvents = async (
    type: string,
    rangeInfo: DatesSetArg,
    selectedUser?: number
  ) => {
    try {
      setEvents({ data: [], status: STATUS.PENDING, message: '' })
      const eventsResponse =
        type === EVENT_TYPE_RANDEZ_VOUS
          ? await filterAppointmentData(rangeInfo, selectedUser || +userId)
          : await getClientReportsList(rangeInfo, selectedUser || +userId)

      setEvents({ data: eventsResponse, status: STATUS.SUCCESS, message: '' })
    } catch (error) {
      const handledError = handleError(error, intl)
      setEvents({
        data: [],
        ...handledError,
      })
    }
  }

  const changeFilter = (value: string) => {
    setEventsType(value)
    localStorage.setItem(CALENDAR_INITIAL_EVENT_TYPE, value)
    getEvents(value, datesArg as DatesSetArg)
  }

  const changeUser = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(sharedDataActions.setSelectedUser(event.target.value))
    getEvents(eventsType, datesArg as DatesSetArg, +event.target.value)
  }

  const selectInterval = (arg: DateSelectArg) => {
    if (eventsType === EVENT_TYPE_VISIT_REPORT) {
      return
    }

    const data = {
      startDate:
        arg.view.type === CALENDAR_VIEW_MONTH
          ? getMonthViewAvailableSlot(
              arg,
              events?.data as IClientCalendarEvent[]
            )
          : utcToZonedTime(arg.start, 'UTC'),
      endDate: utcToZonedTime(arg.end, 'UTC'),
      client: state?.client,
      prevLocation: state?.prevLocation || ROUTES.agenda,
      offset: state?.offset,
      currentPage: state?.currentPage,
      viewType: arg.view.type,
    }

    history.push(
      {
        pathname: ROUTES.newAppointment,
      },
      data
    )
  }

  const calendarConfig = {
    initialDate: defaultDate(history.location?.state?.date),
    initialView: initialView ?? 'timeGridWeek',
    locale: 'fr',
    headerToolbar: {
      left: 'prevYear prev,next nextYear today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin],
    selectable: true,
    select: selectInterval,
    allDayText: intl.formatMessage({
      id: `calendar.allDay`,
    }),
    noEventsText: intl.formatMessage({
      id: `calendar.noEvents`,
    }),
    buttonText: {
      today: intl.formatMessage({
        id: `calendar.button.today`,
      }),
    },
    customButtons: {
      dayGridMonth: {
        text: intl.formatMessage({
          id: `calendar.button.dayGridMonth`,
        }),
        click: () => updateCalendarView('dayGridMonth'),
      },
      timeGridWeek: {
        text: intl.formatMessage({
          id: `calendar.button.timeGridWeek`,
        }),
        click: () => updateCalendarView('timeGridWeek'),
      },
      timeGridDay: {
        text: intl.formatMessage({
          id: `calendar.button.timeGridDay`,
        }),
        click: () => updateCalendarView('timeGridDay'),
      },
      listWeek: {
        text: intl.formatMessage({
          id: `calendar.button.listWeek`,
        }),
        click: () => updateCalendarView('listWeek'),
      },
    },
    eventColor: '#004850',
    timeZone: 'UTC',
    eventClick: (data: any) => {
      if (eventsType === EVENT_TYPE_VISIT_REPORT) {
        return history.push(
          generatePath(ROUTES.viewVisitReport, {
            reportId: data.event.id,
            clientId: data.event.extendedProps.clientId,
          })
        )
      }

      history.push(
        generatePath(ROUTES.viewAppointment, { appointmentId: data.event.id })
      )
    },
    views: {
      timeGridWeek: {
        slotEventOverlap: false,
      },
      timeGridDay: {
        slotEventOverlap: false,
      },
    },
    expandRows: true,
    dayMaxEventRows: 2,
    selectLongPressDelay: 200,
    weekends: false,
    slotMinTime: MIN_TIME_DISPLAYED,
    slotMaxTime: MAX_TIME_DISPLAYED,
    datesSet: handleDates,
    allDaySlot: false,
  }

  const eventsStatusPending = useMemo(() => events.status === STATUS.PENDING, [
    events,
  ])

  return {
    calendarConfig,
    handleDates,
    events,
    calendarRef,
    changeFilter,
    eventsType,
    changeUser,
    eventsStatusPending,
    userId,
  }
}
