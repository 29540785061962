import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import TextInput from '../../form/TextInput'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import DynamicButtons from '../../form/DynamicButtons'
import FormGroupDate from '../../form/FormGroupDate'
import TextareaInput from '../../form/TextareaInput'
import {
  RADIO_OPTIONS_LIST,
  GENDER_OPTIONS_LIST,
} from '../../../constants/form'
import {
  DATEPICKER_FORMAT,
  DYNAMIC_BUTTONS_INPUT_TYPE,
} from '../../../enums/common'
import ErrorMessage from '../../form/ErrorMessage'
import { checkValidation } from '../../../utils/contracts'
import { getDynamicButtonDefaultValue } from '../../../utils/helpers'
import { RECLAMATION_AREA } from '../../../constants/mixedForms'

export const ReclamationFormThirdSection: FC = () => {
  const { register, watch, errors, control, setValue } = useFormContext()

  const [productExpDate, setProductExpDate] = useState({
    startDate: '',
  })

  const expirationDate = {
    id: 'expirationDate',
    label: 'form.field.expirationDate.label',
    value: productExpDate.startDate,
    setValue: setProductExpDate,
    classes: 'inputField inputDate datepickerLeft',
    maxDate: new Date(),
    error: errors.expirationDate,
    displayTime: true,
    format: DATEPICKER_FORMAT,
    control,
  }

  const watchFields = watch([
    'initials',
    'age',
    'gender',
    'productConcerned',
    'productCipCode',
    'lot',
    'expirationDate',
  ])

  const validationPacientArea = useCallback(
    () =>
      !!(
        watchFields.initials ||
        watchFields.age ||
        watchFields.gender?.length
      ) || RECLAMATION_AREA,
    [watchFields.initials, watchFields.age, watchFields.gender]
  )

  const validationProductArea = useCallback(
    () =>
      !!(
        watchFields.productConcerned ||
        watchFields.productCipCode ||
        watchFields.lot ||
        watchFields.expirationDate
      ) || RECLAMATION_AREA,
    [
      watchFields.productConcerned,
      watchFields.productCipCode,
      watchFields.lot,
      watchFields.expirationDate,
    ]
  )

  const [showErrorPacientArea, setShowErrorPacientArea] = useState(false)
  const [showErrorProductArea, setShowErrorProductArea] = useState(false)

  useEffect(() => {
    checkValidation(validationPacientArea, setShowErrorPacientArea)
    checkValidation(validationProductArea, setShowErrorProductArea)
  }, [validationPacientArea, validationProductArea])

  return (
    <>
      <div className="formSection row mx0 mb2 hasBorderBottom">
        <div className="formType colMd3 px0 mb1">
          <DynamicFormattedMessage
            tag="p"
            id="form.section.reclamationInformation.label"
          />
        </div>
        <div className="formData colMd9 px0">
          <div className="row mx0">
            <div className="px15 mb1 fontMedium">
              <DynamicFormattedMessage
                id="form.field.patient.label"
                tag="p"
                className="textPrimary"
              />
              <TextInput
                name="patient"
                id="patient"
                register={register({
                  validate: validationPacientArea,
                })}
                customClass="hidden"
                disabled={true}
              />
              {showErrorPacientArea && <ErrorMessage error={errors.patient} />}
            </div>
            <div className="row wFull mx0">
              <div className="colMd6">
                <TextInput
                  name="initials"
                  id="initials"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="age"
                  id="age"
                  type="number"
                  register={register({
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'number',
                    },
                    max: {
                      value: 99,
                      message: 'number.max99',
                    },
                  })}
                  placeholder="typeIn"
                  error={errors.age}
                />
              </div>
            </div>
            <div className="col12 mb05">
              <div className="px0 mb1">
                <DynamicFormattedMessage
                  id="form.field.gender.label"
                  tag="p"
                  className="textPrimary"
                />
              </div>
              <div className="col12 px0">
                <DynamicButtons
                  optionsArray={GENDER_OPTIONS_LIST}
                  register={register}
                  name="gender"
                  customClass="noLabel"
                  defaultValue={getDynamicButtonDefaultValue(
                    watchFields.gender
                  )}
                  inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
                  setValue={setValue}
                />
              </div>
            </div>
          </div>
          <div className="row mx0">
            <div className="px15 mb1 fontMedium">
              <DynamicFormattedMessage
                id="form.field.product.label"
                tag="p"
                className="textPrimary"
              />
              <TextInput
                name="product"
                id="product"
                register={register({
                  validate: validationProductArea,
                })}
                customClass="hidden"
                disabled={true}
              />
              {showErrorProductArea && <ErrorMessage error={errors.product} />}
            </div>
            <div className="row wFull mx0">
              <div className="colMd6">
                <TextInput
                  name="productConcerned"
                  id="productConcerned"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="productCipCode"
                  id="productCipCode"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
            </div>
            <div className="row wFull mx0">
              <div className="colMd6">
                <TextInput
                  name="lot"
                  id="lot"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6 mb15">
                <FormGroupDate
                  startDate={expirationDate}
                  startDateOnly={true}
                />
              </div>
            </div>
          </div>
          <div className="row mx0">
            <div className="col12">
              <TextareaInput
                name="description"
                id="description"
                placeholder="typeIn"
                register={register({ required: true, maxLength: 1000 })}
                error={errors.description}
              />
            </div>
          </div>
          <div className="row mx0 mb2">
            <div className="px15 mb1">
              <DynamicFormattedMessage
                id="form.field.specialtyAvailable.label"
                tag="p"
                className="textPrimary"
              />
            </div>
            <div className="col12">
              <DynamicButtons
                optionsArray={RADIO_OPTIONS_LIST}
                register={register({ required: true })}
                name="specialtyAvailable"
                customClass="noLabel"
                error={errors.specialtyAvailable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
