import get from 'lodash.get'
import { IStore } from '../index'
import {
  IEngagementHistory,
  IOrderHistory,
  IRecapHistory,
} from '../../interfaces/IHistory'

type THistory = IRecapHistory[] | IOrderHistory[] | IEngagementHistory[]

export const getClientHistoryValue = (
  state: IStore,
  reduxStorePath?: string
): THistory =>
  reduxStorePath
    ? (get(state.clientHistoryReducer, reduxStorePath, []) as THistory)
    : ([] as THistory)
