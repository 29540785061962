import React, { FC } from 'react'
import FullCalendar from '@fullcalendar/react'
import Loader from '../../loader/Loader'
import { IFullCalendarSection } from '../../../interfaces/ICommonComponents'

export const FullCalendarSection: FC<IFullCalendarSection> = ({
  calendarConfig,
  events,
  calendarRef,
  eventsPending = false,
}) => {
  return (
    <div className="relative">
      <FullCalendar
        {...calendarConfig}
        events={events as any}
        ref={calendarRef}
      />
      {eventsPending && (
        <div className="overlay backgroundNeutralLightestFade50 notAllowed">
          <Loader />
        </div>
      )}
    </div>
  )
}
