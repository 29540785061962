import { useMemo } from 'react'
import { useLoadData } from '../UseLoadData'
import { getData } from '../../services/getClientsListService'
import { GROUPMENTS_ROUTE, STATUS } from '../../enums/common'
import { IMultipleSelectOption, IOption } from '../../interfaces/IForms'
import { prepareMultiSelectOptions } from '../../utils/helpers'

export const useGroupIds = () => {
  const groupIdsResponse = useLoadData(() =>
    getData<IOption[], IMultipleSelectOption[]>(
      GROUPMENTS_ROUTE,
      prepareMultiSelectOptions
    )
  )
  const groupIdsStatusPending = useMemo(
    () => groupIdsResponse.status === STATUS.PENDING,
    [groupIdsResponse.status]
  )

  return {
    groupIdsResponse,
    groupIdsStatusPending,
  }
}
