import React from 'react'
import { ContractType } from '../contractType/ContractType'
import { IContractData } from '../../interfaces/IContracts'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { STATUS } from '../../enums/common'
import Loader from '../loader/Loader'
import Alerts from '../alerts/Alerts'
import { PlaceholderMessage } from '../common/ui/PlaceholderMessage'
import { IPlaceholderMessageProps } from '../../interfaces/ICommonComponents'
import { TStatus } from '../../interfaces/INavigation'

export const ContractList = ({
  clientId,
  data,
  status,
  message,
  year,
  showSectionTitle = true,
}: {
  clientId: number
  data: IContractData[]
  status: TStatus
  message: string
  year?: number
  showSectionTitle?: boolean
}) => {
  const placeholderMessageProps: IPlaceholderMessageProps<IContractData[]> = {
    status,
    data,
  }

  return (
    <>
      {showSectionTitle && (
        <h3 className="textSecondaryDarker textMd fontBold textUppercase my2 alignSelfCenter dInlineBlock">
          <DynamicFormattedMessage
            id="page.forms.contracts.title.label"
            tag="span"
          />
          <span className="ml05">{year}</span>
        </h3>
      )}
      {!data?.length && status && status === STATUS.PENDING && <Loader />}
      <Alerts status={status} message={message} />
      <PlaceholderMessage {...placeholderMessageProps} />
      <ul className="contractTypeList unstyledList">
        {data?.length > 0 &&
          data.map((contract: IContractData) => (
            <ContractType
              key={contract.id}
              contract={contract}
              contractName={contract.label}
              contractNo={contract.nrOfContracts}
              clientId={clientId}
              year={showSectionTitle ? undefined : year}
            />
          ))}
      </ul>
    </>
  )
}
