import React, { FC, useState } from 'react'
import { CONTRACT_RULES_HEADERS } from '../../../constants/tables'
import { iconArrowDown, iconArrowUp } from '../../../assets/images/images'
import Button from '../../common/ui/Button'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { Table } from '../../common/ui/tables/Table'
import { IContractRulesSection } from '../../../interfaces/IContracts'

export const RulesSection: FC<IContractRulesSection> = ({
  rules,
  hideGreaterThan,
}) => {
  const [displayRules, setDisplayRules] = useState(false)

  return (
    <div className="row">
      <div className="col12">
        <Button
          onClick={() => setDisplayRules(!displayRules)}
          className="btn textPrimary withBackgroundTransparent px0 py0 dFlex alignItemsCenter mb1"
          data-qa="displayRules"
        >
          <DynamicFormattedMessage
            id="form.contract.rules.label"
            className="h4"
          />
          <img
            src={displayRules ? iconArrowUp : iconArrowDown}
            alt="arrow"
            width="18px"
            className="ml1"
          />
        </Button>
      </div>

      {displayRules && (
        <div className="colXl7">
          <Table tableHeaders={CONTRACT_RULES_HEADERS}>
            {rules.map(({ range, percent, value }, index) => (
              <tr
                key={`tr-${index.toString()}`}
                className={`textCenter textPrimary textCustomXs  ${
                  index % 2 === 0 ? 'backgroundNeutralLight' : ''
                }`}
              >
                <th scope="col"> </th>
                <td>
                  {range?.length && (
                    <DynamicFormattedMessage
                      id="form.contract.range.label"
                      values={{ value1: range[0], value2: range[1] }}
                    />
                  )}
                  {!hideGreaterThan && value && (
                    <DynamicFormattedMessage
                      id="form.contract.greaterThan.label"
                      values={{ value }}
                    />
                  )}
                  {hideGreaterThan && value}
                </td>
                <td>
                  <DynamicFormattedMessage
                    id="form.contract.percent.label"
                    values={{ percentValue: percent }}
                  />
                </td>
              </tr>
            ))}
          </Table>
        </div>
      )}
    </div>
  )
}
