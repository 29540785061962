import { NavLink } from 'react-router-dom'
import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { INavbarSubmenuLink } from '../../interfaces/INavigation'
import { getRoute } from '../../utils/helpers'

export const NavbarSubmenuLink: FC<INavbarSubmenuLink> = ({
  isMenuOpen,
  route,
  navName,
  onNavbarLinkSubmenuClick,
}) => {
  return (
    <li className="navbarSubmenuItem pl2">
      <NavLink
        to={getRoute(navName)}
        onClick={onNavbarLinkSubmenuClick}
        activeClassName="navbarLinkSubmenuActive"
        className="navbarLink navbarLinkSubmenu"
        data-qa={`${navName}Link`}
        exact={true}
      >
        <DynamicFormattedMessage
          id={`${route}.${navName}.title.label`}
          className={`${isMenuOpen ? 'navbarItemLabel' : 'hidden'}`}
        />
      </NavLink>
    </li>
  )
}
