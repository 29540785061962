import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IClientCalendarEvent } from '../../interfaces/IClientDetails'

const clientAppointmentsReducer = createSlice({
  name: 'clientAppointmentsReducer',
  initialState: [] as IClientCalendarEvent[],
  reducers: {
    setAllAppointments: (
      state,
      { payload }: PayloadAction<IClientCalendarEvent[]>
    ) => payload,
  },
})

export const clientAppointmentsActions = clientAppointmentsReducer.actions

export default clientAppointmentsReducer.reducer
