import { useEffect, useState } from 'react'
import { TClientDetailsItem } from '../../interfaces/IClientDetails'
import { IMarkerType } from '../../interfaces/IMap'
import {
  mapMarker,
  mapMarkerGrey,
  mapMarkerRed,
  mapMarkerYellow,
} from '../../assets/images/images'

/**
 * Hook used to set the map marker type for each client.
 */

export const useMapMarker: (client: TClientDetailsItem) => IMarkerType = (
  client
) => {
  const [markerType, setMarkerType] = useState(mapMarkerYellow)

  useEffect(() => {
    const { nextAppointmentDate } = client
    const currentDate = new Date().setHours(0, 0, 0, 0)
    const nextAppointment = nextAppointmentDate
      ? new Date(nextAppointmentDate).setHours(0, 0, 0, 0)
      : null
    let marker = mapMarker

    if (!nextAppointment) {
      marker = mapMarkerGrey
    }

    if (nextAppointment && nextAppointment === currentDate) {
      marker = mapMarkerRed
    }

    if (nextAppointment && nextAppointment > currentDate) {
      marker = mapMarkerYellow
    }

    setMarkerType(marker)
  }, [client])

  return { markerType }
}
