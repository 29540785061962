import React, { FC, useMemo, useState } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import {
  COL_SPAN_BY_VIEW,
  VIEW_TYPE,
  VIEW_TYPES_HEADERS,
  VIEW_TYPES_LIST,
  VIEW_TYPES_SUB_HEADERS,
} from '../../constants/tables'
import { CustomPeriodTable } from './components/CustomPeriodTable'
import { SemesterTableContent } from './components/semesterTable/SemesterTableContent'
import { IRecapPanel } from '../../interfaces/ITables'
import { AnnualTableContent } from './components/annualTable/AnnualTableContent'
import { QuarterTableContent } from './components/quarterTable/QuarterTableContent'
import { TrimesterTableContent } from './components/trimesterTable/TrimesterTableContent'
import { useLoadData } from '../../hooks/UseLoadData'
import { getClientRecapHistory } from '../../services/historyService'
import { STATUS } from '../../enums/common'
import Loader from '../loader/Loader'
import { processRecapHistoryTableData } from '../../utils/history'
import { IHistoryTableData, IRecapHistory } from '../../interfaces/IHistory'
import { getClientHistoryValue } from '../../store/selectors/clientHistorySelectors'

export const HistoryRecapPanel: FC<IRecapPanel> = ({ clientId }) => {
  const [view, setView] = useState(VIEW_TYPE.TOTAL)
  const { data, status } = useLoadData(
    () => getClientRecapHistory(clientId, view),
    {
      dependencies: [view],
      fetchFromRedux: true,
      reduxSelector: getClientHistoryValue,
      reduxStorePath: `${clientId?.toString()}.${view}`,
    }
  )

  const tableRows = processRecapHistoryTableData(data as IRecapHistory[])
  const TableContent = useMemo<FC<IHistoryTableData> | null>(() => {
    if (view === VIEW_TYPE.SEMESTER) return SemesterTableContent
    if (view === VIEW_TYPE.TRIMESTER) return TrimesterTableContent
    if (view === VIEW_TYPE.QUARTERLY) return QuarterTableContent

    return null
  }, [view])

  return (
    <div className="recapPanel pt15">
      <div className="dFlex recapPanelCtaWrapper alignItemsCenter mb15">
        <DynamicFormattedMessage
          id="display.type.label"
          className="textPrimary"
        />
        {VIEW_TYPES_LIST.map((viewType) => (
          <DynamicFormattedMessage
            id={`cta.period.${viewType}.label`}
            tag={Button}
            onClick={() => setView(viewType as VIEW_TYPE)}
            className={`btn btnOutline hasBorderRadius hasBoxShadow ${
              viewType === view ? 'active' : ''
            }`}
            key={viewType}
          />
        ))}
        <DynamicFormattedMessage
          id="display.type.history.legend"
          className="textPrimary legend"
        />
      </div>

      <CustomPeriodTable
        tableHeaders={VIEW_TYPES_HEADERS[view]}
        colSpan={COL_SPAN_BY_VIEW[view]}
        subHeaderList={VIEW_TYPES_SUB_HEADERS[view]}
      >
        {TableContent && <TableContent data={tableRows} />}
      </CustomPeriodTable>
      {view === VIEW_TYPE.TOTAL && <AnnualTableContent data={tableRows} />}
      {status && status === STATUS.PENDING && <Loader />}
    </div>
  )
}
