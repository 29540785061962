import { DependencyList, useEffect, useState } from 'react'

export const useEffectAllDepsChange = (fn: any, deps: any) => {
  const [changeTarget, setChangeTarget] = useState(deps)

  useEffect(() => {
    setChangeTarget((prev: any) => {
      if (prev.every((dep: any, i: any) => dep !== deps[i])) {
        return deps
      }

      return prev
    })
  }, [deps])

  useEffect(fn, changeTarget as DependencyList)
}
