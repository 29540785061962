import React, { FC } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { format, utcToZonedTime } from 'date-fns-tz'
import { IAppointments } from '../../interfaces/IAppointments'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { STATUS, TODAY_APPOINTMENT_TIME_FORMAT } from '../../enums/common'
import { ROUTES } from '../../enums/routes'
import ClientCipCode from '../clientCipcode/ClientCipcode'

export const AppointmentsList: FC<IAppointments> = ({ data, status }) => {
  return (
    <ul className="unstyledList wFull mb15 px05 dayActivitiesList">
      {data.map(
        ({ id, startDate, clientName, address1, city, cipCode, zipCode }) => {
          const clientLocation =
            (address1 &&
              city &&
              `, ${address1}${zipCode ? `, ${zipCode}` : ''}${
                city ? `, ${city}` : ''
              })`) ||
            ((address1 || city) &&
              `, ${address1}${zipCode ? `, ${zipCode}` : ''}${
                city ? `, ${city}` : ''
              })`) ||
            ' '
          const eventDate = format(
            utcToZonedTime(startDate, 'UTC'),
            TODAY_APPOINTMENT_TIME_FORMAT
          )

          return (
            <li
              className="px05 py05 hasBorderBottom dFlex alignItemsCenter"
              key={id}
            >
              <span className="mr1 textSecondaryDarker">{eventDate}</span>
              <div>
                <Link
                  to={generatePath(ROUTES.viewAppointment, {
                    appointmentId: id,
                  })}
                  className="textPrimary linkUnstyled"
                >
                  {clientName}
                  {cipCode && `, (${cipCode}`}
                </Link>
                {cipCode && (
                  <ClientCipCode
                    isVisible={false}
                    cipCode={cipCode}
                    hasParenthesis={[false, false]}
                    className="textPrimary"
                  />
                )}
                <Link
                  to={generatePath(ROUTES.viewAppointment, {
                    appointmentId: id,
                  })}
                  className="textPrimary linkUnstyled"
                >
                  {clientLocation}
                </Link>
              </div>
            </li>
          )
        }
      )}
      <DynamicFormattedMessage
        id="clientDetails.events.missingEvents"
        className="textMd textPrimary textCenter fontRegular mb2"
        shouldRender={!data.length && status !== STATUS.PENDING}
        tag="li"
      />
    </ul>
  )
}
