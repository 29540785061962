import { IHistoryAnnualTotal } from '../interfaces/IHistory'

const HISTORY_ROW_INITIAL_STATE = {
  quantity: 0,
  value: 0,
}

export const HISTORY_ANNUAL_INITIAL_STATE_TOTAL: IHistoryAnnualTotal = {
  previousYear: {
    TOTAL1: HISTORY_ROW_INITIAL_STATE,
  },
  currentYear: {
    TOTAL2: HISTORY_ROW_INITIAL_STATE,
  },
}

const HISTORY_SEMESTER_STATE = {
  S1: HISTORY_ROW_INITIAL_STATE,
  S2: HISTORY_ROW_INITIAL_STATE,
}

export const HISTORY_SEMESTER_INITIAL_STATE_TOTAL: IHistoryAnnualTotal = {
  previousYear: HISTORY_SEMESTER_STATE,
  currentYear: HISTORY_SEMESTER_STATE,
}

const HISTORY_QUARTER_STATE = {
  Q1: HISTORY_ROW_INITIAL_STATE,
  Q2: HISTORY_ROW_INITIAL_STATE,
  Q3: HISTORY_ROW_INITIAL_STATE,
}

export const HISTORY_QUARTER_INITIAL_STATE_TOTAL: IHistoryAnnualTotal = {
  previousYear: HISTORY_QUARTER_STATE,
  currentYear: HISTORY_QUARTER_STATE,
}

const HISTORY_TRIMESTER_STATE = {
  T1: HISTORY_ROW_INITIAL_STATE,
  T2: HISTORY_ROW_INITIAL_STATE,
  T3: HISTORY_ROW_INITIAL_STATE,
  T4: HISTORY_ROW_INITIAL_STATE,
}

export const HISTORY_TRIMESTER_INITIAL_STATE_TOTAL: IHistoryAnnualTotal = {
  previousYear: HISTORY_TRIMESTER_STATE,
  currentYear: HISTORY_TRIMESTER_STATE,
}
