import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ACTION_TYPES } from '../constants/userData'
import {
  CACHE_USAGE,
  CACHE_USAGE_COLOR,
  DATEPICKER_TIME_FORMAT,
  OBJECT_STORE,
  STATUS,
} from '../enums/common'
import {
  IOfflineSynchroData,
  TCacheUsageResponse,
  TDeleteCacheResponse,
} from '../interfaces/IOffline'
import { TActionTypes } from '../interfaces/IUsers'
import {
  formatDate,
  getAmountFromTotal,
  isExternalStorageUploadFailed,
} from '../utils/helpers'
import {
  createIndexedDb,
  isHighOnCache,
  isLowOnCache,
  isMidOnCache,
} from '../utils/offline'
import { clientContractsActions } from '../store/reducers/clientContractsReducer'
import { statusNetworkSelector } from '../store/selectors'
import { useEffectAllDepsChange } from './UseEffectAllDepsChange'

export const useCachedData = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [deleteState, setDeleteState] = useState<TDeleteCacheResponse>({
    status: STATUS.IDLE,
    message: '',
  })
  const [cacheUsage, setCacheUsage] = useState<TCacheUsageResponse>({
    status: CACHE_USAGE_COLOR.SUCCESS,
    message: '',
  })
  const [wasCacheDeleted, setWasCacheDeleted] = useState(false)
  const [isMemoryFull, setIsMemoryFull] = useState(false)
  const [indexedDbData, setIndexedDbData] = useState(null)
  const [indexedDbVisitReportData, setIndexedDbVisitReportData] = useState(null)
  const [indexedDbNewContractData, setIndexedDbNewContractData] = useState(null)
  const [indexedDbSendEmail, setIndexedDbSendEmail] = useState(null)
  const [indexedDbFailedData, setIndexedDbFailedData] = useState({})
  const [synchroData, setSynchroData] = useState<IOfflineSynchroData[] | null>(
    null
  )
  const [synchroFailedData, setSynchroFailedData] = useState<
    IOfflineSynchroData[]
  >([])
  const [isSupported, setIsSupported] = useState(true)
  const [isConnected, setIsConnected] = useState(true)
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const getCacheStorage = useCallback(() => {
    if (!('storage' in navigator && 'estimate' in navigator.storage)) return

    navigator.storage.estimate().then((estimate) => {
      const usedCache = getAmountFromTotal(
        estimate.quota as number,
        estimate.usage as number
      )

      if (isLowOnCache(usedCache))
        return setCacheUsage({
          status: CACHE_USAGE_COLOR.SUCCESS,
          message: intl.formatMessage({
            id: `cacheUsage.${CACHE_USAGE.SUCCESS}`,
          }),
        })

      if (isMidOnCache(usedCache))
        return setCacheUsage({
          status: CACHE_USAGE_COLOR.WARNING,
          message: intl.formatMessage({
            id: `cacheUsage.${CACHE_USAGE.WARNING}`,
          }),
        })

      if (isHighOnCache(usedCache))
        return setCacheUsage({
          status: CACHE_USAGE_COLOR.DANGER,
          message: intl.formatMessage({
            id: `cacheUsage.${CACHE_USAGE.DANGER}`,
          }),
        })

      if (estimate.usage === estimate.quota) return setIsMemoryFull(true)
    })
  }, [intl])

  const openIndexedDB = (
    dbName: string,
    setCallback: Dispatch<SetStateAction<any>>
  ) => {
    const request = createIndexedDb(dbName)
    request.onerror = () => {
      setIsConnected(false)
    }

    request.onsuccess = () => {
      const db = request.result
      try {
        const store = db
          .transaction(OBJECT_STORE, 'readonly')
          .objectStore(OBJECT_STORE)
        const allRequest = store.getAll()
        allRequest.onsuccess = () => {
          setCallback(allRequest as IDBRequest<any[]>)
          setIsConnected(true)
        }
      } catch (e) {
        setIsConnected(false)
      } finally {
        db.close()
      }
    }
  }

  const openIndexedDBSyncData = () => {
    openIndexedDB('workbox-background-sync', setIndexedDbData)
  }

  const openIndexedDBVisitReportData = () => {
    openIndexedDB('visit-report-background-sync', setIndexedDbVisitReportData)
  }

  const openIndexedDbNewContractData = () => {
    openIndexedDB('new-contract-background-sync', setIndexedDbNewContractData)
  }

  const openIndexedDbSendEmail = () => {
    openIndexedDB('new-contract-emails', setIndexedDbSendEmail)
  }

  const openIndexedDBFailedSyncData = () => {
    openIndexedDB('workbox-background-sync-failed', setIndexedDbFailedData)
  }

  const formatSyncData = (
    timestamp: number,
    method: string,
    url: string,
    status: string
  ) => {
    const creationDate = formatDate(
      timestamp ? new Date(timestamp) : new Date(),
      DATEPICKER_TIME_FORMAT
    )
    const actionType = ACTION_TYPES.find(
      (type: TActionTypes) => url && RegExp(type).test(url) && method
    )

    return {
      creationDate,
      fileName: `${intl.formatMessage({
        id: `offline.action.${method.toLowerCase()}`,
      })} ${intl.formatMessage({
        id: `offline.action.${actionType}`,
      })} / ${url}`,
      status,
    }
  }

  const getSyncData = () => {
    openIndexedDBSyncData()
    openIndexedDBVisitReportData()
    openIndexedDbNewContractData()
    openIndexedDbSendEmail()
  }

  const getFailedSyncData = useCallback(() => {
    const result = (indexedDbFailedData as IDBRequest<any[]>)?.result

    if (result) {
      setSynchroFailedData(
        result
          .filter(
            ({ status, code }) => !isExternalStorageUploadFailed(status, code)
          )
          .map(({ method, status, timestamp, url }) =>
            formatSyncData(timestamp, method, url, status)
          )
      )
    }
    // eslint-disable-next-line
  }, [indexedDbFailedData, intl])

  const deleteSyncDataValues = (
    dbName: string,
    successCallback = () => {
      setDeleteState({
        status: STATUS.SUCCESS,
        message: 'deleteCache.success',
      })
      setWasCacheDeleted(true)
    },
    errorCallback = () => {
      setDeleteState({
        status: STATUS.DANGER,
        message: 'error.response.default',
      })
      setWasCacheDeleted(false)
    }
  ) => {
    const request = createIndexedDb(dbName)
    request.onsuccess = () => {
      const db = request.result
      try {
        setDeleteState({
          status: STATUS.IDLE,
          message: '',
        })
        const tx = db.transaction(OBJECT_STORE, 'readwrite')
        const store = tx.objectStore(OBJECT_STORE)
        const storeRequest = store.clear()

        storeRequest.onsuccess = successCallback
      } catch (e) {
        errorCallback()
      } finally {
        db.close()
      }
    }
  }

  const deleteSyncData = () => {
    deleteSyncDataValues('workbox-background-sync')
  }

  const deleteSyncVisitReportData = () => {
    deleteSyncDataValues('visit-report-background-sync')
  }

  const deleteSyncVisitReportImagesData = () => {
    deleteSyncDataValues('visit-report-images')
  }

  const deleteSyncNewContractData = () => {
    deleteSyncDataValues('new-contract-background-sync')
    dispatch(clientContractsActions.removeUnsavedData())
  }

  const deleteSyncEmailsData = () => {
    deleteSyncDataValues('new-contract-emails')
  }

  const deleteFailedSyncData = () => {
    deleteSyncDataValues(
      'workbox-background-sync-failed',
      () => {
        setDeleteState({
          status: STATUS.SUCCESS,
          message: 'deleteFailed.success',
        })
      },
      () => {
        setDeleteState({
          status: STATUS.DANGER,
          message: 'error.response.default',
        })
      }
    )
  }

  useEffect(() => {
    if (!('indexedDB' in window)) {
      setIsSupported(false)
    }

    if (hasNetwork) {
      getSyncData()
      openIndexedDBFailedSyncData()
    }
    // eslint-disable-next-line
  }, [hasNetwork])

  useEffectAllDepsChange(() => {
    if (
      !indexedDbData ||
      !indexedDbVisitReportData ||
      !indexedDbNewContractData ||
      !indexedDbSendEmail
    )
      return

    const result = [
      ...(((indexedDbData || {}) as IDBRequest<any[]>)?.result || []),
      ...(((indexedDbVisitReportData || {}) as IDBRequest<any[]>)?.result ||
        []),
      ...(((indexedDbNewContractData || {}) as IDBRequest<any[]>)?.result ||
        []),
      ...(((indexedDbSendEmail || {}) as IDBRequest<any[]>)?.result || []),
    ]

    if (result) {
      setSynchroData(
        result.map(({ timestamp, requestData }) =>
          formatSyncData(
            timestamp,
            requestData?.method,
            requestData?.url,
            `${requestData?.method} ${intl.formatMessage({
              id: 'network.offline.waiting',
            })}`
          )
        )
      )
    }
  }, [
    indexedDbData,
    indexedDbVisitReportData,
    indexedDbNewContractData,
    indexedDbSendEmail,
  ])

  useEffect(() => {
    if (!indexedDbFailedData) return

    getFailedSyncData()
  }, [getFailedSyncData, indexedDbFailedData])

  useEffect(() => {
    getCacheStorage()
  }, [getCacheStorage])

  useEffect(() => {
    if (!wasCacheDeleted) return
    getCacheStorage()
  }, [getCacheStorage, wasCacheDeleted])

  return {
    synchroData,
    synchroFailedData,
    openIndexedDBFailedSyncData,
    getSyncData,
    getFailedSyncData,
    deleteSyncData,
    deleteSyncVisitReportData,
    deleteSyncVisitReportImagesData,
    deleteSyncNewContractData,
    deleteSyncEmailsData,
    deleteFailedSyncData,
    deleteState,
    cacheUsage,
    isMemoryFull,
    isSupported,
    isConnected,
  }
}
