import { differenceInMinutes, endOfDay, startOfDay } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { Dispatch, SetStateAction } from 'react'
import { IntlShape } from 'react-intl'
import {
  CODE_1011,
  CURRENT_DAY_DATETIME_FORMAT,
  DANGER,
  DATE_PICKER_TIME_INTERVALS_DEFAULT,
  DATE_REQUEST_FORMAT,
  DATEPICKER_FORMAT,
  EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  FILE_DOWNLOAD_TIME_FORMAT,
  FILE_EXTENSION_PDF,
  MISSION_NAME,
  MONTHS,
  NO_DATE,
  RANGE_INPUT_MINUTES,
  REPORT_DATETIME_FORMAT,
  STATUS_BAD_GATEWAY,
  THIRTY_MIN,
  TODAY_APPOINTMENT_TIME_FORMAT,
  WARNING,
  WYSIWYG_INPUT_MAX_LENGTH,
} from '../enums/common'
import {
  IFormSelectOption,
  IOption,
  IMultipleSelectOption,
} from '../interfaces/IForms'
import { IUser } from '../interfaces/IUsers'
import {
  IControlDownload,
  IFileDetails,
  TDynamicButton,
} from '../interfaces/ICommonComponents'
import { IArrayOptions } from '../interfaces/IAppointments'
import { CONTENT_TYPE_APP_PDF } from '../constants/api'
import { APP_MONTH_INDEX } from '../constants/generalData'

export const formatDate = (
  date: string | Date | null,
  dateFormat: string = CURRENT_DAY_DATETIME_FORMAT,
  replacement: string = NO_DATE,
  isHistoryPage?: boolean
) => {
  if (typeof date === 'string') {
    return date ? format(utcToZonedTime(date, 'UTC'), dateFormat) : replacement
  }

  if (isHistoryPage) {
    return date
      ? `<span class="fontBold">${format(
          date,
          REPORT_DATETIME_FORMAT
        )}</span> | ${format(date, TODAY_APPOINTMENT_TIME_FORMAT)}`
      : replacement
  }

  return date ? format(date, dateFormat) : replacement
}

export const toNumber = (value: string) => parseInt(value, 10)

export const roundTwoDecimals = (number: number) =>
  Math.round(number * 100) / 100

export const objEqual = (x: any, y: any): boolean => {
  const ok = Object.keys
  const tx = typeof x
  const ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => objEqual(x[key], y[key]))
    : x === y
}

export const getFormattedString = (
  param: string | undefined,
  joinSeparator: string = '',
  splitSeparator: string = ' '
) => {
  if (!param) return ''

  return param
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f|&;$%@"<>()+,.]/g, '')
    .toLowerCase()
    .split(splitSeparator)
    .reduce((acc, el) => {
      acc.push(capitaliseString(el))
      return acc
    }, [] as string[])
    .join(joinSeparator)
}

export const filterPassedTime = (createdDate: Date) => {
  return new Date().getTime() - createdDate.getTime() > THIRTY_MIN
}

export const handleFileDownload = (url: string, fileName: string) => {
  const element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('download', fileName)
  element.setAttribute('target', '_blank')

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
}

export const isEmptyOrSpaces = (str: string | null) => {
  return str === null || str.match(/^ *$/) !== null
}

// use to handle html content with react dangerouslySetInnerHTML
export const handleHtmlContent = (content: string) => {
  return { __html: content }
}

export const defaultDate = (date: string) =>
  date ? new Date(date) : new Date()

export const dateInterval = (
  startDate: string | Date | null,
  endDate: string | Date | null
): number =>
  (startDate as Date) &&
  (endDate as Date) &&
  differenceInMinutes(endDate as Date, startDate as Date) / RANGE_INPUT_MINUTES

export const getEndDate = (startDate: Date, interval: number) =>
  startDate && new Date(startDate.getTime() + interval * (1000 * 60 * 60))

export const getClosingHours = (startDate: Date) => {
  const closingHours = (startDate as Date) && new Date(startDate)
  closingHours.setHours(21)
  closingHours.setMinutes(0)

  return closingHours
}

export const timeConvert = (number: number) => {
  const hours: number = Math.floor(number)
  const minutes: number = Math.round(number * 60) % 60
  const displayedHours = hours ? `${hours}h` : ''
  const displayedMinutes = minutes ? `${minutes}min` : ''

  return `${displayedHours}${displayedMinutes}`
}

export const joinData = (
  data: (string | undefined | null)[],
  separator?: string
) => {
  return data.filter((el) => !!el).join(`${separator || ','} `)
}

export const capitaliseString = (string: string) =>
  string[0].toUpperCase() + string.slice(1)

export const getAmountFromTotal = (total: number, value: number) =>
  Math.round((value * 100) / total)

export const isEmptyObj = (object: object) =>
  Object.keys(object).length === 0 && object.constructor === Object

export const isObjectEmpty = (obj: object) =>
  Object.getPrototypeOf(obj) === Object.prototype &&
  Object.keys(obj).length === 0

export const getNWord = (string: string, index: number = 0) => {
  const getCamelCaseWords = string
    .replace(/([a-z](?=[A-Z]))/g, '$1 ')
    .split(' ')
  return getCamelCaseWords[index]
}

export const getSelectedLabel = (
  selectedValue: string | IMultipleSelectOption[],
  selectObj: (IFormSelectOption | IMultipleSelectOption)[]
) => {
  if (typeof selectedValue === 'string') {
    const matchSelection = selectObj.find(
      (selected: IFormSelectOption | IMultipleSelectOption) =>
        +selected.value === +selectedValue
    )
    return (
      (matchSelection as IFormSelectOption)?.name ||
      (matchSelection as IMultipleSelectOption)?.label
    )
  }

  const selectedValueLabels = [] as string[]
  ;(selectedValue as IMultipleSelectOption[]).forEach(({ value }) => {
    const matchSelection = selectObj.find(
      (selected: IFormSelectOption | IMultipleSelectOption) =>
        +selected.value === +value
    )
    selectedValueLabels.push(
      (matchSelection as IFormSelectOption)?.name ||
        (matchSelection as IMultipleSelectOption)?.label
    )
  })
  return selectedValueLabels
}

export const prepareMultiSelectOptions = (options: IOption[]) => {
  return options
    .sort((a, b) => a.order! - b.order!)
    .map(({ id, name }) => ({
      value: id.toString(),
      label: name,
    }))
}

export const prepareSelectOptions = (options: IOption[]) => {
  return options
    .sort((a, b) => a.order! - b.order!)
    .map(({ id, name }) => ({
      value: id.toString(),
      name,
    }))
}

export const validateTextInput = (
  value: string,
  hasToBeValidated: boolean,
  inputMaxNo: number = WYSIWYG_INPUT_MAX_LENGTH
) => {
  if (hasToBeValidated && value?.length > inputMaxNo) return 'maxLength'
}

export const timezoneOffset = () => {
  return String(-new Date().getTimezoneOffset() / 60)
}

export const isDateInTheFuture = (date: string) =>
  new Date(date as string).getTime() > new Date().getTime()

export const getAlertType = (messageCode: string = '') =>
  messageCode === EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE ? WARNING : DANGER

export const isExternalStorageUploadFailed = (
  status: number,
  code: number
): boolean => status === +STATUS_BAD_GATEWAY && code === +CODE_1011

export const getSimpleRoute = (routeName: string, query?: string) =>
  `${routeName}${query ? `?${query}` : ''}`

export const getRoute = (routeName: string, query?: string) =>
  `/${routeName}${query ? `?${query}` : ''}`

export const getCurrentYear = (monthIndex: number = APP_MONTH_INDEX) => {
  const date = new Date()

  return date.getMonth() < monthIndex
    ? date.getFullYear()
    : date.getFullYear() + 1
}

export const currentDate = new Date()

export const currentBusinessYearStartingValue = new Date(
  currentDate.getFullYear(),
  APP_MONTH_INDEX,
  1
)

export const getFirstDayOfCurrentYear = () => {
  const initialStartDate = new Date()
  initialStartDate.setMonth(0)
  initialStartDate.setDate(1)

  return initialStartDate
}

export const getStartOfDay = (date: string) =>
  format(startOfDay(new Date(date)), DATE_REQUEST_FORMAT)

export const getEndOfDay = (date: string) =>
  format(endOfDay(new Date(date)), DATE_REQUEST_FORMAT)

export const getFullName = (
  user: Partial<IUser>,
  toUppercase: boolean = false
) =>
  toUppercase
    ? `${user.lastName?.toUpperCase()} ${user.firstName}`
    : `${user.lastName} ${user.firstName}`

export const currentYearValue = new Date().getFullYear()

export const getDynamicButtonDefaultValue = (
  field: TDynamicButton | undefined
) => (field?.length ? (Array.isArray(field) ? field[0] : field) : '')

export const getTimeIntervals = (
  displayTime: boolean,
  timeIntervals: number = DATE_PICKER_TIME_INTERVALS_DEFAULT
): number | null => (displayTime ? timeIntervals : null)

export const getOverlappingIntlValues = (startDate: Date, duration: number) => {
  return {
    date: formatDate(startDate, DATEPICKER_FORMAT),
    startHour: formatDate(startDate, TODAY_APPOINTMENT_TIME_FORMAT),
    endHour: formatDate(
      getEndDate(startDate, duration),
      TODAY_APPOINTMENT_TIME_FORMAT
    ),
  }
}

export const matchOptionsValue = <T>(
  options: IArrayOptions[],
  optionToMatch: T
) =>
  options?.length
    ? options.find((option) => option.value === `${optionToMatch}`)
    : undefined

export const getFileForDownload = (
  responseBody: Blob,
  fileDetails: IFileDetails,
  setControlDownload: Dispatch<SetStateAction<IControlDownload>>
) => {
  const { creationDate, formName, clientName, cipCode } = fileDetails
  const file = new Blob([responseBody], {
    type: CONTENT_TYPE_APP_PDF,
  })
  const fileURL = URL.createObjectURL(file)

  const fileDate = formatDate(creationDate, FILE_DOWNLOAD_TIME_FORMAT)
  const fileClientName = clientName.split(' ').join('_')

  const fileName =
    joinData(
      [fileDate, MISSION_NAME, formName, cipCode, fileClientName],
      '-'
    ).replace(/\s/g, '') + FILE_EXTENSION_PDF

  setControlDownload({
    isDownloadModalOpen: true,
    url: fileURL,
    fileName,
  })
}

export const getFileExtension = (extension: string) =>
  extension.substring(1).toUpperCase()

export const getMonthsOptionsForMultipleSelect = (intl?: IntlShape) =>
  MONTHS.reduce((acc, month, currentIndex) => {
    acc.push({
      value: (currentIndex + 1).toString(),
      label: intl ? intl.formatMessage({ id: `months.${month}.label` }) : month,
    })

    return acc
  }, [] as IMultipleSelectOption[])

export const getMonthsByName = (intl: IntlShape, months: number[]) =>
  months
    .reduce((acc, month) => {
      const monthMatch = MONTHS.find((mon, index) => month - 1 === index)

      if (!monthMatch) {
        return acc
      }

      acc.push(intl.formatMessage({ id: `months.${monthMatch}.label` }))

      return acc
    }, [] as string[])
    .join(', ')

export const getCurrentMonth = () => new Date().getMonth()
