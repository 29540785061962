import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dashboardVisitsCoverageFiltersInitialState } from '../../constants/dashboard'
import {
  IDashboardVisitsCoverageFilters,
  IVisitsCoverageData,
  TChartDataPerType,
} from '../../interfaces/IDashboard'
import { STATUS } from '../../enums/common'
import { TStatus } from '../../interfaces/INavigation'

const dashboardVisitsCoverageReducer = createSlice({
  name: 'dashboardVisitsCoverageReducer',
  initialState: {
    filters: dashboardVisitsCoverageFiltersInitialState as IDashboardVisitsCoverageFilters,
    chartData: {} as IVisitsCoverageData,
    chartDataPerType: [] as TChartDataPerType[],
    fetchDataStatus: STATUS.IDLE as TStatus,
  },
  reducers: {
    setVisitsCoverageFilters: (
      state,
      { payload }: PayloadAction<IDashboardVisitsCoverageFilters>
    ) => ({
      ...state,
      ...{ filters: payload },
    }),
    setChartData: (state, { payload }: PayloadAction<IVisitsCoverageData>) => ({
      ...state,
      ...{ chartData: payload },
    }),
    setChartDataPerType: (
      state,
      { payload }: PayloadAction<TChartDataPerType[]>
    ) => ({
      ...state,
      ...{ chartDataPerType: payload },
    }),
    setFetchDataStatus: (state, { payload }: PayloadAction<TStatus>) => ({
      ...state,
      ...{ fetchDataStatus: payload },
    }),
  },
})

export const dashboardVisitsCoverageReducerActions =
  dashboardVisitsCoverageReducer.actions

export default dashboardVisitsCoverageReducer.reducer
