import { IRoutes } from '../interfaces/INavigation'

export const ROUTES: IRoutes = {
  clientFile: '/accountsPortfolio/:clientId/clientFile',
  clientDetails: '/accountsPortfolio/:clientId',
  forms: '/forms',
  clientForms: '/forms/:clientId',
  accountsPortfolio: '/accountsPortfolio',
  map: '/map',
  agenda: '/agenda',
  newAppointmentAgenda: '/newAppointmentAgenda',
  dashboard: '/dashboard',
  dashboardCa: '/ca',
  dashboardVisitsCoverage: '/visitsCoverage',
  login: '/',
  newAppointment: '/newAppointment',
  editAppointment: '/editAppointment/:appointmentId',
  viewAppointment: '/viewAppointment/:appointmentId',
  viewVisitReport: '/viewVisitReport/:reportId/:clientId',
  newVisitReport: '/newVisitReport/:clientId',
  history: '/history/:clientId',
  newReclamation: '/newReclamation/:clientId',
  newDestructionCertificate: '/newDestructionCertificate/:clientId',
  reclamations: '/contracts/reclamations',
  newEngagement: '/contract/:clientId/:contractId',
  engagements: '/contracts/engagements/:contractId/:contractLabel',
  destructionCertificates: '/contracts/destructionCertificates',
  engagementsHistory:
    '/contracts/engagements-history/:year/:contractId/:contractLabel',
  clientReclamations: '/contracts/reclamations/:clientId',
  clientDestructionCertificates: '/contracts/destructionCertificates/:clientId',
  clientEngagements:
    '/contracts/engagements/:clientId/:contractId/:contractLabel',
  clientEngagementsHistory:
    '/contracts/engagements-history/:year/:clientId/:contractId/:contractLabel',
  offlineSynchro: '/offline-synchro',
}
