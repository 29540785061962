import React, { FC } from 'react'
import { ILinkTo } from '../../../interfaces/ICommonComponents'

const LinkTo: FC<ILinkTo> = ({ children, customClass, hrefContent, type }) => {
  return (
    <a
      className={`link linkUnstyled textInherit dInlineBlock ${customClass}`}
      href={`${type || ''}${hrefContent}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {children}
    </a>
  )
}

export default LinkTo
