import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  IControlDelete,
  TMixedFormLoadDataFunction,
  TMixedFormOnErrorActions,
  TMixedFormSetClientData,
  MIXED_FORM_CONTRACT_TYPE,
} from '../../interfaces/IContracts'
import {
  MIXED_FORM_CONTRACT_TYPE_API_PATHS,
  MIXED_FORM_CONTRACT_TYPE_NEW_FORM_ROUTES,
  MIXED_FORM_CONTRACT_TYPE_PATH,
  MIXED_FORM_TYPE_RECLAMATION,
  MIXED_FORM_TYPE_RECLAMATION_PATH,
  MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE,
  MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE_PATH,
} from '../../constants/mixedForms'
import { clientContractsActions } from '../../store/reducers/clientContractsReducer'
import { deleteDataFromIndexedDb } from '../../utils/offline'
import {
  getDestructionCertificates,
  getReclamations,
} from '../../services/contractsServices'

export const useMixedForms = <T,>(
  clientId: string,
  controlDelete: IControlDelete
) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const mixedFormType: MIXED_FORM_CONTRACT_TYPE | undefined = useMemo(
    () =>
      pathname.includes(MIXED_FORM_TYPE_RECLAMATION_PATH)
        ? MIXED_FORM_TYPE_RECLAMATION
        : pathname.includes(MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE_PATH)
        ? MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE
        : undefined,
    [pathname]
  )

  const mixedFormTypeApiPath = useMemo(() => {
    if (!mixedFormType) return

    return MIXED_FORM_CONTRACT_TYPE_API_PATHS[mixedFormType]
  }, [mixedFormType])

  const mixedFormTypeNewFormRoute = useMemo(() => {
    if (!mixedFormType) return

    return MIXED_FORM_CONTRACT_TYPE_NEW_FORM_ROUTES[mixedFormType]
  }, [mixedFormType])

  const mixedFormTypePath = useMemo(() => {
    if (!mixedFormType) return

    return MIXED_FORM_CONTRACT_TYPE_PATH[mixedFormType]
  }, [mixedFormType])

  const mixedFormOnErrorActions: TMixedFormOnErrorActions = {
    [MIXED_FORM_TYPE_RECLAMATION]: () => {
      if (controlDelete.contractId) {
        dispatch(
          clientContractsActions.deleteOldReclamation({
            clientId,
            reclamationIdentifier: controlDelete.contractId,
          })
        )
      } else {
        dispatch(
          clientContractsActions.deleteNewReclamation({
            clientId,
            reclamationIdentifier: controlDelete.timestamp.toString(),
          })
        )
        deleteDataFromIndexedDb(
          'new-contract-background-sync',
          controlDelete.timestamp
        )
      }
    },
    [MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: () => {
      if (controlDelete.contractId) {
        dispatch(
          clientContractsActions.deleteOldDestructionCertificate({
            clientId,
            identifier: controlDelete.contractId,
          })
        )
      } else {
        dispatch(
          clientContractsActions.deleteNewDestructionCertificate({
            clientId,
            identifier: controlDelete.timestamp.toString(),
          })
        )
        deleteDataFromIndexedDb(
          'new-contract-background-sync',
          controlDelete.timestamp
        )
      }
    },
  }

  const loadDataFunctionByFormType: TMixedFormLoadDataFunction<T> = {
    [MIXED_FORM_TYPE_RECLAMATION]: (id: number) => getReclamations(id),
    [MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: (id: number) =>
      getDestructionCertificates(id),
  }

  const setMixedFormDataByType: TMixedFormSetClientData = {
    [MIXED_FORM_TYPE_RECLAMATION]: clientContractsActions.setClientReclamations,
    [MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]:
      clientContractsActions.setClientDestructionCertificates,
  }

  return {
    mixedFormType,
    mixedFormTypeApiPath,
    mixedFormTypeNewFormRoute,
    mixedFormTypePath,
    mixedFormOnErrorActions,
    loadDataFunctionByFormType,
    setMixedFormDataByType,
  }
}
