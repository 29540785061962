import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { statusNetworkInitialState } from '../../constants/alerts'

const statusNetworkReducer = createSlice({
  name: 'statusNetworkReducer',
  initialState: statusNetworkInitialState,
  reducers: {
    setOfflineStatus: (state) => ({
      ...state,
      onlineMsg: true,
      hasNetwork: false,
    }),
    setOnlineStatus: (state) => ({
      ...state,
      hasNetwork: true,
      onlineMsg: true,
    }),
    resetStatus: (state) => ({
      ...state,
      onlineMsg: false,
    }),
    submitedForm: (state) => ({
      ...state,
      isFormSubmited: true,
    }),
    resetSubmitedForm: (state) => ({
      ...state,
      isFormSubmited: false,
    }),
    setDeletedMsg: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      deletedMsg: payload,
    }),
    resetOfflineMsg: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      onlineMsg: payload,
    }),
    setSyncDoneMsg: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      syncDoneMsg: payload,
    }),
    setSyncLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      syncLoading: payload,
    }),
    setSyncStopped: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      syncStopped: payload,
    }),
  },
})

export const statusNetworkReducerActions = statusNetworkReducer.actions

export default statusNetworkReducer.reducer
