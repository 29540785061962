import { format } from 'date-fns'
import { DATE_REQUEST_FORMAT } from '../enums/common'
import { getJwtUserDetails } from './api'
import {
  ILastVisitReportImages,
  IPlvData,
  IPlvOptions,
  IVisitReportForm,
  IVisitReportReset,
  TVisitReportCurrentVersion,
} from '../interfaces/IVisitReports'
import {
  IOption,
  IMultipleSelectOption,
  ISelectOptionGroup,
} from '../interfaces/IForms'
import { OUI } from '../constants/form'
import {
  ACTION_TYPE,
  NO_PLV,
  VISIT_ACTION_TYPE_ID,
  VISIT_COMPETITION_LIST,
  VISIT_COMPETITION_PLV_TYPE4,
  VISIT_COMPETITION_PROMO_TYPE4,
  VISIT_CONTACT_ID,
  VISIT_DUAL_LAYOUT_TYPE4,
  VISIT_DURATION,
  VISIT_LINIAR_GAIN_TYPE4,
  VISIT_PDL_LIST,
  VISIT_PDL_OPTIONS,
  VISIT_PLV_LIST,
  VISIT_RANGE_TYPE4,
  VISIT_PROMO_TYPE4,
  VISIT_REPORT_RESET_EMPTY,
  VISIT_REPORT_RESET_EMPTY_ARRAY,
  VISIT_REPORT_RESET_NULL,
  VISIT_TRAINING_TYPE4,
  VISIT_REPORT_THIRD_PANEL_VALIDATION_FIELDS,
  VISIT_REPORT_SECOND_PANEL_VALIDATION_FIELDS,
  VISIT_ACTION_TYPE_1,
  VISIT_REPORT_CURRENT_VERSION,
  VISIT_REPORT_ROTATIONS,
  VISIT_RANGE_TYPE5,
  VISIT_DUAL_LAYOUT_TYPE5,
  VISIT_LINIAR_GAIN_TYPE5,
  VISIT_TRAINING_TYPE5,
  VISIT_PROMO_TYPE5,
  VISIT_COMPETITION_PROMO_TYPE5,
  VISIT_COMPETITION_PLV_TYPE5,
  VISIT_RANGE_TYPE6,
  VISIT_DUAL_LAYOUT_TYPE6,
  VISIT_LINIAR_GAIN_TYPE6,
  VISIT_TRAINING_TYPE6,
  VISIT_PROMO_TYPE6,
  VISIT_COMPETITION_PROMO_TYPE6,
  VISIT_COMPETITION_PLV_TYPE6,
  VISIT_MONTHS_LIST,
  VISIT_COMPETITION_MONTHS_LIST,
  VISIT_REFERENCES_TYPE5,
  VISIT_REFERENCES_OPTIONS_TYPE5,
  VISIT_PRESENCE_TYPE4,
  VISIT_PRESENCE_TYPE5,
  VISIT_COMPETITION_TYPES_MAPPING,
  CURRENT_VISIT_COMPETITION_LIST,
} from '../constants/visitReport'
import { getSelectedLabel, prepareMultiSelectOptions } from './helpers'

export const prepareRequestForNewVisitReport = (values: IVisitReportForm) => {
  const version: TVisitReportCurrentVersion = VISIT_REPORT_CURRENT_VERSION
  const actionType = values.contactTypeId
  const bodyValues: any = []

  let strToIntFields = [VISIT_CONTACT_ID, VISIT_DURATION, VISIT_ACTION_TYPE_ID]

  let formFields = [...strToIntFields]

  if (actionType === VISIT_ACTION_TYPE_1) {
    strToIntFields = [...strToIntFields, ...VISIT_REPORT_ROTATIONS]

    const strToBoolFields = [
      VISIT_RANGE_TYPE4,
      VISIT_DUAL_LAYOUT_TYPE4,
      VISIT_LINIAR_GAIN_TYPE4,
      VISIT_TRAINING_TYPE4,
      VISIT_PROMO_TYPE4,
      VISIT_COMPETITION_PROMO_TYPE4,
      VISIT_COMPETITION_PLV_TYPE4,
      VISIT_PRESENCE_TYPE4,
      VISIT_RANGE_TYPE5,
      VISIT_DUAL_LAYOUT_TYPE5,
      VISIT_LINIAR_GAIN_TYPE5,
      VISIT_TRAINING_TYPE5,
      VISIT_PROMO_TYPE5,
      VISIT_COMPETITION_PROMO_TYPE5,
      VISIT_COMPETITION_PLV_TYPE5,
      VISIT_PRESENCE_TYPE5,
      VISIT_RANGE_TYPE6,
      VISIT_DUAL_LAYOUT_TYPE6,
      VISIT_LINIAR_GAIN_TYPE6,
      VISIT_TRAINING_TYPE6,
      VISIT_PROMO_TYPE6,
      VISIT_COMPETITION_PROMO_TYPE6,
      VISIT_COMPETITION_PLV_TYPE6,
    ]

    const MULTISELECT_OPTION_FIELDS = [
      ...VISIT_PLV_LIST,
      ...VISIT_MONTHS_LIST,
      ...VISIT_COMPETITION_MONTHS_LIST,
    ]

    formFields = [...strToBoolFields, ...strToIntFields]

    MULTISELECT_OPTION_FIELDS.forEach((item) => {
      if (
        ((values as IVisitReportForm)[
          `${item}` as keyof IVisitReportForm
        ] as string).length
      ) {
        bodyValues[`${item}`] = prepareMultiselectOptionForRequest(
          (values as IVisitReportForm)[
            `${item}` as keyof IVisitReportForm
          ] as IMultipleSelectOption[]
        )
      }
    })

    VISIT_PDL_LIST.forEach((item) => {
      if (
        ((values as IVisitReportForm)[
          `${item}` as keyof IVisitReportForm
        ] as string).length
      ) {
        bodyValues[`${item}`] = getSelectedLabel(
          (values as IVisitReportForm)[
            `${item}` as keyof IVisitReportForm
          ] as string,
          VISIT_PDL_OPTIONS
        )
      }
    })

    VISIT_COMPETITION_LIST.forEach((comp, index) => {
      if (
        ((values as IVisitReportForm)[
          `${comp}` as keyof IVisitReportForm
        ] as string).length
      ) {
        bodyValues[`${VISIT_COMPETITION_LIST[index]}`] = getSelectedLabel(
          values![
            `${VISIT_COMPETITION_LIST[index]}` as keyof IVisitReportForm
          ] as IMultipleSelectOption[],
          VISIT_COMPETITION_TYPES_MAPPING[
            comp as keyof typeof CURRENT_VISIT_COMPETITION_LIST
          ]
        )
      }
    })

    if (
      ((values as IVisitReportForm)[
        VISIT_REFERENCES_TYPE5 as keyof IVisitReportForm
      ] as string).length
    ) {
      bodyValues[VISIT_REFERENCES_TYPE5] = getSelectedLabel(
        (values as IVisitReportForm)[
          VISIT_REFERENCES_TYPE5 as keyof IVisitReportForm
        ] as IMultipleSelectOption[],
        VISIT_REFERENCES_OPTIONS_TYPE5
      )
    }
  }

  formFields
    .filter((key) => {
      const value = values[key as keyof typeof values]

      return (
        (!!value && (value as string | string[])?.length) ||
        key === VISIT_CONTACT_ID
      )
    })
    .forEach((key) => {
      const value = values[key as keyof typeof values]

      bodyValues[key] = strToIntFields.includes(key)
        ? parseInt(value as string, 10)
        : Array.isArray(value)
        ? value[0] === OUI
        : value === OUI
    })

  if (values.contactOutcomeId) {
    bodyValues.contactOutcomeId = +values.contactOutcomeId.value
  }

  if (values.duration) {
    bodyValues.duration = +values.duration * 60
  }

  bodyValues.comment = values.comment
  bodyValues.visitDate = format(new Date(values.visitDate), DATE_REQUEST_FORMAT)

  return {
    ...bodyValues,
    userId: getJwtUserDetails().id,
    version,
  }
}

const prepareMultiselectOptionForRequest = (
  multipleSelectOptions: IMultipleSelectOption[]
): number[] => {
  return multipleSelectOptions.reduce((multipleSelectData, { value }) => {
    multipleSelectData.push(parseInt(value, 10))

    return multipleSelectData
  }, [] as number[])
}

/**
 * Used to convert javascript datetime object from new visit report form for redux state.
 */
export const convertFormDatetime = (visitReport: IVisitReportForm) => {
  if (visitReport.visitDate) {
    return { ...visitReport, visitDate: JSON.stringify(visitReport.visitDate) }
  }

  return {
    ...visitReport,
  }
}

export const prepareContactOutcomeOptions = (
  options: IOption[],
  typeId: string | null
) => {
  const actionTypes = typeId
    ? ACTION_TYPE.filter(
        (el, index) => index + 1 === parseInt(typeId, 10) || index === 2
      )
    : ACTION_TYPE

  return actionTypes.map((actionType) => {
    const typeOptions = options.filter(({ type }) => actionType === type)

    return {
      label: actionType,
      options: prepareMultiSelectOptions(typeOptions),
    }
  })
}

export const dataURItoFile = (dataURI: string, filename: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([ia], filename, { type: mimeString })
}

export const prepareContactTypesOptions = (options: IOption[]) =>
  options.map(({ name, id }) => ({
    label: name,
    value: id.toString(),
  }))

export const preparePlvListOptions = (
  data: IPlvData[]
): ISelectOptionGroup[] => {
  return data.reduce((acc, { type, options }) => {
    const item: ISelectOptionGroup = {
      label: type,
      options: options.map(({ id, label }) => ({
        label,
        value: id.toString(),
      })),
    }
    acc.push(item)
    return acc
  }, [] as ISelectOptionGroup[])
}

export const getActiveTab = (errorField: string) => {
  if (!errorField) return 0
  if (VISIT_REPORT_THIRD_PANEL_VALIDATION_FIELDS.includes(errorField)) return 2
  if (VISIT_REPORT_SECOND_PANEL_VALIDATION_FIELDS.includes(errorField)) return 1
  return 0
}

export const existPlvOptions = (data: IPlvOptions[]) => {
  return !(data?.length === 1 && data[0].options[0].label === NO_PLV)
}

export const resetVisitReportFields: IVisitReportReset = (
  reset,
  setVisitDate,
  setValue
) => {
  reset()
  setVisitDate('')
  VISIT_REPORT_RESET_EMPTY.map((item) => setValue(`${item}`, ''))
  VISIT_REPORT_RESET_EMPTY_ARRAY.map((item) => setValue(`${item}`, []))
  VISIT_REPORT_RESET_NULL.map((item) => setValue(`${item}`, null))
}

export const prepareRequestForUploadImage = (
  values: IVisitReportForm,
  images: ILastVisitReportImages,
  type: string
) => {
  const image = values[`${type}` as keyof typeof values] as string
  const imageContent = image?.length && JSON.parse(image)
  const fileUploadAction = !imageContent.keepOld && imageContent.dataURL
  const keepOldAction = !imageContent.dataURL && imageContent.keepOld

  if (!imageContent?.dataURL?.length && !imageContent?.keepOld?.length) return

  return {
    file: fileUploadAction
      ? dataURItoFile(imageContent.dataURL, imageContent.fileName)
      : undefined,
    type,
    keepOld: keepOldAction
      ? ((images as ILastVisitReportImages)[
          type as keyof ILastVisitReportImages
        ].key as string)
      : undefined,
  }
}
