import { IRequest } from '../interfaces/IRequest'
import { API_PATHS } from '../enums/apiPaths'
import { api, getAuthHeader } from '../utils/api'
import { getQueryParams } from '../utils/dashboard'
import {
  IDashboardCaFiltersForSubmit,
  IDashboardVisitsCoverageService,
} from '../interfaces/IDashboard'

export const getDashboardService = async <T,>(
  filters: IDashboardVisitsCoverageService | IDashboardCaFiltersForSubmit,
  user: string,
  apiPath?: string,
  labelFields?: string[]
): Promise<T> => {
  const query = getQueryParams(filters, labelFields).join('&')

  const params: IRequest = {
    endpoint: `${API_PATHS.users}/${user}${
      apiPath || API_PATHS.visitsCoverage
    }?${query}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  const { body } = await api(params)

  return body
}
