import React, { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import TextInput from '../../form/TextInput'
import DynamicButtons from '../../form/DynamicButtons'
import FormGroupDate from '../../form/FormGroupDate'
import { RECLAMATION_TYPE_LIST } from '../../../constants/form'
import { DATEPICKER_TIME_FORMAT } from '../../../enums/common'
import { isSmallerThan } from '../../../utils/contracts'

export const ReclamationFormFirstSection: FC = () => {
  const { register, errors, control } = useFormContext()

  const [reclamationDate, setReclamationDate] = useState({
    startDate: '',
  })

  const incidentDate = {
    id: 'incidentDate',
    label: 'form.field.incidentDate.label',
    value: reclamationDate.startDate,
    setValue: setReclamationDate,
    classes: 'inputField inputDate datepickerLeft',
    error: errors.incidentDate,
    displayTime: true,
    format: DATEPICKER_TIME_FORMAT,
    maxDate: new Date(),
    control,
    rules: {
      required: true,
      validate: isSmallerThan,
    },
  }

  return (
    <>
      <div className="row mx0 mb05">
        <DynamicButtons
          optionsArray={RECLAMATION_TYPE_LIST}
          register={register({ required: true })}
          name="type"
          customClass="mixedFormType"
          error={errors.type}
        />
      </div>
      <div className="row pr15 mb2">
        <div className="colMd6 mb15">
          <TextInput
            name="laboratory"
            id="laboratory"
            register={register({ required: true })}
            error={errors.laboratory}
            placeholder="typeIn"
          />
        </div>
        <div className="colMd6 mb15">
          <FormGroupDate startDate={incidentDate} startDateOnly={true} />
        </div>
      </div>
    </>
  )
}
