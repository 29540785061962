import React, { FC, useMemo } from 'react'
import { Tab, Tabs, TabList } from 'react-tabs'
import { ITabProps } from '../../../interfaces/ITabs'
import DynamicFormattedMessage from './DynamicFormattedMessage'
import { ReactComponent as IconDisabled } from '../../../assets/images/iconBan.svg'

export const CustomTabs: FC<ITabProps> = ({
  tabList,
  children,
  activeTab = 0,
  onClick,
  setActiveTab,
  isDisabled = false,
}) => {
  const tabProps = useMemo(() => {
    return setActiveTab
      ? {
          selectedIndex: activeTab,
          onSelect: setActiveTab,
        }
      : {}
  }, [setActiveTab, activeTab])

  return (
    <Tabs {...tabProps}>
      <TabList>
        {tabList.map((tab) => (
          <Tab
            key={tab}
            data-qa={`${tab}Click`}
            onClick={onClick}
            disabled={isDisabled}
          >
            <div className="row noWrap justifyContentCenter">
              {isDisabled && (
                <span className="mr05 disabledIcon">
                  <IconDisabled
                    title="Disabled"
                    stroke="currentColor"
                    fill="currentColor"
                    className="dBlock"
                  />
                </span>
              )}
              <DynamicFormattedMessage id={`tab.${tab}.label`} />
            </div>
          </Tab>
        ))}
      </TabList>

      {children}
    </Tabs>
  )
}
