import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { api, getJwtUserDetails, handleError } from '../utils/api'
import { ROUTES } from '../enums/routes'
import { loginActions } from '../store/reducers/loginReducer'
import { ILoginForm } from '../interfaces/IForms'
import { STATUS } from '../enums/common'
import { AUTHORIZATION_TOKEN } from '../constants/api'
import { TApiResponse } from '../interfaces/IClientDetails'
import { clientsFiltersActions } from '../store/reducers/filtersReducer'
import { API_PATHS } from '../enums/apiPaths'
import { addDataInIndexedDb } from '../utils/offline'
import { statusNetworkReducerActions } from '../store/reducers/statusNetworkReducer'
import { sharedDataSelectors } from '../store/selectors'
import { sharedDataActions } from '../store/reducers/sharedDataReducer'
import { getJwtUsername } from '../utils/user'
import { getFullName } from '../utils/helpers'
import { allClientsActions } from '../store/reducers/allClientsReducer'

const useLoginFormHook = () => {
  const form = useForm<ILoginForm>()
  const history = useHistory()
  const dispatch = useDispatch()
  const intl = useIntl()
  const userWithOptions = useSelector(sharedDataSelectors.getUserSavedData)
    ?.forUser

  const [formState, setFormState] = useState<TApiResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })

  const onSubmit = async (data: FormData) => {
    setFormState({ status: STATUS.PENDING, message: '' })
    try {
      const response = await api({
        endpoint: API_PATHS.login,
        method: 'POST',
        body: JSON.stringify(data),
      })
      setFormState({ status: STATUS.SUCCESS, message: '' })
      localStorage.setItem(
        AUTHORIZATION_TOKEN,
        response.responseHeaders.get(AUTHORIZATION_TOKEN) || ''
      )
      addDataInIndexedDb(
        'authorization-token',
        {
          token: response.responseHeaders.get(AUTHORIZATION_TOKEN),
          isDeleteConfirmationOpen: false,
        } || ''
      )
      dispatch(loginActions.authenticate())
      dispatch(clientsFiltersActions.resetFilters())
      dispatch(statusNetworkReducerActions.setSyncStopped(false))

      const jwt = getJwtUserDetails()
      const loggedUser = jwt?.id?.toString()
      if (userWithOptions === loggedUser) {
        history.push(ROUTES.dashboard)

        return
      }

      dispatch(
        sharedDataActions.setUserSavedData({
          forUser: loggedUser,
          selectedUser: loggedUser,
          username: getJwtUsername(jwt),
          fullName: getFullName(jwt, true),
        })
      )
      dispatch(allClientsActions.setAllClients([]))
      history.push(ROUTES.dashboard)
    } catch (e) {
      const handledError = handleError(e, intl)
      setFormState(handledError)
    }
  }

  return { form, onSubmit, formState }
}

export default useLoginFormHook
