import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import MultipleSelect from '../../../form/MultipleSelect'
import { NO_PLV, VISIT_PDL_OPTIONS } from '../../../../constants/visitReport'
import DynamicButtons from '../../../form/DynamicButtons'
import { RADIO_OPTIONS_LIST } from '../../../../constants/form'
import SelectInput from '../../../form/SelectInput'
import {
  capitaliseString,
  getDynamicButtonDefaultValue,
  getNWord,
} from '../../../../utils/helpers'
import {
  IVisitReportForm,
  IVisitReportMission,
} from '../../../../interfaces/IVisitReports'
import { VisitReportMissionTitle } from './VisitReportMissionTitle'
import { DYNAMIC_BUTTONS_INPUT_TYPE } from '../../../../enums/common'
import { TDynamicButton } from '../../../../interfaces/ICommonComponents'
import { VisitReportPromoAndMonthsSection } from './VisitReportPromoAndMonthsSection'

export const VisitReportSecondPanelMission: FC<IVisitReportMission> = ({
  mission,
  missionData,
  defaultData,
  plvOptions = [],
  customClass = '',
}) => {
  const { actions, training, promo, months, plv, pdl } = missionData
  const { errors, control, register, setValue } = useFormContext()
  const noPlvOption = plvOptions.find(({ options }) =>
    options.find(({ label }) => label === NO_PLV)
  )?.options[0]

  return (
    <div className={`missionSection colMd6 mt0 mb3 ${customClass}`}>
      <VisitReportMissionTitle mission={mission} />
      {actions?.length && (
        <div className="row mb15 alignItemsCenter">
          <DynamicFormattedMessage
            id="form.section.actions.label"
            className="textPrimary fontBold colMd3"
          />
          <div className="colMd9 px1 dFlex flexWrap justifyContentMdEnd justifyContentLgStart">
            {actions.map((action) => (
              <div
                className="px05 mr05 alignItmesCenter justifyContentCenter"
                key={action}
              >
                <div className="py05">
                  <DynamicFormattedMessage
                    id={`form.field.action${capitaliseString(
                      getNWord(action, 0)
                    )}.label`}
                    className="textPrimary textXxs"
                  />
                </div>
                <div className="py05">
                  <DynamicButtons
                    optionsArray={RADIO_OPTIONS_LIST}
                    register={register}
                    name={action}
                    customClass="noLabel noWrap actionButtons"
                    defaultValue={getDynamicButtonDefaultValue(
                      (defaultData as IVisitReportForm)[
                        `${action}` as keyof IVisitReportForm
                      ] as TDynamicButton
                    )}
                    hideLabel={true}
                    inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
                    setValue={setValue}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {training?.length && (
        <div className="row mb15 alignItemsCenter">
          <DynamicFormattedMessage
            id="form.section.training.label"
            className="textPrimary fontBold colMd3"
          />
          <div className="colMd9 px1 dFlex flexWrap justifyContentMdEnd justifyContentLgStart">
            {training.map((value) => (
              <div
                className="px05 mr05 alignItmesCenter justifyContentCenter"
                key={value}
              >
                <div className="py05">
                  <DynamicButtons
                    optionsArray={RADIO_OPTIONS_LIST}
                    register={register}
                    name={value}
                    customClass="noLabel noWrap actionButtons"
                    defaultValue={getDynamicButtonDefaultValue(
                      (defaultData as IVisitReportForm)[
                        `${value}` as keyof IVisitReportForm
                      ] as TDynamicButton
                    )}
                    hideLabel={true}
                    inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
                    setValue={setValue}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {(promo || months) && (
        <VisitReportPromoAndMonthsSection
          defaultData={defaultData}
          promo={promo}
          months={months}
        />
      )}
      {plv && plvOptions?.length > 0 && (
        <div className="row mb15">
          <div className="colMd3 dFlex">
            <DynamicFormattedMessage
              id="form.section.plv.title.label"
              className="textPrimary fontBold"
            />
          </div>
          <div className="colMd9 dFlex justifyContentMdEnd justifyContentLgStart">
            <MultipleSelect
              id={plv}
              options={plvOptions}
              defaultValue={[]}
              singleValueOverride={noPlvOption}
              hideMultiRemove={false}
              showLabel={false}
              className="wFull"
              parentClassName="wFull"
              control={control}
              error={errors[`${plv}`]}
            />
          </div>
        </div>
      )}
      {pdl && (
        <div className="row">
          <div className="colMd3 dFlex">
            <DynamicFormattedMessage
              id="form.section.pdl.label"
              className="textPrimary fontBold"
            />
          </div>
          <SelectInput
            id={pdl}
            name={pdl}
            options={VISIT_PDL_OPTIONS}
            register={register({ required: false })}
            error={errors[`${pdl}`]}
            defaultValue={
              (defaultData as IVisitReportForm)[
                `${pdl}` as keyof IVisitReportForm
              ] as string
            }
            defaultPlaceholder={true}
            customClass="colMd9 justifyContentMdEnd justifyContentLgStart"
            renderLabel={false}
          />
        </div>
      )}
    </div>
  )
}
