import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { STATUS } from '../../enums/common'
import { setClientComment } from '../../services/clientDetailsService'
import { clientDetailsActions } from '../../store/reducers/clientDetailsReducer'
import { TClientFileReponse } from '../../interfaces/IClientDetails'
import { statusNetworkReducerActions } from '../../store/reducers/statusNetworkReducer'
import { statusNetworkSelector } from '../../store/selectors'
import { useClientsDetails } from './UseClientDetails'
import { ROUTES } from '../../enums/routes'
import { handleError } from '../../utils/api'

export const UseClientAddComment = (
  clientId: string,
  fieldAgentComment: string
) => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const [userComment, setUserComment] = useState(fieldAgentComment)
  const [formState, setFormState] = useState<TClientFileReponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const { clientDetailsData, getClientData } = useClientsDetails(clientId)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAbandonModalOpen, setIsAbandonModalOpen] = useState(false)
  const handleCommentChange = (event: any) => {
    setUserComment(event.target.value)

    if (event.target.value !== fieldAgentComment) {
      setIsAbandonModalOpen(true)
    }
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }
  const handleCommentSubmit = async () => {
    setFormState({ status: STATUS.PENDING, message: '' })
    try {
      setIsAbandonModalOpen(false)
      await setClientComment(clientId, userComment)
      setFormState({ status: STATUS.SUCCESS, message: '' })
      dispatch(clientDetailsActions.setClientComment(userComment))
      if (clientDetailsData.fieldAgentComment !== userComment) {
        getClientData()
      }

      return window.history.state
        ? history.go(-1)
        : history.replace(generatePath(ROUTES.clientDetails, { clientId }))
    } catch (e) {
      if (!hasNetwork) {
        setIsAbandonModalOpen(false)
        dispatch(statusNetworkReducerActions.submitedForm())

        return window.history.state
          ? history.go(-1)
          : history.replace(generatePath(ROUTES.clientDetails, { clientId }))
      }

      setIsModalOpen(true)
      const handledError = handleError(e, intl)
      setFormState(handledError)
    }
  }

  return {
    userComment,
    formState,
    handleCommentChange,
    handleCommentSubmit,
    isModalOpen,
    isAbandonModalOpen,
    closeModal,
  }
}
