import React, { FC } from 'react'
import { useUserMedia } from '../../hooks/UseUserMedia'
import { IPhotoCapture } from '../../interfaces/IVisitReports'
import Button from '../common/ui/Button'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

export const PhotoCapture: FC<IPhotoCapture> = ({
  type,
  isCameraOpen,
  photoRef,
  isDisabled,
  wrapperClassName,
  onPhotoUpdateCallback,
  onImageDeleteCallback,
  onImageUploadCallback,
}) => {
  const camera = useUserMedia()
  const { isCanvasEmpty, takePhoto, handleClearPhoto } = camera

  return (
    <div className={`row justifyContentBetween ${wrapperClassName}`}>
      <div className="customImageUploadWrapper dFlex alignItemsStart justifyContentStart mb2">
        <div className="customImageUploadBtnWrapper ">
          {isCanvasEmpty ? (
            <DynamicFormattedMessage
              id="uploadPhoto.button.label"
              tag={Button}
              className={`btn btnPrimary btnUpload btnCameraUpload textXs ${
                !isCameraOpen || isDisabled ? 'btnDisabled' : ''
              }`}
              onClick={() =>
                takePhoto(
                  photoRef,
                  type,
                  onPhotoUpdateCallback,
                  onImageUploadCallback
                )
              }
              disabled={!isCameraOpen || isDisabled}
            />
          ) : (
            <DynamicFormattedMessage
              id="clearPhoto.button.label"
              tag={Button}
              className={`btn btnDelete btnDeleteImage textXs ${
                isDisabled ? 'btnDisabled' : ''
              }`}
              onClick={() => {
                handleClearPhoto(type, onPhotoUpdateCallback)
                if (onImageDeleteCallback) {
                  onImageDeleteCallback()
                }
              }}
              disabled={isDisabled}
            />
          )}
        </div>
      </div>
    </div>
  )
}
