import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mockNewVisitReportFormInitialState } from '../../__mocks__/dataMock'
import { IVisitReportForm } from '../../interfaces/IVisitReports'

const newVisitReportReducer = createSlice({
  name: 'newVisitReportReducer',
  initialState: mockNewVisitReportFormInitialState,
  reducers: {
    setNewVisitReport: (state, { payload }: PayloadAction<IVisitReportForm>) =>
      payload,
  },
})

export const newVisitReportActions = newVisitReportReducer.actions

export default newVisitReportReducer.reducer
