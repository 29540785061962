import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { IUsersOptions } from '../../interfaces/IUsers'
import { CurrentDayAppointments } from './CurrentDayAppointments'
import { VisitReports } from './VisitReports'
import { ActivityReports } from './ActivityReports'
import { useDashboardHook } from '../../hooks/dashboard/UseDashboardHook'
import {
  sharedDataSelectors,
  statusNetworkSelector,
} from '../../store/selectors'
import { UsersSelect } from '../users/UsersSelect'

export const DashboardContent: FC = () => {
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const userId = useSelector(sharedDataSelectors.getUserSavedData).selectedUser
  const {
    users,
    appointments,
    userActivity,
    onUserChange,
    dataStatusPending,
    isUsersLoadingError,
    isUserNotAvailableError,
  } = useDashboardHook()

  return (
    <>
      <UsersSelect
        name="userFilter"
        users={users?.data as IUsersOptions[]}
        onUserChange={onUserChange}
        defaultUser={userId}
        disabled={!hasNetwork || dataStatusPending}
        wrapperClass="colMd5 mb1 px0"
        isUserNotAvailableError={isUserNotAvailableError}
        isUsersLoadingError={isUsersLoadingError}
      />
      <CurrentDayAppointments {...appointments} />
      <VisitReports {...userActivity} />
      <ActivityReports {...userActivity} />
    </>
  )
}
