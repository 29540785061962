import { useEffect, useState } from 'react'
import { IClientAppointmentsResponseData } from '../../interfaces/IClientDetails'
import { clientAppointmentDataInitialState } from '../../constants/userData'
import { STATUS } from '../../enums/common'
import { getClientAppointments } from '../../services/getClientsListService'

/**
 * Used to fetch the client appointments.
 */
export const useClientAppointments = (clientId: string) => {
  const [
    clientAppointments,
    setClientAppointments,
  ] = useState<IClientAppointmentsResponseData>({
    clientAppointmentsData: clientAppointmentDataInitialState,
    status: STATUS.PENDING,
    total: 0,
  })

  useEffect(() => {
    const getClientAppointment = async () => {
      try {
        const {
          body: { data, total },
        } = await getClientAppointments(clientId)
        setClientAppointments((prevState) => {
          return {
            ...prevState,
            clientAppointmentsData: data,
            status: STATUS.SUCCESS,
            total,
          }
        })
      } catch (e) {
        setClientAppointments((prevState) => {
          return {
            ...prevState,
            status: STATUS.DANGER,
            total: 0,
          }
        })
      }
    }

    getClientAppointment()
  }, [clientId])

  return { clientAppointments }
}
