import { IStore } from '../index'

export const getContactTypesValue = (state: IStore) =>
  state.sharedDataReducer.contactTypes

export const getContactOutcomesValue = (state: IStore) =>
  state.sharedDataReducer.contactOutcomes

export const getPlvListValue = (state: IStore) =>
  state.sharedDataReducer.plvList

export const getAppointmentTypesValue = (state: IStore) =>
  state.sharedDataReducer.appointmentTypes

export const getContractsSelectedYearValue = (state: IStore) =>
  state.sharedDataReducer.contractsSelectedYear

export const getNavClick = (state: IStore) => state.sharedDataReducer.navClick

export const getUserSavedData = (state: IStore) =>
  state.sharedDataReducer.userSavedData

export const getUsers = (state: IStore) => state.sharedDataReducer.users

export const getIsPageBlurred = (state: IStore) =>
  state.sharedDataReducer.isPageBlurred
