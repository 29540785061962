import React from 'react'
import { ContractType } from '../contractType/ContractType'
import { MIXED_FORM_CONTRACT_TYPE } from '../../interfaces/IContracts'

export const MixedFormType = ({
  clientId,
  mixedFormType,
  label,
}: {
  clientId: number
  mixedFormType: MIXED_FORM_CONTRACT_TYPE
  label: string
}) => {
  return (
    <ContractType
      contractName={label}
      isMixedForm={true}
      clientId={clientId}
      mixedFormType={mixedFormType}
    />
  )
}
