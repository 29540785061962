import React, { ReactElement, useRef } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import { Controller, useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ErrorMessage from './ErrorMessage'
import Button from '../common/ui/Button'
import { iconErase } from '../../assets/images/images'

function SignatureSection({
  label,
  changeForm,
}: {
  label?: string
  changeForm?: () => void
}): ReactElement {
  const { errors, control, setValue } = useFormContext()
  const signCanvas = useRef<ReactSignatureCanvas | null>(null)
  const formatIntoPng = () => {
    if (changeForm) {
      changeForm()
    }

    if (signCanvas.current) {
      const signatureData = signCanvas.current.toDataURL()
      setValue('signature', signatureData)

      return signatureData
    }
  }

  const clearSignature = () => {
    signCanvas.current?.clear()
    setValue('signature', '')

    if (changeForm) {
      changeForm()
    }
  }

  return (
    <div className="row mx0 mb15">
      <div className="colMd8 colSm12 pl0 pr0 dFlex justifyContentBetween signatureContentWrapper">
        <div className="pageFiltersSimpleSearch pl0">
          <DynamicFormattedMessage
            id={`form.reclamation.${label || 'signature'}.label`}
            tag="p"
            className="textPrimary fontMedium mb15 textLeft"
          />
        </div>
        <div className=" ml15 pr0 pl0 textRight dFlex alignItemsCenter mb15">
          <Button
            className="link btn linkBasic fontMedium textCenter btnLink px0 py0"
            data-qa="clearSignature"
            onClick={() => {
              clearSignature()
            }}
          >
            <img src={iconErase} alt="" className="mr05" />
            <DynamicFormattedMessage id="cta.clearSignature.label" />
          </Button>
        </div>
      </div>
      <div className="col12 px0">
        <div className="signatureWrapper withBackgroundColorPrimary">
          <Controller
            name="signature"
            control={control}
            rules={{ required: true }}
            render={({ onChange }) => (
              <ReactSignatureCanvas
                ref={signCanvas}
                canvasProps={{
                  className: 'signatureCanvas',
                }}
                clearOnResize={false}
                onEnd={() => {
                  onChange(formatIntoPng)
                  return formatIntoPng()
                }}
              />
            )}
            defaultValue=""
          />
        </div>
      </div>
      <ErrorMessage error={errors.signature} />
    </div>
  )
}

export default SignatureSection
