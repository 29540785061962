import React, { FC } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import DynamicFormattedMessage from '../../components/common/ui/DynamicFormattedMessage'
import { PageTitleSection } from '../../components/common/ui/PageTitleSection'
import Button from '../../components/common/ui/Button'
import { ClientFileDataList } from '../../components/clientEventsSection/ClientFileDataList'
import { UseClientAddComment } from '../../hooks/clientDetails/UseClientAddComment'
import { useClientsDetails } from '../../hooks/clientDetails/UseClientDetails'
import { STATUS } from '../../enums/common'
import ModalContainer from '../../components/modal/Modal'
import { ErrorMessageModal } from '../../components/modal/ErrorMessageModal'
import {
  FILTERED_DATA_FIELDS,
  SYNERGIE_DATA_FIELDS,
} from '../../constants/userData'
import CustomModal from '../../components/modal/CustomModal'
import Alerts from '../../components/alerts/Alerts'
import { ROUTES } from '../../enums/routes'
import ClientInfo from '../../components/common/ui/ClientInfo'
import { StickyWrapper } from '../../components/common/ui/StickyWrapper'

const ClientFile: FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const { clientId } = useParams()
  const { clientDetailsData, status, message } = useClientsDetails(clientId)
  const {
    id,
    name,
    cipCode,
    phoneAgentComment,
    fieldAgentComment,
  } = clientDetailsData
  const {
    formState,
    handleCommentChange,
    handleCommentSubmit,
    isModalOpen,
    isAbandonModalOpen,
    closeModal,
  } = UseClientAddComment(id.toString(), fieldAgentComment)

  return (
    <section className="pageContentWrapper clientFilePage pl75">
      <div className="px3 py3">
        <PageTitleSection
          backRoute={
            !window.history.state
              ? generatePath(ROUTES.clientDetails, {
                  clientId,
                })
              : ''
          }
        />
        <section className="pageTitleWrapper mb2">
          <h1 className="pageTitle">
            <DynamicFormattedMessage
              tag="span"
              id="page.clientFile.title.label"
            />
            <ClientInfo name={name} code={cipCode} />
          </h1>
        </section>
        <StickyWrapper>
          <Alerts status={status} message={message} />
          <Alerts status={formState.status} message={formState.message} />
        </StickyWrapper>
        <div className="clientFileDataWrapper withBackgroundColorDefault hasBorderRadius px2 py25">
          <div className="clientFileDataSection hasBorderBottom mb2">
            <div className="clientFileDataType">
              <DynamicFormattedMessage
                tag="span"
                id="clientFile.dataType.coordonnees.label"
              />
            </div>
            <div className="clientFileData">
              <ClientFileDataList
                clientDetailsData={clientDetailsData}
                filteringList={FILTERED_DATA_FIELDS}
                shouldOmit={true}
              />
            </div>
          </div>
          <div className="clientFileDataSection">
            <div className="clientFileDataType">
              <DynamicFormattedMessage
                tag="span"
                id="clientFile.dataType.synergie.label"
              />
            </div>
            <div className="clientFileData">
              <ClientFileDataList
                clientDetailsData={clientDetailsData}
                filteringList={SYNERGIE_DATA_FIELDS}
                shouldOmit={false}
              />
              <DynamicFormattedMessage
                tag="p"
                id="clientFile.dataType.drc.label"
                className="mb2 fontBold textPrimary"
              />
              <p className="mb2">{phoneAgentComment || '-'}</p>
              <DynamicFormattedMessage
                tag="p"
                id="clientFile.dataType.kam.label"
                className="mb1 fontBold textPrimary"
              />
              <textarea
                placeholder={intl.formatMessage({
                  id: `placeholder.typeIn`,
                })}
                rows={6}
                className="mb25 wFull hasBorderRadius clientFileCommentaryInput"
                defaultValue={fieldAgentComment}
                onChange={handleCommentChange}
              />
            </div>
          </div>
          <div className="clientFileButtonsWrapper dFlex">
            <DynamicFormattedMessage
              id="form.button.cancel"
              tag={Button}
              onClick={() =>
                window.history.state
                  ? window.history.go(-1)
                  : history.replace(
                      generatePath(ROUTES.clientDetails, {
                        clientId,
                      })
                    )
              }
              className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15"
              data-qa="backButton"
            />
            <DynamicFormattedMessage
              id="form.button.submit"
              tag={Button}
              className="btn btnPrimary px15 py15"
              data-qa="submitComment"
              onClick={() => handleCommentSubmit()}
              disabled={formState.status === STATUS.PENDING}
            />
          </div>
        </div>
        <ModalContainer
          isOpen={isModalOpen}
          portalClassName="errorMessageModalWrapper"
        >
          <ErrorMessageModal closeModal={closeModal} />
        </ModalContainer>
        <CustomModal isModalOpen={isAbandonModalOpen} />
      </div>
    </section>
  )
}

export default ClientFile
