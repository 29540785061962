import React, { FC } from 'react'
import { ITableHeaders } from '../../../../interfaces/ITables'
import DynamicFormattedMessage from '../DynamicFormattedMessage'

export const Table: FC<ITableHeaders> = ({ tableHeaders, children }) => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  return (
    <table className="customTable wFull">
      <thead className="customTableHeader">
        <tr>
          <th scope="col"> </th>
          {tableHeaders.map((header, index) => (
            <th
              key={header}
              scope="col"
              className={`px1 py1 ${index === currentMonth ? 'active' : ''}`}
            >
              <DynamicFormattedMessage
                id={`table.header.${header}.label`}
                className="fontMedium textUppercase textPrimary textCustomXs textLeft"
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="customTableBody">{children}</tbody>
    </table>
  )
}
