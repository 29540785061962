import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  clientsFiltersSelectors,
  sharedDataSelectors,
} from '../store/selectors'
import { useLoadData } from './UseLoadData'
import { getData } from '../services/getClientsListService'
import {
  DECILE_BEBE_ROUTE,
  GROUPMENTS_ROUTE,
  INITIAL_ACTIVE_PAGE,
  INITIAL_OFFSET,
  ITEMS_PER_PAGE,
  STATUS,
} from '../enums/common'
import {
  IFilters,
  IFiltersSelectFieldsData,
  IUrlQueryFilters,
} from '../interfaces/IFilters'
import {
  ADVANCED_SEARCH_SELECT_INPUT_FIELDS,
  advancedFiltersInitialState,
  DEFAULT_COUNT_VALUE,
  INPUT_SEARCH_ACCOUNTS_PORTFOLIO,
  INPUT_SEARCH_MAP,
  PAGE_PARAM,
} from '../constants/filters'
import { clientsFiltersActions } from '../store/reducers/filtersReducer'
import { useClientsList } from './clientDetails/UseClientsList'
import { usePagination } from './UsePagination'
import { IPlaceholderMessageProps } from '../interfaces/ICommonComponents'
import { TClientDetailsItem } from '../interfaces/IClientDetails'
import { TChangePage } from '../interfaces/INavigation'
import { useUrlParamsHook } from './UseUrlParamsHook'
import { convertFiltersDatetime } from '../utils/data'
import { ACCOUNTS_PORTFOLIO } from '../constants/routes'
import { ROUTES } from '../enums/routes'
import { MAP_ITEMS_SIZE } from '../constants/generalData'
import { clearSimpleSearchInput, isFiltersSet, isPage } from '../utils/filters'
import { sharedDataActions } from '../store/reducers/sharedDataReducer'
import {
  IMultipleSelectOption,
  IOption,
  ISelectOption,
} from '../interfaces/IForms'
import {
  prepareMultiSelectOptions,
  prepareSelectOptions,
} from '../utils/helpers'
import { CLIENT_SOURCE } from '../constants/userData'

export const useLoadClientsHook = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { state, search, pathname } = useLocation()

  const clientsFiltersData = useSelector(
    clientsFiltersSelectors.getClientsFiltersValue
  )
  const {
    loadFiltersFromQuery,
    loadedFiltersFromQuery,
    selectedPage: storeSelectedPage,
  } = useSelector(clientsFiltersSelectors.clientsFiltersData)

  const groupements = useLoadData(() =>
    getData<IOption[], IMultipleSelectOption[]>(
      GROUPMENTS_ROUTE,
      prepareMultiSelectOptions
    )
  )

  const decileBebe = useLoadData(() =>
    getData<IOption[], ISelectOption[]>(DECILE_BEBE_ROUTE, prepareSelectOptions)
  )

  const [fieldsData, setFieldsData] = useState<
    IFiltersSelectFieldsData<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>
  >({
    groupIds: groupements,
    decileBebe,
  })
  const [isFieldsDataReady, setIsFieldsDataReady] = useState<boolean>(false)

  const [filters, setFilters] = useState<IFilters>(clientsFiltersData)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [offset, setOffset] = useState<number>(
    state?.offset || state?.currentPage - 1 || INITIAL_OFFSET
  )
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_COUNT_VALUE)
  const [urlFiltersReady, setUrlFiltersReady] = useState<boolean>(false)
  const navClick = useSelector(sharedDataSelectors.getNavClick)

  const [lastVisitDate] = useState({
    startDate: filters?.lastVisitStart ?? '',
    endDate: filters?.lastVisitEnd ?? '',
  })

  const [isParamsLoadedFromUrl, setIsParamsLoadedFromUrl] = useState<boolean>(
    false
  )

  const [isChangePage, setIsChangePage] = useState<boolean>(false)

  /**
   * - can be used for further development so that we check if we should block
   * the load of the client data until all select field data from filters modal is loaded
   * - an improvement idea would be to allow data to load if no filters are applied, but only page param is set
   * - for portfolio page !!search condition will always evaluate to true, as we always have set the param page
   * - should not be done without unitary tests in place as a lot of variables have to be checked for data to load
   * accordingly
   * - handle changes for loading data with filters with attention
   */
  /* eslint-disable */
  const isQueryOnlyPageParam = useMemo(() => {
    const urlParams: URLSearchParams = new URLSearchParams(search)
    let hasOtherParams = false
    urlParams.forEach((val, key) => {
      if (key.toString() !== PAGE_PARAM) {
        hasOtherParams = true
      }
    })

    return !hasOtherParams
  }, [search])
  /* eslint-enable */

  const isMapPage = useMemo(() => isPage(pathname, ROUTES.map), [pathname])

  const isPortfolioPage = useMemo(
    () => isPage(pathname, ROUTES.accountsPortfolio),
    [pathname]
  )

  const isFilters = useMemo(() => (filters ? isFiltersSet(filters) : false), [
    filters,
  ])

  /**
   * if filters loaded from url, check if field select data finished loaded
   */
  const showClientsList = useMemo(() => {
    if (isMapPage) {
      return !navClick && !(!search && isFilters)
    }

    return search ? isFieldsDataReady : true
  }, [search, isFieldsDataReady, isMapPage, isFilters, navClick])

  /**
   * check if we should wait to load client data (prevents doubled api calls)
   *
   * if filters present in url check if data was loaded from url query filters
   *
   * if client data not ready wait
   * if url filters not ready wait
   *
   * if not url query, load default data
   */
  const waitToLoadClientData = useMemo(() => {
    if (!!search && loadFiltersFromQuery) {
      return loadedFiltersFromQuery ? !showClientsList : !urlFiltersReady
    }

    if (isMapPage) {
      return !showClientsList
    }

    dispatch(clientsFiltersActions.setLoadFiltersFromQuery(false))

    return false
  }, [
    showClientsList,
    loadFiltersFromQuery,
    loadedFiltersFromQuery,
    urlFiltersReady,
    dispatch,
    search,
    isMapPage,
  ])

  const { clients, items, status, message } = useClientsList(
    filters,
    isMapPage ? 0 : offset,
    waitToLoadClientData,
    isMapPage ? MAP_ITEMS_SIZE : ITEMS_PER_PAGE,
    isMapPage ? CLIENT_SOURCE.MAP : undefined
  )

  const { slicedData, currentPage, setCurrentPage } = usePagination(
    clients,
    ITEMS_PER_PAGE,
    storeSelectedPage ?? state?.currentPage ?? INITIAL_ACTIVE_PAGE
  )

  const placeholderMessageProps: IPlaceholderMessageProps<
    TClientDetailsItem[]
  > = {
    status,
    data: clients,
  }

  const changePage: TChangePage = useCallback(
    (page, onlyOffset = false) => {
      setOffset(page - 1)
      if (onlyOffset) return
      setCurrentPage(page)
      setIsChangePage(true)
    },
    [setCurrentPage, setIsChangePage]
  )

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const backRoute = useMemo(() => pathname + (search ?? ''), [pathname, search])

  const getMapRoute = useCallback(() => {
    if (search) {
      const searchParams: URLSearchParams = new URLSearchParams(search)
      searchParams.delete(PAGE_PARAM)

      return `${ROUTES.map}${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`
    }

    return ROUTES.map
  }, [search])

  const getAccountsPortfolioRoute = useCallback(() => {
    const searchParams: URLSearchParams = new URLSearchParams()
    searchParams.set(
      PAGE_PARAM,
      !navClick ? storeSelectedPage.toString() : INITIAL_ACTIVE_PAGE.toString()
    )

    return `${ROUTES.accountsPortfolio}?${searchParams.toString()}${
      search && search.length > 1 ? `&${search.slice(1)}` : ''
    }`
  }, [storeSelectedPage, search, navClick])

  const { loadFiltersFromURlQuery, selectedPage } = useUrlParamsHook(
    storeSelectedPage
  )

  const isUrlPageSet = useCallback(
    (pageNo: number) => {
      const urlParams: URLSearchParams = new URLSearchParams(search)
      const isPageParam: boolean = urlParams.has(PAGE_PARAM)
      const pageParam = urlParams.get(PAGE_PARAM)

      return isPageParam && pageParam && +pageParam === pageNo
    },
    [search]
  )

  // set data loading status
  useEffect(() => {
    if (
      groupements.status === STATUS.SUCCESS &&
      decileBebe.status === STATUS.SUCCESS
    ) {
      setFieldsData({
        groupIds: groupements,
        decileBebe,
      })
      setIsFieldsDataReady(true)
    }
  }, [groupements, decileBebe])

  useEffect(() => {
    // reset search on navClick or when filters are set but query is empty
    if (!isPortfolioPage || !(!search && isFilters)) return
    setFilters((prevState: IFilters) => {
      const newFilters = {
        ...prevState,
        ...advancedFiltersInitialState,
        search: '',
        lastVisitStart: lastVisitDate.startDate,
        lastVisitEnd: lastVisitDate.endDate,
      }

      clearSimpleSearchInput(INPUT_SEARCH_ACCOUNTS_PORTFOLIO)

      dispatch(
        clientsFiltersActions.setFilters(convertFiltersDatetime(newFilters))
      )

      if (changePage) {
        changePage(INITIAL_ACTIVE_PAGE)
      }

      return newFilters
    })
  }, [
    isPortfolioPage,
    search,
    isFilters,
    dispatch,
    lastVisitDate.endDate,
    lastVisitDate.startDate,
    changePage,
  ])

  useEffect(() => {
    // reset search on navClick
    if (!navClick) return

    setFilters((prevState: IFilters) => {
      const newFilters = {
        ...prevState,
        ...advancedFiltersInitialState,
        search: '',
        lastVisitStart: lastVisitDate.startDate,
        lastVisitEnd: lastVisitDate.endDate,
      }

      clearSimpleSearchInput(
        isPortfolioPage ? INPUT_SEARCH_ACCOUNTS_PORTFOLIO : INPUT_SEARCH_MAP
      )

      dispatch(
        clientsFiltersActions.setFilters(convertFiltersDatetime(newFilters))
      )

      if (changePage && currentPage !== INITIAL_ACTIVE_PAGE) {
        dispatch(clientsFiltersActions.setSelectedPage(INITIAL_ACTIVE_PAGE))
        changePage(INITIAL_ACTIVE_PAGE)
      }

      return newFilters
    })

    dispatch(sharedDataActions.setNavClick(false))
  }, [
    navClick,
    isPortfolioPage,
    dispatch,
    lastVisitDate.endDate,
    lastVisitDate.startDate,
    changePage,
    currentPage,
  ])

  useEffect(() => {
    if (!isPortfolioPage) return
    if (!state) {
      setCurrentPage(storeSelectedPage)
    } else {
      setCurrentPage(state?.currentPage || INITIAL_ACTIVE_PAGE)
    }
  }, [state, setCurrentPage, storeSelectedPage, isPortfolioPage])

  useEffect(() => {
    if (!isPortfolioPage || navClick) return
    dispatch(clientsFiltersActions.setSelectedPage(currentPage))
    setOffset(currentPage - 1)
  }, [currentPage, dispatch, isPortfolioPage, navClick])

  useEffect(() => {
    if (!isPortfolioPage || !isChangePage || isUrlPageSet(currentPage)) return

    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set(PAGE_PARAM, currentPage.toString())
    history.push({
      pathname: `${ACCOUNTS_PORTFOLIO}`,
      search: `${searchParams.toString() ? `?${searchParams.toString()}` : ''}`,
    })
  }, [
    search,
    currentPage,
    history,
    isChangePage,
    isPortfolioPage,
    isUrlPageSet,
  ])

  useEffect(() => {
    setTotalCount(status === STATUS.SUCCESS ? items : DEFAULT_COUNT_VALUE)
  }, [items, status])

  useEffect(() => {
    if (
      isParamsLoadedFromUrl ||
      loadedFiltersFromQuery ||
      !(!!search && isFieldsDataReady)
    )
      return

    setIsParamsLoadedFromUrl(true)
    const urlQueryFilters: IUrlQueryFilters = loadFiltersFromURlQuery(
      fieldsData
    )

    if (!urlQueryFilters) return

    setFilters((prevState: IFilters) => {
      const newFilters = {
        ...prevState,
        ...urlQueryFilters.filters,
        lastVisitStart:
          (urlQueryFilters.filters as IFilters).lastVisitStart ?? '',
        lastVisitEnd: (urlQueryFilters.filters as IFilters).lastVisitEnd ?? '',
      }

      dispatch(
        clientsFiltersActions.setFilters(convertFiltersDatetime(newFilters))
      )

      if (
        changePage &&
        isPortfolioPage &&
        urlQueryFilters.page !== storeSelectedPage
      ) {
        changePage(urlQueryFilters.page)
      }

      return newFilters
    })
    setUrlFiltersReady(true)
  }, [
    loadFiltersFromURlQuery,
    search,
    isParamsLoadedFromUrl,
    fieldsData,
    isFieldsDataReady,
    changePage,
    dispatch,
    lastVisitDate.endDate,
    lastVisitDate.startDate,
    selectedPage,
    loadedFiltersFromQuery,
    pathname,
    storeSelectedPage,
    isPortfolioPage,
  ])

  return {
    filters,
    groupements,
    decileBebe,
    totalCount,
    status,
    message,
    setFilters,
    changePage,
    toggleModal,
    isModalOpen,
    fieldsData,
    slicedData,
    offset,
    currentPage,
    waitToLoadClientData,
    placeholderMessageProps,
    clients,
    showClientsList,
    items,
    search,
    backRoute,
    getMapRoute,
    getAccountsPortfolioRoute,
  }
}
