import { useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import {
  dashboardVisitsCoverageSelector,
  statusNetworkSelector,
} from '../../../store/selectors'
import { useUsersDashboardHook } from '../UseUsersDashboardHook'
import { STATUS } from '../../../enums/common'
import { IHandledError } from '../../../interfaces/ICommonComponents'
import { useSelectedUserCheck } from '../UseSelectedUserCheck'

export const useDashboardVisitsCoverage = () => {
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const {
    userId,
    users,
    onUserChange,
    statusPending,
    selectedUserName,
    isUsersLoadingError,
    isUserNotAvailableError,
    setIsUserNotAvailableError,
  } = useUsersDashboardHook()

  const {
    isUserDifferentFromSearchParam,
    shouldUseLoggedUserId,
  } = useSelectedUserCheck(isUserNotAvailableError, isUsersLoadingError)

  const { chartData, chartDataPerType, fetchDataStatus } = useSelector(
    dashboardVisitsCoverageSelector.getDashboardVisitsCoverageValue
  )

  const [formState, setFormState] = useState<IHandledError>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })

  const formStateIdleOrPending = useMemo(
    () => fetchDataStatus === STATUS.IDLE || fetchDataStatus === STATUS.PENDING,
    [fetchDataStatus]
  )

  return {
    hasNetwork,
    userId,
    users,
    onUserChange,
    statusPending,
    selectedUserName,
    formState,
    setFormState,
    formStateIdleOrPending,
    chartData,
    chartDataPerType,
    fetchDataStatus,
    isUsersLoadingError,
    isUserNotAvailableError,
    setIsUserNotAvailableError,
    isUserDifferentFromSearchParam,
    shouldUseLoggedUserId,
  }
}
