import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import TextInput from '../../../form/TextInput'
import { IPrices, IVisitReportForm } from '../../../../interfaces/IVisitReports'
import { getNWord } from '../../../../utils/helpers'
import { useIsTabDisabled } from '../../../../hooks/clientVisitReport/UseIsTabDisabled'

export const VisitReportPrices: FC<IPrices> = ({
  defaultData,
  prices,
  labelNo = 1,
}) => {
  const { errors, register } = useFormContext()
  const isDisabled = useIsTabDisabled()

  return (
    <div className="row">
      <DynamicFormattedMessage
        id="form.section.prices.label"
        className="col12 textPrimary fontBold mb1"
      />
      {prices?.map((value) => (
        <div className="colLg4 colMd6" key={value}>
          <DynamicFormattedMessage
            id={`form.field.price${getNWord(value, labelNo)}.label`}
            className="inputLabel textXs"
          />
          <TextInput
            name={value}
            id={value}
            register={register({
              required: false,
              validate: (val) => {
                if (isDisabled) return
                if (!!val && !val.match(/^(\d+([,]\d{0,2})?|\.?\d{1,2})$/))
                  return 'number.twoDigits'
                if (+val > 99999) return 'number.maxNo'
              },
            })}
            error={errors[`${value}`]}
            placeholder="typeIn"
            customClass="noLabel priceField"
            defaultValue={
              (defaultData as IVisitReportForm)[
                `${value}` as keyof IVisitReportForm
              ] as string
            }
            hasLabel={false}
          />
        </div>
      ))}
    </div>
  )
}
