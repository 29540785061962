import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendEmail } from '../../services/contractsServices'
import { isEmptyObj } from '../../utils/helpers'
import { IEmailData } from '../../interfaces/IContracts'
import { TSendEmailData } from '../../interfaces/ICommonComponents'

export const useSendEmailDynamically = (
  sendEmailData: TSendEmailData | IEmailData,
  reduxAction: (payload: any) => any
) => {
  const dispatch = useDispatch()
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [isFormEmailMessageVisible, setIsFormEmailMessageVisible] = useState(
    false
  )
  const [availableFormEmails, setAvailableFormEmails] = useState([''])

  const handleSendEmailFail = () => {
    setIsFormEmailMessageVisible(true)
    setEmailSuccess(false)
  }

  const handleSendEmailData = useCallback(async () => {
    await sendEmail({
      kamEmail: sendEmailData?.kamEmail,
      dcrEmail: sendEmailData?.dcrEmail,
      clientEmail: sendEmailData?.clientEmail,
      type: sendEmailData?.type,
      entityId: sendEmailData?.entityId as number,
    })
      .then((response) => {
        if (response.body.success) {
          setIsFormEmailMessageVisible(true)
          setEmailSuccess(true)

          return
        }

        handleSendEmailFail()
      })
      .catch(() => {
        handleSendEmailFail()
      })
  }, [sendEmailData])

  useEffect(() => {
    if (isEmptyObj(sendEmailData)) return

    setAvailableFormEmails([
      sendEmailData?.clientEmail?.toString(),
      sendEmailData?.kamEmail?.toString(),
      sendEmailData?.dcrEmail?.toString(),
    ] as string[])

    handleSendEmailData().then(() =>
      dispatch(reduxAction({} as TSendEmailData | IEmailData))
    )
  }, [handleSendEmailData, sendEmailData, dispatch, reduxAction])

  return {
    emailSuccess,
    isFormEmailMessageVisible,
    availableFormEmails,
    handleSendEmailData,
  }
}
