import jwtDecode from 'jwt-decode'
import { IntlShape } from 'react-intl'
import {
  API_VERSION,
  BASE_BACKEND_URL,
  DEFAULT,
  ERROR_MESSAGES_BY_STATUS_OR_CODE,
  ROLE_ADMIN,
  STATUS,
} from '../enums/common'
import { AuthToken, IRequest } from '../interfaces/IRequest'
import { AUTHORIZATION_TOKEN, CONTENT_TYPE_APP_JSON } from '../constants/api'
import { ROUTES } from '../enums/routes'
import { API_PATHS } from '../enums/apiPaths'
import { clearDataFromIndexedDb } from './offline'
import {
  IHandledError,
  IIntlValues,
  TErrorMessage,
} from '../interfaces/ICommonComponents'
import { ResponseError } from '../models/errors/ResponseError'

export const api = async (
  apiParams: IRequest,
  contentType: string = CONTENT_TYPE_APP_JSON,
  download: boolean = false
) => {
  const {
    endpoint,
    method,
    headers = getAuthHeader(),
    body,
    baseURL = `${BASE_BACKEND_URL}${API_VERSION}`,
  } = apiParams

  let defaultHeader = {
    'Content-Type': contentType,
  }

  // Content type automatically set for FormData body
  if (body instanceof FormData) {
    // @ts-ignore
    defaultHeader = {}
  }

  const response = await fetch(baseURL + endpoint, {
    method: method || 'GET',
    headers: { ...defaultHeader, ...headers },
    mode: 'cors',
    body,
  })
  const responseHeaders = response.headers
  const responseBody = download ? await response.blob() : await response.json()
  const { status } = response

  if (status && status === 401 && endpoint !== API_PATHS.login) {
    ;(window as any).location = ROUTES.login
    localStorage.removeItem(AUTHORIZATION_TOKEN)
    clearDataFromIndexedDb('authorization-token')
  }

  if (status.toString()[0] === '4' || status.toString()[0] === '5') {
    const messageCode = getErrorMessageByStatusOrCode(
      status,
      responseBody?.code
    )
    throw new ResponseError(messageCode)
  }

  return { body: responseBody, status, responseHeaders }
}

const getErrorMessageByStatusOrCode = (
  status: number,
  code?: number
): TErrorMessage => {
  const rule = code ? `${status}-${code}` : `${status}`
  return ERROR_MESSAGES_BY_STATUS_OR_CODE[rule] || DEFAULT
}

export const getAuthHeader = () => {
  const token = localStorage.getItem(AUTHORIZATION_TOKEN)

  return { Authorization: token }
}

export const getJwtUserDetails = () => {
  const token = localStorage.getItem(AUTHORIZATION_TOKEN)
  if (!token || !token.length) {
    return {} as AuthToken
  }

  return jwtDecode<AuthToken>(token)
}

export const userIsAdmin = () => {
  const token = localStorage.getItem(AUTHORIZATION_TOKEN)
  if (!token || !token.length) {
    return false
  }

  return jwtDecode<AuthToken>(token)?.role === ROLE_ADMIN
}

export const handleError = (
  error: Error,
  intl: IntlShape,
  values: IIntlValues = {}
): IHandledError => {
  if (error instanceof ResponseError) {
    return {
      status: STATUS.DANGER,
      message: intl.formatMessage(
        {
          id: error.getMessageIdentifier(),
        },
        values
      ),
      messageCode: error.getMessageCode(),
    }
  }

  return {
    status: STATUS.DANGER,
    message: intl.formatMessage({
      id: `error.response.default`,
    }),
    messageCode: DEFAULT,
  }
}
