import React, { FC } from 'react'
import Button from '../common/ui/Button'
import { iconDelete } from '../../assets/images/images'
import { ReactComponent as IconDocument } from '../../assets/images/iconDocument.svg'
import { ICustomContract } from '../../interfaces/IContracts'
import { formatDate, handleHtmlContent } from '../../utils/helpers'
import {
  EVENTS_DATETIME_FORMAT,
  SEND_EMAIL_API_TYPE_CONTRACT_PDF,
} from '../../enums/common'
import { userIsAdmin } from '../../utils/api'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import { DropdownSection } from '../common/ui/DropdownSection'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { useDetailItem } from '../../hooks/clientContracts/UseDetailItem'
import { SendEmailButton } from '../common/ui/SendEmailButton'
import { DownloadButton } from '../common/ui/DownloadButton'

export const DetailItem: FC<ICustomContract> = ({
  id,
  clientName,
  creationDate,
  cipCode,
  contractDate,
  kamEmail,
  dcrEmail,
  setControlDelete,
  setControlDownload,
  downloadFormState,
  setDownloadFormState,
  isDownloadModalOpen,
  setSendEmailState,
  setAvailableEmails,
  contractLabel,
  deleted,
  timestamp,
  isHistoryPage,
  isPdfGenerated,
  isMixedForm,
  mixedFormType,
  mixedFormTypeApiPath,
}) => {
  const {
    contractId,
    isAreaOpen,
    shouldDisplayNoPdfWarning,
    handleSendEmailClick,
    handleDownload,
    handleAdminDeleteControl,
    isDownloadButtonDisabled,
    isDownloadLoading,
    isSendEmailButtonDisabled,
    timeZoneTime,
    setIsAreaOpen,
  } = useDetailItem(
    id,
    clientName,
    creationDate,
    cipCode,
    setControlDelete,
    isDownloadModalOpen,
    setControlDownload,
    downloadFormState,
    setDownloadFormState,
    contractLabel,
    deleted,
    timestamp,
    isPdfGenerated,
    isMixedForm,
    mixedFormType,
    mixedFormTypeApiPath
  )

  return (
    <div
      className={`${
        deleted
          ? 'backgroundDangerLight'
          : id
          ? 'backgroundDefault'
          : 'backgroundLighterGrey'
      } hasBoxShadow hasBorderRadius mb2`}
    >
      <div className={`py15 mx15 ${isAreaOpen ? 'hasBorderBottom' : ''}`}>
        <div className="dFlex alignItemsCenter justifyContentBetween flexWrap">
          <div className="dFlex mb1 mb0sm">
            <div
              className={`${
                shouldDisplayNoPdfWarning
                  ? 'textNeutralLightest'
                  : 'textPrimary'
              }`}
            >
              <IconDocument
                title="icon document"
                stroke="currentColor"
                fill="currentColor"
                className="mr2"
              />
            </div>
            <div className="pr1">
              <p
                className={`textCustomSm fontBold textPrimary mb1 
              ${shouldDisplayNoPdfWarning ? 'textNeutralLightest' : ''}`}
              >
                <span>
                  {`${clientName} `}
                  {cipCode && <ClientCipCode cipCode={cipCode} />}
                </span>
              </p>
              <p
                className="textNeutralLightest contractDate"
                dangerouslySetInnerHTML={handleHtmlContent(
                  formatDate(
                    contractDate || timeZoneTime,
                    EVENTS_DATETIME_FORMAT,
                    '',
                    isHistoryPage
                  )
                )}
              />
            </div>
          </div>
          {shouldDisplayNoPdfWarning && (
            <div className="dFlex alignItemsCenter">
              <DynamicFormattedMessage
                tag="span"
                id="page.contracts.item.message.warning"
                className="contractWarning"
              />
            </div>
          )}
          <div className="dFlex">
            {contractId && (
              <SendEmailButton
                disabled={isSendEmailButtonDisabled}
                onClick={handleSendEmailClick}
              />
            )}
            <DownloadButton
              disabled={isDownloadButtonDisabled}
              onClick={handleDownload}
              loading={isDownloadLoading}
            />
            {userIsAdmin() && !isHistoryPage && (
              <Button
                className={`${
                  deleted ? 'btnDisabled' : ''
                } btn btnDanger btnIcon ml2`}
                key={id}
                onClick={handleAdminDeleteControl}
                disabled={deleted}
              >
                <img src={iconDelete} alt="icon delete" />
              </Button>
            )}
          </div>
        </div>
      </div>
      {contractId && (
        <div
          className={`sendEmailWrapper dNone ${
            isAreaOpen ? 'dFlex justifyContentEnd px15 pt15' : ''
          }`}
        >
          <DropdownSection
            entityId={id}
            sendEmailType={SEND_EMAIL_API_TYPE_CONTRACT_PDF}
            timestamp={timestamp}
            sendEmailAdditionalData={{ kamEmail, dcrEmail }}
            setIsAreaOpen={setIsAreaOpen}
            setSendEmailState={setSendEmailState}
            setAvailableEmails={setAvailableEmails}
          />
        </div>
      )}
    </div>
  )
}
