import React, { FC } from 'react'
import { IUserMediaSection } from '../../../interfaces/IVisitReports'
import { BACK_CAMERA } from '../../../enums/common'
import DynamicFormattedMessage from './DynamicFormattedMessage'
import Button from './Button'

export const UserMediaSection: FC<IUserMediaSection> = ({
  camera,
  isCameraOpen,
  setIsCameraOpen,
}) => {
  const {
    videoRef,
    photoRef,
    paintToCanvas,
    hasUserMedia,
    getCamera,
    stop,
    usedCamera,
    setUsedCamera,
    isVideoPlaying,
    handleSwitchToFront,
    handleSwitchToBack,
    disabledSwitch,
  } = camera

  const handlePlayCamera = () => {
    setUsedCamera(BACK_CAMERA)
    setIsCameraOpen(hasUserMedia)
    getCamera(BACK_CAMERA)
  }

  const handleStopCamera = () => {
    stop()
    setIsCameraOpen(false)
  }

  const isBackCamera = usedCamera === BACK_CAMERA
  return (
    <>
      <div className="mb2">
        <DynamicFormattedMessage
          id="form.userMediaSection.legend"
          tag="p"
          className="textPrimary"
        />
      </div>
      <div
        className={`row mb2 ${
          isCameraOpen
            ? 'sticky hasBorderBottom withBackgroundColorDefault py1'
            : ''
        }`}
      >
        <div className="colMd5 mb2">
          {hasUserMedia && isCameraOpen ? (
            <DynamicFormattedMessage
              id="form.userMediaSection.stopCamera"
              tag={Button}
              className="btn btnUpload btnStopCamera"
              onClick={handleStopCamera}
            />
          ) : (
            <DynamicFormattedMessage
              id="form.userMediaSection.playCamera"
              tag={Button}
              className={`btn btnUpload btnPlayCamera ${
                !hasUserMedia ? 'btnDisabled' : ''
              }`}
              onClick={handlePlayCamera}
              disabled={!hasUserMedia}
            />
          )}
          {!hasUserMedia && (
            <DynamicFormattedMessage
              id="upload.noCamera.label"
              tag="p"
              className="textPrimary textXs mt05"
            />
          )}
        </div>
        <div className={`dNone ${isCameraOpen ? 'dFlex flexWrap colMd7' : ''}`}>
          <div className="cameraWarpper col12 mb2 textCenter">
            <video
              ref={videoRef}
              onCanPlay={() => paintToCanvas()}
              muted
              className="video hasBoxShadow"
            />
            <canvas className="dNone" ref={photoRef} />
          </div>
          {hasUserMedia && isCameraOpen && !disabledSwitch && (
            <div className="switchCamera col12 textCenter">
              <DynamicFormattedMessage
                id="form.userMediaSection.switchToFront"
                tag={Button}
                className={`btn btnPrimary ${
                  !isVideoPlaying ? 'btnDisabled' : ''
                }`}
                onClick={handleSwitchToFront}
                disabled={!isVideoPlaying}
                shouldRender={isBackCamera}
              />
              <DynamicFormattedMessage
                id="form.userMediaSection.switchToBack"
                tag={Button}
                className={`btn btnPrimary ${
                  !isVideoPlaying ? 'btnDisabled' : ''
                }`}
                onClick={handleSwitchToBack}
                disabled={!isVideoPlaying}
                shouldRender={!isBackCamera}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
