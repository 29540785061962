import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageTitleSection } from '../../components/common/ui/PageTitleSection'
import CustomModal from '../../components/modal/CustomModal'
import { NEW_DESTRUCTION_CERTIFICATE } from '../../constants/routes'
import { useClientsDetails } from '../../hooks/clientDetails/UseClientDetails'
import ClientInfo from '../../components/common/ui/ClientInfo'
import DestructionCertificateForm from '../../components/forms/DestructionCertificateForm'

const NewDestructionCertificate: FC = () => {
  const { clientId } = useParams()
  const { clientDetailsData, status, message } = useClientsDetails(clientId)
  const { name, cipCode } = clientDetailsData
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <section className="pageContentWrapper destructionCertificatePage pl75">
      <div className="px3 py3">
        <PageTitleSection pageName={NEW_DESTRUCTION_CERTIFICATE}>
          <ClientInfo name={name} code={cipCode} />
        </PageTitleSection>
        <div className="destructionCertificateFormWrapper withBackgroundColorDefault hasBorderRadius px2 py2">
          <DestructionCertificateForm
            setIsModalOpen={setIsModalOpen}
            clientDetailsData={clientDetailsData}
            dataLoadStatus={status}
            dataLoadMessage={message}
          />
        </div>
      </div>
      <CustomModal isModalOpen={isModalOpen} />
    </section>
  )
}

export default NewDestructionCertificate
