import { BRAND_2, BRAND_3, BRAND_5 } from '../enums/common'
import { IFilters } from '../interfaces/IFilters'

export const FILTER_BRANDS = [BRAND_2, BRAND_3, BRAND_5]

export const advancedFiltersInitialState: IFilters = {
  name: '',
  address: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  groupIds: [],
  lastVisitStart: '',
  lastVisitEnd: '',
  range: '',
  cipCode: '',
  search: '',
  brandNuk: [],
  decileBebe: [],
  brandClientHipp: [],
  brandClientNuk: [],
  brandClientMarque3: [],
  uga: '',
  noAppointments: false,
  recentColaborationCode: [],
  previousColaborationCode: [],
}

export const FILTER_NAME_FIELD = 'name'
export const FILTER_ADDRESS_FIELD = 'address'
export const FILTER_ZIPCODE_FIELD = 'zipCode'
export const FILTER_CITY_FIELD = 'city'
export const FILTER_PHONE_NUMBER_FIELD = 'phoneNumber'
export const FILTER_GROUPMENT_FIELD = 'groupIds'
export const FILTER_LAST_VISIT_START_FIELD = 'lastVisitStart'
export const FILTER_LAST_VISIT_END_FIELD = 'lastVisitEnd'
export const FILTER_RANGE_FIELD = 'range'
export const FILTER_CIP_CODE_FIELD = 'cipCode'
export const FILTER_DEFAULT_SEARCH_FIELD = 'search'
export const FILTER_BRAND_NUK = 'brandNuk'
export const FILTER_DECILE_BEBE = 'decileBebe'
export const FILTER_BRAND_CLIENT_HIPP = 'brandClientHipp'
export const FILTER_BRAND_CLIENT_NUK = 'brandClientNuk'
export const FILTER_BRAND_CLIENT_MARQUE3 = 'brandClientMarque3'
export const FILTER_UGA_FIELD = 'uga'
export const FILTER_APPOINTMENTS_FIELD = 'noAppointments'
export const FILTER_CIBLE_2_FIELD = 'targetType2Ids'
export const FILTER_CIBLE_1_FIELD = 'targetType1Ids'
export const FILTER_RECENT_COLABORATION_CODE = 'recentColaborationCode'
export const FILTER_PREVIOUS_COLABORATION_CODE = 'previousColaborationCode'

export const DEFAULT_COUNT_VALUE = -1
export const PAGE_PARAM = 'page'

export const ADVANCED_SEARCH_QUERY_MAPPING = {
  page: PAGE_PARAM,
  name: FILTER_NAME_FIELD,
  address: FILTER_ADDRESS_FIELD,
  phoneNumber: FILTER_PHONE_NUMBER_FIELD,
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
  range: FILTER_RANGE_FIELD,
  cipCode: FILTER_CIP_CODE_FIELD,
  zipCode: FILTER_ZIPCODE_FIELD,
  city: FILTER_CITY_FIELD,
  noAppointments: FILTER_APPOINTMENTS_FIELD,
  search: FILTER_DEFAULT_SEARCH_FIELD,
  recentColaborationCode: FILTER_RECENT_COLABORATION_CODE,
  previousColaborationCode: FILTER_PREVIOUS_COLABORATION_CODE,
  brandNuk: FILTER_BRAND_NUK,
  decileBebe: FILTER_DECILE_BEBE,
  brandClientHipp: FILTER_BRAND_CLIENT_HIPP,
  brandClientNuk: FILTER_BRAND_CLIENT_NUK,
  brandClientMarque3: FILTER_BRAND_CLIENT_MARQUE3,
  uga: FILTER_UGA_FIELD,
  groupIds: FILTER_GROUPMENT_FIELD,
} as const

export const ADVANCED_SEARCH_INPUT_FIELDS = {
  name: FILTER_NAME_FIELD,
  address: FILTER_ADDRESS_FIELD,
  phoneNumber: FILTER_PHONE_NUMBER_FIELD,
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
  range: FILTER_RANGE_FIELD,
  cipCode: FILTER_CIP_CODE_FIELD,
  zipCode: FILTER_ZIPCODE_FIELD,
  city: FILTER_CITY_FIELD,
  noAppointments: FILTER_APPOINTMENTS_FIELD,
  recentColaborationCode: FILTER_RECENT_COLABORATION_CODE,
  previousColaborationCode: FILTER_PREVIOUS_COLABORATION_CODE,
  brandNuk: FILTER_BRAND_NUK,
  brandClientHipp: FILTER_BRAND_CLIENT_HIPP,
  brandClientNuk: FILTER_BRAND_CLIENT_NUK,
  brandClientMarque3: FILTER_BRAND_CLIENT_MARQUE3,
  uga: FILTER_UGA_FIELD,
} as const

export const ADVANCED_SEARCH_SELECT_INPUT_FIELDS = {
  groupIds: FILTER_GROUPMENT_FIELD,
  decileBebe: FILTER_DECILE_BEBE,
} as const

export const ADVANCED_SEARCH_SIMPLE_SELECT_INPUT_FIELDS = {
  decileBebe: FILTER_DECILE_BEBE,
} as const

export const ADVANCED_SEARCH_DATE_FIELDS = {
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
} as const

export const ADVANCED_SEARCH_BOOLEAN_FIELDS = {} as const

export const ADVANCED_FILTERS_MODAL_FORM_KEY = 'advancedFiltersModalForm'
export const MAP_FORM_KEY = 'mapForm'

export const INPUT_SEARCH_ACCOUNTS_PORTFOLIO = 'simpleSearchName'

export const INPUT_SEARCH_MAP = 'simpleSearchText'
