import React from 'react'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'

export const VisitReportMissionTitle = ({ mission }: { mission: string }) => {
  return (
    <DynamicFormattedMessage
      id={`form.section.competitors${mission}.title.label`}
      tag="h3"
      className="wFull textPrimary textUppercase mb15 pt1"
    />
  )
}
