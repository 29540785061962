import { IContractBrandAnnexe, IContractRules } from '../interfaces/IContracts'

export const CONTACT_PERSON_INPUT = 'contactPerson'
export const CUSTOM_CONTACT_PERSON_VALUE = 'customContactPerson'

export const CONTRACT_NUK_RULES: IContractRules[] = [
  {
    range: [200, 299],
    percent: 35,
  },
  {
    range: [300, 399],
    percent: 38,
  },
  {
    value: 400,
    percent: 41,
  },
]

export const CONTRACT_ELLAONE_RULES: IContractRules[] = [
  {
    value: 30,
    percent: 8.6,
  },
]

export const CONTRACT_CICATRIDINE_RULES: IContractRules[] = [
  {
    value: 24,
    percent: 30,
  },
  {
    value: 48,
    percent: 32,
  },
  {
    value: 72,
    percent: 34,
  },
  {
    value: 108,
    percent: 36,
  },
  {
    value: 144,
    percent: 38,
  },
  {
    value: 180,
    percent: 40,
  },
]

export const CONTRACT_REFS = [
  {
    name: '6',
    value: '6',
  },
  {
    name: '9',
    value: '9',
  },
  {
    name: '12',
    value: '12',
  },
]

export const CONTRACT_COMPEED_RULES: IContractRules[] = [
  {
    range: [100, 249],
    ref: 6,
    percent: 28,
  },
  {
    range: [100, 249],
    ref: 9,
    percent: 30,
  },
  {
    range: [100, 249],
    ref: 12,
    percent: null,
  },
  {
    range: [250, 399],
    ref: 6,
    percent: 30,
  },
  {
    range: [250, 399],
    ref: 9,
    percent: 31,
  },
  {
    range: [250, 399],
    ref: 12,
    percent: 32,
  },
  {
    range: [400, 599],
    ref: 6,
    percent: 32,
  },
  {
    range: [400, 599],
    ref: 9,
    percent: 33,
  },
  {
    range: [400, 599],
    ref: 12,
    percent: 34,
  },
  {
    range: [600, 799],
    ref: 6,
    percent: 33,
  },
  {
    range: [600, 799],
    ref: 9,
    percent: 34,
  },
  {
    range: [600, 799],
    ref: 12,
    percent: 35,
  },
  {
    range: [800, 999],
    ref: 6,
    percent: 35,
  },
  {
    range: [800, 999],
    ref: 9,
    percent: 36,
  },
  {
    range: [800, 999],
    ref: 12,
    percent: 37,
  },
  {
    value: 1000,
    ref: 6,
    percent: 37,
  },
  {
    value: 1000,
    ref: 9,
    percent: 38,
  },
  {
    value: 1000,
    ref: 12,
    percent: 39,
  },
]

export const CONTRACT_BRANDS_ANNEXE: IContractBrandAnnexe = {
  ellaone: [
    {
      name: 'annexe6',
      displayValue: 80,
    },
  ],
  cicatridine: [
    {
      name: 'annexe5',
      displayValue: 108,
    },
  ],
  compeed: [
    {
      name: 'annexe3',
      displayValue: 600,
    },
    {
      name: 'annexe4',
      displayValue: 250,
    },
  ],
}
