import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../enums/routes'
import { statusNetworkReducerActions } from '../../store/reducers/statusNetworkReducer'
import { statusNetworkSelector } from '../../store/selectors'
import Button from '../common/ui/Button'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { checkAuth } from '../../services/authenticationServices'
import { InfoMessageAlerts } from './InfoMessageAlerts'
import ModalContainer from '../modal/Modal'
import { useCachedData } from '../../hooks/UseCachedData'
import Loader from '../loader/Loader'
import { OFFLINE_MESSAGE } from '../../enums/common'

const NetworkStatusAlerts: FC = () => {
  const dispatch = useDispatch()
  const {
    hasNetwork,
    onlineMsg,
    isFormSubmited,
    deletedMsg,
    syncDoneMsg,
    syncLoading,
    syncStopped,
  } = useSelector(statusNetworkSelector.getStatusNetworkValue)
  const isAuthenticated = checkAuth()
  const { synchroData, getSyncData } = useCachedData()
  const [syncDone, setSyncDone] = useState(false)
  const [syncImageStoreDone, setSyncImageStoreDone] = useState(false)

  const onClose = () => {
    dispatch(statusNetworkReducerActions.setDeletedMsg(false))
  }

  const handleSyncDoneMsg = () => {
    window.location.reload()
    setTimeout(
      () => dispatch(statusNetworkReducerActions.setSyncDoneMsg(false)),
      2000
    )
  }

  useEffect(() => {
    const onMessage = (event: ServiceWorkerContainerEventMap['message']) => {
      if (!event.data) return

      switch (event.data.type) {
        case OFFLINE_MESSAGE.onSyncStoreDone:
          getSyncData()
          setSyncDone(true)
          break
        case OFFLINE_MESSAGE.onSyncImageStoreDone:
          setSyncImageStoreDone(true)
          break
        case OFFLINE_MESSAGE.onSyncLoading:
          dispatch(statusNetworkReducerActions.setSyncLoading(true))
          dispatch(statusNetworkReducerActions.setSyncStopped(false))
          break
        case OFFLINE_MESSAGE.invalidToken:
          dispatch(statusNetworkReducerActions.setSyncLoading(false))
          dispatch(statusNetworkReducerActions.setSyncStopped(true))
          break
        case OFFLINE_MESSAGE.deleteCacheOpened:
          dispatch(statusNetworkReducerActions.setSyncLoading(false))
          break
        default:
          break
      }
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', onMessage)
    }
    return () => {
      navigator.serviceWorker.removeEventListener('message', onMessage)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      hasNetwork &&
      synchroData &&
      !synchroData.length &&
      syncDone &&
      syncImageStoreDone
    ) {
      dispatch(statusNetworkReducerActions.resetOfflineMsg(false))
      dispatch(statusNetworkReducerActions.setSyncLoading(false))
      dispatch(statusNetworkReducerActions.setSyncDoneMsg(true))
      setSyncDone(false)
    }
    // eslint-disable-next-line
  }, [hasNetwork, synchroData, syncDone, syncImageStoreDone])

  return (
    <div className="textCenter mt1">
      {hasNetwork ? (
        <div>
          {onlineMsg && isAuthenticated && (
            <div className="dFlex textPrimary textCenter alert alertSuccess alertNetwork justifyContentBetween">
              <div className="wFull">
                {synchroData?.length ? (
                  <>
                    <DynamicFormattedMessage id="network.online.message.sync" />
                    <DynamicFormattedMessage
                      id="network.online.link"
                      className="alertNetworkLink"
                      to={ROUTES.offlineSynchro}
                      tag={Link}
                    />
                  </>
                ) : (
                  <DynamicFormattedMessage id="network.online.message" />
                )}
              </div>
              <DynamicFormattedMessage
                id="network.online.closeBt"
                tag={Button}
                onClick={() =>
                  dispatch(statusNetworkReducerActions.resetStatus())
                }
                className="btn btnClose btnSuccess navbarToggleButton navbarToggleIsActive withBackgroundTransparent textInherit px0 py0 mx0 my0"
                data-qa="network.online.closeBt"
              />
            </div>
          )}
          {syncLoading && (
            <ModalContainer
              isOpen={syncLoading}
              portalClassName="errorMessageModalWrapper infoMessageModalWrapper"
            >
              <div>
                <InfoMessageAlerts
                  message="network.offline.message.sync.loading"
                  type="warning"
                  flash={false}
                />
                <Loader />
              </div>
            </ModalContainer>
          )}
          {syncDoneMsg && (
            <ModalContainer
              isOpen={syncDoneMsg}
              portalClassName="errorMessageModalWrapper infoMessageModalWrapper"
            >
              {!synchroData?.length ? (
                <InfoMessageAlerts
                  message="network.offline.message.sync.done"
                  type="warning"
                  asButton={true}
                  flash={false}
                  close={true}
                  closeAction={handleSyncDoneMsg}
                />
              ) : (
                <div>
                  <InfoMessageAlerts
                    message="network.offline.message.sync.loading"
                    type="warning"
                    flash={false}
                  />
                  <Loader />
                </div>
              )}
            </ModalContainer>
          )}
          {syncStopped && (
            <div className="mt2">
              <InfoMessageAlerts
                type="warning"
                message="offline.onSync.stopped"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="alertNetworkContainer relative">
          <div className="textCenter alert alertDanger errorMessage alertNetwork">
            <DynamicFormattedMessage id="network.offline.message" />
            <DynamicFormattedMessage
              id="network.offline.link"
              className="alertNetworkLink"
              to={ROUTES.offlineSynchro}
              tag={Link}
            />
          </div>
          {isFormSubmited &&
            setTimeout(
              () => dispatch(statusNetworkReducerActions.resetSubmitedForm()),
              3000
            ) && (
              <div className="alertNetwork alertNetworkForm textCenter alert alertWarning dFlex justifyContentBetween warningMessage mt05">
                <div className="wFull">
                  <strong>
                    <DynamicFormattedMessage id="network.offline.message.strong.form" />
                  </strong>
                  <DynamicFormattedMessage id="network.offline.message.form" />
                </div>
                <DynamicFormattedMessage
                  id="network.online.closeBt"
                  tag={Button}
                  onClick={() =>
                    dispatch(statusNetworkReducerActions.resetSubmitedForm())
                  }
                  className="btn btnClose btnWarning navbarToggleButton navbarToggleIsActive withBackgroundTransparent textInherit px0 py0 mx0 my0"
                  data-qa="network.offline.closeBt"
                />
              </div>
            )}
          {deletedMsg && (
            <InfoMessageAlerts
              message="network.offline.message.delete"
              type="warning"
              flash={true}
              close={true}
              closeAction={onClose}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default NetworkStatusAlerts
