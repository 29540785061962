import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { visitReportInitialState } from '../../constants/visitReport'
import { IVisitReport } from '../../interfaces/IVisitReports'

const visitReportReducer = createSlice({
  name: 'visitReportReducer',
  initialState: visitReportInitialState,
  reducers: {
    setVisitReport: (state, { payload }: PayloadAction<IVisitReport>) =>
      payload,
  },
})

export const visitReportActions = visitReportReducer.actions

export default visitReportReducer.reducer
