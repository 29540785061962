import React, { FC } from 'react'
import ModalContainer from '../../modal/Modal'
import { InfoMessageAlerts } from '../../alerts/InfoMessageAlerts'
import { IPageBlur } from '../../../interfaces/ICommonComponents'
import { usePageBlur } from '../../../hooks/UsePageBlur'

export const PageBlur: FC<IPageBlur> = ({ path, shouldBlurPage }) => {
  const { isModalOpen, closeModal } = usePageBlur(path, shouldBlurPage)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      portalClassName="blurMessageModal infoMessageModalWrapper confirmationMessageModalWrapper"
    >
      <InfoMessageAlerts
        message="noContent.label"
        type="warning"
        asButton={true}
        flash={false}
        close={true}
        closeAction={closeModal}
      />
    </ModalContainer>
  )
}
