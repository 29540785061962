import jwtDecode from 'jwt-decode'
import { AUTHORIZATION_TOKEN } from '../constants/api'
import { AuthToken } from '../interfaces/IRequest'
import { clearDataFromIndexedDb } from '../utils/offline'

export const checkAuth = () => {
  const token = localStorage.getItem(AUTHORIZATION_TOKEN)
  if (!token || !token.length) {
    return false
  }

  try {
    jwtDecode<AuthToken>(token)
  } catch (e) {
    return false
  }

  const tokenDecoded = jwtDecode<AuthToken>(token)
  const currentDate = new Date()
  const isTokenExpired =
    tokenDecoded && tokenDecoded.exp < currentDate.getTime()

  if (!isTokenExpired) {
    localStorage.removeItem(AUTHORIZATION_TOKEN)
    clearDataFromIndexedDb('authorization-token')
  }

  return isTokenExpired
}
