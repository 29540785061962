import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import TextInput from '../../form/TextInput'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import DynamicButtons from '../../form/DynamicButtons'
import {
  NOTIFIER_TYPE_LIST,
  RADIO_OPTIONS_LIST,
  OUI,
  AUTRE,
  EMAIL_REGEX,
} from '../../../constants/form'
import { checkValidation } from '../../../utils/contracts'
import ErrorMessage from '../../form/ErrorMessage'
import { RECLAMATION_AREA } from '../../../constants/mixedForms'

export const ReclamationFormSecondSection: FC = () => {
  const { register, watch, errors } = useFormContext()
  const watchFields = watch([
    'agreementContactInfo',
    'notifier',
    'fullName',
    'officeName',
    'reclamationAddress',
    'postalCode',
    'city',
    'phone',
    'fax',
    'email',
  ])

  const validationContactArea = useCallback(() => {
    if (watchFields.agreementContactInfo === OUI) {
      return (
        !!(
          watchFields.fullName ||
          watchFields.officeName ||
          watchFields.reclamationAddress ||
          watchFields.city ||
          watchFields.postalCode ||
          watchFields.phone ||
          watchFields.fax ||
          watchFields.email
        ) || RECLAMATION_AREA
      )
    }
  }, [
    watchFields.agreementContactInfo,
    watchFields.fullName,
    watchFields.officeName,
    watchFields.reclamationAddress,
    watchFields.city,
    watchFields.postalCode,
    watchFields.phone,
    watchFields.fax,
    watchFields.email,
  ])

  const [showErrorContactArea, setShowErrorContactArea] = useState(false)

  useEffect(() => {
    checkValidation(validationContactArea, setShowErrorContactArea)
  }, [validationContactArea])

  return (
    <>
      <div className="formSection row mx0 mb2">
        <div className="formType colMd3 px0 mb1">
          <DynamicFormattedMessage
            tag="p"
            id="form.section.reclamationNotifier.label"
          />
        </div>
        <div className="formData colMd9 px0">
          <div className="row mx0 mb05 px15">
            <DynamicButtons
              optionsArray={NOTIFIER_TYPE_LIST}
              register={register({ required: true })}
              name="notifier"
              error={errors.notifier}
            />
            <div
              className={`customInput col12 px0 dNone ${
                watchFields.notifier === AUTRE && 'dFlex'
              }`}
            >
              <TextInput
                name="other"
                id="other"
                register={register({
                  required: watchFields.notifier === AUTRE,
                })}
                customClass="noLabel mt1"
                placeholder="typeIn"
                error={errors.other}
              />
            </div>
          </div>
          <div
            className={`row mx0 mb05 ${
              watchFields.agreementContactInfo === OUI && 'mb15'
            }`}
          >
            <div className="px15 mb1">
              <DynamicFormattedMessage
                id="form.field.agreementContactInfo.label"
                tag="p"
                className="textPrimary"
              />
            </div>
            <div className="col12">
              <DynamicButtons
                optionsArray={RADIO_OPTIONS_LIST}
                register={register({
                  required: true,
                  validate: validationContactArea,
                })}
                name="agreementContactInfo"
                customClass="noLabel"
                defaultValue={RADIO_OPTIONS_LIST[1].value}
              />
              {showErrorContactArea && (
                <ErrorMessage error={errors.agreementContactInfo} />
              )}
            </div>
            <div
              className={`col12 px0 mt15 flexWrap hasBorderBottom dNone ${
                watchFields.agreementContactInfo === OUI && 'dFlex'
              }`}
            >
              <div className="colMd6">
                <TextInput
                  name="fullName"
                  id="fullName"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="officeName"
                  id="officeName"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="reclamationAddress"
                  id="reclamationAddress"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="city"
                  id="city"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="postalCode"
                  id="postalCode"
                  register={register}
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="phone"
                  id="phone"
                  register={register({
                    required: false,
                    pattern: {
                      value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                      message: 'phoneNumber',
                    },
                  })}
                  error={errors.phone}
                  type="tel"
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="fax"
                  id="fax"
                  register={register({
                    required: false,
                    pattern: {
                      value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                      message: 'phoneNumber',
                    },
                  })}
                  error={errors.phone}
                  type="tel"
                  placeholder="typeIn"
                />
              </div>
              <div className="colMd6">
                <TextInput
                  name="email"
                  id="email"
                  type="email"
                  register={register({
                    required: false,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'email',
                    },
                  })}
                  placeholder="typeIn"
                  error={errors.email}
                />
              </div>
            </div>
          </div>
          <div className="row mx0">
            <div className="px15 mb1">
              <DynamicFormattedMessage
                id="form.field.agreementExtraDetails.label"
                tag="p"
                className="textPrimary"
              />
            </div>
            <div className="col12">
              <DynamicButtons
                optionsArray={RADIO_OPTIONS_LIST}
                register={register({ required: true })}
                name="agreementExtraDetails"
                customClass="noLabel"
                error={errors.agreementExtraDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
