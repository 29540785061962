import React, { FC, useEffect, useState } from 'react'
import Button from './Button'
import DynamicFormattedMessage from './DynamicFormattedMessage'
import { IButtonWithIcon } from '../../../interfaces/ICommonComponents'

export const ButtonWithIcon: FC<IButtonWithIcon> = ({
  children,
  name,
  disabled,
  label = '',
}) => {
  const [isButtonDisabled, setButtonIsDisabled] = useState(false)

  useEffect(() => {
    setButtonIsDisabled(disabled)
  }, [disabled])

  return (
    <Button
      className={`btn btnOutline wFull hasBoxShadow withBackgroundColorDefault textUppercase dFlex alignItemsCenter justifyContentCenter ${
        isButtonDisabled ? 'btnDisabled' : ''
      }`}
      disabled={isButtonDisabled}
      data-qa={`userAction.cta.label.${name}`}
    >
      {!!label?.length && (
        <DynamicFormattedMessage
          id={`userAction.cta.label.${label}`}
          className="flexGrow"
        />
      )}
      {children}
    </Button>
  )
}
