import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../../enums/routes'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'
import { statusNetworkSelector } from '../../store/selectors'

export const useClientDetailsActions = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const resetContractsSelectedYear = () => {
    if (location.pathname.includes(ROUTES.accountsPortfolio))
      dispatch(sharedDataActions.setContractsSelectedYear(0))
  }

  return { resetContractsSelectedYear, hasNetwork }
}
