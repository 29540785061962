import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { INavigationHook } from '../interfaces/INavigation'

/**
 * Hook used to toggle navigation
 */
export const useToggleNavigation: () => INavigationHook = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { pathname } = useLocation()

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('noScroll')

      return
    }

    document.body.classList.remove('noScroll')
  }, [isMenuOpen])

  useEffect(() => {
    setIsMenuOpen(false)
  }, [pathname])

  return { isMenuOpen, setIsMenuOpen, toggleMenu }
}
