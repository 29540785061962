import { useFormContext } from 'react-hook-form'
import { useState } from 'react'
import { ImageListType, ImageType } from 'react-images-uploading'

export const useImageUploadHook = (photoPath: string) => {
  const { setValue } = useFormContext()
  const image = photoPath ? [{ dataURL: photoPath }] : []

  const [images, setImages] = useState<ImageType[]>(image)
  const maxNumber = 1

  const onChange = (imageList: ImageListType, type: number | string) => {
    // data for submit
    setImages(imageList as never[])
    const value = imageList[0]?.dataURL
      ? {
          dataURL: imageList[0]?.dataURL,
          fileName: imageList[0]?.file?.name,
        }
      : { dataURL: '' }

    setValue(`${type}`, JSON.stringify(value))
  }

  return { images, maxNumber, onChange }
}
