import React, { FC, useEffect, useState } from 'react'
import { generatePath, useLocation } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import { FullCalendarSection } from '../components/common/calendar/FullCalendarSection'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import { useCalendarHook } from '../hooks/clientAgenda/UseCalendarHook'
import DynamicFormattedMessage from '../components/common/ui/DynamicFormattedMessage'
import { ROUTES } from '../enums/routes'
import { IPageTitleProps } from '../interfaces/ICommonComponents'
import { getJwtUserDetails } from '../utils/api'
import Alerts from '../components/alerts/Alerts'
import { StickyWrapper } from '../components/common/ui/StickyWrapper'

const NewAppointmentAgenda: FC = () => {
  const jwt = getJwtUserDetails()
  const { state } = useLocation()
  const offset = state?.offset
  const prevLocation = state?.prevLocation
  const currentPage = state?.currentPage

  const {
    calendarConfig,
    events,
    calendarRef,
    eventsStatusPending,
  } = useCalendarHook(jwt?.id?.toString())
  const [pageTitleProps, setPageTitleProps] = useState<IPageTitleProps>({})

  useEffect(() => {
    if (offset && offset >= 0) {
      setPageTitleProps({
        backRoute: generatePath(ROUTES.accountsPortfolio),
        backData: offset,
      })
    }
  }, [offset])

  return (
    <section className="pageContentWrapper appointmentAgendaPage pl75">
      <div className="px3 py3">
        <PageTitleSection
          {...pageTitleProps}
          backRoute={
            !window.history.state
              ? prevLocation || ROUTES.accountsPortfolio
              : ''
          }
          currentPage={currentPage}
        />
        <section className="mb2 calendarWrapper">
          <div className="messageWrapper">
            <DynamicFormattedMessage
              id="calendar.appointment.message"
              tag="p"
              className="textPrimary textCenter fontMedium alert alertSuccess"
            />
          </div>
          <StickyWrapper>
            <Alerts status={events.status} message={events.message} />
          </StickyWrapper>
          <FullCalendarSection
            calendarConfig={{ ...calendarConfig, eventClick: () => {} }}
            events={events?.data}
            calendarRef={calendarRef as React.RefObject<FullCalendar>}
            eventsPending={eventsStatusPending}
          />
        </section>
      </div>
    </section>
  )
}

export default NewAppointmentAgenda
