import { useMemo } from 'react'
import { HISTORY_ANNUAL_INITIAL_STATE_TOTAL } from '../../__mocks__/historyMock'
import { TYPE_SUMMARY, VIEW_TYPE } from '../../constants/tables'
import {
  IHistoryAnnualTotal,
  IHistoryTableRow,
  THistoryViewType,
} from '../../interfaces/IHistory'
import { getHistoryTotalPerViewType } from '../../utils/history'

export const useHistoryTotal = (
  data: IHistoryTableRow[],
  viewType: THistoryViewType = VIEW_TYPE.TOTAL,
  initialState: IHistoryAnnualTotal = HISTORY_ANNUAL_INITIAL_STATE_TOTAL
) => {
  const summaryData = useMemo(
    () =>
      data?.length ? data?.filter(({ type }) => type === TYPE_SUMMARY) : [],
    [data]
  )

  return useMemo(
    () => getHistoryTotalPerViewType(summaryData, viewType, initialState),
    [summaryData, viewType, initialState]
  )
}
