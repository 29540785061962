import React, { FC, useState } from 'react'
import 'react-tabs/style/react-tabs.css'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import VisitReportForm from '../../components/forms/VisitReportForm'
import CustomModal from '../../components/modal/CustomModal'
import { VisitReportPageTitle } from '../../components/clientVisitReport/VisitReportPageTitle'
import NetworkStatusAlerts from '../../components/alerts/NetworkStatusAlerts'
import { newVisitReportActions } from '../../store/reducers/newVisitReportReducer'
import { mockNewVisitReportFormInitialState } from '../../__mocks__/dataMock'

const NewVisitReport: FC = () => {
  const { clientId } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const onBackConfirm = () => {
    dispatch(
      newVisitReportActions.setNewVisitReport(
        mockNewVisitReportFormInitialState
      )
    )
  }

  return (
    <section className="pageContentWrapper visitReportPage pl75">
      <div className="px3 py3">
        <div className="pageTitleWrapper mb2 alignItemsCenter">
          <VisitReportPageTitle clientId={clientId} />
          <NetworkStatusAlerts />
        </div>
        <div className="tabsWrapper tabsReport withBackgroundColorDefault hasBorderRadius">
          <VisitReportForm
            clientId={clientId}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
        <CustomModal isModalOpen={isModalOpen} onBackConfirm={onBackConfirm} />
      </div>
    </section>
  )
}

export default NewVisitReport
