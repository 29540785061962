import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { advancedFiltersInitialState } from '../../constants/filters'
import { IFilters, IFiltersStore } from '../../interfaces/IFilters'
import { INITIAL_ACTIVE_PAGE } from '../../enums/common'

const clientsFiltersReducer = createSlice({
  name: 'clientsFiltersReducer',
  initialState: {
    filters: advancedFiltersInitialState,
    loadFiltersFromQuery: true,
    loadedFiltersFromQuery: false,
    selectedPage: INITIAL_ACTIVE_PAGE,
    isClickResetSimpleSearch: false,
  } as IFiltersStore,
  reducers: {
    setFilters: (state, { payload }: PayloadAction<IFilters>) => ({
      ...state,
      ...{ filters: payload },
    }),
    resetFilters: (state) => ({
      ...state,
      ...{ filters: advancedFiltersInitialState },
    }),
    setLoadFiltersFromQuery: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      ...{ setLoadFiltersFromQuery: payload },
    }),
    setLoadedFiltersFromQuery: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      ...{ loadedFiltersFromQuery: payload },
    }),
    setSelectedPage: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      ...{ selectedPage: payload },
    }),
    setIsClickResetSimpleSearch: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      ...{ isClickResetSimpleSearch: payload },
    }),
  },
})

export const clientsFiltersActions = clientsFiltersReducer.actions

export default clientsFiltersReducer.reducer
