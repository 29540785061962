import React, { FC } from 'react'
import { ContractForm } from '../../components/forms/ContractForm'

const NewContract: FC = () => {
  return (
    <section className="pageContentWrapper dashboardPage pl75">
      <div className="px3 py3">
        <ContractForm />
      </div>
    </section>
  )
}

export default NewContract
