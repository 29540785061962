import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../common/ui/Button'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { OfflineSynchroTable } from './OfflineSynchroTable'
import ModalContainer from '../modal/Modal'
import { useCachedData } from '../../hooks/UseCachedData'
import { InfoMessageAlerts } from '../alerts/InfoMessageAlerts'
import { OFFLINE_EVENT_TAG, STATUS } from '../../enums/common'
import { ConfirmationMessageModal } from '../modal/ConfirmationMessageModal'
import { addDataInIndexedDb, clearDataFromIndexedDb } from '../../utils/offline'
import { statusNetworkSelector } from '../../store/selectors'
import { AUTHORIZATION_TOKEN } from '../../constants/api'
import { StickyWrapper } from '../common/ui/StickyWrapper'

export const OfflineSynchroContent: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    synchroData,
    synchroFailedData,
    openIndexedDBFailedSyncData,
    getSyncData,
    getFailedSyncData,
    deleteSyncData,
    deleteSyncVisitReportData,
    deleteSyncVisitReportImagesData,
    deleteSyncNewContractData,
    deleteSyncEmailsData,
    deleteFailedSyncData,
    deleteState,
    cacheUsage,
    isMemoryFull,
    isSupported,
    isConnected,
  } = useCachedData()

  const { hasNetwork, syncDoneMsg } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  useEffect(() => {
    getSyncData()
    openIndexedDBFailedSyncData()
    // eslint-disable-next-line
  }, [syncDoneMsg])

  useEffect(() => {
    const token = localStorage.getItem(AUTHORIZATION_TOKEN)

    clearDataFromIndexedDb('authorization-token')
    addDataInIndexedDb('authorization-token', {
      token,
      isDeleteConfirmationOpen: isModalOpen,
    })
  }, [isModalOpen])

  const handleCancelDeleteModal = () => {
    const synchroDataUnsynced =
      hasNetwork &&
      'serviceWorker' in navigator &&
      'SyncManager' in window &&
      synchroData?.length
    if (!synchroDataUnsynced) return
    navigator.serviceWorker.ready.then((registration) =>
      registration.sync.register(OFFLINE_EVENT_TAG.backgroundForceSync)
    )
  }

  return (
    <>
      <StickyWrapper>
        {isMemoryFull && (
          <InfoMessageAlerts message="cacheUsage.fullMemory" type="danger" />
        )}
        {!isConnected && (
          <InfoMessageAlerts message="error.response.default" type="danger" />
        )}
        {deleteState.status !== STATUS.IDLE && (
          <InfoMessageAlerts
            message={deleteState.message as string}
            type={deleteState.status}
            flash={true}
          />
        )}
      </StickyWrapper>
      <section className="memorySpaceSection dFlex flexWrap alignItemsCenter justifyContentBetween withBackgroundColorDefault hasBorderRadius px15 py15 mb2">
        <div className={`memorySpaceStatus mr2 py1 text${cacheUsage.status}`}>
          <span>{cacheUsage.message}</span>
          <DynamicFormattedMessage
            id="network.offline.memory.space"
            tag="span"
          />
        </div>
        <div>
          <DynamicFormattedMessage
            id="network.offline.empty.cache"
            tag={Button}
            data-qa="network.offline.empty.cache"
            className={`btn btnOutline hasBoxShadow px1 py1 ${
              !isSupported || !synchroData?.length ? 'btnDisabled' : ''
            }`}
            onClick={() => {
              setIsModalOpen(true)
            }}
            disabled={!isSupported || !synchroData?.length}
          />
        </div>
      </section>
      <section className="synchroSection overflowAuto withBackgroundColorDefault hasBorderRadius px15 py15 mb2">
        <div className="pb1">
          <DynamicFormattedMessage
            id="network.offline.synchro.label"
            tag="h3"
            className="textPrimary fontRegular"
          />
        </div>
        <div className="synchroSectionData">
          <DynamicFormattedMessage
            id={`${
              isSupported
                ? 'network.offline.noData'
                : 'network.offline.noSupport'
            }`}
            className="textMd textPrimary textCenter fontRegular mb2"
            shouldRender={!synchroData?.length}
            tag="p"
          />
          {synchroData?.length !== 0 && (
            <OfflineSynchroTable data={synchroData || []} />
          )}
        </div>
      </section>
      {synchroFailedData.length !== 0 && (
        <section className="synchroFailedSection overflowAuto withBackgroundColorDefault hasBorderRadius px15 py15 mb2">
          <div className="dFlex justifyContentBetween pb1">
            <DynamicFormattedMessage
              id="network.offline.synchroFailed.label"
              tag="h3"
              className="textPrimary fontRegular"
            />
            <DynamicFormattedMessage
              id="network.offline.empty.failedSync"
              tag={Button}
              data-qa="network.offline.empty.failedSync"
              className={`btn btnOutline hasBoxShadow px1 py1 ${
                !isSupported ? 'btnDisabled' : ''
              }`}
              onClick={() => {
                deleteFailedSyncData()
                openIndexedDBFailedSyncData()
                getFailedSyncData()
              }}
              disabled={!isSupported}
            />
          </div>
          <div className="synchroFailedSectionData">
            <DynamicFormattedMessage
              id={`${
                isSupported
                  ? 'network.offline.noDataFailed'
                  : 'network.offline.noSupport'
              }`}
              className="textMd textPrimary textCenter fontRegular mb2"
              shouldRender={!synchroFailedData.length}
              tag="p"
            />
            <OfflineSynchroTable data={synchroFailedData} />
          </div>
        </section>
      )}
      <ModalContainer isOpen={isModalOpen}>
        <ConfirmationMessageModal
          onConfirm={() => {
            deleteSyncData()
            deleteSyncVisitReportData()
            deleteSyncVisitReportImagesData()
            deleteSyncNewContractData()
            deleteSyncEmailsData()
            setIsModalOpen(false)
            getSyncData()
          }}
          backButton={() => {
            setIsModalOpen(false)
            handleCancelDeleteModal()
          }}
          messages={{
            cancel: 'cancelDelete',
            confirm: 'confirmDelete',
            title: 'titleDeleteCache',
            content: 'contentDeleteCache',
          }}
        />
      </ModalContainer>
    </>
  )
}
