import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { IClientDetails } from '../interfaces/IClientDetails'
import {
  IAppointmentDefault,
  IAppointmentFormData,
  IAppointmentType,
  IClientOptions,
  IContactPerson,
} from '../interfaces/IAppointments'
import {
  DATE_REQUEST_FORMAT,
  ITEMS_SIZE,
  MAX_TIME_HOUR,
  MAX_TIME_MINUTES,
  MIN_TIME_HOUR,
} from '../enums/common'
import { getJwtUserDetails } from './api'
import { IMultipleSelectOption } from '../interfaces/IForms'
import { getEndDate } from './helpers'

export const prepareOptionsForSelect = (
  clients: IClientDetails[],
  clientDetails?: IClientOptions
) => {
  const optionsArray: IMultipleSelectOption[] = clients
    .filter(({ id }) => id !== clientDetails?.id)
    .map(({ name, cipCode, city, id }: IClientOptions) => {
      const cipCodeLabel = cipCode ? ` (${cipCode})` : ''
      const cityLabel = city ? ` - ${city}` : ''

      return {
        label: `${name}${cipCodeLabel}${cityLabel}`,
        value: id.toString(),
      }
    })

  // add selected client in clients list even if the api doesn't return it
  if (clientDetails) {
    const cipCodeLabel = clientDetails.cipCode
      ? ` (${clientDetails.cipCode})`
      : ''
    const cityLabel = clientDetails.city ? ` - ${clientDetails.city}` : ''

    optionsArray.push({
      label: `${clientDetails.name}${cipCodeLabel}${cityLabel}`,
      value: clientDetails.id.toString(),
    })
  }

  return optionsArray
}

export const prepareOptionsForContactPersons = (
  contactPersons: IContactPerson[] = []
) =>
  contactPersons
    .filter(({ firstName, lastName }: IContactPerson) => firstName || lastName)
    .map(({ firstName, lastName, id }: IContactPerson) => ({
      firstName,
      lastName,
      name: `${lastName || ''} ${firstName || ''}`,
      value: id.toString(),
    }))

export const prepareAppointmentRequest = (
  values: IAppointmentFormData,
  appointmentId: number
) => {
  const endDateValue = getEndDate(values.startDate, values.duration)
  const bodyFields = {
    startDate: format(values.startDate, DATE_REQUEST_FORMAT),
    endDate: format(endDateValue, DATE_REQUEST_FORMAT),
    clientId: parseInt(values.clientId, 10),
    appointmentTypeId: parseInt(values.appointmentTypeId, 10),
    contactPersonId: values.contactPersonId
      ? parseInt(values.contactPersonId, 10)
      : null,
    ...(!appointmentId && { userId: getJwtUserDetails().id }),
  }

  return { ...values, ...bodyFields }
}

export const prepareAppointmentTypesOptions = (types: IAppointmentType[]) =>
  types
    .filter(({ isVisible }) => isVisible)
    .map(({ id, eventType }: IAppointmentType) => ({
      label: eventType,
      value: id.toString(),
    }))

export const isGreaterThan = (value: Date, comparedValue = new Date()) => {
  return value.getTime() > comparedValue.getTime()
}

export const prepareAppointmentDetailsResponse = (
  appointment: IAppointmentDefault
) => ({
  ...appointment,
  startDate: utcToZonedTime(appointment.startDate as string, 'UTC'),
  endDate: utcToZonedTime(appointment.endDate as string, 'UTC'),
})

export const createIntervalQuery = () => {
  const paramDate = new Date()
  paramDate.setHours(0)
  paramDate.setMinutes(0)
  paramDate.setSeconds(0)
  paramDate.setMilliseconds(0)

  const startDate = format(paramDate, DATE_REQUEST_FORMAT)
  const endDate = format(
    paramDate.setDate(paramDate.getDate() + 1),
    DATE_REQUEST_FORMAT
  )

  return `?size=${ITEMS_SIZE}&offset=0&startDate=${startDate}&endDate=${endDate}`
}

export const getMinHour = (value: Date) => {
  const minHour = new Date(value)
  minHour.setHours(MIN_TIME_HOUR)
  minHour.setMinutes(0)
  minHour.setSeconds(0)
  return minHour
}

export const getMaxHour = (value: Date) => {
  const maxHour = new Date(value)
  maxHour.setHours(MAX_TIME_HOUR)
  maxHour.setMinutes(MAX_TIME_MINUTES)
  maxHour.setSeconds(0)
  return maxHour
}

export const isGreaterThanOpeningHours = (value: Date) =>
  value.getTime() >= getMinHour(value).getTime()

export const isSmallerThanClosingHours = (value: Date) =>
  value.getTime() <= getMaxHour(value).getTime()
