import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageTitleSection } from '../../components/common/ui/PageTitleSection'
import { NEW_APPOINTMENT } from '../../constants/routes'
import AppointmentForm from '../../components/forms/AppointmentForm'
import CustomModal from '../../components/modal/CustomModal'

const AppointmentPage: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { appointmentId } = useParams()

  return (
    <section className="pageContentWrapper appointmentPage pl75">
      <div className="px3 py3">
        <PageTitleSection pageName={NEW_APPOINTMENT} />
        <div className="appointmentFormWrapper withBackgroundColorDefault hasBorderRadius px2 py2">
          <AppointmentForm
            appointmentId={appointmentId}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </div>
      <CustomModal isModalOpen={isModalOpen} />
    </section>
  )
}

export default AppointmentPage
