import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEmailData } from '../../interfaces/IContracts'

const clientContractsSendEmailReducer = createSlice({
  name: 'clientContractsSendEmailReducer',
  initialState: {} as IEmailData,
  reducers: {
    setContractsSendEmailValues: (
      state,
      { payload }: PayloadAction<IEmailData>
    ) => payload,
  },
})

export const clientContractsSendEmailActions =
  clientContractsSendEmailReducer.actions

export default clientContractsSendEmailReducer.reducer
