import React, { FC, useEffect, useState } from 'react'
import { Range, getTrackBackground } from 'react-range'
import { RANGE_INPUT_MIN } from '../../enums/common'
import { IRangeStepInput } from '../../interfaces/IForms'
import { timeConvert } from '../../utils/helpers'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

const RangeStepInput: FC<IRangeStepInput> = ({
  customClass,
  defValue,
  hasLabel,
  maxValue,
  minValue,
  name,
  onChange,
  step,
  outputUnit = true,
}) => {
  let min = minValue
  if (minValue === maxValue) {
    min = 0
  }

  const [values, setValues] = useState([defValue])
  const [isVisible, setIsVisible] = useState(false)

  const outputValue = outputUnit ? timeConvert(values[0]) : values[0]

  useEffect(() => {
    if (values[0] < maxValue) {
      return setIsVisible(false)
    }

    if (values[0] > maxValue) {
      setValues([maxValue])
      setIsVisible((prevState) => !prevState)
    }
  }, [values, maxValue])

  return (
    <div className="inputGroup">
      {hasLabel && (
        <DynamicFormattedMessage
          tag="p"
          id={`form.field.${name}.label`}
          className="inputLabel"
        />
      )}
      <div
        className={`rangeInputWrapper dFlex justifyContentCenter flexWrap mt3 mb2 ${customClass}`}
        id="rangeInput"
      >
        <Range
          values={values}
          step={step}
          min={min}
          max={maxValue}
          onChange={(val) => {
            const value = min ? val : [RANGE_INPUT_MIN]
            setValues(value)
          }}
          onFinalChange={onChange}
          renderTrack={({ props, children }) => (
            <div
              className="dFlex wFull "
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
            >
              <div
                className="rangeInputTrack wFull hasBorderRadius alignSelfCenter py05"
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values,
                    colors: ['#0FD7C3', '#F2F2F2'],
                    min,
                    max: maxValue,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              className="rangeInputThumb dFlex justifyContentCenter alignItemsCenter withBackgroundColorDefault"
              {...props}
            >
              <output
                className="rangeInputOutput textPrimary relative"
                id="output"
              >
                {outputValue}
              </output>
            </div>
          )}
        />
        {isVisible && (
          <DynamicFormattedMessage
            tag="p"
            id="form.field.range.warning.label"
            className="textPrimary textDanger my1 px1"
            values={{ warning: outputValue }}
          />
        )}
      </div>
    </div>
  )
}

export default RangeStepInput
