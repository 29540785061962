import React, { Dispatch, FC, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import ContactPersonSection from '../common/ContactPersonSection'
import ClientAddressSection from '../common/ClientAddressSection'
import { IClientDetailsData } from '../../../interfaces/IClientDetails'
import ProductsTable from './products/ProductsTable'
import { IUsersOptions } from '../../../interfaces/IUsers'
import { IDestructionCertificateProduct } from '../../../interfaces/IContracts'

const DestructionCertificateFirstSection: FC<{
  clientDetails: IClientDetailsData
  contactPersons: IUsersOptions[]
  newContactPerson: IUsersOptions
  setNewContactPerson: Dispatch<SetStateAction<IUsersOptions>>
  products: IDestructionCertificateProduct[]
  setProducts: Dispatch<SetStateAction<IDestructionCertificateProduct[]>>
  formProductsCount: number
}> = ({
  clientDetails,
  contactPersons,
  newContactPerson,
  setNewContactPerson,
  products,
  setProducts,
  formProductsCount,
}) => {
  const { errors } = useFormContext()

  return (
    <div className="firstSection">
      <div className="row ml0">
        <DynamicFormattedMessage
          tag="h3"
          id="form.section.destructionCertificate.top.label"
          className="label pl0 pb2"
        />
      </div>
      <div className="row ml0">
        <div
          className={`colMd6 hasBorder pl0 ${
            errors.contactPerson ? 'mb2' : ''
          }`}
        >
          <ContactPersonSection
            contactPersons={contactPersons}
            newContactPerson={newContactPerson}
            setNewContactPerson={setNewContactPerson}
          />
        </div>
      </div>
      <div className="row ml0 address pb2 mb2">
        <div className="colMd6 pl0">
          <ClientAddressSection clientDetails={clientDetails} />
        </div>
      </div>
      <div className="row ml0 products overflowAuto">
        <div className="colMd12 pl0">
          <DynamicFormattedMessage
            tag="h3"
            id="form.destructionCertificate.products.table.label"
            className="pl0 pb2 textPrimary"
          />
          <DynamicFormattedMessage
            tag="p"
            id="form.destructionCertificate.products.table.info"
            className="pl0 pb2 textPrimary"
          />
          <ProductsTable
            products={products}
            setProducts={setProducts}
            formProductsCount={formProductsCount}
          />
        </div>
      </div>
    </div>
  )
}

export default DestructionCertificateFirstSection
