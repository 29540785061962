import React, { FC } from 'react'
import { ITooltipContent } from '../../../interfaces/ICommonComponents'
import DynamicFormattedMessage from '../ui/DynamicFormattedMessage'

export const TooltipContent: FC<ITooltipContent> = ({ route, data }) => {
  return (
    <ul>
      {data.map((el) => (
        <li
          key={el}
          className="row flexNowrap mx0 mb05 justifyContentStart alignItemsCenter textLeft"
        >
          <span className={`dot customColor${el}`} />
          <DynamicFormattedMessage
            tag="p"
            id={`tooltip.${route}.${el}.label`}
            className="ml15"
          />
        </li>
      ))}
    </ul>
  )
}
