import React, { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import DynamicButtons from '../../../form/DynamicButtons'
import { OUI, RADIO_OPTIONS_LIST } from '../../../../constants/form'
import {
  getCurrentMonth,
  getDynamicButtonDefaultValue,
  getMonthsOptionsForMultipleSelect,
} from '../../../../utils/helpers'
import {
  IVisitReportForm,
  IVisitReportPromoAndMonthsSection,
} from '../../../../interfaces/IVisitReports'
import { TDynamicButton } from '../../../../interfaces/ICommonComponents'
import { DYNAMIC_BUTTONS_INPUT_TYPE } from '../../../../enums/common'
import MultipleSelect from '../../../form/MultipleSelect'

export const VisitReportPromoAndMonthsSection: FC<IVisitReportPromoAndMonthsSection> = ({
  promo,
  months,
  defaultData,
  customLabel,
}) => {
  const intl = useIntl()
  const { errors, control, register, getValues, setValue } = useFormContext()
  const monthsOptions = getMonthsOptionsForMultipleSelect(intl)

  const [hasMonthsSelectChanged, setHasMonthsSelectChanged] = useState(false)

  const monthsSelectedValues = getValues(`${months}`)
  const promoCheckedValues = getValues(`${promo}`)

  const handlePromoChange = () => {
    if (promoCheckedValues?.length) return

    setHasMonthsSelectChanged(false)
  }

  const handleMonthsSelectChange = () => {
    setHasMonthsSelectChanged(true)
  }

  const promoCheckedYes = useMemo(
    () => (promoCheckedValues?.length ? promoCheckedValues[0] === OUI : false),
    [promoCheckedValues]
  )

  const shouldChangeMonthsOptionToCurrentMonth = useMemo(
    () =>
      // Month should change if no month was previously selected and promo field has yes option checked
      !monthsSelectedValues?.length &&
      !hasMonthsSelectChanged &&
      promoCheckedYes,
    [monthsSelectedValues, promoCheckedYes, hasMonthsSelectChanged]
  )

  useEffect(() => {
    // Handle month change to current month
    if (!shouldChangeMonthsOptionToCurrentMonth) return

    const currentMonthOption = monthsOptions.filter(
      (month) => +month.value === getCurrentMonth() + 1
    )

    setValue(`${months}`, currentMonthOption)
  }, [setValue, monthsOptions, months, shouldChangeMonthsOptionToCurrentMonth])

  return (
    <div className="row mb15 alignItemsCenter">
      {promo && (
        <div className="colMd6 mb15 alignItemsCenter">
          <DynamicFormattedMessage
            id={customLabel || 'form.section.promoNuk.label'}
            className="textPrimary fontBold dBlock"
          />
          <div className="row px1">
            <div className="px05 py05 mr05">
              <DynamicButtons
                optionsArray={RADIO_OPTIONS_LIST}
                register={register}
                name={promo}
                customClass="noLabel noWrap actionButtons"
                defaultValue={getDynamicButtonDefaultValue(
                  (defaultData as IVisitReportForm)[
                    `${promo}` as keyof IVisitReportForm
                  ] as TDynamicButton
                )}
                hideLabel={true}
                inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
                setValue={setValue}
                onChange={handlePromoChange}
              />
            </div>
          </div>
        </div>
      )}
      {months && (
        <div className="colMd6 mb15 alignItemsCenter">
          <DynamicFormattedMessage
            id="form.section.months.title.label"
            className="textPrimary fontBold dBlock"
          />
          <div className="row px15">
            <MultipleSelect
              id={months}
              options={monthsOptions}
              defaultValue={[]}
              hideMultiRemove={false}
              showLabel={false}
              className="wFull"
              parentClassName="wFull"
              control={control}
              error={errors[`${months}`]}
              onSelectChange={handleMonthsSelectChange}
            />
          </div>
        </div>
      )}
    </div>
  )
}
