import React, { FC } from 'react'
import { FieldError } from 'react-hook-form'
import { useSelector } from 'react-redux'
import MultipleSelect from '../../form/MultipleSelect'
import { IDashboardCaFiltersSection } from '../../../interfaces/IDashboard'
import Button from '../../common/ui/Button'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { useDashboardFiltersHook } from '../../../hooks/dashboard/UseDashboardFiltersHook'
import { statusNetworkSelector } from '../../../store/selectors'

const Filters: FC<IDashboardCaFiltersSection> = ({
  filters,
  setFilters,
  selectedUser,
  callbackOnSubmit,
  formState,
  setFormState,
  usersStatusPending,
  shouldUseLoggedUserId,
}) => {
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const {
    handleSubmit,
    errors,
    control,
    onSubmit,
    resetFilters,
    currentYear,
    showFilterWarning,
    groupements,
    type2,
    marqueeData,
    filtersLoadStatusPending,
    disabledActionButtons,
  } = useDashboardFiltersHook({
    filters,
    setFilters,
    selectedUser,
    callbackOnSubmit,
    formState,
    setFormState,
    usersStatusPending,
    shouldUseLoggedUserId,
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={resetFilters}>
      <div className="row">
        <div className="px1 mb15 colLg5">
          <MultipleSelect
            id="marque"
            control={control}
            error={errors.marque as FieldError | undefined}
            options={marqueeData.data}
            defaultValue={filters?.marque || []}
            disabled={!hasNetwork || filtersLoadStatusPending}
            className="marqueInput customSearchInput"
          />
        </div>
      </div>
      <div className="row">
        <div className="px1 mb15 colLg5">
          <MultipleSelect
            id="targetType2Ids"
            control={control}
            error={errors.targetType2Ids as FieldError | undefined}
            options={type2.data}
            defaultValue={filters?.targetType2Ids || []}
            labelValues={{ year: currentYear }}
            disabled={!hasNetwork || filtersLoadStatusPending}
            className="targetType2IdsInput customSearchInput"
          />
        </div>
      </div>
      <div className="row">
        <div className="px1 mb15 colLg5">
          <MultipleSelect
            id="groupIds"
            control={control}
            error={errors.groupIds as FieldError | undefined}
            options={groupements.data}
            defaultValue={filters?.groupIds || []}
            disabled={!hasNetwork || filtersLoadStatusPending}
            className="groupIdsInput customSearchInput"
          />
        </div>
      </div>
      <div className="row">
        <div className="colLg5 dFlex justifyContentBetween dashboardFiltersCtaRow px1">
          <Button
            type="reset"
            className={`btn btnLink mr3 pl0 ${
              disabledActionButtons ? 'btnLinkDisabled' : ''
            }`}
            data-qa="resetDashboardCtaFilters"
            disabled={disabledActionButtons}
          >
            <DynamicFormattedMessage id="form.button.resetFilters" />
          </Button>
          <Button
            type="submit"
            className={`btn btnPrimary textUppercase ${
              disabledActionButtons ? 'btnDisabled' : ''
            }`}
            data-qa="submitDashboardCtaFilters"
            disabled={disabledActionButtons}
          >
            <DynamicFormattedMessage id="form.button.submit" />
          </Button>
        </div>
      </div>
      {showFilterWarning && (
        <div className="row">
          <div className="colLg5 dashboardFiltersCtaRow mt2 pl1">
            <DynamicFormattedMessage
              className="textWarning"
              id="dashboard.ca.selectFilter"
              tag="p"
            />
          </div>
        </div>
      )}
    </form>
  )
}

export default Filters
