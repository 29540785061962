import React, { useEffect, useState } from 'react'
import { IMessageAlerts } from '../../interfaces/ICommonComponents'
import { capitaliseString, joinData } from '../../utils/helpers'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'

export const InfoMessageAlerts = ({
  type,
  message,
  asButton = false,
  data = [],
  intlAdditionalData = '',
  joinSeparator = ',',
  flash = false,
  close = false,
  closeAction = () => {},
}: IMessageAlerts) => {
  const [isVisible, setIsVisible] = useState(true)
  const alertType = capitaliseString(type)

  useEffect(() => {
    if (!flash) return setIsVisible(true)
    setTimeout(() => {
      setIsVisible(false)
      if (close && closeAction) closeAction()
    }, 3000)
  }, [flash, close, closeAction])

  return (
    <div
      className={`dNone textCenter fontRegular alert alert${alertType} text${alertType} ${
        isVisible ? 'dBlock' : 'dNone'
      } ${
        close && isVisible
          ? 'dFlex justifyContentBetween warningMessage mt05'
          : 'dNone'
      }`}
    >
      <div className="wFull">
        <DynamicFormattedMessage
          id={message}
          tag={asButton ? Button : 'p'}
          onClick={asButton ? closeAction : () => {}}
          values={{
            data: data ? joinData(data, joinSeparator) : '',
            intlAdditionalData,
          }}
          className={
            asButton
              ? `btn btnLink withBackgroundTransparent fontRegular text${alertType} px0 py0 mx0 my0`
              : ''
          }
        />
      </div>
      {close && (
        <DynamicFormattedMessage
          id="network.online.closeBt"
          tag={Button}
          onClick={closeAction || setIsVisible(false)}
          className={`btn btnClose btn${alertType} navbarToggleButton navbarToggleIsActive withBackgroundTransparent textInherit px0 py0 mx0 my0`}
          data-qa="info.message.closeBt"
        />
      )}
    </div>
  )
}
