import { useEffect, useState } from 'react'
import {
  IMarkerCoordinates,
  IUserLocationCoordinates,
} from '../../interfaces/IMap'

const RADIUS = 6371e3
const HALF_CIRCLE_DEGREE = 180
const METER_TO_KM = 1000

/**
 * Used to fetch the client coordinates.
 */
export const useClientsUserDistance = (
  clientCoordinates: IMarkerCoordinates,
  userLocationCoordinates: IUserLocationCoordinates
) => {
  const { gpsLatitude, gpsLongitude } = clientCoordinates
  const { latitude, longitude } = userLocationCoordinates
  const [clientUserDistance, setClientUserDistance] = useState(0)

  useEffect(() => {
    if (!latitude || !longitude || !gpsLatitude || !gpsLongitude) {
      return
    }
    const clientLatitude = gpsLatitude
    const clientLongitude = gpsLongitude

    const radius = RADIUS
    const fiClient = (clientLatitude * Math.PI) / HALF_CIRCLE_DEGREE
    const fiUser = (latitude * Math.PI) / HALF_CIRCLE_DEGREE
    const deltaFi = ((latitude - clientLatitude) * Math.PI) / 180
    const deltaLamda =
      ((longitude - clientLongitude) * Math.PI) / HALF_CIRCLE_DEGREE
    const a =
      Math.sin(deltaFi / 2) * Math.sin(deltaFi / 2) +
      Math.cos(fiClient) *
        Math.cos(fiUser) *
        Math.sin(deltaLamda / 2) *
        Math.sin(deltaLamda / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    setClientUserDistance(Math.round((radius * c) / METER_TO_KM))
  }, [latitude, longitude, gpsLatitude, gpsLongitude])

  return { clientUserDistance }
}
