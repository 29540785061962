import { useIntl } from 'react-intl'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { statusNetworkSelector } from '../../store/selectors'
import { useLoadData } from '../UseLoadData'
import {
  deleteAppointment,
  downloadAppointmentIcs,
  getAppointmentDetails,
  getAppointmentTypes,
} from '../../services/appointmentsService'
import {
  APPOINTMENT_ACTION,
  FILE_EXTENSION_ICS,
  STATUS,
} from '../../enums/common'
import { useDeleteHook } from '../UseDeleteHook'
import {
  handleFileDownload,
  isDateInTheFuture,
  joinData,
  matchOptionsValue,
} from '../../utils/helpers'
import { TApiResponse } from '../../interfaces/IClientDetails'
import { getJwtUserDetails, handleError } from '../../utils/api'
import { DATA_INITIAL_STATE } from '../../constants/api'
import { ROUTES } from '../../enums/routes'
import { IDownloadResponse } from '../../interfaces/ICommonComponents'

export const useViewAppointment = () => {
  const intl = useIntl()
  const { lastName, firstName } = getJwtUserDetails()
  const { appointmentId } = useParams()
  const history = useHistory()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const { state } = useLocation()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const appointmentResponse = useLoadData(() =>
    getAppointmentDetails(appointmentId, APPOINTMENT_ACTION.VIEW)
  )
  const { data } = appointmentResponse

  const appointmentTypes = useLoadData(() => getAppointmentTypes())

  const {
    isErrorModalOpen,
    closeErrorModal,
    requestDelete,
    deleteResponse,
  } = useDeleteHook(() => deleteAppointment(appointmentId), {
    onSuccess: () =>
      state && state.clientId
        ? state.eventBackUrl
          ? history.replace(state.eventBackUrl)
          : window.history.back()
        : window.location.replace(ROUTES.agenda),
  })

  const handleDeleteAppointmentButtonClick = () => setIsModalOpen(true)

  const onDeleteAppointmentConfirmation = () => {
    requestDelete()
    setIsModalOpen(false)
    if (!hasNetwork) {
      return state && state.clientId
        ? state.eventBackUrl
          ? history.replace(state.eventBackUrl)
          : window.history.back()
        : history.replace(ROUTES.agenda, {
            date: data?.startDate,
          })
    }
  }

  const onDeleteAppointmentCancel = () => setIsModalOpen(false)

  const clientAppointmentData = {
    ...appointmentResponse,
    deleteStatus: deleteResponse.status,
  }

  const isClientAppointmentDataPending = useMemo(
    () =>
      clientAppointmentData.status === STATUS.PENDING ||
      appointmentTypes.status === STATUS.PENDING,
    [clientAppointmentData.status, appointmentTypes.status]
  )

  const isAppointmentInTheFuture = useMemo(
    () => isDateInTheFuture(data?.startDate as string),
    [data]
  )

  const [sendEmailState, setSendEmailState] = useState<TApiResponse>(
    DATA_INITIAL_STATE
  )
  const [availableEmails, setAvailableEmails] = useState([''])

  const clientPageUrl = useMemo(
    () =>
      window?.location
        ? `${window?.location?.origin}${ROUTES.accountsPortfolio}/${clientAppointmentData?.data?.client?.id}`
        : '',
    [clientAppointmentData]
  )
  const sendEmailContentData = useMemo(() => {
    return {
      clientPageUrl,
    }
  }, [clientPageUrl])

  const [
    downloadAppointmentState,
    setDownloadAppointmentState,
  ] = useState<TApiResponse>(DATA_INITIAL_STATE)

  const getFileForDownload = async (response: IDownloadResponse) => {
    const fileURL = URL.createObjectURL(response.body)

    const clientName = data?.client?.name
    const clientCipCode = data?.client?.cipCode
      ? `(${data?.client?.cipCode})`
      : ''
    const appointmentType = matchOptionsValue<number>(
      appointmentTypes?.data,
      data.appointmentTypeId
    )?.label

    const fileName =
      joinData(
        [
          intl.formatMessage({
            id: 'fileName.word.1',
          }),
          appointmentType,
          intl.formatMessage({
            id: 'fileName.word.2',
          }),
          clientName,
          clientCipCode,
          intl.formatMessage({
            id: 'fileName.word.2',
          }),
          firstName,
          lastName,
        ],
        ' '
      ) + FILE_EXTENSION_ICS

    return {
      fileURL,
      fileName,
    }
  }

  const handleDownloadClick = () => {
    setDownloadAppointmentState({
      status: STATUS.PENDING,
      message: '',
    })

    downloadAppointmentIcs(appointmentId, clientPageUrl)
      .then((response) => {
        setDownloadAppointmentState({
          status: STATUS.SUCCESS,
          message: '',
        })

        getFileForDownload(response).then(({ fileURL, fileName }) =>
          handleFileDownload(fileURL, fileName)
        )
      })
      .catch((error) => {
        const handledError = handleError(error, intl)

        setDownloadAppointmentState({
          ...handledError,
        })
      })
  }

  const isDownloadStatePending = useMemo(
    () => downloadAppointmentState.status === STATUS.PENDING,
    [downloadAppointmentState.status]
  )

  return {
    appointmentId,
    appointmentTypes,
    isModalOpen,
    isErrorModalOpen,
    closeErrorModal,
    deleteResponse,
    handleDeleteAppointmentButtonClick,
    onDeleteAppointmentConfirmation,
    onDeleteAppointmentCancel,
    clientAppointmentData,
    isClientAppointmentDataPending,
    isAppointmentInTheFuture,
    sendEmailState,
    setSendEmailState,
    availableEmails,
    setAvailableEmails,
    sendEmailContentData,
    downloadAppointmentState,
    handleDownloadClick,
    isDownloadStatePending,
    hasNetwork,
  }
}
