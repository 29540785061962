import { useIntl } from 'react-intl'
import {
  DASHBOARD_CA_LAST_YEAR_COLOR,
  DASHBOARD_CA_CURRENT_YEAR_COLOR,
} from '../../../constants/dashboard'
import { IBarChartData } from '../../../interfaces/IDashboard'
import { currentYearValue } from '../../../utils/helpers'

export const useMonthlyBarChartHook = ({ dataset }: IBarChartData) => {
  const intl = useIntl()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
    interaction: {
      mode: 'index' as const,
    },
  }

  const labels = [
    intl.formatMessage({ id: 'months.Jan.label' }),
    intl.formatMessage({ id: 'months.Feb.label' }),
    intl.formatMessage({ id: 'months.March.label' }),
    intl.formatMessage({ id: 'months.April.label' }),
    intl.formatMessage({ id: 'months.May.label' }),
    intl.formatMessage({ id: 'months.Jun.label' }),
    intl.formatMessage({ id: 'months.Jul.label' }),
    intl.formatMessage({ id: 'months.Aug.label' }),
    intl.formatMessage({ id: 'months.Sept.label' }),
    intl.formatMessage({ id: 'months.Oct.label' }),
    intl.formatMessage({ id: 'months.Nov.label' }),
    intl.formatMessage({ id: 'months.Dec.label' }),
  ]

  const datasetLastYearLabel = intl.formatMessage(
    {
      id: 'dashboard.ca.lastYear.label',
    },
    { lastYear: currentYearValue - 1 }
  )
  const datasetCurrentYearLabel = intl.formatMessage(
    {
      id: 'dashboard.ca.currentYear.label',
    },
    { currentYear: currentYearValue }
  )

  const data = {
    labels,
    datasets: [
      {
        label: datasetLastYearLabel,
        data: dataset.lastYear,
        backgroundColor: DASHBOARD_CA_LAST_YEAR_COLOR,
      },
      {
        label: datasetCurrentYearLabel,
        data: dataset.currentYear,
        backgroundColor: DASHBOARD_CA_CURRENT_YEAR_COLOR,
      },
    ],
  }

  return {
    options,
    data,
  }
}
