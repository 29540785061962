import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit'
import loginReducer from './reducers/loginReducer'
import clientDetailsReducer from './reducers/clientDetailsReducer'
import clientsFiltersReducer from './reducers/filtersReducer'
import clientListReducer from './reducers/clientListReducer'
import clientContactPersonsReducer from './reducers/clientContactPersonsReducer'
import clientContractsReducer from './reducers/clientContractsReducer'
import clientContractsSendEmailReducer from './reducers/clientContractsSendEmailReducer'
import clientLastVisitReportReducer from './reducers/clientLastVisitReportReducer'
import visitReportReducer from './reducers/visitReportReducer'
import newVisitReportReducer from './reducers/newVisitReportReducer'
import sharedDataReducer from './reducers/sharedDataReducer'
import statusNetworkReducer from './reducers/statusNetworkReducer'
import clientHistoryReducer from './reducers/clientHistoryReducer'
import allClientsReducer from './reducers/allClientsReducer'
import clientAppointmentsReducer from './reducers/clientAppointmentsReducer'
import dashboardCaReducer from './reducers/dashboardCaReducer'
import dashboardVisitsCoverageReducer from './reducers/dashboardVisitsCoverageReducer'

const store = configureStore({
  reducer: combineReducers({
    loginReducer,
    allClientsReducer,
    clientDetailsReducer,
    clientsFiltersReducer,
    clientListReducer,
    clientContactPersonsReducer,
    clientContractsReducer,
    clientContractsSendEmailReducer,
    clientLastVisitReportReducer,
    visitReportReducer,
    newVisitReportReducer,
    sharedDataReducer,
    statusNetworkReducer,
    clientHistoryReducer,
    clientAppointmentsReducer,
    dashboardCaReducer,
    dashboardVisitsCoverageReducer,
  }),
})

export default store

export type IStore = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, IStore, null, Action<string>>
