import L from 'leaflet'
import { mapMarker } from '../../assets/images/images'

const pharmacyLocationIcon = new L.Icon({
  iconUrl: mapMarker,
  iconRetinaUrl: mapMarker,
  popupAnchor: [0, -10],
  iconSize: [40, 94],
  iconAnchor: [20, 47],
  className: 'leafletIconPharmacy',
})

export { pharmacyLocationIcon }
