import { createSlice } from '@reduxjs/toolkit'
import { checkAuth } from '../../services/authenticationServices'

const loginReducer = createSlice({
  name: 'LoginReducers',
  initialState: checkAuth(),
  reducers: {
    authenticate: () => true,
    signOut: () => false,
  },
})

export const loginActions = loginReducer.actions

export default loginReducer.reducer
