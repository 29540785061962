import React, { FC } from 'react'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { ChartSection } from './ChartSection'
import { IChartGlobalArea } from '../../../interfaces/IDashboard'

export const ChartGlobalArea: FC<IChartGlobalArea> = ({
  chartDataResponse,
  selectedUserName,
}) => {
  return (
    <section className="chartSection withBackgroundColorDefault hasBorderRadius py15 px15 textSecondary mb2">
      <DynamicFormattedMessage
        id="dashboard.visitsCoverage.global.section"
        tag="h2"
        className="dInlineBlock textCustomSm textUppercase mb15 pb05 borderBottom02SecondaryLight"
      />
      <ChartSection
        chartDataResponse={chartDataResponse}
        selectedUserName={selectedUserName}
        customClass="col12 colMd6 colLg4 px0"
      />
    </section>
  )
}
