import { IStore } from '../index'

export const clientsFiltersData = (state: IStore) => state.clientsFiltersReducer

export const getClientsFiltersValue = ({ clientsFiltersReducer }: IStore) => {
  const startDate = JSON.parse(
    clientsFiltersReducer.filters.lastVisitStart || '""'
  )
  const endDate = JSON.parse(clientsFiltersReducer.filters.lastVisitEnd || '""')

  return {
    ...clientsFiltersReducer.filters,
    lastVisitStart: startDate ? (new Date(startDate) as any) : '',
    lastVisitEnd: endDate ? (new Date(endDate) as any) : '',
  }
}
