import React, { FC } from 'react'
import { IClientEventData } from '../../interfaces/IClientDetails'
import { ClientEventCard } from '../clientAppointmentCard/ClientEventCard'
import { isEventCached } from '../../utils/offline'

const ClientEventsList: FC<{
  data: IClientEventData[]
  total: number
  clientId: string
  path: string
  cachedData?: string[] | undefined
  customClass?: string
  itemsToBeDisplayed?: number
}> = ({
  data,
  total,
  clientId,
  path,
  cachedData,
  customClass,
  itemsToBeDisplayed,
}) => {
  return (
    <>
      {total !== 0 &&
        data
          .slice(0, itemsToBeDisplayed || data.length)
          .map((eventItem: IClientEventData) => {
            return (
              <ClientEventCard
                key={eventItem.id}
                path={path}
                data={eventItem}
                clientId={clientId}
                customClass={customClass ?? ''}
                disabled={!isEventCached(cachedData, eventItem.id)}
              />
            )
          })}
    </>
  )
}

export default ClientEventsList
