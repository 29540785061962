import React, { FC } from 'react'
import Button from '../../common/ui/Button'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { IFiltersActionButtons } from '../../../interfaces/IDashboard'

export const FiltersActionButtons: FC<IFiltersActionButtons> = ({
  disabled,
}) => {
  return (
    <div className="filtersCtaWrapper col12 dFlex justifyContentBetween mt2">
      <Button
        type="reset"
        className={`btn btnLink mr2 pl0 ${disabled ? 'btnLinkDisabled' : ''}`}
        data-qa="resetDashboardCtaFilters"
        disabled={disabled}
      >
        <DynamicFormattedMessage id="form.button.resetFilters" />
      </Button>
      <Button
        type="submit"
        className={`btn btnPrimary textUppercase ${
          disabled ? 'btnDisabled' : ''
        }`}
        data-qa="submitDashboardCtaFilters"
        disabled={disabled}
      >
        <DynamicFormattedMessage id="form.button.submit" />
      </Button>
    </div>
  )
}
