import React, { FC } from 'react'
import { TCustomTableProps } from '../../../../interfaces/ITables'
import DynamicFormattedMessage from '../DynamicFormattedMessage'

export const CustomTable: FC<TCustomTableProps> = ({
  tableHeaders,
  data,
  customClass = '',
}) => {
  return (
    <table className={`customTable wFull ${customClass}`}>
      <thead className="customTableHeader">
        <tr>
          {tableHeaders.map((headerName) => (
            <td key={headerName}>
              <DynamicFormattedMessage
                id={`table.header.${headerName}.label`}
                className="fontBold textUppercase textPrimary textCustomXs textLeft"
              />
            </td>
          ))}
        </tr>
      </thead>
      <tbody className="customTableBody">
        {data?.length !== 0 &&
          (data as any[]).map((dataItem, index) => (
            <tr key={index.toString()}>
              {Object.keys(dataItem).map((item) => (
                <td
                  key={item}
                  className="textUppercase textPrimary textCustomXs"
                >
                  {dataItem[item] || '-'}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  )
}
