import React, { FC } from 'react'
import { ITooltipTitle } from '../../../interfaces/ICommonComponents'
import Button from '../ui/Button'
import DynamicFormattedMessage from '../ui/DynamicFormattedMessage'

export const TooltipTitle: FC<ITooltipTitle> = ({ setActive, title }) => {
  const toggleTooltip = () => {
    setActive((prevState) => !prevState)
  }

  return (
    <div className="row flexNowrap alignItemsCenter mr15">
      {title && (
        <DynamicFormattedMessage
          tag="label"
          id={title}
          className="inputLabel mb0"
        />
      )}
      <DynamicFormattedMessage
        id="tooltip.button.label"
        data-qa="tooltip.button.label"
        tag={Button}
        onClick={toggleTooltip}
        className="btn btnLink btnLinkUnstyled px05 ml05"
      />
    </div>
  )
}
