import React, { FC } from 'react'
import { ReactComponent as IconCopyToClipboard } from '../../assets/images/iconCopyToClipboard.svg'
import Button from '../common/ui/Button'

const ClientCipCode: FC<{
  cipCode?: string
  hasParenthesis?: boolean[]
  isVisible?: boolean
  className?: string
}> = ({
  cipCode = '',
  hasParenthesis = [true, true],
  isVisible = true,
  className = '',
}) => (
  <>
    {hasParenthesis[0] && <span>(</span>}
    {isVisible && cipCode}
    <Button
      className={`btn withBackgroundTransparent textInherit px0 py0 ${className}`}
      onClick={(event) => {
        event.preventDefault()
        navigator.clipboard.writeText(cipCode || '')
      }}
    >
      <IconCopyToClipboard
        title="CopyToClipboard"
        stroke="currentColor"
        fill="currentColor"
      />
    </Button>
    {hasParenthesis[1] && <span className={className}>)</span>}
  </>
)

export default ClientCipCode
