import { IActivity, IUser } from '../interfaces/IUsers'
import { AuthToken } from '../interfaces/IRequest'

export const processUserActivityData = (activity: IActivity[] = []) => {
  const visit: number[] = []
  const appointment: number[] = []

  if (!(activity && activity.length)) {
    return []
  }

  activity.forEach(({ visits, appointments }: IActivity) => {
    visit.push(visits)
    appointment.push(appointments)
  })

  return [visit, appointment]
}

export const isUserAvailable = (users: IUser[], userId: string) =>
  users?.some((user) => user?.id?.toString() === userId)

export const getJwtUsername = (jwt: AuthToken) =>
  jwt.username || `${jwt.lastName} ${jwt.firstName}`

const getMatchedUser = (users: IUser[], userId: string) =>
  users?.find((user) => user?.id?.toString() === userId)

export const getLoggedUsername = (users: IUser[], userId: string) => {
  const matchedUser = getMatchedUser(users, userId)

  return (
    matchedUser?.username ||
    `${matchedUser?.lastName} ${matchedUser?.firstName}`
  )
}
