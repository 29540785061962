import React, { ReactElement } from 'react'
import Modal from 'react-modal'
import { IModalContainerProp } from '../../interfaces/IModal'

function ModalContainer(props: IModalContainerProp): ReactElement {
  const { isOpen, children, portalClassName } = props

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      portalClassName={portalClassName}
    >
      <div role="dialog">{children}</div>
    </Modal>
  )
}

export default ModalContainer
