import React from 'react'
import Pagination from 'react-js-pagination'
import { INITIAL_ACTIVE_PAGE, RANGE_DISPLAYED } from '../enums/common'
import { IPaginationProps } from '../interfaces/INavigation'

export function PaginationWrapper({
  totalItemsCount,
  currentPage,
  onChange,
  itemsPerPage,
}: IPaginationProps) {
  return (
    <Pagination
      hideFirstLastPages
      prevPageText="<"
      nextPageText=">"
      activePage={currentPage || INITIAL_ACTIVE_PAGE}
      itemsCountPerPage={itemsPerPage}
      totalItemsCount={totalItemsCount}
      pageRangeDisplayed={RANGE_DISPLAYED}
      onChange={onChange}
    />
  )
}
