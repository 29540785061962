import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IEngagementHistory,
  IOrderHistory,
  IRecapHistory,
} from '../../interfaces/IHistory'
import { VIEW_TYPE } from '../../constants/tables'

interface IClientHistoryReducer {
  [id: string]: {
    [VIEW_TYPE.TOTAL]: IRecapHistory[]
    [VIEW_TYPE.SEMESTER]: IRecapHistory[]
    [VIEW_TYPE.QUARTERLY]: IRecapHistory[]
    [VIEW_TYPE.TRIMESTER]: IRecapHistory[]
    ordersHistory: IOrderHistory[]
    engagementsHistory: IEngagementHistory[]
  }
}

const clientHistoryReducer = createSlice({
  name: 'clientHistoryReducer',
  initialState: {} as IClientHistoryReducer,
  reducers: {
    setClientRecapViewTotal: (
      state,
      { payload }: PayloadAction<IClientHistoryReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          [VIEW_TYPE.TOTAL]: payload[clientId]
            ? payload[clientId][VIEW_TYPE.TOTAL]
            : [],
        },
      }
    },
    setClientRecapViewSemester: (
      state,
      { payload }: PayloadAction<IClientHistoryReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          [VIEW_TYPE.SEMESTER]: payload[clientId]
            ? payload[clientId][VIEW_TYPE.SEMESTER]
            : [],
        },
      }
    },
    setClientRecapViewQuarterly: (
      state,
      { payload }: PayloadAction<IClientHistoryReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          [VIEW_TYPE.QUARTERLY]: payload[clientId]
            ? payload[clientId][VIEW_TYPE.QUARTERLY]
            : [],
        },
      }
    },
    setClientRecapViewTrimester: (
      state,
      { payload }: PayloadAction<IClientHistoryReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          [VIEW_TYPE.TRIMESTER]: payload[clientId]
            ? payload[clientId][VIEW_TYPE.TRIMESTER]
            : [],
        },
      }
    },
    setClientOrdersHistory: (
      state,
      { payload }: PayloadAction<IClientHistoryReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          ordersHistory: payload[clientId]
            ? payload[clientId].ordersHistory
            : [],
        },
      }
    },
    setClientEngagementsHistory: (
      state,
      { payload }: PayloadAction<IClientHistoryReducer>
    ) => {
      const clientId = Object.keys(payload)[0]
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          engagementsHistory: payload[clientId]
            ? payload[clientId].engagementsHistory
            : [],
        },
      }
    },
  },
})

export const clientHistoryActions = clientHistoryReducer.actions

export default clientHistoryReducer.reducer
