import React, { createRef, FC, useEffect, useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import {
  IClientListMapProps,
  IUserLocationCoordinates,
} from '../../interfaces/IMap'
import { useClientListMap } from '../../hooks/clientDetails/UseClientListMap'
import { iconUser } from './UserLocationIcon'
import { DynamicMapMarker } from './DynamicMapMarker'
import { ROUTES } from '../../enums/routes'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import { MAP, PHONE } from '../../enums/common'
import LinkTo from '../common/ui/LinkTo'

export const ClientListMap: FC<IClientListMapProps> = ({
  latitude,
  longitude,
  clients,
  hasSearchData,
  backRoute,
  offset,
  currentPage,
}) => {
  const hasLocationAccess = !!latitude || !!longitude
  const userCoordinates: IUserLocationCoordinates = { longitude, latitude }
  const { mapCenter } = useClientListMap(clients, userCoordinates)
  const { gpsLatitude, gpsLongitude } = mapCenter
  const groupRef = createRef()
  const [map, setMap] = useState<any>()

  useEffect(() => {
    if (map && groupRef) {
      if (hasSearchData) {
        const group = groupRef?.current
        // @ts-ignore
        map.fitBounds(group.getBounds())
      } else if (latitude && longitude) {
        map.fitBounds([[latitude, longitude]])
      }
    }
    // eslint-disable-next-line
  }, [map, groupRef])

  return (
    <div
      className="clientDetailsPageMapWrapper relative"
      key={`${gpsLatitude}${gpsLongitude}`}
    >
      <MapContainer
        center={[gpsLatitude, gpsLongitude]}
        zoom={hasLocationAccess ? 13 : 11}
        scrollWheelZoom={true}
        whenCreated={(m) => setMap(m)}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <MarkerClusterGroup ref={groupRef}>
          {clients &&
            clients.length > 0 &&
            clients.map((client) => {
              const addressBlock = `${client.address1}${
                client.zipCode ? `, ${client.zipCode}` : ''
              }${client.city ? `, ${client.city}` : ''}`
              const hasCoordinates = client.gpsLatitude && client.gpsLongitude

              if (!hasCoordinates) return false

              return (
                <Marker
                  key={client.id}
                  position={[client.gpsLatitude, client.gpsLongitude]}
                  icon={DynamicMapMarker(client)}
                >
                  <Popup>
                    <Link
                      to={{
                        pathname: generatePath(ROUTES.clientDetails, {
                          clientId: client?.id,
                        }),
                        state: { offset, currentPage, backRoute },
                      }}
                      className="clientDetailsPage linkUnstyled textCustomSm wordBreak"
                      data-qa="clientDetailsPage"
                    >
                      <span className="pr05">{client.name}</span>
                      {client.cipCode && (
                        <ClientCipCode
                          className="customParenthesisMap"
                          isVisible={true}
                          cipCode={client?.cipCode}
                          hasParenthesis={[true, true]}
                        />
                      )}
                    </Link>
                    <div className="dInlineFlex flexColumn pt15 wFull">
                      <LinkTo
                        type={MAP}
                        hrefContent={
                          hasCoordinates
                            ? `${client.gpsLatitude},${client.gpsLongitude}`
                            : addressBlock
                        }
                      >
                        <span className="linkBasic textCustomXs">
                          {client.address1}
                          {client.zipCode && `, ${client.zipCode}`}
                        </span>
                      </LinkTo>
                      <LinkTo
                        type={PHONE}
                        hrefContent={client.phoneNumber}
                        customClass="mb05"
                      >
                        <span className="linkBasic textCustomXs">
                          {client.phoneNumber}
                        </span>
                      </LinkTo>
                    </div>
                  </Popup>
                </Marker>
              )
            })}
        </MarkerClusterGroup>

        {latitude && longitude && (
          <Marker position={[latitude, longitude]} icon={iconUser} />
        )}
      </MapContainer>
    </div>
  )
}
