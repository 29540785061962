import React, { FC } from 'react'
import { IClientFileItemData } from '../../interfaces/IClientDetails'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import LinkTo from '../common/ui/LinkTo'
import { EMAIL, MAP, PHONE } from '../../enums/common'
import { NON, OUI } from '../../constants/form'

const ClientFileItemData: FC<IClientFileItemData> = ({
  clientDetailsData,
  item,
}) => {
  if (item === 'phoneNumber')
    return (
      <LinkTo type={PHONE} hrefContent={clientDetailsData[item]}>
        {clientDetailsData[item]}
      </LinkTo>
    )

  if (item === 'email')
    return (
      <LinkTo type={EMAIL} hrefContent={clientDetailsData[item]}>
        {clientDetailsData[item]}
      </LinkTo>
    )

  if (item === 'address1') {
    const hasCoordinates =
      clientDetailsData.gpsLatitude && clientDetailsData.gpsLongitude
    const clientLocation = `${clientDetailsData.address1}${
      clientDetailsData.zipCode ? `, ${clientDetailsData.zipCode}` : ''
    }${clientDetailsData.city ? `, ${clientDetailsData.city}` : ''}`

    return (
      <LinkTo
        type={MAP}
        hrefContent={
          hasCoordinates
            ? `${clientDetailsData.gpsLatitude},${clientDetailsData.gpsLongitude}`
            : clientLocation
        }
      >
        {clientDetailsData[item]}
      </LinkTo>
    )
  }

  if (item === 'cipCode')
    return (
      <ClientCipCode
        hasParenthesis={[false, false]}
        cipCode={clientDetailsData[item]}
      />
    )

  const displayedData =
    typeof clientDetailsData[item] !== 'boolean'
      ? clientDetailsData[item]
      : clientDetailsData[item]
      ? OUI
      : NON
  return <p>{displayedData || '-'}</p>
}

export default ClientFileItemData
