const blobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const compressImage = (
  imageBase64: string,
  resizeFactor: number,
  quality: number
) =>
  new Promise((resolve, reject) => {
    try {
      const originalImage = new Image()
      // we wait for initial image to load to have width/height for canvas
      // otherwise image will not be compressed
      originalImage.onload = (onLoadResult) => {
        try {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          const image = onLoadResult.target as HTMLImageElement
          const originalWidth = image.width
          const originalHeight = image.height

          const initialImageSize = image.src.length / 1024

          const canvasWidth = originalWidth * resizeFactor
          const canvasHeight = originalHeight * resizeFactor

          canvas.width = canvasWidth
          canvas.height = canvasHeight

          context!.drawImage(image, 0, 0, canvasWidth, canvasHeight)

          // reducing the quality of the image
          canvas.toBlob(
            async (blob) => {
              if (blob) {
                if (initialImageSize < blob.size / 1024) resolve(imageBase64)
                const compressedImage = await blobToBase64(blob)
                resolve(compressedImage)
              } else {
                // image could not be compressed
                resolve(imageBase64)
              }
            },
            'image/jpeg',
            quality
          )
        } catch (exception) {
          reject(exception)
        }
      }
      originalImage.src = JSON.parse(imageBase64).dataURL
    } catch (exception) {
      reject(exception)
    }
  })
