import { Dispatch, SetStateAction } from 'react'
import { API_VERSION, BASE_BACKEND_URL, OBJECT_STORE } from '../enums/common'

const HALF_CACHE = 50
const TWO_THIRD_CACHE = 70
const FULL_CACHE = 100

export const isLowOnCache = (usedCache: number) => usedCache < HALF_CACHE

export const isMidOnCache = (usedCache: number) =>
  usedCache >= HALF_CACHE && usedCache < TWO_THIRD_CACHE

export const isHighOnCache = (usedCache: number) =>
  usedCache >= TWO_THIRD_CACHE && usedCache < FULL_CACHE

export const createIndexedDb = (name: string, key: boolean = false) => {
  const req = indexedDB.open(name)

  req.onupgradeneeded = () => {
    const db = req.result
    try {
      if (!db.objectStoreNames.contains(OBJECT_STORE)) {
        db.createObjectStore(OBJECT_STORE, {
          autoIncrement: !key,
          keyPath: key ? 'timestamp' : null,
        })
      }
    } catch (e) {
      // eslint-disable-next-line
    } finally {
      db.close()
    }
  }

  return req
}

export const addDataInIndexedDb = (
  name: string,
  data: any,
  onSuccess = () => {},
  key?: number | string
) => {
  const req = createIndexedDb(name)
  req.onsuccess = () => {
    const db = req.result
    try {
      const tx = db.transaction(OBJECT_STORE, 'readwrite')
      const objectStore = tx.objectStore(OBJECT_STORE)

      const editReq = objectStore.put(data, key)
      editReq.onsuccess = onSuccess
    } finally {
      db.close()
    }
  }
}

export const clearDataFromIndexedDb = (name: string) => {
  const req = createIndexedDb(name)
  req.onsuccess = () => {
    const db = req.result
    try {
      const objectStore = db
        .transaction(OBJECT_STORE, 'readwrite')
        .objectStore(OBJECT_STORE)

      objectStore.clear()
    } finally {
      db.close()
    }
  }
}

export const deleteDataFromIndexedDb = (name: string, id: number | string) => {
  const req = createIndexedDb(name)
  req.onsuccess = () => {
    const db = req.result
    try {
      const objectStore = db
        .transaction(OBJECT_STORE, 'readwrite')
        .objectStore(OBJECT_STORE)

      objectStore.delete(id)
    } finally {
      db.close()
    }
  }
}

export const openIndexedDb = (
  dbName: string,
  successCallback: (result: any, objectStore: IDBObjectStore) => any
) => {
  const req = createIndexedDb(dbName)
  req.onsuccess = () => {
    const db = req.result
    try {
      const objectStore = db
        .transaction(OBJECT_STORE, 'readwrite')
        .objectStore(OBJECT_STORE)
      const data = objectStore.getAll()

      data.onsuccess = () => successCallback(data?.result, objectStore)
    } finally {
      db.close()
    }
  }
}

export const asyncForEach = async (array: any, callback: any) => {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array)
  }
}

// Gets all data from a specific cache
export const getCachedData = async (
  cacheName: string,
  setCallback: Dispatch<SetStateAction<any>>
) => {
  const cacheStorage = await caches.open(cacheName)
  const cachedResponse = await cacheStorage.matchAll()
  setCallback(cachedResponse as readonly Response[])
}

// Returns an array with all matched data from cache for specific url
export const getCachedDataIds = (
  cachedDt: readonly Response[],
  type: string
): string[] =>
  cachedDt.reduce((acc: string[], data: Response) => {
    const isMatchedData = data?.url.match(
      `${BASE_BACKEND_URL}${API_VERSION}${type}`
    )

    if (isMatchedData) {
      const matchedData = data?.url
        ?.toString()
        .split(type)[1]
        .replace(/[^0-9]/g, '')

      acc.push(matchedData as string)
    }

    return acc
  }, [] as string[])

// Returns if event is cached or not
export const isEventCached = (
  cachedEvents: string[] | undefined,
  id: number | string
) => {
  if (!cachedEvents?.length) return

  return cachedEvents?.some((el) => +el === +id)
}
