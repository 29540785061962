import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { iconLocation, iconPhone } from '../../assets/images/images'
import { IClient } from '../../interfaces/IClientDetails'
import { ROUTES } from '../../enums/routes'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import LinkTo from '../common/ui/LinkTo'
import { MAP, PHONE } from '../../enums/common'

const ClientDetailsCard = ({
  client,
  offset,
  currentPage,
  backRoute,
}: IClient) => {
  const {
    id,
    name,
    cipCode,
    lastVisitReport,
    zipCode,
    address1,
    phoneNumber,
    city,
    gpsLatitude,
    gpsLongitude,
  } = client
  const addressBlock = `${address1}${zipCode ? `, ${zipCode}` : ''}${
    city ? `, ${city}` : ''
  }`
  const hasCoordinates = gpsLatitude && gpsLongitude

  return (
    <li className="clientDetailsItem px1 py1">
      <div className="clientDetailsItemHeader mb1">
        <div className="colMd8 pl0 clientDetailsTitle">
          <Link
            to={{
              pathname: generatePath(ROUTES.clientDetails, {
                clientId: id,
              }),
              state: { offset, currentPage, backRoute },
            }}
            className="clientDetailsName linkUnstyled"
          >
            <span className="clientDetailsNameLabel pr05">{name}</span>
            {cipCode && (
              <ClientCipCode
                className="customParenthesisClient"
                isVisible={true}
                cipCode={cipCode}
                hasParenthesis={[true, true]}
              />
            )}
          </Link>
        </div>
        <DynamicFormattedMessage
          id="cta.newAppointment.label"
          className="btn btnPrimary clientDetailsAppointmentCta textCenter linkUnstyled alignSelfStart"
          data-qa="clientDetails"
          tag={Link}
          to={{
            pathname: ROUTES.newAppointmentAgenda,
            state: {
              client,
              offset,
              prevLocation: backRoute ?? window.location.pathname,
              currentPage,
            },
          }}
        />
      </div>
      <div className="clientDetailsItemContent">
        <p className="mb1">
          <LinkTo
            type={MAP}
            hrefContent={
              hasCoordinates ? `${gpsLatitude},${gpsLongitude}` : addressBlock
            }
          >
            <img src={iconLocation} alt="location icon" className="mr1" />
            <span>{addressBlock}</span>
          </LinkTo>
        </p>
        <p className="mb1">
          <LinkTo type={PHONE} hrefContent={phoneNumber}>
            <img src={iconPhone} alt="phone icon" className="mr1" />
            <span>{phoneNumber}</span>
          </LinkTo>
        </p>
      </div>
      {lastVisitReport?.id && (
        <div className="clientDetailsItemFooter">
          <DynamicFormattedMessage
            id="account.card.lastVisit.label"
            values={{
              lastVisitReportDaysCount: client?.lastVisitReport?.days,
            }}
            className="clientDetailsItemFooterLabel"
          />
          <DynamicFormattedMessage
            id="cta.visitReport.label"
            tag={Link}
            to={generatePath(ROUTES.viewVisitReport, {
              reportId: lastVisitReport.id,
              clientId: id,
            })}
            className="clientDetailsItemFooterCta fontMedium textRight pr05"
            data-qa="visitReport"
          />
        </div>
      )}
    </li>
  )
}

export default ClientDetailsCard
