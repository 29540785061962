import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { MONTHS_PERIOD } from '../../constants/tables'
import { MonthlyTableContent } from '../common/ui/tables/MonthlyTableContent'
import { Table } from '../common/ui/tables/Table'
import { IActivityData } from '../../interfaces/IUsers'
import Alerts from '../alerts/Alerts'
import Loader from '../loader/Loader'
import { STATUS } from '../../enums/common'

export const ActivityReports: FC<IActivityData> = ({
  data,
  status,
  message,
}) => {
  return (
    <section className="appointmentsReports overflowAuto withBackgroundColorDefault hasBorderRadius px15 py15">
      <Alerts status={status} message={message} />
      <div className="pb1">
        <DynamicFormattedMessage
          id="dashboard.appointmentsReports.label"
          tag="h3"
          className="textPrimary fontRegular"
        />
      </div>
      {status && status === STATUS.PENDING && <Loader />}
      {data?.activity && (
        <div className="appointmentsReportsData">
          <Table tableHeaders={MONTHS_PERIOD}>
            <MonthlyTableContent {...data} />
          </Table>
        </div>
      )}
    </section>
  )
}
