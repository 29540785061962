import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IUsersOptions } from '../../interfaces/IUsers'
import { statusNetworkSelector } from '../../store/selectors'
import { UsersSelect } from '../users/UsersSelect'
import { useUsersDashboardHook } from '../../hooks/dashboard/UseUsersDashboardHook'
import { useDashboardCaHook } from '../../hooks/dashboard/UseDashboardCaHook'
import Filters from './dashboardCa/Filters'
import ChartSection from './dashboardCa/ChartSection'
import { dashboardCaActions } from '../../store/reducers/dashboardCaReducer'
import { STATUS } from '../../enums/common'
import Loader from '../loader/Loader'
import { useSelectedUserCheck } from '../../hooks/dashboard/UseSelectedUserCheck'
import { UserUnavailableMessage } from './common/UserUnavailableMessage'

export const DashboardCaContent: FC = () => {
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const {
    users,
    userId,
    statusPending,
    onUserChange,
    selectedUserName,
    isUsersLoadingError,
    isUserNotAvailableError,
  } = useUsersDashboardHook()
  const {
    filters,
    setFilters,
    showChartSection,
    chartTitle,
    currentChartUser,
    chartData,
    formState,
    setFormState,
  } = useDashboardCaHook()

  const {
    isUserDifferentFromSearchParam,
    shouldUseLoggedUserId,
  } = useSelectedUserCheck(isUserNotAvailableError, isUsersLoadingError)

  const updateSelectedUser = useCallback(() => {
    dispatch(
      dashboardCaActions.setCurrentChartUser({
        id: userId,
        name: selectedUserName,
      })
    )
  }, [userId, selectedUserName, dispatch])

  useEffect(() => {
    // set display name for chart when user is admin and preselected in the list
    if (currentChartUser && !currentChartUser.name && selectedUserName) {
      updateSelectedUser()
    }
  }, [selectedUserName, currentChartUser, updateSelectedUser])

  return (
    <div className="dashboardCaContent">
      <UsersSelect
        name="userFilter"
        users={users?.data as IUsersOptions[]}
        onUserChange={onUserChange}
        defaultUser={userId}
        disabled={!hasNetwork || statusPending}
        wrapperClass="userFilter colLg5 mb15 pl0 pr0 pr1lg"
        isUsersLoadingError={isUsersLoadingError}
        isUserNotAvailableError={isUserNotAvailableError}
      />
      <UserUnavailableMessage shouldRender={isUserDifferentFromSearchParam} />
      <div className="filtersSection withBackgroundColorDefault hasBorderRadius px15 pt15 pb1">
        <Filters
          filters={filters}
          setFilters={setFilters}
          selectedUser={{ id: userId, name: selectedUserName }}
          callbackOnSubmit={updateSelectedUser}
          formState={formState}
          setFormState={setFormState}
          usersStatusPending={statusPending}
          shouldUseLoggedUserId={shouldUseLoggedUserId}
        />
      </div>
      {formState.status === STATUS.PENDING && <Loader />}
      {showChartSection && (
        <ChartSection
          title={chartTitle}
          className="mt1 px15 py15 withBackgroundColorDefault hasBorderRadius"
          chartData={chartData}
          formState={formState}
        />
      )}
    </div>
  )
}
