import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import TextInput from '../../../form/TextInput'
import {
  IMissionData,
  IVisitReportForm,
} from '../../../../interfaces/IVisitReports'
import { VisitReportPrices } from './VisitReportPrices'
import { useIsTabDisabled } from '../../../../hooks/clientVisitReport/UseIsTabDisabled'

export const VisitReportRotationSection: FC<IMissionData> = ({
  defaultData,
  rotations,
  prices,
}) => {
  const { errors, register } = useFormContext()
  const isDisabled = useIsTabDisabled()
  const date = new Date()
  const currentMonth = date.getMonth()
  const currentYear =
    currentMonth < 9 ? date.getFullYear() : date.getFullYear() + 1

  return (
    <div className="rotationSection mb2 ml2">
      <div className="row">
        <DynamicFormattedMessage
          id="form.section.rotations.label"
          className="col12 textPrimary fontBold mb1"
        />
        {rotations?.map((value, index) => (
          <div className="colMd6" key={value}>
            <DynamicFormattedMessage
              id="form.field.rotation.label"
              className="inputLabel textXs"
              values={{ year: currentYear - index }}
            />
            <TextInput
              name={value}
              id={value}
              register={register({
                required: false,
                validate: (val) => {
                  if (isDisabled) return
                  if (!!val && !val.match(/^[0-99999]*$/)) return 'number'
                  if (+val > 99999) return 'number.maxNo'
                },
              })}
              error={errors[`${value}`]}
              placeholder="typeIn"
              customClass="noLabel"
              defaultValue={
                (defaultData as IVisitReportForm)[
                  `${value}` as keyof IVisitReportForm
                ] as string
              }
              hasLabel={false}
            />
          </div>
        ))}
      </div>
      {prices?.length && (
        <VisitReportPrices defaultData={defaultData} prices={prices} />
      )}
    </div>
  )
}
