import { useEffect, useMemo } from 'react'
import { useLoadData } from '../UseLoadData'
import {
  getClientLastVisitReportImages,
  getContactOutcome,
  getContactTypes,
  getPlvList,
} from '../../services/visitReportsService'
import { getContactPersons } from '../../services/appointmentsService'
import { clientContactPersonsActions } from '../../store/reducers/clientContactPersonsReducer'
import { clientLastVisitReportActions } from '../../store/reducers/clientLastVisitReportReducer'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'
import { getClientContactPersons } from '../../store/selectors/clientContactPersonsSelectors'
import {
  getContactOutcomesValue,
  getContactTypesValue,
  getPlvListValue,
} from '../../store/selectors/sharedDataSelectors'
import { VISIT_REPORT_IMAGE_TYPES_LIST } from '../../constants/visitReport'
import { ILastVisitReportImages } from '../../interfaces/IVisitReports'
import { STATUS } from '../../enums/common'

export const useInitiateVisitReport = (clientId: string) => {
  const { data: contactTypes, status: contactTypesStatus } = useLoadData(
    () => getContactTypes(),
    {
      fetchFromRedux: true,
      storeInRedux: true,
      reduxAction: sharedDataActions.setContactTypes,
      reduxSelector: getContactTypesValue,
    }
  )

  const { data: contactOutcomes, status: contactOutcomesStatus } = useLoadData(
    () => getContactOutcome(),
    {
      fetchFromRedux: true,
      storeInRedux: true,
      reduxAction: sharedDataActions.setContactOutcomes,
      reduxSelector: getContactOutcomesValue,
    }
  )

  const { data: plvData, status: plvDataStatus } = useLoadData(
    () => getPlvList(),
    {
      initialData: [],
      fetchFromRedux: true,
      storeInRedux: true,
      reduxAction: sharedDataActions.setPlvList,
      reduxSelector: getPlvListValue,
    }
  )

  const dependencies = useMemo(() => [clientId], [clientId])
  const { data: contactPersons, status: contactPersonsStatus } = useLoadData(
    () => getContactPersons(parseInt(clientId, 10)),
    {
      dependencies,
      fetchFromRedux: true,
      storeInRedux: true,
      reduxAction:
        clientContactPersonsActions.replaceAllWithClientContactPersons,
      reduxSelector: getClientContactPersons,
      reduxStorePath: clientId?.toString(),
    }
  )

  const { data: lastVisitReport, status: lastVisitReportStatus } = useLoadData(
    () => getClientLastVisitReportImages(parseInt(clientId, 10)),
    {
      fetchFromRedux: false,
      storeInRedux: true,
      reduxAction:
        clientLastVisitReportActions.replaceAllWithClientLastVisitReport,
      reduxStorePath: clientId?.toString(),
    }
  )

  useEffect(() => {
    if (lastVisitReport) {
      try {
        VISIT_REPORT_IMAGE_TYPES_LIST.forEach((image) => {
          if (lastVisitReport[image as keyof ILastVisitReportImages]?.content) {
            const img = new Image()
            img.src =
              lastVisitReport[image as keyof ILastVisitReportImages]?.content ||
              ''
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [lastVisitReport])

  const visitReportDataStatus = useMemo(
    () => [
      contactTypesStatus,
      contactOutcomesStatus,
      plvDataStatus,
      contactPersonsStatus,
      lastVisitReportStatus,
    ],
    [
      contactTypesStatus,
      contactOutcomesStatus,
      plvDataStatus,
      contactPersonsStatus,
      lastVisitReportStatus,
    ]
  )

  const isVisitReportDataStatusPending = useMemo(
    () =>
      visitReportDataStatus.reduce(
        (acc, status) => acc || status === STATUS.PENDING,
        false
      ),
    [visitReportDataStatus]
  )

  return {
    contactTypes,
    contactOutcomes,
    contactPersons,
    plvData,
    lastVisitReport,
    isVisitReportDataStatusPending,
  }
}
