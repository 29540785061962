import { IArrayOptions } from '../interfaces/IAppointments'

export const EMAIL_REGEX = /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})?$/
export const MIN_AGE = 18
export const RANGE_LABEL = 'km'
export const NAME_INPUT_MAX_LENGTH = 49

export const OUI = 'Oui'
export const NON = 'Non'
export const BOTH = 'Les deux'
export const MISSING_LABEL = 'missing'
export const NOT_AVAILABLE = 'N/A'

const PHARMA = 'Pharmacovigilance'
const RECLAMATION = 'Reclamation produit'
const MATERIOVIGILANCE = 'Materiovigilance'
const COSMETOVIGILANCE = 'Cosmetovigilance'
const PHARMA_LABEL = 'Pharmacovigilance'
const RECLAMATION_LABEL = 'Réclamation produit'
const MATERIOVIGILANCE_LABEL = 'Matériovigilance'
const COSMETOVIGILANCE_LABEL = 'Cosmétovigilance'

const MEDECIN = 'Médecin'
const PHARMACIEN = 'Pharmacien'
const INFIRMIER = 'Infirmier'
const FEMME_LABEL = 'Femme'
const HOMME_LABEL = 'Homme'
const FEMME = 'F'
const HOMME = 'M'

export const AUTRE = 'Autre'

export const UPLOAD_IMAGE_FORM = ['file', 'entityId', 'type', 'keepOld']

export const RADIO_OPTIONS_LIST: IArrayOptions[] = [
  { value: OUI, label: OUI },
  { value: NON, label: NON },
]

export const FILTERS_RADIO_OPTIONS_LIST: IArrayOptions[] = [
  { value: '1', label: OUI },
  { value: '2', label: NON },
  { value: '3', label: BOTH },
]

export const YES_NO_OPTIONS = [
  {
    value: 'oui',
    label: 'Oui',
  },
  {
    value: 'non',
    label: 'Non',
  },
]

export const FIELD_SEARCH = 'search'

export const RECLAMATION_TYPE_LIST: IArrayOptions[] = [
  { value: PHARMA, label: PHARMA_LABEL },
  { value: RECLAMATION, label: RECLAMATION_LABEL },
  { value: MATERIOVIGILANCE, label: MATERIOVIGILANCE_LABEL },
  { value: COSMETOVIGILANCE, label: COSMETOVIGILANCE_LABEL },
]

export const NOTIFIER_TYPE_LIST: IArrayOptions[] = [
  { value: MEDECIN, label: MEDECIN },
  { value: PHARMACIEN, label: PHARMACIEN },
  { value: INFIRMIER, label: INFIRMIER },
  { value: AUTRE, label: AUTRE },
]

export const GENDER_OPTIONS_LIST: IArrayOptions[] = [
  { value: FEMME, label: FEMME_LABEL },
  { value: HOMME, label: HOMME_LABEL },
]

export const ON_KEY_PRESS = 'Enter'

export const SELECT_OPTIONS_PLACEHOLDER = 'search.placeholder'
export const SELECT_NO_TIER_OPTIONS_PLACEHOLDER =
  'appointment.pharmacy.no.options.placeholder'
