import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clientDetailDataInitialState } from '../../constants/userData'
import { IClientDetailsData } from '../../interfaces/IClientDetails'

const clientDetailsReducer = createSlice({
  name: 'clientDetailsReducer',
  initialState: clientDetailDataInitialState,
  reducers: {
    setClientDetails: (state, { payload }: PayloadAction<IClientDetailsData>) =>
      payload,
    setClientComment: (state, { payload }: PayloadAction<string>) => {
      return { ...state, fieldAgentComment: payload }
    },
  },
})

export const clientDetailsActions = clientDetailsReducer.actions

export default clientDetailsReducer.reducer
