import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import DynamicFormattedMessage from './DynamicFormattedMessage'
import { iconLogoMinimal } from '../../../assets/images/images'
import { IPageTitleProps } from '../../../interfaces/ICommonComponents'
import Button from './Button'
import NetworkStatusAlerts from '../../alerts/NetworkStatusAlerts'

export const PageTitleSection: FC<IPageTitleProps> = ({
  pageName,
  backButtonLabel,
  backRoute,
  backData,
  totalCount,
  children,
}) => {
  const history = useHistory()

  const handleOnClick = () => {
    if (!backRoute) {
      return history.go(-1)
    }

    return history.push(backRoute, {
      offset: backData,
      currentPage: history?.location?.state?.currentPage,
    })
  }

  return (
    <section className="pageTitleWrapper mb2 alignItemsCenter">
      <div className="dFlex justifyContentBetween alignItemsCenter">
        {pageName ? (
          <h1 className="pageTitle">
            <DynamicFormattedMessage
              tag="span"
              id={`page.${pageName}.title.label`}
              values={{ totalCount }}
            />
            {children}
          </h1>
        ) : (
          <DynamicFormattedMessage
            id={`cta.goBack${backButtonLabel ? '.clientDetails' : ''}.label`}
            tag={Button}
            className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15"
            data-qa="backButton"
            values={{ backButtonLabel }}
            onClick={handleOnClick}
          />
        )}
        <img src={iconLogoMinimal} alt="logo minimal" />
      </div>
      <NetworkStatusAlerts />
    </section>
  )
}
