import React from 'react'
import { IAlertParams } from '../../interfaces/INavigation'
import { DANGER } from '../../enums/common'
import { capitaliseString } from '../../utils/helpers'

const Alerts = ({ status, message, type = DANGER }: IAlertParams) => {
  const alertType = capitaliseString(type as string)
  if (status !== DANGER) {
    return <div />
  }

  return (
    <div
      className={`textCenter text${alertType} alert alert${alertType} errorMessage errorHighlight`}
    >
      {message}
    </div>
  )
}

export default Alerts
