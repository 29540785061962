import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import store from './store'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import './assets/styles/style.scss'

import messages from './i18n/fr.json'

import App from './App'
import NetworkStatus from './components/networkStatus/NetworkStatus'

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="fr" messages={messages}>
      <NetworkStatus>
        <App />
      </NetworkStatus>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
