import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { TStatus } from '../interfaces/INavigation'
import { STATUS } from '../enums/common'
import { statusNetworkReducerActions } from '../store/reducers/statusNetworkReducer'
import { statusNetworkSelector } from '../store/selectors'
import { ROUTES } from '../enums/routes'
import { handleError } from '../utils/api'

interface IResponseData {
  status: TStatus
  message: string
  messageCode?: string
}

interface IExtraParams<T> {
  onSuccess?: () => void
  onError?: () => void
}

/**
 * General function used to create delete requests and manage error popup.
 */
export const useDeleteHook = <T,>(
  deleteServiceFunction: () => Promise<T>,
  {
    onSuccess = () =>
      window.history.state
        ? window.history.back()
        : window.location.replace(ROUTES.agenda),
    onError,
  }: IExtraParams<T> = {}
) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [deleteResponse, setDeleteResponse] = useState<IResponseData>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })

  const closeErrorModal = () => setIsErrorModalOpen(false)

  const requestDelete = async () => {
    try {
      await deleteServiceFunction()
      setDeleteResponse({ status: STATUS.SUCCESS, message: '' })
      onSuccess()
    } catch (e) {
      if (onError) {
        onError()
        setDeleteResponse({ status: STATUS.SUCCESS, message: '' })
      }

      if (!hasNetwork) {
        return dispatch(statusNetworkReducerActions.setDeletedMsg(true))
      }

      setIsErrorModalOpen(true)
      if (!onError) {
        const handledError = handleError(e, intl)
        setDeleteResponse(handledError)
      }
    }
  }

  return {
    deleteResponse,
    closeErrorModal,
    isErrorModalOpen,
    requestDelete,
  }
}
