import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { STATUS } from '../../enums/common'
import Loader from '../loader/Loader'
import Alerts from '../alerts/Alerts'
import { IActivityData } from '../../interfaces/IUsers'

export const VisitReports: FC<IActivityData> = ({ data, status, message }) => {
  const { visitReportsSummary } = data

  return (
    <section className="visitReports col12 hasBorderRadius px15 py1 mx0 mb2">
      <Alerts status={status} message={message} />
      <div className="row mx0">
        <div className="colMd4 colSm1 pl0 py05 dFlex alignItemsCenter">
          <DynamicFormattedMessage
            id="dashboard.visitReports.label"
            tag="h3"
            className="textPrimary fontRegular"
          />
        </div>
        {status && status === STATUS.PENDING && <Loader />}
        {visitReportsSummary && (
          <ul className="colMd8 colSm12 px0 unstyledList dFlex justifyContentStart flexWrap visitReportsList">
            <li className="px05 py05 mr25 my05 dFlex alignItemsCenter fontMedium">
              <DynamicFormattedMessage
                id="dashboard.visitReports.month.label"
                tag="h3"
                className="textSecondary fontMedium"
              />
              <span className="monthValue textSecondary pl05">
                {visitReportsSummary?.currentMonth || '-'}
              </span>
            </li>
            <li className="px05 py05 mr25 my05 dFlex alignItemsCenter fontMedium">
              <DynamicFormattedMessage
                id="dashboard.visitReports.trim.label"
                tag="h3"
                className="textSecondary fontMedium"
              />
              <span className="textSecondary pl05">
                {visitReportsSummary?.currentTrimester || '-'}
              </span>
            </li>
            <li className="px05 py05 my05 dFlex alignItemsCenter fontMedium">
              <DynamicFormattedMessage
                id="dashboard.visitReports.year.label"
                tag="h3"
                className="textSecondary fontMedium"
              />
              <span className="textSecondary pl05">
                {visitReportsSummary?.currentYear || '-'}
              </span>
            </li>
          </ul>
        )}
      </div>
    </section>
  )
}
