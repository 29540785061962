import React, { FC } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../enums/routes'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { ReactComponent as IconLogoMinimal } from '../../assets/images/WH-minimal-white.svg'
import { IVisitReportPageTitle } from '../../interfaces/IVisitReports'
import { NEW_VISIT_REPORT } from '../../constants/routes'
import { useClientsDetails } from '../../hooks/clientDetails/UseClientDetails'
import { STATUS } from '../../enums/common'

export const VisitReportPageTitle: FC<IVisitReportPageTitle> = ({
  clientId,
  withLogo = true,
}) => {
  const {
    clientDetailsData: { name, cipCode },
    status,
  } = useClientsDetails(clientId)

  return (
    <>
      {name && status === STATUS.SUCCESS && (
        <section className="pageTitleWrapper dFlex justifyContentBetween alignItemsCenter mb2">
          <h1 className="pageTitle">
            <DynamicFormattedMessage
              tag="span"
              id={`page.${NEW_VISIT_REPORT}.title.label`}
            />
            <Link
              to={{
                pathname: generatePath(ROUTES.clientDetails, {
                  clientId,
                }),
              }}
              className="linkUnstyled"
              data-qa="ctaClientFile"
            >
              {`${name} `}
            </Link>
            {cipCode && <ClientCipCode cipCode={cipCode} />}
          </h1>
          {withLogo && <IconLogoMinimal title="logo minimal" />}
        </section>
      )}
    </>
  )
}
