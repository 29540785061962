import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import set from 'lodash.set'
import { TStatus } from '../interfaces/INavigation'
import { STATUS } from '../enums/common'
import { IStore } from '../store'
import { handleError } from '../utils/api'

interface IResponseData<T> {
  data: T
  status: TStatus
  message: string
  messageCode?: string
}

interface IExtraParams<T> {
  initialData?: T
  dependencies?: any[]
  fetchFromRedux?: boolean
  storeInRedux?: boolean
  reduxSelector?: (state: IStore, id?: string) => T
  reduxAction?: (payload: any) => any
  reduxStorePath?: string
}

/**
 * General function used to fetch data.
 */
export const useLoadData = <T,>(
  loadDataFunction: () => Promise<T> | undefined,
  {
    initialData = [] as any,
    dependencies = [],
    fetchFromRedux,
    storeInRedux,
    reduxAction,
    reduxSelector,
    reduxStorePath,
  }: IExtraParams<T> = {}
) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const storedData: any = useSelector((state: IStore) => {
    if (reduxSelector) {
      if (reduxStorePath) {
        return reduxSelector(state, reduxStorePath)
      }
      return reduxSelector(state)
    }
    return null
  })
  const [responseData, setResponseData] = useState<IResponseData<T>>({
    data: initialData,
    status: STATUS.PENDING,
    message: '',
  })

  useEffect(() => {
    const response = loadDataFunction()
    if (response === undefined) return

    const fetchData = () => {
      setResponseData({
        data: initialData,
        status: STATUS.PENDING,
        message: '',
      })

      response
        .then((data) => {
          setResponseData({ data, status: STATUS.SUCCESS, message: '' })
          if (storeInRedux && reduxAction) {
            const payload = reduxStorePath
              ? set({}, reduxStorePath, data)
              : data
            dispatch(reduxAction(payload))
          }
        })
        .catch((e) => {
          const handledError = handleError(e, intl)

          setResponseData({
            data: initialData,
            ...handledError,
          })
        })
    }

    const storeHasValue =
      storedData &&
      (Array.isArray(storedData)
        ? storedData.length
        : typeof storedData === 'object'
        ? Object.keys(storedData).length
        : !!storedData)

    if (fetchFromRedux && storeHasValue) {
      setResponseData({ data: storedData, status: STATUS.SUCCESS, message: '' })
    } else {
      fetchData()
    }
  }, dependencies) //eslint-disable-line

  return responseData
}
