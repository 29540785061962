import React, { FC, KeyboardEvent } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import DynamicFormattedMessage from '../components/common/ui/DynamicFormattedMessage'
import TextInput from '../components/form/TextInput'
import { logoLoginBackground } from '../assets/images/images'
import useLoginFormHook from '../hooks/UseLoginForm'
import { STATUS } from '../enums/common'
import Loader from '../components/loader/Loader'
import Alerts from '../components/alerts/Alerts'
import { loginSelectors } from '../store/selectors'
import { ROUTES } from '../enums/routes'
import { ON_KEY_PRESS } from '../constants/form'

const Login: FC = () => {
  const {
    form: { register, handleSubmit, errors },
    onSubmit,
    formState,
  } = useLoginFormHook()
  const { status, message } = formState
  const classNameDanger = `${
    status && status === STATUS.DANGER ? 'inputError' : ''
  }`

  const triggerSubmit = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ON_KEY_PRESS) {
      handleSubmit(onSubmit)()
    }
  }

  if (useSelector(loginSelectors.getLoginValue)) {
    return <Redirect to={ROUTES.dashboard} />
  }

  return (
    <section className="loginPage withBackgroundColorThird hFullVh dFlex">
      <div className="container">
        <div className="loginPageContent dFlex">
          <img
            src={logoLoginBackground}
            alt="background logo"
            className="dBlock mwFull"
          />
          <div className="loginPageFormWrapper withBackgroundColorDefault relative px45 pt2 pb3">
            <h3 className="textCenter mb2 loginPageTitle">
              <DynamicFormattedMessage id="login.title.label" />
            </h3>
            {status && status === STATUS.PENDING ? (
              <div className="loginLoaderWrapper">
                <Loader />
              </div>
            ) : (
              <div onKeyUp={triggerSubmit}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="loginPageForm row"
                >
                  <div className={`mb2 col12 ${classNameDanger}`}>
                    <TextInput
                      name="username"
                      id="username"
                      register={register({ required: true })}
                      error={errors.username}
                    />
                  </div>
                  <div className={`mb2 col12 ${classNameDanger}`}>
                    <TextInput
                      name="password"
                      id="password"
                      type="password"
                      register={register({ required: true })}
                      error={errors.password}
                    />
                  </div>
                  <div className="col12">
                    <Alerts status={status} message={message} />
                  </div>
                  <div className="col12 dFlex justifyContentCenter">
                    <button
                      className="btn btnPrimary textUppercase wFull loginCta"
                      data-qa="loginCta"
                    >
                      <DynamicFormattedMessage id="form.button.login" />
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
