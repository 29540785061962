import React, { FC, useState } from 'react'
import { SendEmailButton } from './SendEmailButton'
import { DropdownSection } from './DropdownSection'
import { ISendEmailSection } from '../../../interfaces/ICommonComponents'
import { ScreenOverlay } from '../Tooltip/ScreenOverlay'

export const SendEmailSection: FC<ISendEmailSection> = ({
  entityId,
  sendEmailType,
  inputEmailKey,
  setSendEmailState,
  setAvailableEmails,
  contentData,
}) => {
  const [isAreaOpen, setIsAreaOpen] = useState(false)

  const handleSendEmailButtonClick = () => {
    setIsAreaOpen((prev) => !prev)
  }

  return (
    <div className="sendEmailSection">
      <SendEmailButton disabled={false} onClick={handleSendEmailButtonClick} />
      <div
        className={`sendEmailWrapper dNone ${
          isAreaOpen ? 'dFlex justifyContentEnd px15 pt15' : ''
        }`}
      >
        <DropdownSection
          entityId={entityId}
          sendEmailType={sendEmailType}
          setIsAreaOpen={setIsAreaOpen}
          inputEmailKey={inputEmailKey}
          setSendEmailState={setSendEmailState}
          setAvailableEmails={setAvailableEmails}
          sendEmailAdditionalData={{ contentData }}
        />
      </div>
      <ScreenOverlay
        active={isAreaOpen}
        handleOnClick={() => setIsAreaOpen(false)}
      />
    </div>
  )
}
