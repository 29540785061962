import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { ICloseModal } from '../../interfaces/IModal'
import { capitaliseString, getAlertType } from '../../utils/helpers'

export const ErrorMessageModal: FC<ICloseModal> = ({ closeModal, message }) => {
  const errorType = capitaliseString(getAlertType(message))

  return (
    <div
      className={`errorMessageModal background${errorType} textCenter relative`}
    >
      <span
        className="errorMessageModalCloseCta"
        onClick={() => closeModal()}
      />
      <DynamicFormattedMessage
        id={`modal.error.${message || 'comment'}.label`}
      />
    </div>
  )
}
