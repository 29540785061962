import { useEffect, useState } from 'react'
import { TClientDetailsItem } from '../../interfaces/IClientDetails'
import {
  IMarkerCoordinates,
  IUserLocationCoordinates,
} from '../../interfaces/IMap'
import { getClientListMapCenter } from '../../utils/data'

/**
 * Used to generate client list map
 */
export const useClientListMap = (
  clients: TClientDetailsItem[],
  userCoordinates: IUserLocationCoordinates
) => {
  const [mapCenter, setMapCenter] = useState<IMarkerCoordinates>({
    gpsLatitude: 0,
    gpsLongitude: 0,
  })

  useEffect(() => {
    setMapCenter(getClientListMapCenter(clients, userCoordinates)) // eslint-disable-next-line
  }, [userCoordinates.latitude, userCoordinates.longitude, clients.length])

  return { mapCenter }
}
