import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPlvData } from '../../interfaces/IVisitReports'
import { IArrayOptions } from '../../interfaces/IAppointments'
import { IOption } from '../../interfaces/IForms'
import { getJwtUserDetails } from '../../utils/api'
import { IUser, IUserSavedData } from '../../interfaces/IUsers'
import { getJwtUsername } from '../../utils/user'
import { getFullName } from '../../utils/helpers'

const jwt = getJwtUserDetails()

const sharedDataReducer = createSlice({
  name: 'clientSharedDataReducer',
  initialState: {
    contactTypes: [] as IArrayOptions[],
    contactOutcomes: [] as IOption[],
    plvList: [] as IPlvData[],
    appointmentTypes: [] as IArrayOptions[],
    contractsSelectedYear: 0,
    navClick: false,
    userSavedData: {
      forUser: jwt?.id?.toString(),
      selectedUser: jwt?.id?.toString(),
      username: getJwtUsername(jwt),
      fullName: getFullName(jwt, true),
    },
    users: [] as IUser[],
    isPageBlurred: false,
  },
  reducers: {
    setContactTypes: (state, { payload }: PayloadAction<IArrayOptions[]>) => ({
      ...state,
      contactTypes: payload,
    }),
    setContactOutcomes: (state, { payload }: PayloadAction<IOption[]>) => ({
      ...state,
      contactOutcomes: payload,
    }),
    setPlvList: (state, { payload }: PayloadAction<IPlvData[]>) => ({
      ...state,
      plvList: payload,
    }),
    setAppointmentTypes: (
      state,
      { payload }: PayloadAction<IArrayOptions[]>
    ) => ({
      ...state,
      appointmentTypes: payload,
    }),
    setContractsSelectedYear: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      contractsSelectedYear: payload,
    }),
    setNavClick: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      navClick: payload,
    }),
    setUserSavedData: (state, { payload }: PayloadAction<IUserSavedData>) => ({
      ...state,
      userSavedData: payload,
    }),
    setSelectedUser: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      userSavedData: {
        forUser: state.userSavedData.forUser,
        selectedUser: payload,
        username: state.userSavedData.username,
        fullName: state.userSavedData.fullName,
      },
    }),
    setForUser: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      userSavedData: {
        forUser: payload,
        selectedUser: state.userSavedData.selectedUser,
        username: state.userSavedData.username,
        fullName: state.userSavedData.fullName,
      },
    }),
    setLoggedUsername: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      userSavedData: {
        forUser: state.userSavedData.forUser,
        selectedUser: state.userSavedData.selectedUser,
        username: payload,
        fullName: state.userSavedData.fullName,
      },
    }),
    setLoggedFullname: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      userSavedData: {
        forUser: state.userSavedData.forUser,
        selectedUser: state.userSavedData.selectedUser,
        username: state.userSavedData.username,
        fullName: payload,
      },
    }),
    setUsers: (state, { payload }: PayloadAction<IUser[]>) => ({
      ...state,
      users: payload,
    }),
    setIsPageBlurred: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isPageBlurred: payload,
    }),
  },
})

export const sharedDataActions = sharedDataReducer.actions

export default sharedDataReducer.reducer
