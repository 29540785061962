import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  SubTitle,
  Title,
  Tooltip,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'
import React, { FC } from 'react'
import { IPieChart } from '../../../interfaces/IDashboard'
import { usePieChartHook } from '../../../hooks/dashboard/charts/UsePieChartHook'
import { ChartTitle } from '../common/ChartTitle'

export const PieChart: FC<IPieChart> = ({
  title,
  subtitle,
  labels,
  chartData,
  shouldChartRender,
}) => {
  ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle)

  const { data, options } = usePieChartHook(
    labels,
    chartData,
    shouldChartRender
  )

  return (
    <div className="chartContainer">
      <ChartTitle title={title} />
      <Pie data={data} options={options} />
      <ChartTitle title={subtitle} />
    </div>
  )
}
