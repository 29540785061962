import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import {
  INavbarLinkProps,
  INavLinkSubmenuItem,
} from '../../interfaces/INavigation'
import Button from '../common/ui/Button'
import ModalContainer from '../modal/Modal'
import { ConfirmationMessageModal } from '../modal/ConfirmationMessageModal'
import { useNavbarLink } from '../../hooks/navigation/UseNavbarLink'
import { getRoute } from '../../utils/helpers'
import { NavbarSubmenu } from './NavbarSubmenu'

export const NavbarLink: FC<INavbarLinkProps> = ({
  isMenuOpen,
  setIsMenuOpen,
  route,
  iconClosed,
  iconOpened,
  isLogout,
  nodes,
  closeMenuOnClick,
  query,
}) => {
  const imageSrc = isMenuOpen ? iconOpened : iconClosed

  const {
    isModalOpen,
    isSubmenuOpen,
    onNavbarLinkClick,
    onNavbarLinkSubmenuClick,
    onLogoutConfirm,
    onLogoutCancel,
    onLogoutButtonClick,
    navbarLinkClass,
  } = useNavbarLink(isMenuOpen, setIsMenuOpen, nodes, closeMenuOnClick)

  const submenuProps = {
    isMenuOpen,
    route,
    nodes: nodes as INavLinkSubmenuItem[],
    onNavbarLinkSubmenuClick,
  }

  return (
    <>
      <li className="navbarItem">
        {!isLogout ? (
          <NavLink
            to={getRoute(route, query)}
            onClick={(event) => {
              onNavbarLinkClick(event)
            }}
            activeClassName="navbarLinkActive"
            className={`navbarLink ${navbarLinkClass}`}
            data-qa={`${route}Link`}
            exact={true}
          >
            <img src={imageSrc} alt={`${route} icon`} />
            <DynamicFormattedMessage
              id={`${route}.title.label`}
              className={`${isMenuOpen ? 'navbarItemLabel' : 'hidden'}`}
            />
          </NavLink>
        ) : (
          <Button
            className="navbarLink wFull btn btnLink"
            data-qa={`${route}Link`}
            onClick={onLogoutButtonClick}
          >
            <img src={imageSrc} alt={`${route} icon`} />
            <DynamicFormattedMessage
              id={`${route}.title.label`}
              className={`${isMenuOpen ? 'navbarItemLabel' : 'hidden'}`}
            />
          </Button>
        )}
        {isSubmenuOpen && <NavbarSubmenu {...submenuProps} />}
      </li>
      <ModalContainer isOpen={isModalOpen}>
        <ConfirmationMessageModal
          reversOrder={true}
          onConfirm={onLogoutConfirm}
          backButton={onLogoutCancel}
          messages={{
            confirm: 'logoutConfirm',
            cancel: 'logoutCancel',
            title: 'logoutTitle',
            content: 'logoutMessage',
          }}
        />
      </ModalContainer>
    </>
  )
}
