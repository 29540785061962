import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import { IConfirmationModalProps } from '../../interfaces/IModal'

export const ConfirmationMessageModal: FC<IConfirmationModalProps> = ({
  onConfirm,
  backButton,
  messages,
  reversOrder,
  children,
}) => {
  const BackButtonJsx = () => (
    <DynamicFormattedMessage
      id={`modal.confirmation.cta.${messages?.cancel || 'leave'}.label`}
      tag={Button}
      className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15"
      data-qa="leavePage"
      onClick={backButton}
    />
  )

  const OnConfirmJSx = () => (
    <DynamicFormattedMessage
      id={`modal.confirmation.cta.${messages?.confirm || 'stay'}.label`}
      tag={Button}
      className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15"
      data-qa="stayOnPage"
      onClick={onConfirm}
    />
  )

  return (
    <div className="contentWrapper dFlex justifyContentStrech textCenter relative">
      <DynamicFormattedMessage
        id={`modal.confirmation.${messages?.title || 'title'}.label`}
        tag="p"
        className="pt05 mb2 textPrimary textMd"
      />
      <DynamicFormattedMessage
        id={`modal.confirmation.${messages?.content || 'description'}.label`}
        tag="p"
        className="mb25 textPrimary"
      />
      {children}
      <div className="dFlex justifyContentBetween">
        {reversOrder ? (
          <>
            <OnConfirmJSx />
            <BackButtonJsx />
          </>
        ) : (
          <>
            <BackButtonJsx />
            <OnConfirmJSx />
          </>
        )}
      </div>
    </div>
  )
}
