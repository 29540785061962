import { useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { dashboardCaSelectors } from '../../store/selectors'
import { dashboardCaFiltersInitialState } from '../../constants/dashboard'
import { isValidSelection } from '../../utils/dashboard'
import { IDashboardCaFilters } from '../../interfaces/IDashboard'
import { ISelectOption } from '../../interfaces/IFilters'
import { IHandledError } from '../../interfaces/ICommonComponents'
import { STATUS } from '../../enums/common'

export const useDashboardCaHook = () => {
  const intl = useIntl()
  const {
    filtersCa,
    defaultSelection,
    currentChartUser,
    chartData,
  } = useSelector(dashboardCaSelectors.getDashboardData)
  const [showChartSection, setShowChartSection] = useState(false)
  const [filters, setFilters] = useState(
    filtersCa || dashboardCaFiltersInitialState
  )
  const [chartTitle, setChartTitle] = useState<string>()
  const [formState, setFormState] = useState<IHandledError>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })

  const prepareFilterDisplay = useCallback(
    (selectedFilters: IDashboardCaFilters) => {
      if (!selectedFilters) return

      const result: string[] = []
      Object.entries(selectedFilters).forEach(([key, value]) => {
        if (value.length > 0) {
          const filterMessageId = intl.formatMessage({
            id: `dashboard.ca.filter.${key}.label`,
          })
          result.push(
            `${filterMessageId} [${value
              .map((val: ISelectOption) => val.label)
              .join(', ')}]`
          )
        }
      })

      return result.join(', ')
    },
    [intl]
  )

  const prepareChartTitle = useCallback(
    (user: string, selectedFilters: IDashboardCaFilters) => {
      return intl.formatMessage(
        { id: 'dashboard.ca.chart.title' },
        { kamName: user, filters: prepareFilterDisplay(selectedFilters) }
      )
    },
    [intl, prepareFilterDisplay]
  )

  const renderChart = useCallback(() => {
    setShowChartSection(true)
  }, [])

  const formStateIdleOrPending = useMemo(
    () =>
      formState.status === STATUS.IDLE || formState.status === STATUS.PENDING,
    [formState.status]
  )

  useEffect(() => {
    setFilters(filtersCa)
    setChartTitle(
      prepareChartTitle(
        currentChartUser ? currentChartUser.name : '',
        filtersCa
      )
    )
  }, [filtersCa, currentChartUser, prepareChartTitle])

  useEffect(() => {
    if (
      defaultSelection ||
      !filters ||
      !isValidSelection(filters) ||
      formStateIdleOrPending
    ) {
      setShowChartSection(false)
      return
    }
    renderChart()
  }, [defaultSelection, filters, renderChart, formStateIdleOrPending])

  return {
    filters,
    setFilters,
    showChartSection,
    chartTitle,
    currentChartUser,
    chartData,
    formState,
    setFormState,
  }
}
