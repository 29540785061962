import { clientDetailsDataFieldsOrder } from '../constants/userData'

export const filterClientData = <T>(
  data: T,
  filteringList: (keyof T)[],
  shouldOmit: boolean
): (keyof T)[] =>
  (Object.keys(data) as (keyof T)[]).filter((item) => {
    if (shouldOmit) {
      return !filteringList.includes(item)
    }

    return filteringList.includes(item)
  })

export const orderClientData = <T>(data: (keyof T)[]): (keyof T)[] =>
  (Object.values(clientDetailsDataFieldsOrder) as (keyof T)[]).filter((item) =>
    data.includes(item)
  )
