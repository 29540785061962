import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import SelectInput from '../../form/SelectInput'
import {
  CONTACT_PERSON_INPUT,
  CUSTOM_CONTACT_PERSON_VALUE,
} from '../../../constants/contracts'
import Button from '../../common/ui/Button'
import { IUsersOptions } from '../../../interfaces/IUsers'
import { NAME_INPUT_MAX_LENGTH } from '../../../constants/form'
import { isEmptyOrSpaces } from '../../../utils/helpers'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import TextInput from '../../form/TextInput'

const ContactPersonSection: FC<{
  contactPersons: IUsersOptions[]
  newContactPerson: IUsersOptions
  setNewContactPerson: Dispatch<SetStateAction<IUsersOptions>>
}> = ({ contactPersons, newContactPerson, setNewContactPerson }) => {
  const {
    register,
    errors,
    clearErrors,
    getValues,
    setError,
    setValue,
  } = useFormContext()
  const [isContactSectionOpen, setIsContactSectionOpen] = useState(false)

  const clearContactPersonErrors = () => clearErrors(['firstName', 'lastName'])

  const handleCreateContactPerson = () => {
    const contactPerson = {
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
    }

    clearContactPersonErrors()

    let hasError = false
    Object.entries(contactPerson).forEach((item) => {
      if (item[1]?.length > NAME_INPUT_MAX_LENGTH) {
        setError(item[0], { type: 'maxLength', message: 'maxLength' })
        hasError = true
      }

      if (isEmptyOrSpaces(item[1])) {
        setError(item[0], { type: 'required', message: 'required' })
        hasError = true
      }
    })

    if (!hasError) {
      const newContact = {
        firstName: contactPerson.firstName,
        lastName: contactPerson.lastName,
        name: `${contactPerson.lastName} ${contactPerson.firstName}`,
        value: CUSTOM_CONTACT_PERSON_VALUE,
      }

      setNewContactPerson(newContact)
      setValue(CONTACT_PERSON_INPUT, CUSTOM_CONTACT_PERSON_VALUE)
    }

    return hasError
  }

  return (
    <>
      <div className="dFlex justifyContentBetween">
        <div className={`colMd11 pl0 pr0 ${errors.contactPerson ? '' : 'mb2'}`}>
          <SelectInput
            name={CONTACT_PERSON_INPUT}
            id={CONTACT_PERSON_INPUT}
            register={register({ required: true })}
            options={[...contactPersons, newContactPerson]}
            className="textPrimary"
            error={errors.contactPerson}
            defaultValue={newContactPerson.value}
          />
        </div>
        <Button
          className="btn textUnderlineNone withBackgroundColorDefault btnContactSection textLg linkDefault my0 py0"
          data-qa="ctaNewContact "
          onClick={() => {
            clearContactPersonErrors()
            setIsContactSectionOpen(!isContactSectionOpen)
          }}
        >
          {isContactSectionOpen ? '-' : '+'}
        </Button>
      </div>
      {isContactSectionOpen && (
        <div className="row hasBorderBottom mb2 mt05">
          <div className="colMd12 px15 py0">
            <DynamicFormattedMessage
              id="form.field.label.extraContactPerson"
              tag="h4"
              className="inputLabel"
            />
          </div>
          <TextInput
            name="firstName"
            id="firstName"
            customClass="colMd6"
            register={register({
              required: false,
              maxLength: NAME_INPUT_MAX_LENGTH,
            })}
            error={errors.firstName}
            placeholder="firstName"
            hasLabel={false}
          />
          <TextInput
            name="lastName"
            id="lastName"
            customClass="colMd6"
            register={register({
              required: false,
              maxLength: NAME_INPUT_MAX_LENGTH,
            })}
            error={errors.lastName}
            placeholder="lastName"
            hasLabel={false}
          />
          <div className="row justifyContentEnd wFull">
            <DynamicFormattedMessage
              id="form.button.confirmation"
              tag={Button}
              className="btn btnPrimary textUppercase mb15 mx0 px15"
              data-qa="ctaNewContact"
              onClick={() => {
                const hasError = handleCreateContactPerson()
                if (!hasError) {
                  setIsContactSectionOpen(!isContactSectionOpen)
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ContactPersonSection
