import React, { FC } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import useGeolocation from 'react-hook-geolocation'
import { IClientDetailMapProps } from '../../interfaces/IMap'
import Loader from '../loader/Loader'
import { pharmacyLocationIcon } from './PharmacyLocationIcon'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import LinkTo from '../common/ui/LinkTo'
import { MAP } from '../../enums/common'
import { useClientsUserDistance } from '../../hooks/clientDetails/UseClientUserDistance'

export const ClientDetailsMap: FC<IClientDetailMapProps> = ({
  clientLatitude,
  clientLongitude,
  clientAddress,
}) => {
  const geolocation = useGeolocation({ enableHighAccuracy: true })
  const { latitude, longitude } = geolocation
  const hasClientCoordinates = clientLatitude && clientLongitude
  const gpsLatitude = hasClientCoordinates ? clientLatitude : latitude
  const gpsLongitude = hasClientCoordinates ? clientLongitude : longitude

  const { clientUserDistance } = useClientsUserDistance(
    {
      gpsLatitude: clientLatitude,
      gpsLongitude: clientLongitude,
    },
    { latitude, longitude }
  )

  if (!latitude || !longitude || !gpsLatitude || !gpsLongitude) {
    return <Loader />
  }

  return (
    <div
      className="clientDetailsPageMapWrapper relative"
      key={`${gpsLatitude}${gpsLongitude}`}
    >
      <MapContainer
        center={[gpsLatitude, gpsLongitude]}
        zoom={13}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {hasClientCoordinates && (
          <Marker
            position={[gpsLatitude, gpsLongitude]}
            icon={pharmacyLocationIcon}
          />
        )}
      </MapContainer>
      {hasClientCoordinates && (
        <div className="clientDetailsPageLocationInfo dFlex px15 py15">
          <LinkTo type={MAP} hrefContent={`${gpsLatitude},${gpsLongitude}`}>
            {clientAddress}
          </LinkTo>
          {clientUserDistance > 0 && (
            <p className="clientUserDistance textRight">
              <span className="dBlock">{clientUserDistance} km</span>
              <DynamicFormattedMessage
                id="map.location.distance.label"
                className="dBlock"
              />
            </p>
          )}
        </div>
      )}
    </div>
  )
}
