import React, { FC } from 'react'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import {
  IHistoryTableData,
  IHistoryTableRow,
} from '../../../../interfaces/IHistory'
import {
  ANNUAL_RECAP_TABLE_HEADER,
  TYPE_SUMMARY,
} from '../../../../constants/tables'
import {
  currentBusinessYearStartingValue,
  currentDate,
  currentYearValue,
  isEmptyObj,
} from '../../../../utils/helpers'
import { useHistoryTotal } from '../../../../hooks/history/UseHistoryTotal'
import { AnnualTableRow } from './AnnualTableRow'

export const AnnualTableContent: FC<IHistoryTableData> = ({ data }) => {
  const summaryTotal = useHistoryTotal(data)

  return (
    <table className="customTable customTableRecap wFull">
      <thead className="customTableHeader">
        <tr>
          {ANNUAL_RECAP_TABLE_HEADER.map(
            (headerName: string, index: number) => (
              <td key={headerName} className={`${index !== 0 && 'textCenter'}`}>
                <DynamicFormattedMessage
                  id={`table.header.${headerName}.label`}
                  className="fontBold textUppercase textPrimary textCustomXs textLeft"
                  values={{
                    year:
                      currentDate >= currentBusinessYearStartingValue
                        ? currentYearValue + 1
                        : currentYearValue,
                    previousYear:
                      currentDate >= currentBusinessYearStartingValue
                        ? currentYearValue
                        : currentYearValue - 1,
                  }}
                />
              </td>
            )
          )}
        </tr>
      </thead>
      <tbody className="customTableBody">
        {!!data?.length && !isEmptyObj(summaryTotal) && (
          <AnnualTableRow
            currentYear={summaryTotal.currentYear}
            previousYear={summaryTotal.previousYear}
            customClass="fontBold backgroundSecondaryLight"
          />
        )}
        {!!data?.length &&
          data.map(
            (
              { type, name, currentYear, previousYear }: IHistoryTableRow,
              index: number
            ) => {
              return (
                <AnnualTableRow
                  key={index.toString()}
                  currentYear={currentYear}
                  previousYear={previousYear}
                  name={name}
                  customClass={`${
                    type === TYPE_SUMMARY && 'fontBold backgroundNeutralLight'
                  } ${
                    !currentYear?.TOTAL2?.value &&
                    !previousYear?.TOTAL1?.value &&
                    'recapItemOutline'
                  }`}
                />
              )
            }
          )}
      </tbody>
    </table>
  )
}
