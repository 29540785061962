import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TClientDetailsItem } from '../../interfaces/IClientDetails'

const allClientsReducer = createSlice({
  name: 'allClientsReducer',
  initialState: [] as TClientDetailsItem[],
  reducers: {
    setAllClients: (
      state,
      { payload }: PayloadAction<TClientDetailsItem[]>
    ) => {
      return payload
    },
  },
})

export const allClientsActions = allClientsReducer.actions

export default allClientsReducer.reducer
