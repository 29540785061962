import React from 'react'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'

export const ChartPlaceholder = () => {
  return (
    <DynamicFormattedMessage
      id="dashboard.visitsCoverage.chart.placeholder"
      tag="p"
      className="dBlock textPrimary textCenter px2 py2"
    />
  )
}
