import React from 'react'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'

export const UserUnavailableMessage = ({
  shouldRender,
}: {
  shouldRender: boolean
}) => {
  return (
    <DynamicFormattedMessage
      id="section.users.user.unavailable"
      className="dInlineBlock textWarning mb15"
      shouldRender={shouldRender}
    />
  )
}
