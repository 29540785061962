import get from 'lodash.get'
import { IStore } from '../index'
import { IContractForm } from '../../interfaces/IContracts'

export const getClientContractsData = (
  state: IStore,
  reduxStorePath?: string
) =>
  reduxStorePath
    ? get(state.clientContractsReducer, reduxStorePath, {} as IContractForm)
    : ({} as IContractForm)
