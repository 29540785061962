import { useMemo } from 'react'
import { useLoadData } from '../UseLoadData'
import {
  getClientEngagementsHistory,
  getClientOrderHistory,
  getClientRecapHistory,
} from '../../services/historyService'
import { clientHistoryActions } from '../../store/reducers/clientHistoryReducer'
import { VIEW_TYPE } from '../../constants/tables'
import { STATUS } from '../../enums/common'

export const useInitiateHistory = (clientId: string) => {
  const dependencies = useMemo(() => [clientId], [clientId])

  const { data: recapViewTotal, status: recapViewTotalStatus } = useLoadData(
    () => getClientRecapHistory(parseInt(clientId, 10), VIEW_TYPE.TOTAL),
    {
      dependencies,
      storeInRedux: true,
      reduxAction: clientHistoryActions.setClientRecapViewTotal,
      reduxStorePath: `${clientId?.toString()}.${VIEW_TYPE.TOTAL}`,
    }
  )

  const {
    data: recapViewSemester,
    status: recapViewSemesterStatus,
  } = useLoadData(
    () => getClientRecapHistory(parseInt(clientId, 10), VIEW_TYPE.SEMESTER),
    {
      dependencies,
      storeInRedux: true,
      reduxAction: clientHistoryActions.setClientRecapViewSemester,
      reduxStorePath: `${clientId?.toString()}.${VIEW_TYPE.SEMESTER}`,
    }
  )

  const {
    data: recapViewQuarterly,
    status: recapViewQuarterlyStatus,
  } = useLoadData(
    () => getClientRecapHistory(parseInt(clientId, 10), VIEW_TYPE.QUARTERLY),
    {
      dependencies,
      storeInRedux: true,
      reduxAction: clientHistoryActions.setClientRecapViewQuarterly,
      reduxStorePath: `${clientId?.toString()}.${VIEW_TYPE.QUARTERLY}`,
    }
  )

  const {
    data: recapViewTrimester,
    status: recapViewTrimesterStatus,
  } = useLoadData(
    () => getClientRecapHistory(parseInt(clientId, 10), VIEW_TYPE.TRIMESTER),
    {
      dependencies,
      storeInRedux: true,
      reduxAction: clientHistoryActions.setClientRecapViewTrimester,
      reduxStorePath: `${clientId?.toString()}.${VIEW_TYPE.TRIMESTER}`,
    }
  )

  const { data: ordersHistory, status: ordersHistoryStatus } = useLoadData(
    () => getClientOrderHistory(parseInt(clientId, 10)),
    {
      dependencies,
      storeInRedux: true,
      reduxAction: clientHistoryActions.setClientOrdersHistory,
      reduxStorePath: `${clientId?.toString()}.ordersHistory`,
    }
  )

  const {
    data: engagementsHistory,
    status: engagementsHistoryStatus,
  } = useLoadData(() => getClientEngagementsHistory(parseInt(clientId, 10)), {
    dependencies,
    storeInRedux: true,
    reduxAction: clientHistoryActions.setClientEngagementsHistory,
    reduxStorePath: `${clientId?.toString()}.engagementsHistory`,
  })

  const historyDataStatus = useMemo(
    () => [
      recapViewTotalStatus,
      recapViewSemesterStatus,
      recapViewQuarterlyStatus,
      recapViewTrimesterStatus,
      ordersHistoryStatus,
      engagementsHistoryStatus,
    ],
    [
      recapViewTotalStatus,
      recapViewSemesterStatus,
      recapViewQuarterlyStatus,
      recapViewTrimesterStatus,
      ordersHistoryStatus,
      engagementsHistoryStatus,
    ]
  )

  const isHistoryDataStatusPending = useMemo(
    () =>
      historyDataStatus.reduce(
        (acc, status) => acc || status === STATUS.PENDING,
        false
      ),
    [historyDataStatus]
  )

  return {
    recapViewTotal,
    recapViewSemester,
    recapViewQuarterly,
    recapViewTrimester,
    ordersHistory,
    engagementsHistory,
    isHistoryDataStatusPending,
  }
}
