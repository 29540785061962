import React, { FC } from 'react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useMonthlyBarChartHook } from '../../../hooks/dashboard/charts/UseMonthlyBarChartHook'
import { IBarChart } from '../../../interfaces/IDashboard'

const BarChart: FC<IBarChart> = ({ title, chartData }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const dataset = {
    lastYear: chartData?.lastYear.map(({ turnover }) => turnover),
    currentYear: chartData?.currentYear.map(({ turnover }) => turnover),
  }

  const { options, data } = useMonthlyBarChartHook({ dataset, title })

  return <Bar options={options} data={data} />
}

export default BarChart
