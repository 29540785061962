import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { SELECTED_USER_PARAM } from '../../constants/dashboard'
import { getJwtUserDetails, userIsAdmin } from '../../utils/api'

export const useSelectedUserCheck = (
  isUserNotAvailableError: boolean,
  isUsersLoadingError: boolean
) => {
  const location = useLocation()
  const selectedUserSearchParam = useMemo(
    () => new URLSearchParams(location.search).get(SELECTED_USER_PARAM),
    [location.search]
  )

  const isUserDifferentFromSearchParam = useMemo(
    () =>
      !userIsAdmin() &&
      !!selectedUserSearchParam &&
      selectedUserSearchParam !== getJwtUserDetails()?.id.toString(),
    [selectedUserSearchParam]
  )

  const shouldUseLoggedUserId = useMemo(
    () =>
      isUserNotAvailableError ||
      isUsersLoadingError ||
      isUserDifferentFromSearchParam,
    [
      isUserNotAvailableError,
      isUsersLoadingError,
      isUserDifferentFromSearchParam,
    ]
  )

  return {
    isUserDifferentFromSearchParam,
    shouldUseLoggedUserId,
  }
}
