import React, { FC } from 'react'
import { generatePath, Link, useLocation } from 'react-router-dom'
import { format, utcToZonedTime } from 'date-fns-tz'
import { useSelector } from 'react-redux'
import { iconPerson } from '../../assets/images/images'
import { IClientEvent } from '../../interfaces/IClientDetails'
import { EVENTS_DATETIME_FORMAT } from '../../enums/common'
import { joinData } from '../../utils/helpers'
import { statusNetworkSelector } from '../../store/selectors'

export const ClientEventCard: FC<IClientEvent> = ({
  data,
  path,
  clientId,
  customClass,
  disabled = false,
}) => {
  const {
    id,
    startDate,
    eventType,
    formationType,
    outcome,
    contactPerson,
  } = data
  const eventDate = utcToZonedTime(startDate, 'UTC')
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const { pathname } = useLocation()

  return (
    <div className={`clientEventCard ${customClass && customClass}`}>
      <p className="clientEventCardDate">
        {format(eventDate, EVENTS_DATETIME_FORMAT)}
      </p>
      <div className="mb15 pt1 dFlex">
        <Link
          to={{
            pathname: generatePath(path, {
              appointmentId: id,
              reportId: id,
              clientId,
            }),
            state: { clientId, eventBackUrl: pathname },
          }}
          className={`linkUnstyled clientEventCardType ${
            !hasNetwork && disabled ? 'notAllowed textLighterGrey' : ''
          }`}
          onClick={(e) =>
            !hasNetwork && disabled ? e.preventDefault() : () => {}
          }
        >
          {joinData([eventType, formationType, outcome])}
        </Link>
      </div>
      <p className="dFlex clientEventCardClientName">
        <img src={iconPerson} alt="user person icon" className="mr1" />
        <span>{contactPerson !== ' ' ? contactPerson : '-'}</span>
      </p>
    </div>
  )
}
