import React, { createElement, ReactHTML } from 'react'
import { FormattedMessage } from 'react-intl'
import messages from '../../../i18n/fr.json'

type TranslationKey = keyof typeof messages

interface IBase<T> {
  tag?: keyof ReactHTML | React.FC<T>
  shouldRender?: boolean
  id: TranslationKey | string
  defaultMessage?: string
  values?: any
  children?: React.ReactNode
  wrapTextClass?: string
}

type IDynamicFormattedMessage<T> = IBase<T> &
  {
    [K in keyof T]: T[K]
  }

/**
 * Component used to render an intl message having as a wrapper a given tag
 *
 * @param tag
 * @param shouldRender
 * @param dynamicProps
 * @constructor
 */
function DynamicFormattedMessage<P extends object>({
  tag = 'span',
  shouldRender = true,
  ...dynamicProps
}: IDynamicFormattedMessage<P>) {
  if (!shouldRender) return null

  const {
    defaultMessage,
    id,
    values,
    children,
    wrapTextClass,
    ...parentProps
  } = dynamicProps
  const messageProps = { defaultMessage, id, values: dynamicProps.values }

  return createElement<P>(
    tag,
    parentProps as P,
    <>
      {wrapTextClass && (
        <span className={wrapTextClass}>
          <FormattedMessage {...messageProps} />
        </span>
      )}
      {!wrapTextClass && <FormattedMessage {...messageProps} />}
      {children}
    </>
  )
}

export default DynamicFormattedMessage
