import React, { FC } from 'react'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import { AGENDA } from '../constants/routes'
import { AgendaSection } from '../components/clientAgenda/AgendaSection'
import { useInitiateAppointment } from '../hooks/clientDetails/UseInitiateAppointment'
import { advancedFiltersInitialState } from '../constants/filters'

const Agenda: FC = () => {
  useInitiateAppointment(advancedFiltersInitialState)

  return (
    <section className="pageContentWrapper agendaPage pl75">
      <div className="px3 py3">
        <PageTitleSection pageName={AGENDA} />
        <AgendaSection />
      </div>
    </section>
  )
}

export default Agenda
