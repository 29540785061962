import { ROUTES } from '../enums/routes'
import { API_PATHS } from '../enums/apiPaths'
import { TApiRoutes } from '../interfaces/IRequest'

export const RECLAMATION_AREA = 'reclamationArea'

export const MIXED_FORM_TYPE_RECLAMATION = 'reclamation'
export const MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE = 'destructionCertificate'

export const MIXED_FORM_TYPE_RECLAMATION_PATH = 'reclamations'
export const MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE_PATH =
  'destructionCertificates'

export const MIXED_FORM_CONTRACT_TYPE_ROUTES_CLIENT: {
  [key in
    | typeof MIXED_FORM_TYPE_RECLAMATION
    | typeof MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: string
} = {
  [MIXED_FORM_TYPE_RECLAMATION]: ROUTES.clientReclamations,
  [MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]:
    ROUTES.clientDestructionCertificates,
}

export const MIXED_FORM_CONTRACT_TYPE_ROUTES_GENERAL: {
  [key in
    | typeof MIXED_FORM_TYPE_RECLAMATION
    | typeof MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: string
} = {
  [MIXED_FORM_TYPE_RECLAMATION]: ROUTES.reclamations,
  [MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: ROUTES.destructionCertificates,
}

export const MIXED_FORM_CONTRACT_TYPE_NEW_FORM_ROUTES: {
  [key in
    | typeof MIXED_FORM_TYPE_RECLAMATION
    | typeof MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: string
} = {
  [MIXED_FORM_TYPE_RECLAMATION]: ROUTES.newReclamation,
  [MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: ROUTES.newDestructionCertificate,
}

export const MIXED_FORM_CONTRACT_TYPE_API_PATHS: {
  [key in
    | typeof MIXED_FORM_TYPE_RECLAMATION
    | typeof MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: TApiRoutes
} = {
  [MIXED_FORM_TYPE_RECLAMATION]: API_PATHS.reclamations,
  [MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: API_PATHS.destructionCertificates,
}

export const MIXED_FORM_CONTRACT_TYPE_PATH: {
  [key in
    | typeof MIXED_FORM_TYPE_RECLAMATION
    | typeof MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]:
    | typeof MIXED_FORM_TYPE_RECLAMATION_PATH
    | typeof MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE_PATH
} = {
  [MIXED_FORM_TYPE_RECLAMATION]: MIXED_FORM_TYPE_RECLAMATION_PATH,
  [MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE]: MIXED_FORM_TYPE_DESTRUCTION_CERTIFICATE_PATH,
}
