import React, { FC, useMemo } from 'react'
import { IClientEventsSection } from '../../interfaces/IClientDetails'
import { STATUS } from '../../enums/common'
import Loader from '../loader/Loader'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { ROUTES } from '../../enums/routes'
import { EVENT_SECTION_APPOINTMENTS_COUNT } from '../../constants/visitReport'
import ClientEventsList from './ClientEventsList'

export const ClientEventsSection: FC<IClientEventsSection> = ({
  clientReports,
  clientAppointments,
  clientLastOrder,
  clientId,
  cachedClientAppointments = [],
  cachedClientReports = [],
}) => {
  const extraPadding = `${clientReports.total > 3 ? 'extraPadding' : ''}`
  const { data: clientLastOrderDate } = clientLastOrder

  const isDataLoadPending = useMemo(
    () =>
      clientAppointments.status === STATUS.PENDING ||
      clientReports.status === STATUS.PENDING ||
      clientLastOrder.status === STATUS.PENDING,
    [clientAppointments.status, clientReports.status, clientLastOrder.status]
  )

  const isDataLoadSuccess = useMemo(
    () =>
      clientAppointments.status === STATUS.SUCCESS &&
      clientReports.status === STATUS.SUCCESS &&
      clientLastOrder.status === STATUS.SUCCESS,
    [clientAppointments.status, clientReports.status, clientLastOrder.status]
  )

  return (
    <div className="clientDetailsPageEventsListWrapper hasBorderRadius withBackgroundColorDefault px15 py15">
      <DynamicFormattedMessage
        id="events.section.title.label"
        tag="p"
        className="mb1 clientDetailsPageEventsSectionTitle"
      />
      {isDataLoadSuccess && (
        <div className="clientDetailsPastEvents px15 pt15 pb1 dFlex">
          <DynamicFormattedMessage
            id={`events.section.${
              clientLastOrderDate ? 'past.events' : 'no.data'
            }.label`}
            tag="p"
            className="mr1 mb1"
          />
          {clientLastOrderDate && (
            <span className="fontMedium"> {clientLastOrderDate} </span>
          )}
        </div>
      )}
      <div
        className={`clientDetailsPageEventsContent ${extraPadding} ${
          isDataLoadPending ? 'loading' : ''
        }`}
      >
        {isDataLoadSuccess && (
          <>
            <ClientEventsList
              data={clientAppointments.clientAppointmentsData}
              total={clientAppointments.total}
              clientId={clientId}
              path={ROUTES.viewAppointment}
              cachedData={cachedClientAppointments}
              customClass="futureEvent"
              itemsToBeDisplayed={EVENT_SECTION_APPOINTMENTS_COUNT}
            />
            <ClientEventsList
              data={clientReports.clientReportsData}
              total={clientReports.total}
              clientId={clientId}
              path={ROUTES.viewVisitReport}
              cachedData={cachedClientReports}
            />
            <DynamicFormattedMessage
              shouldRender={
                clientReports.total === 0 && clientAppointments.total === 0
              }
              id="clientDetails.events.missingEvents"
            />
          </>
        )}
        {isDataLoadPending && <Loader />}
      </div>
    </div>
  )
}
