import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUsersOptions } from '../../interfaces/IUsers'

interface IClientContactPersonsReducer {
  [id: string]: IUsersOptions[]
}

const clientContactPersonsReducer = createSlice({
  name: 'clientContactPersonsReducer',
  initialState: {} as IClientContactPersonsReducer,
  reducers: {
    setClientContactPersons: (
      state,
      { payload }: PayloadAction<IClientContactPersonsReducer>
    ) => ({ ...state, ...payload }),
    replaceAllWithClientContactPersons: (
      state,
      { payload }: PayloadAction<IClientContactPersonsReducer>
    ) => ({ ...payload }),
  },
})

export const clientContactPersonsActions = clientContactPersonsReducer.actions

export default clientContactPersonsReducer.reducer
