import React, { FC } from 'react'
import { iconSendEmail } from '../../../assets/images/images'
import Button from './Button'
import { ICustomButton } from '../../../interfaces/ICommonComponents'

export const SendEmailButton: FC<ICustomButton> = ({ disabled, onClick }) => {
  return (
    <Button
      className={`${disabled ? 'btnDisabled' : ''} btn btnSendEmail btnIcon`}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={iconSendEmail} alt="icon email" />
    </Button>
  )
}
