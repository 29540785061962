export const CONTRACT_BRANDS = {
  NUK: 'nuk',
  COMPEED: 'compeed',
  ELLAONE: 'ellaone',
  CICATRIDINE: 'cicatridine',
} as const

export const CONTRACT_FIELD_TYPE = {
  UNITS: 'units',
  ANNEXE: 'annexe',
  REFERENCE: 'reference',
} as const
