import React, { ReactElement, KeyboardEvent, useMemo } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import Button from '../common/ui/Button'
import {
  IAdvancedSearchFormData,
  TModalFilters,
} from '../../interfaces/IFilters'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import TextInput from '../form/TextInput'
import FormGroupDate from '../form/FormGroupDate'
import {
  FILTERS_RADIO_OPTIONS_LIST,
  ON_KEY_PRESS,
  RANGE_LABEL,
  YES_NO_OPTIONS,
} from '../../constants/form'
import { useGeolocation } from '../../hooks/map/UseGeolocation'
import { DYNAMIC_BUTTONS_INPUT_TYPE } from '../../enums/common'
import DynamicButtons from '../form/DynamicButtons'
import {
  ADVANCED_SEARCH_SELECT_INPUT_FIELDS,
  FILTER_BRANDS,
} from '../../constants/filters'
import MultipleSelect from '../form/MultipleSelect'
import Checkbox from '../form/Checkbox'
import { IMultipleSelectOption } from '../../interfaces/IForms'
import {
  currentYearValue,
  getDynamicButtonDefaultValue,
} from '../../utils/helpers'
import { useFiltersHook } from '../../hooks/accountsPortfolio/UseFiltersHook'
import { FILTER_CLIENT, FILTER_CLIENT_YEARS } from '../../constants/dashboard'
import { TDynamicButton } from '../../interfaces/ICommonComponents'

export const FiltersModal = ({
  setFilters,
  changePage,
  filters,
  toggleModal,
  fieldsData,
}: TModalFilters<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>): ReactElement => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
  } = useForm<IAdvancedSearchFormData>({
    mode: 'onChange',
  })

  const { groupIds: groupements, decileBebe } = fieldsData

  const {
    isFocused,
    setIsFocused,
    isDisabled,
    setIsDisabled,
    lastVisitDate,
    setLastVisitDate,
    onSubmit,
    resetFilters,
  } = useFiltersHook(
    { setFilters, filters, toggleModal, changePage, fieldsData },
    () => {
      setValue('groupIds', [])
      setValue('decileBebe', [])
      setLastVisitDate({
        startDate: '',
        endDate: '',
      })
    }
  )

  useGeolocation({ isFocused, setIsDisabled })

  const startDate = useMemo(() => {
    return {
      id: 'lastVisitStart',
      value: lastVisitDate.startDate ? new Date(lastVisitDate.startDate) : '',
      label: 'form.field.lastVisit',
      setValue: setLastVisitDate,
      classes: 'inputField inputDate datepickerLeft',
      maxDate: new Date(),
      control,
      error: errors.lastVisitStart,
    }
  }, [
    lastVisitDate.startDate,
    errors.lastVisitStart,
    control,
    setLastVisitDate,
  ])

  const endDate = useMemo(() => {
    return {
      id: 'lastVisitEnd',
      value: lastVisitDate.endDate ? new Date(lastVisitDate.endDate) : '',
      setValue: setLastVisitDate,
      classes: 'inputField inputDate datepickerRight',
      control,
      error: errors.lastVisitEnd,
    }
  }, [lastVisitDate.endDate, errors.lastVisitEnd, control, setLastVisitDate])

  const triggerSubmit = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ON_KEY_PRESS && !e.shiftKey) {
      handleSubmit(onSubmit)()
    }
  }

  const decileBebeData: IMultipleSelectOption[] = (decileBebe.data as IMultipleSelectOption[]).map(
    (item) => ({ value: item.value, label: item.name })
  )

  return (
    <div onKeyUp={triggerSubmit}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="row modalFiltersForm"
        onReset={() => resetFilters()}
      >
        <div className="px1 mb15 colMd4">
          <TextInput
            name="name"
            id="name"
            register={register({ required: false, maxLength: 80 })}
            error={errors.name}
            placeholder="typeIn"
            defaultValue={filters?.name}
          />
        </div>
        <div className="px1 mb15 colMd5">
          <TextInput
            name="address"
            id="address"
            register={register({ required: false, maxLength: 100 })}
            error={errors.address}
            placeholder="typeIn"
            defaultValue={filters?.address}
          />
        </div>
        <div className="px1 mb15 colMd3">
          <TextInput
            name="zipCode"
            id="zipCode"
            register={register({
              required: false,
              pattern: {
                value: /^[0-9]*$/,
                message: 'number',
              },
              minLength: {
                value: 2,
                message: 'number.minLength',
              },
              maxLength: {
                value: 5,
                message: 'number.maxLength',
              },
              min: {
                value: 0,
                message: 'number',
              },
              max: {
                value: 99999,
                message: 'number.maxLength',
              },
            })}
            error={errors.zipCode}
            placeholder="typeIn"
            defaultValue={filters?.zipCode}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="city"
            id="city"
            register={register({ required: false, maxLength: 80 })}
            error={errors.city}
            placeholder="typeIn"
            defaultValue={filters?.city}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="phoneNumber"
            id="phoneNumber"
            register={register({
              required: false,
              pattern: {
                value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                message: 'phoneNumber',
              },
            })}
            error={errors.phoneNumber}
            placeholder="typeIn"
            defaultValue={filters?.phoneNumber}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <MultipleSelect
            id="groupIds"
            control={control}
            error={errors.groupIds as FieldError | undefined}
            options={groupements.data as IMultipleSelectOption[]}
            defaultValue={filters?.groupIds || []}
            inModalContainer={true}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <FormGroupDate
            startDate={startDate}
            endDate={endDate}
            startDateOnly={true}
            isClearable={true}
          />
        </div>
        <div className="px1 colMd4 relative inputRange">
          <TextInput
            name="range"
            id="range"
            register={register({
              required: false,
              pattern: {
                value: /^[0-9]*$/,
                message: 'number',
              },
            })}
            error={errors.range}
            placeholder="typeIn"
            defaultValue={filters?.range}
            onFocus={() => setIsFocused(true)}
            disabled={isDisabled}
            customLabel={RANGE_LABEL}
            hasInfoLabel
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="cipCode"
            id="cipCode"
            register={register({ required: false, maxLength: 80 })}
            error={errors.cipCode}
            placeholder="typeIn"
            defaultValue={filters?.cipCode}
          />
        </div>
        <div className="px1 colMd4">
          <TextInput
            name="uga"
            id="uga"
            register={register({ required: false, maxLength: 80 })}
            error={errors.uga}
            placeholder="typeIn"
            defaultValue={filters?.uga}
            customClass="mb3"
          />
        </div>
        <div className="px1 mb15 colMd4">
          <MultipleSelect
            id="decileBebe"
            control={control}
            inModalContainer={true}
            options={decileBebeData as IMultipleSelectOption[]}
            error={errors.decileBebe as FieldError | undefined}
            defaultValue={filters?.decileBebe || []}
          />
        </div>
        <div className="row mx0 px0 mb15 colMd12 dFlex">
          {FILTER_BRANDS.map((brand) => (
            <div key={brand} className="colSm12 colMd4">
              <DynamicButtons
                optionsArray={YES_NO_OPTIONS}
                register={register}
                name={`brand${brand}`}
                customClass="mb1"
                error={
                  errors[
                    `brand${brand}` as keyof IAdvancedSearchFormData
                  ] as FieldError
                }
                defaultValue={getDynamicButtonDefaultValue(
                  filters![
                    `brand${brand}` as keyof IAdvancedSearchFormData
                  ] as TDynamicButton
                )}
                inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
                setValue={setValue}
              />
            </div>
          ))}
        </div>
        <div className="px1 mb15 colMd4 colXl4 dFlex noAppointments">
          <Checkbox
            name="noAppointments"
            id="noAppointments"
            register={register}
            defaultValue={filters?.noAppointments}
          />
        </div>
        <div className="row mx0 px0 colMd8 wFull">
          {FILTER_CLIENT_YEARS.map((filterClient, index) => (
            <DynamicButtons
              key={filterClient}
              optionsArray={FILTERS_RADIO_OPTIONS_LIST}
              register={register({ required: false })}
              error={errors[filterClient]}
              name={filterClient}
              defaultValue={getDynamicButtonDefaultValue(
                filters![
                  filterClient as keyof IAdvancedSearchFormData
                ] as TDynamicButton
              )}
              labelName={FILTER_CLIENT}
              labelValues={{ year: currentYearValue - index }}
              customClass="colSm6 px15 mb15"
              inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
              setValue={setValue}
            />
          ))}
        </div>
        <div className="col12 modalFiltersCtaRow px1">
          <div className="modalFiltersCtaWrapper">
            <Button
              onClick={toggleModal}
              type="submit"
              className="btn btnOutline textUppercase"
              data-qa="cancelFilters"
            >
              <DynamicFormattedMessage id="form.button.cancel" />
            </Button>
          </div>
          <div className="modalFiltersCtaWrapper">
            <Button
              type="reset"
              className="btn btnLink mr3"
              data-qa="resetFilters"
            >
              <DynamicFormattedMessage id="form.button.resetFilters" />
            </Button>
            <Button
              type="submit"
              className="btn btnPrimary textUppercase"
              data-qa="submitAdvancedFilters"
            >
              <DynamicFormattedMessage id="form.button.submit" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
