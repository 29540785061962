import React, { FC, useState } from 'react'
import { CONTRACT_COMPEED_RULES_HEADERS } from '../../../constants/tables'
import { iconArrowDown, iconArrowUp } from '../../../assets/images/images'
import Button from '../../common/ui/Button'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { Table } from '../../common/ui/tables/Table'
import { IContractRulesSection } from '../../../interfaces/IContracts'
import { getRowsNumber } from '../../../utils/contracts'

export const CompeedRulesSection: FC<IContractRulesSection> = ({ rules }) => {
  const [displayRules, setDisplayRules] = useState(false)

  return (
    <div className="row">
      <div className="col12">
        <Button
          onClick={() => setDisplayRules(!displayRules)}
          className="btn textPrimary withBackgroundTransparent px0 py0 dFlex alignItemsCenter mb1"
          data-qa="displayRules"
        >
          <DynamicFormattedMessage
            id="form.contract.rules.label"
            className="h4"
          />
          <img
            src={displayRules ? iconArrowUp : iconArrowDown}
            alt="arrow"
            width="18px"
            className="ml1"
          />
        </Button>
      </div>

      {displayRules && (
        <div className="colXl7 compeedRules">
          <Table tableHeaders={CONTRACT_COMPEED_RULES_HEADERS}>
            {rules.map(({ range, ref, percent, value }, index) => {
              return percent ? (
                <tr
                  className="textCenter textPrimary textCustomXs"
                  key={`tr-${index.toString()}`}
                >
                  <th scope="col"> </th>
                  {index % 3 === 0 && (
                    <td rowSpan={getRowsNumber(index, rules)} className="rule">
                      {range?.length && (
                        <DynamicFormattedMessage
                          id="form.contract.range.label"
                          values={{ value1: range[0], value2: range[1] }}
                        />
                      )}
                      {value && (
                        <DynamicFormattedMessage
                          id="form.contract.greaterThan.label"
                          values={{ value }}
                        />
                      )}
                    </td>
                  )}
                  <td className="py05">
                    <DynamicFormattedMessage
                      id="form.contract.ref.label"
                      values={{ ref }}
                    />
                  </td>
                  <td className="py05">
                    <DynamicFormattedMessage
                      id="form.contract.percent.label"
                      values={{ percentValue: percent }}
                    />
                  </td>
                </tr>
              ) : null
            })}
          </Table>
        </div>
      )}
    </div>
  )
}
