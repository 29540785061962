import { useEffect, useState } from 'react'
import { IClientReportsResponseData } from '../../interfaces/IClientDetails'
import { clientReportsDataInitialState } from '../../constants/userData'
import { STATUS } from '../../enums/common'
import { getClientReports } from '../../services/getClientsListService'
import { processClientReports } from '../../utils/data'

/**
 * Used to fetch the client reports.
 */
export const useClientReports = (clientId: string) => {
  const [
    clientReports,
    setClientReports,
  ] = useState<IClientReportsResponseData>({
    clientReportsData: clientReportsDataInitialState,
    status: STATUS.PENDING,
    total: 0,
  })

  useEffect(() => {
    const getClientReport = async () => {
      try {
        const {
          body: { data, total },
        } = await getClientReports(clientId)
        setClientReports((prevState) => {
          return {
            ...prevState,
            clientReportsData: processClientReports(data),
            status: STATUS.SUCCESS,
            total,
          }
        })
      } catch (e) {
        setClientReports((prevState) => {
          return {
            ...prevState,
            status: STATUS.DANGER,
            total: 0,
          }
        })
      }
    }

    getClientReport()
  }, [clientId])

  return { clientReports }
}
