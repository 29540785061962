import React, { FC } from 'react'
import { ITabForm } from '../../../interfaces/IVisitReports'
import {
  VISIT_MISSIONS_V2,
  VISIT_THIRD_PANEL_MISSION_TYPE1_V2,
  VISIT_THIRD_PANEL_MISSION_TYPE2_V2,
  VISIT_THIRD_PANEL_MISSION_TYPE3_V2,
} from '../../../constants/visitReport'
import { VisitReportThirdPanelMission } from './commonComponents/VisitReportThirdPanelMission'

export const VisitReportThirdPanel: FC<ITabForm> = ({ defaultData }) => {
  return (
    <div className="row">
      <VisitReportThirdPanelMission
        mission={VISIT_MISSIONS_V2.type1}
        missionData={VISIT_THIRD_PANEL_MISSION_TYPE1_V2}
        defaultData={defaultData}
        customClass="hasBorderRight"
      />
      <VisitReportThirdPanelMission
        mission={VISIT_MISSIONS_V2.type2}
        missionData={VISIT_THIRD_PANEL_MISSION_TYPE2_V2}
        defaultData={defaultData}
        customClass="hasBorderRight"
      />
      <VisitReportThirdPanelMission
        mission={VISIT_MISSIONS_V2.type3}
        missionData={VISIT_THIRD_PANEL_MISSION_TYPE3_V2}
        defaultData={defaultData}
      />
    </div>
  )
}
