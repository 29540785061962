import React, { FC } from 'react'
import { NotFound } from '../components/common/notFound/NotFound'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'

const ErrorPage: FC = () => (
  <section className="pageContentWrapper errorPage px2 py2">
    <div className="px3 py3">
      <PageTitleSection pageName="errorPage" />
      <NotFound />
    </div>
  </section>
)

export default ErrorPage
