import React, { FC } from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import { ICustomModal } from '../../interfaces/IModal'
import { ConfirmationMessageModal } from './ConfirmationMessageModal'
import ModalContainer from './Modal'

const CustomModal: FC<ICustomModal> = ({
  isModalOpen,
  onBackConfirm = () => {},
}) => (
  <NavigationPrompt
    when={isModalOpen}
    allowGoBack={true}
    afterConfirm={() => onBackConfirm()}
  >
    {({ onConfirm, onCancel }) => (
      <ModalContainer isOpen={true}>
        <ConfirmationMessageModal
          onConfirm={() => onCancel()}
          backButton={() => onConfirm()}
        />
      </ModalContainer>
    )}
  </NavigationPrompt>
)

export default CustomModal
