import React from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { Controller } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import { fr } from 'date-fns/locale'
import { useIntl } from 'react-intl'
import { getDay, setHours, setMinutes } from 'date-fns'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { IDateGroupParams } from '../../interfaces/IForms'
import ErrorMessage from './ErrorMessage'
import { IAppointmentDate } from '../../interfaces/IAppointments'
import {
  DATEPICKER_FORMAT,
  MAX_TIME_HOUR,
  MAX_TIME_MINUTES,
  MIN_TIME_HOUR,
} from '../../enums/common'
import { getTimeIntervals } from '../../utils/helpers'

registerLocale('fr', fr)
setDefaultLocale('fr')

const FormGroupDate = ({
  startDate,
  endDate,
  customClass,
  rawChange = false,
  startDateOnly,
  allowWeekend,
  isClearable = false,
  setPortalId = false,
}: IDateGroupParams) => {
  const intl = useIntl()
  const { setValue } = startDate
  const filterPassedTime = (time: number) =>
    new Date().getTime() < new Date(time).getTime()
  const timeCaption = intl.formatMessage({
    id: `datepicker.timeCaption.label`,
  })

  const isWeekday = (date: Date) => getDay(date) !== 0 && getDay(date) !== 6

  return (
    <div className="datePickerGroupWrapper alignItemsEnd dFlex row">
      <div
        className={`${
          !startDateOnly ? 'col6' : 'col12'
        } dFlex datePickerElement flexColumn ${
          isClearable ? 'isClearable' : ''
        } ${!startDate.value ? 'withoutValue' : ''} ${customClass || ''}`}
      >
        {startDate.label && (
          <DynamicFormattedMessage
            id={startDate.label}
            className="inputLabel wFull"
          />
        )}
        <Controller
          name={startDate.id}
          control={startDate.control}
          defaultValue={startDate.value}
          rules={startDate.rules}
          render={({ onChange }) => (
            <DatePicker
              portalId={setPortalId ? 'root-portal' : ''}
              locale="fr"
              selected={startDate.value}
              value={startDate.value}
              className={startDate.classes}
              selectsStart
              startDate={startDate.value}
              endDate={endDate?.value || null}
              maxDate={startDate.maxDate || endDate?.value || null}
              filterTime={startDate.filterTime ? filterPassedTime : null}
              minDate={startDate.minDate || null}
              dateFormat={startDate.format || DATEPICKER_FORMAT}
              data-qa={startDate.id}
              timeCaption={timeCaption}
              showTimeSelect={startDate.displayTime || false}
              onChange={(value: Date) => {
                onChange(value)
                setValue((prevState: IAppointmentDate) => {
                  return {
                    ...prevState,
                    startDate: value,
                  }
                })
              }}
              filterDate={allowWeekend ? () => allowWeekend : isWeekday}
              minTime={setHours(0, MIN_TIME_HOUR)}
              maxTime={setHours(
                setMinutes(new Date(), MAX_TIME_MINUTES),
                MAX_TIME_HOUR
              )}
              onChangeRaw={(e: Event) =>
                rawChange ? () => {} : e.preventDefault()
              }
              timeIntervals={getTimeIntervals(
                startDate.displayTime,
                startDate.timeIntervals
              )}
              // !!! For isClearable prop to display clear icon after clear is done we should have a fallback for startDate.value an empty string.
              // !!! The datepicker module default behavior is to remove clear icon after clear is done (https://reactdatepicker.com/#example-clear-datepicker-input).
              isClearable={isClearable}
            />
          )}
        />
        <ErrorMessage error={startDate.error} />
      </div>
      {!startDateOnly && (
        <div
          className={`col6 dFlex datePickerElement flexColumn  ${
            isClearable ? 'isClearable' : ''
          } ${!endDate.value ? 'withoutValue' : ''} ${customClass || ''}`}
        >
          {endDate.label && (
            <DynamicFormattedMessage
              id={endDate.label}
              className="inputLabel wFull"
            />
          )}
          <Controller
            name={endDate.id}
            control={endDate.control}
            defaultValue={endDate.value}
            rules={endDate.rules}
            render={({ onChange }) => (
              <DatePicker
                locale="fr"
                selected={endDate.value}
                value={endDate.value}
                className={endDate.classes}
                selectsEnd
                startDate={startDate.value}
                endDate={endDate.value}
                minDate={endDate.minDate || startDate.value}
                dateFormat={startDate.format || DATEPICKER_FORMAT}
                data-qa={endDate.id}
                timeCaption={timeCaption}
                showTimeSelect={endDate.displayTime || false}
                filterTime={endDate.filterTime ? filterPassedTime : null}
                onChange={(value: Date) => {
                  onChange(value)
                  setValue((prevState: IAppointmentDate) => {
                    return {
                      ...prevState,
                      endDate: value,
                    }
                  })
                }}
                onChangeRaw={(e: Event) => e.preventDefault()}
                timeIntervals={getTimeIntervals(
                  endDate.displayTime,
                  endDate.timeIntervals
                )}
                // !!! For isClearable prop to display clear icon after clear is done we should have a fallback for endDate.value an empty string.
                // !!! The datepicker module default behavior is to remove clear icon after clear is done (https://reactdatepicker.com/#example-clear-datepicker-input).
                isClearable={isClearable}
              />
            )}
          />
          <ErrorMessage error={endDate.error} />
        </div>
      )}
    </div>
  )
}

export default FormGroupDate
