import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import {
  IFilters,
  ISimpleSearchFormData,
  IPageFiltersSection,
} from '../interfaces/IFilters'
import { clientsFiltersActions } from '../store/reducers/filtersReducer'
import { convertFiltersDatetime } from '../utils/data'
import { FIELD_SEARCH } from '../constants/form'
import { advancedFiltersInitialState } from '../constants/filters'
import { useUrlParamsHook } from './UseUrlParamsHook'
import { INITIAL_ACTIVE_PAGE } from '../enums/common'

const UseSimpleSearchFormHook = (props: IPageFiltersSection) => {
  const dispatch = useDispatch()
  const {
    setFilters,
    filters,
    changePage,
    fieldToWatch = 'search',
    isModalOpen,
  } = props
  const form = useForm<ISimpleSearchFormData>()
  const { register, watch, handleSubmit, errors, formState } = form
  const watchField = watch(fieldToWatch)
  const { isSubmitSuccessful } = formState
  const { search } = useLocation()

  const { setUrlQueryParams } = useUrlParamsHook()

  const isURLQuerySearchParamSet = useCallback(
    (simpleSearchText: string) => {
      const urlParams: URLSearchParams = new URLSearchParams(search)
      if (!urlParams.has(FIELD_SEARCH)) return false

      const param = urlParams.get(FIELD_SEARCH)

      return param === simpleSearchText
    },
    [search]
  )

  useEffect(() => {
    if ((!watchField && !isSubmitSuccessful) || isModalOpen) {
      return
    }

    const timer = setTimeout(() => {
      handleSubmit((data) => {
        // for duplicate page cases, when we already have the search field filter set in url and loaded from there
        // do not trigger another search when search field value is set
        // otherwise page will be set to INITIAL_ACTIVE_PAGE
        if (
          filters![FIELD_SEARCH] === data[FIELD_SEARCH] ||
          isURLQuerySearchParamSet(data[FIELD_SEARCH])
        )
          return

        setFilters((prevState: IFilters) => {
          if (changePage) {
            changePage(INITIAL_ACTIVE_PAGE)
          }

          if (!data[fieldToWatch] && fieldToWatch === FIELD_SEARCH) {
            dispatch(
              clientsFiltersActions.setFilters(
                convertFiltersDatetime({
                  ...prevState,
                  ...advancedFiltersInitialState,
                })
              )
            )

            return advancedFiltersInitialState
          }

          if (fieldToWatch === FIELD_SEARCH) {
            dispatch(
              clientsFiltersActions.setFilters(
                convertFiltersDatetime({
                  ...prevState,
                  ...advancedFiltersInitialState,
                  [fieldToWatch]: data[fieldToWatch],
                })
              )
            )

            return {
              ...advancedFiltersInitialState,
              [fieldToWatch]: data[fieldToWatch],
            }
          }

          dispatch(
            clientsFiltersActions.setFilters(
              convertFiltersDatetime({
                ...prevState,
                ...advancedFiltersInitialState,
                [fieldToWatch]: data[fieldToWatch],
              })
            )
          )

          return {
            ...prevState,
            ...advancedFiltersInitialState,
            [fieldToWatch]: data[fieldToWatch],
          }
        })

        setUrlQueryParams({
          ...advancedFiltersInitialState,
          [fieldToWatch]: data[fieldToWatch],
        })
      })()
    }, 500)

    return () => clearTimeout(timer)
  }, [watchField, handleSubmit, setFilters, fieldToWatch, isURLQuerySearchParamSet]) //eslint-disable-line

  return { register, errors }
}

export default UseSimpleSearchFormHook
