import { IRequest } from '../interfaces/IRequest'
import { api } from '../utils/api'
import {
  IEngagementHistory,
  IOrderHistory,
  IRecapHistory,
} from '../interfaces/IHistory'
import {
  prepareEngagementHistoryResponse,
  prepareOrderHistoryResponse,
} from '../utils/history'
import { API_PATHS } from '../enums/apiPaths'

export const getClientEngagementsHistory = async (
  clientId: number
): Promise<IEngagementHistory[]> => {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.engagementsHistory}`,
  }

  const {
    body: { data },
  } = await api(params)

  return prepareEngagementHistoryResponse(data)
}

export const getClientOrderHistory = async (
  clientId: number
): Promise<IOrderHistory[]> => {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.ordersHistory}`,
  }

  const {
    body: { data },
  } = await api(params)

  return prepareOrderHistoryResponse(data)
}

export const getClientRecapHistory = async (
  clientId: number,
  view: string
): Promise<IRecapHistory[]> => {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.recap}?view=${view}`,
  }

  const {
    body: { categories },
  } = await api(params)

  return categories
}
