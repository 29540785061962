import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { STATUS } from '../enums/common'
import {
  IAppointmentFormParams,
  TNewAppointmentResponse,
} from '../interfaces/IAppointments'
import { prepareAppointmentRequest } from '../utils/appointments'
import {
  createNewAppointment,
  editAppointment,
} from '../services/appointmentsService'
import { ROUTES } from '../enums/routes'
import { statusNetworkReducerActions } from '../store/reducers/statusNetworkReducer'
import { statusNetworkSelector } from '../store/selectors'
import { handleError } from '../utils/api'
import { getOverlappingIntlValues } from '../utils/helpers'

export const useNewAppointmentFormHook = (
  appointmentId: number,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  const intl = useIntl()
  const form = useForm()
  const history = useHistory()
  const [formState, setFormState] = useState<TNewAppointmentResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const onSubmit = async (values: any) => {
    setIsModalOpen(false)
    const body: IAppointmentFormParams = prepareAppointmentRequest(
      values,
      appointmentId
    )

    setFormState({ status: STATUS.PENDING, message: '' })
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      appointmentId
        ? await editAppointment(body, appointmentId)
        : await createNewAppointment(body)
      setFormState({ status: STATUS.SUCCESS, message: '' })
      history.push(ROUTES.agenda, { date: values.startDate })
    } catch (e) {
      if (!hasNetwork) {
        dispatch(statusNetworkReducerActions.submitedForm())
        return history.push(ROUTES.agenda, { date: values.startDate })
      }

      const intlValues = getOverlappingIntlValues(
        values.startDate,
        values.duration
      )
      const handledError = handleError(e, intl, intlValues)
      setFormState(handledError)
    }
  }

  return { form, onSubmit, formState }
}
