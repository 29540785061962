import { useCallback, useState } from 'react'
import set from 'lodash.set'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { prepareFieldSelectOptions } from '../utils/filters'
import {
  IFilterFieldsType,
  IFilterMappingType,
  IFilters,
  IFiltersSelectFieldsData,
  IFilterValueType,
  ISelectOption,
} from '../interfaces/IFilters'
import {
  ADVANCED_SEARCH_DATE_FIELDS,
  ADVANCED_SEARCH_INPUT_FIELDS,
  ADVANCED_SEARCH_QUERY_MAPPING,
  ADVANCED_SEARCH_SELECT_INPUT_FIELDS,
  ADVANCED_SEARCH_SIMPLE_SELECT_INPUT_FIELDS,
  advancedFiltersInitialState,
  PAGE_PARAM,
} from '../constants/filters'
import { INITIAL_ACTIVE_PAGE } from '../enums/common'
import { getSimpleRoute, isObjectEmpty } from '../utils/helpers'

export const useUrlParamsHook = (
  storeSelectedPage: number = INITIAL_ACTIVE_PAGE
) => {
  const location = useLocation()
  const history = useHistory()
  const [selectedPage] = useState(storeSelectedPage)

  const prepareURlParamObjectForSelectInput = <T>(
    mapper: IFilterMappingType<T>,
    field: keyof T,
    value: ISelectOption[],
    key: string = 'value'
  ) => {
    const filter = value.map((item: ISelectOption) => item[key])
    return set({} as IFilterMappingType<T>, mapper[field], filter.join(','))
  }

  const prepareURLParamObjectForTextValue = <T>(
    mapper: IFilterMappingType<T>,
    field: keyof T,
    value: string | boolean
  ) => {
    return set({} as IFilterMappingType<T>, mapper[field], value)
  }

  const isSelectField = useCallback(
    (fieldKey: string) =>
      // @ts-ignore
      Object.hasOwn(ADVANCED_SEARCH_SELECT_INPUT_FIELDS, fieldKey),
    []
  )

  const isSimpleSelectField = useCallback(
    (fieldKey: string) =>
      // @ts-ignore
      Object.hasOwn(ADVANCED_SEARCH_SIMPLE_SELECT_INPUT_FIELDS, fieldKey),
    []
  )

  const isDatetimeField = useCallback(
    (fieldKey: string) =>
      // @ts-ignore
      Object.hasOwn(ADVANCED_SEARCH_DATE_FIELDS, fieldKey),
    []
  )

  const isValueEmpty = useCallback((value) => {
    return (
      !value ||
      (value &&
        typeof value === 'object' &&
        !Array.isArray(value) &&
        isObjectEmpty(value)) ||
      (value &&
        typeof value === 'object' &&
        Array.isArray(value) &&
        !value.length)
    )
  }, [])

  const prepareUrlQueryFromFilters = useCallback(
    <T>(values: T) => {
      if (!values) return

      const params: string[] = []
      Object.entries(values).forEach(([key, value]) => {
        if (isValueEmpty(value)) return

        let obj
        if (typeof value === 'object') {
          if (isSelectField(key)) {
            obj = prepareURlParamObjectForSelectInput<
              typeof ADVANCED_SEARCH_QUERY_MAPPING
            >(
              ADVANCED_SEARCH_QUERY_MAPPING,
              key as keyof typeof ADVANCED_SEARCH_QUERY_MAPPING,
              value as ISelectOption[]
            )
          } else if (
            isDatetimeField(key) &&
            Object.prototype.toString.call(value) === '[object Date]'
          ) {
            obj = prepareURLParamObjectForTextValue<
              typeof ADVANCED_SEARCH_QUERY_MAPPING
            >(
              ADVANCED_SEARCH_QUERY_MAPPING,
              key as keyof typeof ADVANCED_SEARCH_QUERY_MAPPING,
              value.toString() as string
            )
          } else if (value.length === 1) {
            // this condition is for the dynamic buttons check
            obj = prepareURLParamObjectForTextValue<
              typeof ADVANCED_SEARCH_QUERY_MAPPING
            >(
              ADVANCED_SEARCH_QUERY_MAPPING,
              key as keyof typeof ADVANCED_SEARCH_QUERY_MAPPING,
              value[0] as string
            )
          }
        } else if (typeof value === 'string') {
          obj = prepareURLParamObjectForTextValue<
            typeof ADVANCED_SEARCH_QUERY_MAPPING
          >(
            ADVANCED_SEARCH_QUERY_MAPPING,
            key as keyof typeof ADVANCED_SEARCH_QUERY_MAPPING,
            value as string
          )
        } else if (typeof value === 'boolean') {
          obj = prepareURLParamObjectForTextValue<
            typeof ADVANCED_SEARCH_QUERY_MAPPING
          >(
            ADVANCED_SEARCH_QUERY_MAPPING,
            key as keyof typeof ADVANCED_SEARCH_QUERY_MAPPING,
            value as boolean
          )
        }

        if (!obj) return
        params.push(new URLSearchParams(obj).toString())
      })

      return params.join('&')
    },
    [isSelectField, isValueEmpty, isDatetimeField]
  )

  const loadFiltersFromURlQuery = useCallback(
    (
      selectFieldsData: IFiltersSelectFieldsData<
        typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS
      >
    ): { filters: {}; page: number } => {
      const searchParams: URLSearchParams = new URLSearchParams(location.search)
      const filters: IFilterFieldsType<IFilters> = {
        ...advancedFiltersInitialState,
      }
      let urlPage = selectedPage
      if (!searchParams) return { filters: {}, page: selectedPage }
      searchParams.forEach((value, key) => {
        const filterKey =
          ADVANCED_SEARCH_QUERY_MAPPING[
            key as keyof typeof ADVANCED_SEARCH_QUERY_MAPPING
          ]
        // set current page set in url
        if (filterKey === PAGE_PARAM) {
          urlPage = +value
          return
        }

        if (isSelectField(filterKey)) {
          // @todo check this is ok
          filters[
            filterKey as keyof typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS
          ] = isSimpleSelectField(filterKey)
            ? value
            : (prepareFieldSelectOptions(
                selectFieldsData[
                  filterKey as keyof typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS
                ].data,
                value
              ) as IFilterValueType)
        } else {
          filters[
            filterKey as keyof typeof ADVANCED_SEARCH_INPUT_FIELDS
          ] = value
        }
      })

      return { filters: { ...filters }, page: urlPage }
    },
    [location.search, selectedPage, isSelectField, isSimpleSelectField]
  )

  const setUrlQueryParams = (
    values: IFilters,
    includePageNo: boolean = true
  ) => {
    const urlParams = prepareUrlQueryFromFilters<IFilters>(values)
    const search = includePageNo
      ? `?page=${selectedPage}${urlParams ? `&${urlParams}` : ''}`
      : `?${urlParams ? `${urlParams}` : ''}`
    history.push({
      pathname: location.pathname,
      search,
    })
  }

  const prepareUrl = (
    values: IFilters,
    pathname: string,
    currentPage: number = selectedPage,
    includePageNo: boolean = true
  ): string => {
    const urlParams = prepareUrlQueryFromFilters<IFilters>(values)

    const search = includePageNo
      ? `page=${currentPage}${urlParams ? `&${urlParams}` : ''}`
      : `${urlParams ? `&${urlParams}` : ''}`

    return generatePath(getSimpleRoute(pathname, search))
  }

  return {
    prepareUrlQueryFromFilters,
    loadFiltersFromURlQuery,
    setUrlQueryParams,
    selectedPage,
    prepareUrl,
  }
}
