import React, { FC, useState } from 'react'
import { IForthTab } from '../../../interfaces/IVisitReports'
import { useUserMedia } from '../../../hooks/UseUserMedia'
import { VisitReportForthPanelMission } from './commonComponents/VisitReportForthPanelMission'
import { UserMediaSection } from '../../common/ui/UserMediaSection'

export const VisitReportForthPanel: FC<IForthTab> = ({
  defaultData,
  images,
  updateForm,
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const camera = useUserMedia()

  return (
    <>
      <UserMediaSection
        camera={camera}
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
      <VisitReportForthPanelMission
        camera={camera}
        defaultData={defaultData}
        images={images}
        isCameraOpen={isCameraOpen}
        updateForm={updateForm}
      />
    </>
  )
}
