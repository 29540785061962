import React, { FC } from 'react'
import { CustomTabs } from '../../../common/ui/CustomTabs'
import { ITabProps } from '../../../../interfaces/ITabs'
import { useIsTabDisabled } from '../../../../hooks/clientVisitReport/UseIsTabDisabled'

export const CustomTabsForm: FC<ITabProps> = ({
  tabList,
  children,
  activeTab,
  onClick,
  setActiveTab,
}) => {
  const isDisabled = useIsTabDisabled()

  return (
    <CustomTabs
      tabList={tabList}
      activeTab={activeTab}
      onClick={onClick}
      setActiveTab={setActiveTab}
      isDisabled={isDisabled}
    >
      {children}
    </CustomTabs>
  )
}
