import React, { FC, useCallback, useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { getSelectedBlocksType } from 'draftjs-utils'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { IWysiwygEditor } from '../../interfaces/IForms'
import { ON_KEY_PRESS } from '../../constants/form'

const WysiwygEditor: FC<IWysiwygEditor> = ({
  onChange,
  defaultValue,
  setInputPlainText,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = (editorSt: any) => {
    setEditorState(editorSt)
    setInputPlainText(editorSt.getCurrentContent().getPlainText())

    if (!editorState.getCurrentContent().hasText()) {
      return onChange('-')
    }

    return onChange(draftToHtml(convertToRaw(editorSt.getCurrentContent())))
  }

  const getEditorValue = useCallback(() => {
    if (defaultValue && defaultValue !== '-') {
      const contentBlock = htmlToDraft(defaultValue)
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [defaultValue])

  const handleReturn = (e: globalThis.KeyboardEvent) => {
    // override behavior for enter key
    let newEditorState = null
    const blockType = getSelectedBlocksType(editorState)

    if (e.key === ON_KEY_PRESS && blockType === 'unstyled') {
      newEditorState = RichUtils.insertSoftNewline(editorState)
    }

    if (newEditorState) setEditorState(newEditorState)
    return !!newEditorState
  }

  useEffect(() => {
    getEditorValue()
  }, [getEditorValue])

  useEffect(() => {
    setInputPlainText(editorState.getCurrentContent().getPlainText())
  }, [editorState, setInputPlainText])

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName="editorToolbar"
        wrapperClassName="editorWrapper"
        editorClassName="editorMain px1"
        onEditorStateChange={onEditorStateChange}
        editorStyle={{ minHeight: '200px' }}
        handleReturn={handleReturn}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'colorPicker',
            'remove',
            'history',
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          colorPicker: {
            colors: [
              'rgb(0,72,80)', // project $primaryColor
              'rgb(41,115,128)', // project $secondary
              'rgb(15,215,195)', // project $secondaryColorLight
              'rgb(176,54,97)', // project $dangerColor
              'rgb(204,50,98)', // project $customColor1
              'rgb(86,48,170)', // project $customColor3
              'rgb(97,189,109)',
              'rgb(26,188,156)',
              'rgb(84,172,210)',
              'rgb(44,130,201)',
              'rgb(147,101,184)',
              'rgb(71,85,119)',
              'rgb(204,204,204)',
              'rgb(65,168,95)',
              'rgb(0,168,133)',
              'rgb(61,142,185)',
              'rgb(41,105,176)',
              'rgb(85,57,130)',
              'rgb(40,50,78)',
              'rgb(0,0,0)',
              'rgb(247,218,100)',
              'rgb(251,160,38)',
              'rgb(235,107,86)',
              'rgb(226,80,65)',
              'rgb(163,143,132)',
              'rgb(239,239,239)',
              'rgb(255,255,255)',
              'rgb(250,197,28)',
              'rgb(243,121,52)',
              'rgb(209,72,65)',
              'rgb(184,49,47)',
              'rgb(124,112,107)',
              'rgb(209,213,216)',
            ],
          },
          fontSize: {
            options: [10, 11, 12, 14, 16, 18, 24, 30],
          },
          blockType: {
            options: [
              'Normal',
              'H1',
              'H2',
              'H3',
              'H4',
              'H5',
              'H6',
              'Blockquote',
            ],
          },
        }}
      />
    </>
  )
}

export default WysiwygEditor
