import React, { FC } from 'react'
import { useClientsDetails } from '../../../hooks/clientDetails/UseClientDetails'
import ClientInfo from './ClientInfo'

const PageTitleClientDetailsSection: FC<{ clientId: string }> = ({
  clientId,
}) => {
  const { name, cipCode } = useClientsDetails(clientId)?.clientDetailsData

  return <ClientInfo name={name} code={cipCode} />
}

export default PageTitleClientDetailsSection
