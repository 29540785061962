import React, { FC } from 'react'
import { IUserActivity } from '../../../../interfaces/IUsers'
import { processUserActivityData } from '../../../../utils/user'
import DynamicFormattedMessage from '../DynamicFormattedMessage'

export const MonthlyTableContent: FC<IUserActivity> = (data) => {
  const { activity } = data
  const activityData = processUserActivityData(activity)

  return (
    <>
      {activityData?.length &&
        activityData.map((el: number[], index: number) => (
          <tr key={`tr-${index.toString()}`} className="textPrimary">
            <th scope="row" className="textXs fontMedium textLeft">
              <DynamicFormattedMessage
                id={`dashboard.${
                  index === 0 ? 'visitReports' : 'appointments'
                }.label`}
              />
            </th>
            {el.map((value: number, i: number) => (
              <td key={`td-${i.toString()}`} className="textCenter">
                {value}
              </td>
            ))}
          </tr>
        ))}
    </>
  )
}
