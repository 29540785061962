import { useState } from 'react'
import {
  GEOLOCATION,
  PERMISSION_DENIED,
  PERMISSION_GRANTED,
  PERMISSION_PROMPT,
} from '../enums/common'
import {
  getGeolocationCurrentPosition,
  setGeolocationToLocalStorage,
} from '../utils/filters'

export const useGeolocationPermissions = () => {
  const [locationPermissionGranted, setLocationPermissionGranted] = useState(
    false
  )

  const handlePermissions = () => {
    if (!navigator.permissions || !navigator.permissions.query) return

    navigator.permissions
      .query({ name: GEOLOCATION })
      .then((result) => {
        switch (result.state) {
          case PERMISSION_GRANTED:
            setLocationPermissionGranted(true)
            break
          case PERMISSION_DENIED:
            setLocationPermissionGranted(false)
            break
          case PERMISSION_PROMPT:
            setLocationPermissionGranted(false)
            getGeolocationCurrentPosition(setGeolocationToLocalStorage)
            break
          default:
            break
        }

        result.addEventListener('change', () => {
          if (result.state === PERMISSION_DENIED) {
            setLocationPermissionGranted(false)

            return
          }

          if (result.state === PERMISSION_PROMPT) {
            setLocationPermissionGranted(false)

            return
          }

          setLocationPermissionGranted(true)
        })
      })
      .catch(() => {
        setLocationPermissionGranted(false)
      })
  }

  return {
    locationPermissionGranted,
    handlePermissions,
  }
}
