import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../enums/routes'
import { notFound } from '../../../assets/images/images'
import DynamicFormattedMessage from '../ui/DynamicFormattedMessage'

export const NotFound = () => (
  <div>
    <div className="textPrimary textCenter mb3">
      <DynamicFormattedMessage id="notFound.message" tag="h2" />
      <>
        <DynamicFormattedMessage
          id="notFound.linkMessage"
          tag="h2"
          className="textMd dInlineBlock"
        />
        <Link
          to={ROUTES.dashboard}
          className="linkDefault textMd fontMedium ml05"
        >
          <DynamicFormattedMessage id="notFound.link" />
        </Link>
      </>
      <DynamicFormattedMessage id="notFound.errorCode" tag="p" />
    </div>
    <div className="row flexNowrap mx0">
      <div className="mb2 mr2 textCustomSm">
        <DynamicFormattedMessage id="notFound.text1" tag="p" className="mb2" />
        <DynamicFormattedMessage id="notFound.text2" tag="p" />
      </div>
      <div>
        <img src={notFound} alt="404 not-found" />
      </div>
    </div>
  </div>
)
