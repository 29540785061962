import React, { FC } from 'react'
import { IChartPerTypeArea } from '../../../interfaces/IDashboard'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { ChartSection } from './ChartSection'
import Loader from '../../loader/Loader'
import { STATUS } from '../../../enums/common'
import { ChartPlaceholder } from '../common/ChartPlaceholder'

export const ChartPerTypeArea: FC<IChartPerTypeArea> = ({
  chartDataResponsePerType,
  selectedUserName,
}) => {
  return (
    <section className="chartSection withBackgroundColorDefault hasBorderRadius py15 px15 textSecondary">
      <DynamicFormattedMessage
        id="dashboard.visitsCoverage.type.section"
        tag="h2"
        className="dInlineBlock textCustomSm textUppercase mb15 pb05 borderBottom02SecondaryLight"
      />
      {chartDataResponsePerType?.length ? (
        <div className="row">
          {chartDataResponsePerType.map((chartDataType) => {
            const targetType = Object.keys(chartDataType)[0]
            const chartDataResponse = chartDataType[targetType]

            return chartDataResponse?.status !== STATUS.PENDING ? (
              <ChartSection
                key={targetType}
                chartDataResponse={chartDataResponse}
                selectedUserName={selectedUserName}
                targetType={targetType}
                customClass="col12 colMd6 colLg4"
              />
            ) : (
              <Loader />
            )
          })}
        </div>
      ) : (
        <ChartPlaceholder />
      )}
    </section>
  )
}
