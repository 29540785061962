import React, { FC } from 'react'
import { IPeriodTableProps } from '../../../interfaces/ITables'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { ANNUAL_RECAP_TABLE_HEADER } from '../../../constants/tables'
import {
  currentBusinessYearStartingValue,
  currentDate,
  currentYearValue,
} from '../../../utils/helpers'

export const CustomPeriodTable: FC<IPeriodTableProps> = ({
  tableHeaders,
  colSpan,
  subHeaderList,
  children,
}) => {
  if (tableHeaders === ANNUAL_RECAP_TABLE_HEADER) {
    return <></>
  }

  return (
    <table className="customTable customTableQuarter wFull">
      <thead className="customTableQuarterHeader">
        <tr>
          <th
            rowSpan={2}
            className="fontBold textUppercase textPrimary textCustomXs textLeft"
          >
            <DynamicFormattedMessage id="table.header.range.label" />
            <DynamicFormattedMessage
              id="table.header.product.label"
              className="textItalic mt05 dFlex"
            />
          </th>
          {tableHeaders.map((headerName, index) => (
            <th
              key={`${headerName}-${index + 1}`}
              colSpan={colSpan}
              className="fontBold textUppercase textPrimary textCustomXs"
            >
              <DynamicFormattedMessage
                id={`table.header.${headerName}.label`}
                className="fontBold textUppercase textPrimary textCustomXs"
                values={{
                  year:
                    currentDate >= currentBusinessYearStartingValue
                      ? currentYearValue + 1
                      : currentYearValue,
                  previousYear:
                    currentDate >= currentBusinessYearStartingValue
                      ? currentYearValue
                      : currentYearValue - 1,
                }}
              />
            </th>
          ))}
        </tr>
        <tr className="customTableSubHeader fontBold textUppercase textPrimary textCustomXs">
          {subHeaderList.map((subHeader, index) => (
            <th key={`${subHeader}-${index + 1}`}>{subHeader}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  )
}
