import { IRequest } from '../interfaces/IRequest'
import { api, getAuthHeader } from '../utils/api'
import { API_PATHS } from '../enums/apiPaths'

export const setClientComment = (clientId: string, clientComment: string) => {
  const comment = { commentKam: clientComment }
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}`,
    method: 'PATCH',
    headers: getAuthHeader(),
    body: JSON.stringify(comment),
  }

  return api(params)
}
