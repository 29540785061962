import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { IDestructionCertificateProductRow } from '../../../../interfaces/IContracts'
import TextInput from '../../../form/TextInput'
import {
  PRODUCT_TABLE_COMMENT_MAX_LENGTH,
  PRODUCT_TABLE_INPUT_MAX_LENGTH,
} from '../../../../constants/destructionCertificate'
import Button from '../../../common/ui/Button'
import FormGroupDate from '../../../form/FormGroupDate'
import { DATEPICKER_FORMAT } from '../../../../enums/common'

const ProductsTableRow: FC<IDestructionCertificateProductRow> = ({
  index,
  updateProductsList,
  deleteFormRow,
}) => {
  const intl = useIntl()
  const {
    register,
    errors,
    control,
    getValues,
    trigger,
    setValue,
    clearErrors,
  } = useFormContext()

  const [expirationDate, setExpirationDate] = useState({
    startDate: new Date(),
  })

  const expirationDateInit = {
    id: 'expirationDate',
    label: false,
    value: expirationDate.startDate,
    setValue: setExpirationDate,
    classes: 'inputField inputDate datepickerLeft',
    maxDate: new Date(),
    error: errors.expirationDate,
    displayTime: false,
    format: DATEPICKER_FORMAT,
    control,
  }

  const onProductSave = async () => {
    const isValidProductData = await trigger([
      `newProduct[${index}].index`,
      `newProduct[${index}].cipCode`,
      `newProduct[${index}].designation`,
      `newProduct[${index}].quantity`,
      `newProduct[${index}].lot`,
      `newProduct[${index}].comment`,
    ])

    if (isValidProductData) {
      const { newProduct } = getValues()
      newProduct[index].expirationDate = expirationDate.startDate.toString()
      updateProductsList(newProduct[index], index)
      setValue(`newProduct[${index}]`, {
        cipCode: '',
        designation: '',
        quantity: '',
        lot: '',
        comment: '',
      })
      clearErrors('formProductsCount')
    }
  }

  return (
    <tr className="newProductRow" key={`newProductRow${index.toString()}`}>
      <td key={`newProductRow${index.toString()}Index`}>&nbsp;</td>
      <td key={`newProductRow${index.toString()}CipCode`}>
        <input
          type="hidden"
          name={`newProduct[${index}].index`}
          id="index"
          value={index}
          ref={register}
        />
        <TextInput
          name={`newProduct[${index}].cipCode`}
          id="cipCode"
          customClass=""
          register={register({
            required: true,
            maxLength: PRODUCT_TABLE_INPUT_MAX_LENGTH,
          })}
          error={errors.newProduct && errors.newProduct[index]?.cipCode}
          hasLabel={false}
        />
      </td>
      <td key={`newProductRow${index.toString()}Designation`}>
        <TextInput
          name={`newProduct[${index}].designation`}
          id="designation"
          customClass=""
          register={register({
            required: true,
            maxLength: PRODUCT_TABLE_INPUT_MAX_LENGTH,
          })}
          error={errors.newProduct && errors.newProduct[index]?.designation}
          hasLabel={false}
        />
      </td>
      <td key={`newProductRow${index.toString()}Quantity`}>
        <TextInput
          name={`newProduct[${index}].quantity`}
          id="quantity"
          customClass=""
          register={register({
            required: true,
            maxLength: PRODUCT_TABLE_INPUT_MAX_LENGTH,
            pattern: {
              value: /^[0-9]*$/,
              message: 'number',
            },
          })}
          error={errors.newProduct && errors.newProduct[index]?.quantity}
          hasLabel={false}
        />
      </td>
      <td key={`newProductRow${index.toString()}Lot`}>
        <TextInput
          name={`newProduct[${index}].lot`}
          id="lot"
          customClass=""
          register={register({
            required: true,
            maxLength: PRODUCT_TABLE_INPUT_MAX_LENGTH,
            pattern: {
              value: /^[0-9]*$/,
              message: 'number',
            },
          })}
          error={errors.newProduct && errors.newProduct[index]?.lot}
          hasLabel={false}
        />
      </td>
      <td key={`newProductRow${index.toString()}ExpirationDate`}>
        <FormGroupDate
          startDate={expirationDateInit}
          startDateOnly={true}
          allowWeekend={true}
          setPortalId={true}
        />
      </td>
      <td key={`newProductRow${index.toString()}Comment`}>
        <TextInput
          name={`newProduct[${index}].comment`}
          id="comment"
          customClass=""
          register={register({
            required: true,
            maxLength: PRODUCT_TABLE_COMMENT_MAX_LENGTH,
          })}
          error={errors.newProduct && errors.newProduct[index]?.comment}
          hasLabel={false}
        />
      </td>
      <td
        className="dFlex actions"
        key={`newProductRow${index.toString()}Actions`}
      >
        <Button className="btn btnPrimary" onClick={onProductSave}>
          {intl.formatMessage({
            id:
              'form.destructionCertificate.products.table.cta.addProduct.label',
          })}
        </Button>
        <Button
          className="btn btnDelete px2 py2 ml05"
          onClick={() => deleteFormRow(index)}
        />
      </td>
    </tr>
  )
}

export default ProductsTableRow
