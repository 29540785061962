import messages from '../../i18n/fr.json'
import { TErrorMessage } from '../../interfaces/ICommonComponents'

export class ResponseError extends Error {
  private readonly messageCode: TErrorMessage

  public constructor(messageCode: TErrorMessage) {
    super()
    this.messageCode = messageCode

    Object.setPrototypeOf(this, ResponseError.prototype)
  }

  public getMessageCode = () => this.messageCode

  public getMessageIdentifier = () =>
    `error.response.${this.messageCode}` as keyof typeof messages

  public hasMessageCode = (messageCode: string) =>
    this.messageCode === messageCode
}
