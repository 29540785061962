import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { format, utcToZonedTime } from 'date-fns-tz'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import {
  CURRENT_DAY_DATETIME_FORMAT,
  INITIAL_OFFSET,
  STATUS,
} from '../../enums/common'
import Loader from '../loader/Loader'
import Alerts from '../alerts/Alerts'
import { WEEK_DAYS_LIST } from '../../constants/userData'
import { ROUTES } from '../../enums/routes'
import { AppointmentsList } from './AppointmentsList'
import { ICurrentDayAppointment } from '../../interfaces/IAppointments'

export const CurrentDayAppointments: FC<ICurrentDayAppointment> = ({
  data,
  status,
  message,
}) => {
  const currentDate = new Date()
  const utcCurrentDate = utcToZonedTime(currentDate, 'UTC')
  const currentDayName = WEEK_DAYS_LIST[currentDate.getDay() - 1]

  return (
    <section className="dayActivities withBackgroundColorDefault hasBorderRadius px15 py15 mb2">
      <DynamicFormattedMessage
        id="todayAppointments.title.label"
        tag="h3"
        className="textMd textPrimary fontRegular mb1"
      />
      <div className="mb15 textCustomSm textSecondary fontRegular">
        <DynamicFormattedMessage id={`day.${currentDayName}.label`} />
        <span>, {format(utcCurrentDate, CURRENT_DAY_DATETIME_FORMAT)}</span>
      </div>
      <Alerts status={status} message={message} />
      {status && status === STATUS.PENDING && <Loader />}
      <AppointmentsList data={data} status={status} />
      <div className="dayActivitiesCtaWrapper dFlex justifyContentBetween">
        <DynamicFormattedMessage
          id="userAction.cta.label.newAppointment"
          className="btn btnPrimary px15 py15 submitAppointment linkUnstyled"
          data-qa="submitNewRandezVous"
          tag={Link}
          to={{
            pathname: ROUTES.newAppointmentAgenda,
            state: {
              offset: INITIAL_OFFSET,
              prevLocation: window.location.pathname,
            },
          }}
          type="submit"
        />
      </div>
    </section>
  )
}
