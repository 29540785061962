import React, { FC } from 'react'
import { IClientInfo } from '../../../interfaces/IClientDetails'
import ClientCipCode from '../../clientCipcode/ClientCipcode'

const ClientInfo: FC<IClientInfo> = ({ name, code }) => {
  return (
    <>
      {name && ` - ${name} `}
      {code && (
        <ClientCipCode
          isVisible={true}
          cipCode={code}
          hasParenthesis={[true, true]}
        />
      )}
    </>
  )
}

export default ClientInfo
