import React, { FC } from 'react'
import { IVisitReportMission } from '../../../../interfaces/IVisitReports'
import { VisitReportRotationSection } from './VisitReportRotationSection'
import { VisitReportCompetitionSection } from './VisitReportCompetitionSection'
import { VisitReportMissionTitle } from './VisitReportMissionTitle'

export const VisitReportThirdPanelMission: FC<IVisitReportMission> = ({
  mission,
  missionData,
  defaultData,
  customClass = '',
}) => {
  const {
    rotations,
    prices,
    competition,
    compMultiselect,
    competitionPromoPlv,
    promo,
    months,
    presence,
    references,
  } = missionData

  return (
    <div className={`missionSection colMd6 mt0 mb15 ${customClass}`}>
      <VisitReportMissionTitle mission={mission} />
      <VisitReportRotationSection
        defaultData={defaultData}
        rotations={rotations}
        prices={prices}
      />
      <VisitReportCompetitionSection
        competition={competition}
        competitionPromoPlv={competitionPromoPlv}
        defaultData={defaultData}
        mission={mission}
        compMultiselect={compMultiselect}
        promo={promo}
        months={months}
        presence={presence}
        references={references}
      />
    </div>
  )
}
