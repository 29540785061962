import L, { Icon } from 'leaflet'
import { TClientDetailsItem } from '../../interfaces/IClientDetails'
import { useMapMarker } from '../../hooks/map/UseMapMarker'

export const DynamicMapMarker: (client: TClientDetailsItem) => Icon = (
  client
) => {
  const { markerType } = useMapMarker(client)

  return new L.Icon({
    iconUrl: markerType,
    iconRetinaUrl: markerType,
    popupAnchor: [0, -10],
    iconSize: [40, 94],
    iconAnchor: [20, 47],
    className: 'leafletIconPharmacy',
  })
}
