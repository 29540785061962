import React, { FC } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { TabPanel } from 'react-tabs'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import { CustomTabs } from '../components/common/ui/CustomTabs'
import { CustomTable } from '../components/common/ui/tables/CustomTable'
import { HistoryRecapPanel } from '../components/clientHistory/HistoryRecapPanel'
import DynamicFormattedMessage from '../components/common/ui/DynamicFormattedMessage'
import { HISTORY_TABS } from '../constants/tabs'
import {
  ENGAGEMENTS_HISTORY_TABLE_HEADERS,
  ORDER_HISTORY_TABLE_HEADERS,
} from '../constants/tables'
import { useLoadData } from '../hooks/UseLoadData'
import {
  getClientEngagementsHistory,
  getClientOrderHistory,
} from '../services/historyService'
import { STATUS } from '../enums/common'
import Loader from '../components/loader/Loader'
import { getClientHistoryValue } from '../store/selectors/clientHistorySelectors'
import { IEngagementHistory, IOrderHistory } from '../interfaces/IHistory'
import { ROUTES } from '../enums/routes'
import Alerts from '../components/alerts/Alerts'
import { useClientsDetails } from '../hooks/clientDetails/UseClientDetails'
import ClientInfo from '../components/common/ui/ClientInfo'
import { StickyWrapper } from '../components/common/ui/StickyWrapper'

const History: FC = () => {
  const { clientId } = useParams()

  const orderHistory = useLoadData(() => getClientOrderHistory(clientId), {
    dependencies: [clientId],
    fetchFromRedux: true,
    reduxSelector: getClientHistoryValue,
    reduxStorePath: `${clientId?.toString()}.ordersHistory`,
  })
  const engagementHistory = useLoadData(
    () => getClientEngagementsHistory(clientId),
    {
      dependencies: [clientId],
      fetchFromRedux: true,
      reduxSelector: getClientHistoryValue,
      reduxStorePath: `${clientId?.toString()}.engagementsHistory`,
    }
  )
  const { name, cipCode } = useClientsDetails(clientId)?.clientDetailsData

  return (
    <section className="pageContentWrapper historyPage pl75">
      <div className="px3 py3">
        <PageTitleSection
          backRoute={
            !window.history.state
              ? orderHistory.status !== STATUS.DANGER
                ? generatePath(ROUTES.clientDetails, {
                    clientId,
                  })
                : ROUTES.dashboard
              : ''
          }
        />
        <StickyWrapper>
          <Alerts status={orderHistory.status} message={orderHistory.message} />
        </StickyWrapper>
        <section className="pageTitleWrapper mb2">
          <h1 className="pageTitle">
            <DynamicFormattedMessage tag="span" id="page.history.title.label" />
            <ClientInfo name={name} code={cipCode} />
          </h1>
        </section>

        <div className="tabsWrapper tabsHistory withBackgroundColorDefault hasBorderRadius">
          <CustomTabs tabList={HISTORY_TABS}>
            <div className="pb2 px2">
              <TabPanel>
                <HistoryRecapPanel clientId={clientId} />
              </TabPanel>
              <TabPanel>
                <CustomTable
                  tableHeaders={ORDER_HISTORY_TABLE_HEADERS}
                  data={orderHistory.data as IOrderHistory[]}
                />
                {orderHistory.status &&
                  orderHistory.status === STATUS.PENDING && <Loader />}
              </TabPanel>
              <TabPanel>
                <CustomTable
                  tableHeaders={ENGAGEMENTS_HISTORY_TABLE_HEADERS}
                  data={engagementHistory.data as IEngagementHistory[]}
                  customClass="engagementTable"
                />
                {engagementHistory.status &&
                  engagementHistory.status === STATUS.PENDING && <Loader />}
              </TabPanel>
            </div>
          </CustomTabs>
        </div>
      </div>
    </section>
  )
}

export default History
