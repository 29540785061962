import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TClientDetailsItem } from '../../interfaces/IClientDetails'

const clientListReducer = createSlice({
  name: 'clientListReducer',
  initialState: [] as TClientDetailsItem[],
  reducers: {
    setClientList: (state, { payload }: PayloadAction<TClientDetailsItem[]>) =>
      payload,
  },
})

export const clientListActions = clientListReducer.actions

export default clientListReducer.reducer
