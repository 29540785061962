import { IRequest } from '../interfaces/IRequest'
import { api, getAuthHeader } from '../utils/api'
import { IVisitReport } from '../interfaces/IVisitReports'

export function getClientVisitReport(
  reportId: string
): Promise<{ body: IVisitReport }> {
  const params: IRequest = {
    endpoint: `/visit-reports/${reportId}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  return api(params)
}
