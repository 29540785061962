import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { IAccountPortfolioData } from '../../interfaces/IClientDetails'
import { getClientsListService } from '../../services/getClientsListService'
import { IFilters } from '../../interfaces/IFilters'
import { GEOLOCATION, ITEMS_PER_PAGE, STATUS } from '../../enums/common'
import { CLIENT_SOURCE } from '../../constants/userData'
import { handleError } from '../../utils/api'
import {
  getGeolocationCurrentPosition,
  setGeolocationToLocalStorage,
} from '../../utils/filters'
import { useGeolocationPermissions } from '../UseGeolocationPermissions'
import { clientsFiltersActions } from '../../store/reducers/filtersReducer'

/**
 * Used to fetch the clients.
 */
export const useClientsList: (
  filters: IFilters,
  offset: number,
  waitToCall: boolean,
  size?: number,
  source?: CLIENT_SOURCE
) => IAccountPortfolioData = (
  filters,
  offset,
  waitToCall,
  size = ITEMS_PER_PAGE,
  source
) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [state, setState] = useState<IAccountPortfolioData>({
    items: 0,
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
    clients: [],
  })

  const { clients, items, status, message } = state

  const {
    locationPermissionGranted,
    handlePermissions,
  } = useGeolocationPermissions()

  useEffect(() => {
    if (!filters.range) return

    handlePermissions()
  }, [filters.range, handlePermissions])

  useEffect(() => {
    if (localStorage.getItem(GEOLOCATION) === null) {
      getGeolocationCurrentPosition(setGeolocationToLocalStorage).then(() => {
        if (!locationPermissionGranted) return

        window.location.reload()
      })
    }
  }, [locationPermissionGranted])

  useEffect(() => {
    if (waitToCall) return

    const getClientDetailsList = async () => {
      setState({
        items: 0,
        status: STATUS.PENDING,
        message: '',
        messageCode: '',
        clients: [],
      })

      try {
        const {
          body: { data, total },
        } = await getClientsListService(filters, offset, size, source)

        setState((prevState) => {
          return {
            ...prevState,
            status: STATUS.SUCCESS,
            items: total,
            clients: data,
          }
        })
      } catch (error) {
        const handledError = handleError(error, intl)
        setState((prevState) => {
          return {
            ...prevState,
            items: 0,
            ...handledError,
          }
        })
      }
    }

    dispatch(clientsFiltersActions.setLoadedFiltersFromQuery(true))
    getClientDetailsList()
  }, [filters, offset, size, source, intl, waitToCall, dispatch])

  return { clients, items, status, message }
}
