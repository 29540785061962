import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import {
  IDestructionCertificateNewProductForm,
  IDestructionCertificateProduct,
} from '../../../../interfaces/IContracts'
import Button from '../../../common/ui/Button'
import ProductsTableRow from './ProductsTableRow'
import { DD_MM_YYYY_DATE_FORMAT } from '../../../../enums/common'
import { formatDate } from '../../../../utils/helpers'
import TextInput from '../../../form/TextInput'

const ProductsTable: FC<{
  products: IDestructionCertificateProduct[]
  setProducts: Dispatch<SetStateAction<IDestructionCertificateProduct[]>>
  formProductsCount: number
}> = ({ products, setProducts, formProductsCount }) => {
  const intl = useIntl()
  const { clearErrors, register, errors, unregister } = useFormContext()
  const [newProductHtml, setNewProductHtml] = useState<
    IDestructionCertificateNewProductForm[]
  >([])
  const [lastIndex, setLastIndex] = useState<number>(0)

  const comments = useMemo(
    () =>
      products.map((product, key) => (
        <p key={`${key.toString()}--comment`}>{product.comment}</p>
      )),
    [products]
  )

  const removeProductFormRow = useCallback(
    (index: number) => {
      unregister([
        `newProduct[${index}].cipCode`,
        `newProduct[${index}].designation`,
        `newProduct[${index}].quantity`,
        `newProduct[${index}].lot`,
        `newProduct[${index}].comment`,
      ])
      clearErrors([
        `newProduct[${index}].cipCode`,
        `newProduct[${index}].designation`,
        `newProduct[${index}].quantity`,
        `newProduct[${index}].lot`,
        `newProduct[${index}].comment`,
      ])
      setNewProductHtml((prevState) =>
        prevState.filter((item) => item.index !== index)
      )
    },
    [clearErrors, unregister]
  )

  const updateProductsList = useCallback(
    (product: IDestructionCertificateProduct, index: number) => {
      setProducts((prevState) => [...prevState, product])
      removeProductFormRow(index)
    },
    [removeProductFormRow, setProducts]
  )

  useEffect(() => {
    if (newProductHtml.length || products.length) return

    setNewProductHtml((prevState) => [
      ...prevState,
      {
        index: 0,
        element: (
          <ProductsTableRow
            key="productsTableRow0"
            index={0}
            updateProductsList={updateProductsList}
            deleteFormRow={removeProductFormRow}
          />
        ),
      },
    ])
    clearErrors([
      `newProduct[0].cipCode`,
      `newProduct[0].designation`,
      `newProduct[0].quantity`,
      `newProduct[0].lot`,
      `newProduct[0].comment`,
    ])
  }, [
    updateProductsList,
    newProductHtml,
    removeProductFormRow,
    clearErrors,
    products,
  ])

  const addNewProductSectionItem = useCallback(() => {
    setLastIndex(lastIndex + 1)
    setNewProductHtml((prevState) => [
      ...prevState,
      {
        index: lastIndex + 1,
        element: (
          <ProductsTableRow
            key={`productsTableRow${lastIndex + 1}`}
            index={lastIndex + 1}
            updateProductsList={updateProductsList}
            deleteFormRow={removeProductFormRow}
          />
        ),
      },
    ])
  }, [lastIndex, updateProductsList, removeProductFormRow])

  const deleteProduct = (productIndex: number) => {
    setProducts((prevState) =>
      prevState.filter((item, index) => index !== productIndex)
    )
    clearErrors([
      `newProduct[${productIndex}].cipCode`,
      `newProduct[${productIndex}].designation`,
      `newProduct[${productIndex}].quantity`,
      `newProduct[${productIndex}].lot`,
      `newProduct[${productIndex}].comment`,
    ])
  }

  const lastItem = useMemo(() => products.length - 1, [products])

  return (
    <>
      <table className="textPrimary">
        <thead>
          <tr key="rowHeader">
            <td key="rowHeaderIndex">#</td>
            <td className="colCipCode" key="rowHeaderCipCode">
              {intl.formatMessage({
                id: 'form.destructionCertificate.products.table.header.cipCode',
              })}
            </td>
            <td className="colDesignation" key="rowHeaderDesignation">
              {intl.formatMessage({
                id:
                  'form.destructionCertificate.products.table.header.designation',
              })}
            </td>
            <td className="colQuantity" key="rowHeaderQuantity">
              {intl.formatMessage({
                id:
                  'form.destructionCertificate.products.table.header.quantity',
              })}
            </td>
            <td className="colLot" key="rowHeaderLot">
              {intl.formatMessage({
                id: 'form.destructionCertificate.products.table.header.lot',
              })}
            </td>
            <td className="colExpDate" key="rowHeaderExpirationDate">
              {intl.formatMessage({
                id:
                  'form.destructionCertificate.products.table.header.expirationDate',
              })}
            </td>
            <td className="colComment" key="rowHeaderComment">
              {intl.formatMessage({
                id: 'form.destructionCertificate.products.table.header.comment',
              })}
            </td>
            <td className="colActions" key="rowHeaderActions">
              {intl.formatMessage({
                id: 'form.destructionCertificate.products.table.header.actions',
              })}
            </td>
          </tr>
        </thead>
        <tbody>
          {!!products.length &&
            products.map((product, index) => (
              <tr key={`row${index.toString()}`}>
                <td key={`row${index.toString()}Index`}>{index + 1}</td>
                <td key={`row${index.toString()}CipCode`}>{product.cipCode}</td>
                <td key={`row${index.toString()}Designation`}>
                  {product.designation}
                </td>
                <td key={`row${index.toString()}Quantity`}>
                  {product.quantity}
                </td>
                <td key={`row${index.toString()}Lot`}>{product.lot}</td>
                <td key={`row${index.toString()}ExpirationDate`}>
                  {formatDate(
                    product.expirationDate && new Date(product.expirationDate),
                    DD_MM_YYYY_DATE_FORMAT,
                    ''
                  )}
                </td>
                {index === 0 && <td rowSpan={products.length}>{comments}</td>}
                <td key={`row${index.toString()}Actions`}>
                  <div className="dInlineFlex">
                    {index === lastItem && (
                      <Button
                        className="btn btnAdd px2 py2"
                        onClick={addNewProductSectionItem}
                      />
                    )}
                    <Button
                      className="btn btnDelete px2 py2 ml05"
                      onClick={() => deleteProduct(index)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          {!!newProductHtml && newProductHtml.map((item) => item.element)}
        </tbody>
      </table>
      <TextInput
        name="formProductsCount"
        id="formProductsCount"
        customClass="colMd6 pl0"
        type="hidden"
        defaultValue={formProductsCount ? formProductsCount.toString() : ''}
        register={register({
          required: 'formProductsCount',
        })}
        error={errors.formProductsCount}
        placeholder="firstName"
        hasLabel={false}
      />
    </>
  )
}

export default ProductsTable
