import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { sharedDataActions } from '../store/reducers/sharedDataReducer'
import { ROUTES } from '../enums/routes'

export const usePageBlur = (path: string, shouldBlurPage: boolean) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
    dispatch(sharedDataActions.setIsPageBlurred(false))
    history.push(ROUTES.dashboard)
  }

  useEffect(() => {
    setIsModalOpen(shouldBlurPage)
    dispatch(sharedDataActions.setIsPageBlurred(shouldBlurPage))
  }, [shouldBlurPage, setIsModalOpen, dispatch])

  return { isModalOpen, closeModal }
}
