import React, { FC, useState } from 'react'
import { ITooltip } from '../../../interfaces/ICommonComponents'
import DynamicFormattedMessage from '../ui/DynamicFormattedMessage'
import { TooltipTitle } from './TooltipTitle'
import { ScreenOverlay } from './ScreenOverlay'

export const Tooltip: FC<ITooltip> = ({ children, title = '' }) => {
  const [active, setActive] = useState(false)

  const hideTooltip = () => {
    setActive(false)
  }

  return (
    <div className="relative">
      <TooltipTitle setActive={setActive} title={title} />
      {active && (
        <>
          <div className="tooltipTip withBackgroundColorDefault px15 py15">
            {children || (
              <DynamicFormattedMessage
                id="tooltip.defaultContent.label"
                tag="p"
                className="textPrimary"
              />
            )}
          </div>
          <ScreenOverlay active={active} handleOnClick={hideTooltip} />
        </>
      )}
    </div>
  )
}
