import React, { ReactElement } from 'react'
import {
  Control,
  Controller,
  FieldError,
  RegisterOptions,
} from 'react-hook-form'
import ReactSelect, { ValueType } from 'react-select'
import ErrorMessage from './ErrorMessage'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import {
  IMultipleSelectOption,
  ISelectOptionGroup,
} from '../../interfaces/IForms'
import { useMultipleSelect } from '../../hooks/form/UseMultipleSelect'

interface IBasicProps {
  id: string
  error?: FieldError
  options: IMultipleSelectOption[] | ISelectOptionGroup[]
  control: Control<Record<string, any>>
  rules?: RegisterOptions
  labelValues?: {}
  hideMultiRemove?: boolean
  singleValueOverride?: IMultipleSelectOption
  showLabel?: boolean
  parentClassName?: string
  className?: string
  noOptionsMessage?: string
  onSelectChange?: (values: ValueType<IMultipleSelectOption[], boolean>) => void
  closeMenuOnSelect?: boolean
  disabled?: boolean
  inModalContainer?: boolean
}

type TProps = IBasicProps &
  (
    | { isMulti?: true; defaultValue?: IMultipleSelectOption[] | null }
    | { isMulti: false; defaultValue?: IMultipleSelectOption | null }
  )

function MultipleSelect({
  id,
  error,
  options,
  control,
  rules,
  defaultValue,
  labelValues,
  isMulti = true,
  hideMultiRemove = true,
  singleValueOverride,
  showLabel = true,
  className,
  parentClassName,
  noOptionsMessage = 'form.field.noOption.label',
  onSelectChange = () => {},
  closeMenuOnSelect = false,
  disabled = false,
  inModalContainer = false,
}: TProps): ReactElement {
  const {
    intl,
    handleOnChange,
    containerRef,
    additionalProps,
  } = useMultipleSelect(
    isMulti,
    inModalContainer,
    singleValueOverride,
    onSelectChange
  )

  return (
    <div
      ref={containerRef}
      className={`inputGroup multipleSelect ${parentClassName}`}
    >
      {showLabel && (
        <DynamicFormattedMessage
          id={`form.field.${id}.label`}
          className="inputLabel"
          values={labelValues}
        />
      )}
      <Controller
        name={id}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={(props) => (
          <ReactSelect
            {...props}
            defaultValue={defaultValue}
            isMulti={isMulti}
            options={options}
            isClearable={true}
            closeMenuOnSelect={closeMenuOnSelect}
            placeholder={intl.formatMessage({
              id: `form.option.select`,
            })}
            className={`${className || 'customSearchInput'}`}
            classNamePrefix="selectGroup"
            onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
            onChange={handleOnChange(props)}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
              ...(hideMultiRemove && { MultiValueRemove: () => null }),
            }}
            noOptionsMessage={() =>
              intl.formatMessage({ id: noOptionsMessage })
            }
            isDisabled={disabled}
            menuPlacement="auto"
            {...additionalProps}
          />
        )}
      />
      <ErrorMessage error={error} />
    </div>
  )
}

export default MultipleSelect
