import React, { FC } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import DynamicFormattedMessage from '../components/common/ui/DynamicFormattedMessage'
import { DetailItem } from '../components/contracts/DetailItem'
import { ICustomContract } from '../interfaces/IContracts'
import { ENGAGEMENTS_PER_PAGE, INITIAL_OFFSET, STATUS } from '../enums/common'
import Loader from '../components/loader/Loader'
import { PlaceholderMessage } from '../components/common/ui/PlaceholderMessage'
import Alerts from '../components/alerts/Alerts'
import { ROUTES } from '../enums/routes'
import { handleFileDownload } from '../utils/helpers'
import { ConfirmationMessageModal } from '../components/modal/ConfirmationMessageModal'
import ModalContainer from '../components/modal/Modal'
import { ErrorMessageModal } from '../components/modal/ErrorMessageModal'
import { InfoMessageAlerts } from '../components/alerts/InfoMessageAlerts'
import { FileToDownload } from '../components/contracts/FileToDownload'
import { PaginationWrapper } from './PaginationWrapper'
import PageTitleClientDetailsSection from '../components/common/ui/PageTitleClientDetailsSection'
import { StickyWrapper } from '../components/common/ui/StickyWrapper'
import { useCustomContract } from '../hooks/clientContracts/UseCustomContract'

const CustomContractPage: FC = () => {
  const {
    clientId,
    contractId,
    contractLabel,
    isHistoryPage,
    emailSuccess,
    currentPage,
    controlDelete,
    controlDownload,
    isFormEmailMessageVisible,
    availableFormEmails,
    setControlDelete,
    deleteResponse,
    requestDelete,
    status,
    message,
    changePage,
    contracts,
    setControlDownload,
    isDownloadModalOpen,
    url,
    fileName,
    downloadFormState,
    setDownloadFormState,
    sendEmailState,
    setSendEmailState,
    availableEmails,
    setAvailableEmails,
    fileExtension,
    isModalOpen,
    isTimePassed,
    totalItemsCount,
    isPaginationVisible,
    mixedFormType,
    mixedFormTypePath,
    mixedFormTypeApiPath,
    mixedFormTypeNewFormRoute,
  } = useCustomContract()

  return (
    <section className="pageContentWrapper engagementsPage pl75">
      <div className="px3 py3">
        <PageTitleSection
          backRoute={
            !window.history.state ||
            window.history.state?.state?.offset === INITIAL_OFFSET
              ? clientId
                ? generatePath(ROUTES.clientForms, {
                    clientId,
                  })
                : ROUTES.forms
              : ''
          }
        />
        <StickyWrapper>
          <Alerts
            status={deleteResponse ? deleteResponse.status : status}
            message={deleteResponse.message || message}
          />
          <Alerts status={status} message={message} />
          <Alerts
            status={downloadFormState.status}
            message={downloadFormState.message}
          />
          {contractId && (
            <Alerts
              status={sendEmailState.status}
              message={sendEmailState.message}
            />
          )}
          {contractId && sendEmailState.status === STATUS.SUCCESS && (
            <InfoMessageAlerts
              message={`form.file.${sendEmailState.status}`}
              type={sendEmailState.status}
              data={availableEmails}
              intlAdditionalData={fileExtension}
              flash={true}
            />
          )}
          {isFormEmailMessageVisible && (
            <InfoMessageAlerts
              message={emailSuccess ? `form.file.success` : `form.file.danger`}
              type={emailSuccess ? 'success' : 'danger'}
              data={emailSuccess ? availableFormEmails : []}
              intlAdditionalData={fileExtension}
              flash={true}
            />
          )}
        </StickyWrapper>
        <section className="pageTitleWrapper row mx0 mb25 justifyContentBetween">
          <h1 className="pageTitle">
            {isHistoryPage && (
              <DynamicFormattedMessage
                tag="span"
                id="page.engagementsHistory.title.label"
                className="mb1 mb0sm"
              />
            )}
            <DynamicFormattedMessage
              tag="span"
              id={
                contractId
                  ? `page.contracts.title.${contractLabel}`
                  : `page.${mixedFormTypePath}.title.label`
              }
              className="mb1 mb0sm"
            />
            {clientId && <PageTitleClientDetailsSection clientId={clientId} />}
          </h1>
          {clientId && !isHistoryPage && (
            <DynamicFormattedMessage
              id={
                contractId
                  ? 'cta.addContract.label'
                  : 'cta.addReclamation.label'
              }
              tag={Link}
              className="btn btnPrimary px15 py15 linkUnstyled mt1 mt0md"
              data-qa={contractId ? 'addContract' : 'addReclamation'}
              to={generatePath(
                contractId ? ROUTES.newEngagement : mixedFormTypeNewFormRoute!,
                {
                  clientId,
                  contractId,
                }
              )}
            />
          )}
        </section>
        {status && status === STATUS.PENDING && <Loader />}
        <PlaceholderMessage status={status} data={contracts} />
        <div className="CustomPageItemsWrapper">
          {!!contracts?.length &&
            contracts.map((item: ICustomContract, index) => {
              const props = {
                ...item,
                key: item.id,
                setControlDelete,
                setControlDownload,
                downloadFormState,
                setDownloadFormState,
                isDownloadModalOpen,
                isHistoryPage,
                isMixedForm: !!mixedFormType,
                mixedFormType,
                mixedFormTypeApiPath,
              }

              if (contractId) {
                props.setSendEmailState = setSendEmailState
                props.setAvailableEmails = setAvailableEmails
                props.contractLabel = contractLabel
              }

              return (
                <div key={`item-${index + 1}`}>
                  <DetailItem {...props} />
                </div>
              )
            })}
        </div>
      </div>
      {isPaginationVisible && (
        <div className="pb25">
          <PaginationWrapper
            currentPage={currentPage}
            totalItemsCount={totalItemsCount}
            onChange={changePage}
            itemsPerPage={ENGAGEMENTS_PER_PAGE}
          />
        </div>
      )}
      <ModalContainer isOpen={isModalOpen}>
        <ConfirmationMessageModal
          onConfirm={() => {
            requestDelete()
            setControlDelete({
              ...controlDelete,
              isModalOpen: false,
            })
          }}
          backButton={() =>
            setControlDelete({
              ...controlDelete,
              isModalOpen: false,
            })
          }
          messages={{
            cancel: 'cancelDelete',
            confirm: 'confirmDelete',
            title: 'titleDeleteContract',
            content: 'contentDeleteContract',
          }}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={isDownloadModalOpen}
        portalClassName="ReactModalPortal withChildren"
      >
        <ConfirmationMessageModal
          onConfirm={() => {
            setControlDownload({
              ...controlDownload,
              isDownloadModalOpen: false,
            })
            handleFileDownload(url, fileName)
          }}
          backButton={() =>
            setControlDownload({
              ...controlDownload,
              isDownloadModalOpen: false,
            })
          }
          messages={{
            cancel: 'cancelDownload',
            confirm: 'confirmDownload',
            title: 'titleDownload',
            content: 'noContent',
          }}
        >
          <FileToDownload url={url} fileName={fileName} />
        </ConfirmationMessageModal>
      </ModalContainer>
      <ModalContainer
        isOpen={isTimePassed}
        portalClassName="errorMessageModalWrapper"
      >
        <ErrorMessageModal
          closeModal={() => {
            setControlDelete({
              ...controlDelete,
              isTimePassed: false,
            })
          }}
          message={isTimePassed ? 'deleteContracts' : 'general'}
        />
      </ModalContainer>
    </section>
  )
}

export default CustomContractPage
