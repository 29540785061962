import React, { FC, useEffect, useState } from 'react'
import { iconDownload } from '../../../assets/images/images'
import Loader from '../../loader/Loader'
import Button from './Button'
import { ICustomButton } from '../../../interfaces/ICommonComponents'

export const DownloadButton: FC<ICustomButton> = ({
  disabled,
  onClick,
  loading,
}) => {
  const [showLoader, setShowLoader] = useState(false)

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowLoader(true)
    onClick(event)
  }

  useEffect(() => {
    setShowLoader(!!loading)
  }, [loading])

  return (
    <Button
      className={`btn btnDownload btnIcon ml2 ${
        showLoader || disabled ? 'btnDisabled' : ''
      }`}
      onClick={handleOnClick}
      disabled={showLoader || disabled}
    >
      {!showLoader ? <img src={iconDownload} alt="icon printer" /> : <Loader />}
    </Button>
  )
}
