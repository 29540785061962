import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IContractData,
  IContractHistoryData,
  IContractHistoryItem,
} from '../../interfaces/IContracts'
import { TStatus } from '../../interfaces/INavigation'
import { sharedDataSelectors } from '../../store/selectors'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'
import { STATUS } from '../../enums/common'

export const useClientForms = (data: IContractHistoryData, status: TStatus) => {
  const dispatch = useDispatch()
  const selectedYear = useSelector(
    sharedDataSelectors.getContractsSelectedYearValue
  )
  const navClick = useSelector(sharedDataSelectors.getNavClick)

  const [contractTypeList, setContractTypeList] = useState<IContractData[]>([])

  const { currentYear, history } = data
  const { year, listContractTypes } = currentYear ?? {
    year: undefined,
    listContractTypes: [],
  }

  const years = useMemo(() => {
    const listYears = history
      ? history.map((item: IContractHistoryItem) => item.year)
      : []

    return listYears
      .sort()
      .reverse()
      .map((key: number) => ({ name: key.toString(), value: key.toString() }))
  }, [history])

  useEffect(() => {
    if (selectedYear) {
      const historyItem = history?.find(
        (item: IContractHistoryItem) => item.year === selectedYear
      )
      const list = historyItem ? historyItem.listContractTypes : []
      setContractTypeList(list)
    }
  }, [history, selectedYear])

  const onSelectYearChange = (event: any) => {
    dispatch(sharedDataActions.setContractsSelectedYear(+event.target.value))
  }

  const showHistorySection = useMemo(
    () =>
      !!data?.history?.length &&
      status !== STATUS.PENDING &&
      history &&
      Object.keys(history).length > 0,
    [data, status, history]
  )

  useEffect(() => {
    if (navClick) {
      dispatch(sharedDataActions.setContractsSelectedYear(0))
      dispatch(sharedDataActions.setNavClick(false))
    }
  }, [navClick, dispatch])

  return {
    selectedYear,
    contractTypeList,
    year,
    listContractTypes,
    years,
    showHistorySection,
    onSelectYearChange,
  }
}
