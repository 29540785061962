import React, { FC } from 'react'
import { INavbarSubmenu } from '../../interfaces/INavigation'
import { NavbarSubmenuLink } from './NavbarSubmenuLink'

export const NavbarSubmenu: FC<INavbarSubmenu> = ({
  isMenuOpen,
  route,
  nodes,
  onNavbarLinkSubmenuClick,
}) => {
  return (
    <ul className={`navbarSubmenuItems ${isMenuOpen ? 'px2' : 'hidden'}`}>
      {!!nodes?.length &&
        nodes?.map(({ navName }) => {
          const props = {
            isMenuOpen,
            route,
            navName,
            onNavbarLinkSubmenuClick,
          }

          return <NavbarSubmenuLink key={navName} {...props} />
        })}
    </ul>
  )
}
