import { useSelector } from 'react-redux'
import React, { FC, useMemo } from 'react'
import { getJwtUserDetails, userIsAdmin } from '../../../utils/api'
import { sharedDataSelectors } from '../../../store/selectors'
import { ADMIN_USER, REGULAR_USER } from '../../../enums/common'
import DynamicFormattedMessage from './DynamicFormattedMessage'
import { getJwtUsername } from '../../../utils/user'
import { capitaliseString } from '../../../utils/helpers'

export const LoggedUser: FC = () => {
  const jwt = getJwtUserDetails()
  const userSavedData = useSelector(sharedDataSelectors.getUserSavedData)
  const userType = useMemo(
    () =>
      userSavedData?.selectedUser === userSavedData?.forUser
        ? REGULAR_USER
        : ADMIN_USER,
    [userSavedData]
  )

  return (
    <DynamicFormattedMessage
      id={`user.logged.${userType}.username`}
      values={{
        adminUsername: getJwtUsername(jwt),
        username: userSavedData?.username,
        role: userIsAdmin()
          ? capitaliseString(ADMIN_USER)
          : REGULAR_USER.toUpperCase(),
      }}
      className="textDefault textBold textCenter my2"
    />
  )
}
