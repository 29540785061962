import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { IRangeStepSection } from '../../interfaces/IForms'
import ErrorMessage from './ErrorMessage'
import RangeStepInput from './RangeStepInput'

const RangeStepSection: FC<IRangeStepSection> = ({ range }) => {
  const { setValue } = range

  return (
    <div className="rangeStepSection">
      <Controller
        name={range.id}
        control={range.control}
        defaultValue={range.defValue}
        rules={range.rules}
        render={({ onChange }) => {
          return (
            <RangeStepInput
              onChange={(value) => {
                onChange(value)
                setValue(value[0])
              }}
              name={range.name}
              minValue={range.minValue}
              maxValue={range.maxValue}
              customClass={range.customClass}
              step={range.step}
              defValue={range.defValue}
              hasLabel={range.hasLabel}
              outputUnit={range.outputUnit}
            />
          )
        }}
      />
      <ErrorMessage error={range.error} />
    </div>
  )
}

export default RangeStepSection
