import React, { FC, useMemo } from 'react'
import { IChartTitle } from '../../../interfaces/IDashboard'

export const ChartTitle: FC<IChartTitle> = ({ title, customClass = '' }) => {
  const chartTitle = useMemo(
    () =>
      typeof title === 'string'
        ? title
        : title.map((rowTitle) => (
            <span key={rowTitle} className="dBlock">
              {rowTitle}
            </span>
          )),
    [title]
  )

  return (
    <h4 className={`textCenter wordBreak mb1 ${customClass}`}>{chartTitle}</h4>
  )
}
