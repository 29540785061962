import React, { ChangeEvent, FC, ReactElement, useState, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import {
  IContractBrandAnnexe,
  IContractSection,
} from '../../interfaces/IContracts'
import SelectInput from '../form/SelectInput'
import TextInput from '../form/TextInput'
import {
  CONTRACT_BRANDS_ANNEXE,
  CONTRACT_CICATRIDINE_RULES,
  CONTRACT_COMPEED_RULES,
  CONTRACT_ELLAONE_RULES,
  CONTRACT_NUK_RULES,
  CONTRACT_REFS,
} from '../../constants/contracts'
import { CompeedRulesSection } from './rules/CompeedRulesSection'
import { RulesSection } from './rules/RulesSection'
import { CONTRACT_BRANDS, CONTRACT_FIELD_TYPE } from '../../enums/contracts'
import DynamicButtons from '../form/DynamicButtons'
import { getPercentValue } from '../../utils/contracts'
import { NON, OUI, RADIO_OPTIONS_LIST } from '../../constants/form'

export const Contract: FC<IContractSection> = ({ fields }) => {
  const { register, getValues, setValue, errors, watch } = useFormContext()
  const [displayRefError, setDisplayRefError] = useState(false)
  const watchFields = watch(['compeed', 'ellaone', 'cicatridine'])

  const brandDiscount = (name: string, unitsValue: number) => {
    switch (name) {
      case CONTRACT_BRANDS.NUK:
        return getPercentValue(unitsValue, CONTRACT_NUK_RULES)
      case CONTRACT_BRANDS.COMPEED:
        return getPercentValue(
          unitsValue,
          CONTRACT_COMPEED_RULES,
          +getValues('ref')
        )
      case CONTRACT_BRANDS.ELLAONE:
        return getPercentValue(unitsValue, CONTRACT_ELLAONE_RULES)
      case CONTRACT_BRANDS.CICATRIDINE:
        return getPercentValue(unitsValue, CONTRACT_CICATRIDINE_RULES)
      default:
        return 0
    }
  }

  const checkBrandAnnexeDisplay = (name: string, unitsValue: number) => {
    const brandAnnexes =
      CONTRACT_BRANDS_ANNEXE[name as keyof IContractBrandAnnexe]

    brandAnnexes?.forEach((annexe) =>
      setValue(annexe.name, unitsValue >= annexe.displayValue ? OUI : NON)
    )
  }

  const handleRefsOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const refValue = +e.target.value
    const unitsValue = +getValues(CONTRACT_BRANDS.COMPEED)
    if (unitsValue) {
      const discountValue = getPercentValue(
        unitsValue,
        CONTRACT_COMPEED_RULES,
        refValue
      )

      setValue(`${CONTRACT_BRANDS.COMPEED}Discount`, `${discountValue || 0}%`)

      setDisplayRefError(discountValue === null)
    }
  }

  const handleUnitsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unitsValue = +e.target.value
    const discountValue = brandDiscount(e.target.name, unitsValue)

    setValue(`${e.target.name}Discount`, `${discountValue || 0}%`)

    if (e.target.name === CONTRACT_BRANDS.COMPEED) {
      setDisplayRefError(discountValue === null)
    }

    checkBrandAnnexeDisplay(e.target.name, unitsValue)
  }

  const BrandRulesSection: { [key: string]: ReactElement } = {
    [CONTRACT_BRANDS.NUK]: <RulesSection rules={CONTRACT_NUK_RULES} />,
    [CONTRACT_BRANDS.COMPEED]: (
      <CompeedRulesSection rules={CONTRACT_COMPEED_RULES} />
    ),
    [CONTRACT_BRANDS.ELLAONE]: <RulesSection rules={CONTRACT_ELLAONE_RULES} />,
    [CONTRACT_BRANDS.CICATRIDINE]: (
      <RulesSection rules={CONTRACT_CICATRIDINE_RULES} hideGreaterThan={true} />
    ),
  }

  const isMandatory = useMemo(() => {
    return !(
      !!watchFields.compeed ||
      !!watchFields.ellaone ||
      !!watchFields.cicatridine
    )
  }, [watchFields.compeed, watchFields.ellaone, watchFields.cicatridine])

  return (
    <div className="col12">
      {fields.map(({ id, label, name, mandatory, type }: any) => (
        <div key={id}>
          {type === CONTRACT_FIELD_TYPE.UNITS && (
            <div
              className={`brandSection pb25 brandSection${name.toUpperCase()}`}
            >
              <DynamicFormattedMessage
                id={`form.contract.${name}.title`}
                tag="h3"
                className="wFull textPrimary textUppercase mb3 pt05"
              />
              {BrandRulesSection[name as string]}
              <div className="row mt2">
                {name === CONTRACT_BRANDS.COMPEED && (
                  <div className="colXl3 mb05">
                    <DynamicFormattedMessage
                      id="form.contract.ref.title.label"
                      tag="h4"
                      className="textPrimary fontRegular pb05"
                    />
                    <SelectInput
                      name="ref"
                      id="ref"
                      register={register({
                        required: true,
                      })}
                      renderLabel={false}
                      options={CONTRACT_REFS}
                      error={errors.ref}
                      defaultValue={CONTRACT_REFS[0].value}
                      defaultPlaceholder={true}
                      onChange={handleRefsOnChange}
                    />
                  </div>
                )}
                <div
                  className={`${
                    name === CONTRACT_BRANDS.COMPEED ? 'colXl4' : 'colXl7'
                  }`}
                >
                  <h4 className="textPrimary fontRegular pb05">{`${label}${
                    mandatory ? '*' : ''
                  }`}</h4>
                  <TextInput
                    name={name}
                    id={id}
                    type="number"
                    register={register({
                      required: isMandatory,
                      pattern: {
                        value: /^[0-9]*$/,
                        message: 'number',
                      },
                    })}
                    placeholder="typeIn"
                    error={errors[name]}
                    hasLabel={false}
                    onChange={handleUnitsOnChange}
                  />
                </div>
                <div className="colXl5 pl2">
                  <DynamicFormattedMessage
                    id="form.contract.percent.title.label"
                    className="textPrimary"
                  />
                  <TextInput
                    name={`${name}Discount`}
                    id={`${name}Discount`}
                    defaultValue="0%"
                    register={register}
                    inputClass="textSecondaryDarker textMd px0 noBorder fontBold"
                    disabled={true}
                    hasLabel={false}
                  />
                </div>
              </div>
              {name === CONTRACT_BRANDS.COMPEED && (
                <>
                  <TextInput
                    name="validateUnits"
                    id="validateUnits"
                    register={register({
                      required: displayRefError,
                    })}
                    customClass="hidden"
                    disabled={true}
                    hasLabel={false}
                  />
                  {displayRefError && (
                    <div className="errorMessage">
                      <DynamicFormattedMessage
                        tag="span"
                        id="form.validation.validateUnits"
                        className="errorMessage"
                        values={{ ref: getValues('ref') }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {type === CONTRACT_FIELD_TYPE.ANNEXE && (
            <div className="row mx0">
              <div className="colSm6 pl0 py15 dFlex alignItemsCenter hasBorderBottom">
                <h4 className="textPrimary fontRegular pb05">{label}</h4>
              </div>
              <div className="colSm6 py15 dFlex justifyContentEnd hasBorderBottom">
                <DynamicButtons
                  optionsArray={RADIO_OPTIONS_LIST}
                  register={register}
                  name={name}
                  customClass="noWrap actionButtons"
                  defaultValue={NON}
                  hideLabel={true}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
