import { useEffect, useState } from 'react'
import { getFilteredAppointmentsTotal } from '../../services/appointmentsService'
import { IClientFilteredAppointmentsTotal } from '../../interfaces/IClientDetails'

/**
 * Used to fetch the total number of the filtered client reports
 */
export const useClientFilteredAppointmentsTotal = (clientId: number) => {
  const [
    clientFilteredAppointmentsTotal,
    setClientFilteredAppointmentsTotal,
  ] = useState<IClientFilteredAppointmentsTotal | null>(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getFilteredAppointmentsTotal(clientId)
        setClientFilteredAppointmentsTotal(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [clientId])

  return clientFilteredAppointmentsTotal
}
