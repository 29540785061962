import React, { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ROUTES } from './enums/routes'
import { PrivateRoute } from './components/routes/PrivateRoute'
import {
  AccountsPortfolio,
  Agenda,
  NewAppointmentAgenda,
  AppointmentPage,
  ClientDetails,
  Dashboard,
  History,
  Forms,
  Login,
  Map,
  ErrorPage,
  OfflineSynchro,
} from './pages'
import CustomContractPage from './pages/CustomContractPage'
import {
  ClientFile,
  NewVisitReport,
  ViewVisitReportVersioning,
  ViewAppointment,
} from './pages/clientPages'
import { NewContract, NewReclamation } from './pages/formsPages'
import DashboardCa from './pages/dashboard/DashboardCa'
import DashboardVisitsCoverage from './pages/dashboard/DashboardVisitsCoverage'
import { useAppHook } from './hooks/UseAppHook'
import NewDestructionCertificate from './pages/formsPages/NewDestructionCertificate'

const App: FC = () => {
  useAppHook()

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path={ROUTES.offlineSynchro}>
          <OfflineSynchro />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.newReclamation}>
          <NewReclamation />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.newDestructionCertificate}>
          <NewDestructionCertificate />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.newEngagement}>
          <NewContract />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.newAppointment}>
          <AppointmentPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.newVisitReport}>
          <NewVisitReport />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.viewVisitReport}>
          <ViewVisitReportVersioning />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.clientFile}>
          <ClientFile />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.clientDetails}>
          <ClientDetails />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.clientForms}>
          <Forms />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.forms}>
          <Forms />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.map}>
          <Map />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.accountsPortfolio}>
          <AccountsPortfolio />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.agenda}>
          <Agenda />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.newAppointmentAgenda}>
          <NewAppointmentAgenda />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.dashboard}>
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.dashboardCa}>
          <DashboardCa />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.dashboardVisitsCoverage}>
          <DashboardVisitsCoverage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.newAppointment}>
          <AppointmentPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.editAppointment}>
          <AppointmentPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.viewAppointment}>
          <ViewAppointment />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.newVisitReport}>
          <NewVisitReport />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.history}>
          <History />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.clientReclamations}>
          <CustomContractPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.clientDestructionCertificates}>
          <CustomContractPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.clientEngagements}>
          <CustomContractPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.clientEngagementsHistory}>
          <CustomContractPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.reclamations}>
          <CustomContractPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.destructionCertificates}>
          <CustomContractPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.engagements}>
          <CustomContractPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.engagementsHistory}>
          <CustomContractPage />
        </PrivateRoute>
        <Route path={ROUTES.login} exact component={Login} />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
