import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Dispatch, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import set from 'lodash.set'
import { generatePath, useHistory } from 'react-router-dom'
import { IReclamationForm } from '../interfaces/IContracts'
import {
  EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  INITIAL_ACTIVE_PAGE,
  INITIAL_OFFSET,
  STATUS,
} from '../enums/common'
import { TApiResponse } from '../interfaces/IClientDetails'
import {
  createNewReclamation,
  handleNewContractAddToIndexedDb,
} from '../services/contractsServices'
import { prepareReclamationRequest } from '../utils/contracts'
import { statusNetworkReducerActions } from '../store/reducers/statusNetworkReducer'
import { statusNetworkSelector } from '../store/selectors'
import { clientContractsActions } from '../store/reducers/clientContractsReducer'
import { API_PATHS } from '../enums/apiPaths'
import { ROUTES } from '../enums/routes'
import { handleError } from '../utils/api'
import { ResponseError } from '../models/errors/ResponseError'

const useNewReclamationFormHook = (
  clientId: string,
  clientName: string,
  cipCode: string,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  const history = useHistory()
  const intl = useIntl()
  const form = useForm<IReclamationForm>()
  const [formState, setFormState] = useState<TApiResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })

  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const returnToPreviousPage = () =>
    window.history.state
      ? window.history.back()
      : history.replace(
          generatePath(ROUTES.clientReclamations, {
            clientId,
          }),
          { offset: INITIAL_OFFSET, currentPage: INITIAL_ACTIVE_PAGE }
        )

  const onSubmit = async (values: any) => {
    setIsModalOpen(false)
    setFormState({ status: STATUS.PENDING, message: '' })
    const body: IReclamationForm = prepareReclamationRequest(values, clientId)
    try {
      await createNewReclamation(body)
      setFormState({ status: STATUS.SUCCESS, message: '' })
      return returnToPreviousPage()
    } catch (e) {
      if (!hasNetwork) {
        const timestamp = Date.now()
        dispatch(
          clientContractsActions.addReclamation(
            set({}, `${clientId}.reclamations`, [
              {
                id: '',
                clientName,
                cipCode,
                creationDate: timestamp,
                timestamp,
              },
            ])
          )
        )
        handleNewContractAddToIndexedDb(
          body,
          'new-contract-background-sync',
          timestamp,
          timestamp,
          API_PATHS.reclamations
        )
        dispatch(statusNetworkReducerActions.submitedForm())
        return returnToPreviousPage()
      }

      const handledError = handleError(e, intl)
      setFormState(handledError)

      if (
        e instanceof ResponseError &&
        e.hasMessageCode(EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE)
      ) {
        setIsModalOpen(false)
        setTimeout(() => {
          return returnToPreviousPage()
        }, 5000)
      }
    }
  }

  return { form, onSubmit, formState }
}

export default useNewReclamationFormHook
