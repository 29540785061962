import React, { FC } from 'react'
import { OFFLINE_SYNCHRO_HEADERS } from '../../constants/tables'
import { TOfflineSynchroTable } from '../../interfaces/IOffline'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

export const OfflineSynchroTable: FC<TOfflineSynchroTable> = ({
  tableHeaders = OFFLINE_SYNCHRO_HEADERS,
  data,
  customClass = '',
}) => {
  return (
    <>
      <table className={`customTable wFull ${customClass}`}>
        <thead className="customTableHeader">
          <tr>
            {tableHeaders?.map((headerName: any) => (
              <td key={headerName}>
                <DynamicFormattedMessage
                  id={`table.header.${headerName}.label`}
                  className="fontBold textUppercase textPrimary textCustomXs textLeft"
                />
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="customTableBody">
          {data?.length !== 0 &&
            (data as any[]).map((dataItem, index) => (
              <tr key={index.toString()}>
                <td className="textPrimary textCustomXs">{index + 1}</td>
                {Object.keys(dataItem).map((item) => (
                  <>
                    <td
                      key={item}
                      className="textUppercase textPrimary textCustomXs"
                    >
                      {dataItem[item] || '-'}
                    </td>
                  </>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}
