import { IStore } from '../index'

export const getNewVisitReportValues = ({ newVisitReportReducer }: IStore) => {
  const visitDate = JSON.parse(newVisitReportReducer.visitDate || '""')

  return {
    ...newVisitReportReducer,
    visitDate: visitDate ? (new Date(visitDate) as any) : '',
  }
}
